import callApi, {api} from "../api/callApi";
import {urlVessel, urlVesselEvent, urlVesselTypes} from "../api/v1";
import {store} from "../store/store";
import {IVessel} from "../varibles/interface";
import {updateUserSetting} from "../../pages/LoginPage/reducer";

export function getVesselTypesFetch() {
    return callApi(urlVesselTypes, {
        method: 'GET'
    })
}

export function createVesselEventFetch(body: any) {
    return callApi(urlVesselEvent, {
        method: "POST",
        body: JSON.stringify(body)
    })
}

export function getVesselsFetch() {
    return callApi(urlVessel, {
        method: 'GET'
    })
}

export function getVesselsAndVesselContractFetch() {
    return callApi(urlVessel + '/contract', {
        method: 'GET'
    })
}

export function deleteVesselEventFetch(eventId: any) {
    return callApi(urlVesselEvent + "/" + eventId, {
        method: "DELETE"
    })
}

export function getVesselDataFetch(vesselId: string) {
    return callApi(urlVessel + '/data/' + vesselId, {
        method: 'GET'
    })
}

export function getOperationByVesselFetch(vesselId: string) {
    return callApi(urlVessel + '/' + vesselId, {
        method: 'GET'
    })
}

export function createVesselFetch(vessel: any) {
    return callApi(urlVessel, {
        method: 'POST',
        body: JSON.stringify(vessel)
    })
}

export function updateVesselFetch(vessel: any) {
    return callApi(urlVessel, {
        method: 'PUT',
        body: JSON.stringify(vessel)
    })
}

export function getPositionVesselsFetch(iotNames: string[]) {
    return callApi(urlVessel + '/iot', {
        method: 'POST',
        body: JSON.stringify(iotNames)
    })
}

export function getPositionVessels2Fetch(iotNames: string[]) {
    return callApi(urlVessel + '/ioT', {
        method: 'POST',
        body: JSON.stringify(iotNames)
    })
}

interface IGetVesselSchedule {
    id: string,
    start_time: number,
    finish_time: number
}

export function getVesselSchedule(params: IGetVesselSchedule) {
    return callApi(urlVessel + '/activity_log', {
        method: 'POST',
        body: JSON.stringify(params)
    })
}

export const VesselService = {
    vesselByTenantId: (abort?: AbortController) => {
        const state = store.getState();
        const {tenant_id} = state.login?.user || {};

        const callback = (rs: IVessel[]) => {
            if (rs.length > 0) {
                const {default_vessel} = state.login?.user || {};
                const {id = null} = rs.find((item: any) => item.id === default_vessel) || {};
                if (!id)
                    store.dispatch(updateUserSetting([{}, rs[0].id]));
            }
            return rs
        }
        return api.get<IVessel[]>(urlVessel + '?tenant_id=' + tenant_id, {signal: abort?.signal, callback})
    }
}