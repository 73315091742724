import React, {Component} from 'react';
import {IKeyUpdate} from "../constants";
import {Select} from "antd";
import styles from "./Treatment/style.module.scss";
import {checkLimit} from "../../../../../../../util/varibles/global";
import {KeyOfTask, patternFloat} from "../../../../../../../util/varibles/constants";
import {notify, NotifyCode} from "../../../../../../../util/varibles/message";

const listOfUnit: any = {
    t: {
        mame: 'Total weight',
        method: (props: any) => {
            const {total_weight} = props.data.sub_operation;
            return Math.round(total_weight / 1000) / 1000;
        }
    },
    fish: {
        name: 'Fish amount',
        method: (props: any) => {
            const {fish_amount} = props.data.sub_operation;
            return fish_amount;
        }
    },
    '%': {
        mame: '%',
        method: (props: any) => {
            const {unit, data} = props;
            const {fish_amount: fishAmountRoot} = unit;
            const {fish_amount, percent} = data.sub_operation;
            return percent ? percent : Math.round((fish_amount / fishAmountRoot * 100));
        }
    }
}

interface IProps {
    data: any
    site: any
    unit: any
    isSorting: boolean

    handleChange(list: IKeyUpdate[]): void
}

class MoveToUnit extends Component<IProps> {
    static defaultProps = {
        isSorting: false
    }

    handleChange = (value: any) => {
        const {error: errorOfTask} = this.props.data;
        delete errorOfTask.unit;

        this.props.handleChange([
            {key: KeyOfTask.DESTINATION_ID, value: value.id},
            {key: KeyOfTask.DESTINATION_FIELD, value: 'unit'},
            {key: KeyOfTask.DESTINATION_NAME, value: value.unit_id},
            {key: KeyOfTask.ERROR, value: errorOfTask},
        ])
    }

    handleChangeAmount = (e: any) => {
        const {value} = e.target;
        const reg = new RegExp(patternFloat);
        if (!reg.test(value)) {
            notify.error(NotifyCode.E9)();
            return;
        }

        const {sub_operation} = this.props.data;
        const {avg_weight, unit = '%'} = sub_operation;
        const {fish_amount: fishAmountRoot, total_weight: totalWeightRoot} = this.props.unit;
        let fish_amount, total_weight, percent;
        if (unit === 't') {
            total_weight = checkLimit(0, totalWeightRoot, value * 1000000);
            fish_amount = Math.floor(total_weight / avg_weight);
            percent = Math.round(fish_amount / fishAmountRoot * 100)
        } else if (unit === 'fish') {
            fish_amount = checkLimit(0, fishAmountRoot, +value);
            total_weight = fish_amount * avg_weight;
            percent = Math.round(fish_amount / fishAmountRoot * 100)
        } else {
            percent = checkLimit(0, 100, value);
            fish_amount = Math.floor(fishAmountRoot * percent / 100);
            total_weight = fish_amount * avg_weight
        }
        this.props.handleChange([{
            key: KeyOfTask.SUB_OPERATION, value: {...sub_operation, fish_amount, total_weight, percent}
        }])
    }

    handleConvertAmount = () => {
        const {unit = '%'} = this.props.data.sub_operation;
        if (unit === 't') {
            const {avg_weight, fish_amount} = this.props.data.sub_operation;
            this.props.handleChange([{
                key: KeyOfTask.SUB_OPERATION, value: {
                    ...this.props.data.sub_operation,
                    total_weight: avg_weight * fish_amount
                }
            }])
        }
    }

    handleChangeUnit = (unit: any) => {
        const {sub_operation} = this.props.data;
        this.props.handleChange([{key: KeyOfTask.SUB_OPERATION, value: {...sub_operation, unit}}])
    }

    render() {
        const {data, unit: unitOfSubOp} = this.props
        const {destination_id, sub_operation: {unit = '%'}} = data;
        const {isSorting} = this.props;
        const {units} = this.props.site;
        const {Option} = Select;
        const {name, method} = listOfUnit[unit];
        return <>
            <Select
                className={[styles['select-custom'], 'w-full max-w-160'].join(' ')}
                placeholder="Select a unit"
                variant={"borderless"}
                value={destination_id}
                popupClassName={styles['dropdown-custom']}
                onSelect={(value: any, option: any) => this.handleChange(option.unit)}
                popupMatchSelectWidth={false}
            >
                {units.map((item: any) => <Option key={item.id} value={item.id} unit={item}>
                    {item.unit_id}
                </Option>)}
            </Select>
            {!isSorting && <>
                <input
                    className={[styles['input-custom'], 'w-full max-w-120'].join(' ')}
                    placeholder={name}
                    value={method({data, unit: unitOfSubOp})}
                    onChange={this.handleChangeAmount}
                    onBlur={this.handleConvertAmount}
                />
                <Select
                    className={[styles['select-custom'], styles['select-unit']].join(' ')}
                    variant={"borderless"}
                    value={unit}
                    popupClassName={styles['dropdown-custom']}
                    onSelect={(value: any) => this.handleChangeUnit(value)}
                    popupMatchSelectWidth={false}
                >
                    <Option value='%'>%</Option>
                    <Option value='t'>t</Option>
                    <Option value='fish'>fish</Option>
                </Select>
            </>}
        </>;
    }
}

export default MoveToUnit;