import React, {useCallback, useEffect, useState} from 'react';
import styles from "../style.module.scss";
import {useResizeDetector} from "react-resize-detector";
import Widget from "./Widget";
import {useSelector} from "react-redux";
import Timeline from "./Timeline";
import MapComponent from "./Map";
import {DeviceService} from "../../../util/services/device";
import {selectVessel} from "../../../util/store/selectors";
import {getCurrentVesselData} from "../utils";
import {datetime} from "../../../util/library/datetime";

let timeout: any;

const convertData = (item: any) => [datetime(item.time).UTCText, item.value]

const ProcessData: React.FC = () => {
    const [{loading, currentData, sensorsData}, setForm] = useState<{
        loading: { [id: string]: boolean },
        currentData: { [id: string]: any },
        sensorsData: { [id: string]: any },
    }>({
        loading: {},
        currentData: {},
        sensorsData: {}
    })
    const isLive = useSelector(selectVessel.isLive);
    const vesselLive = useSelector(selectVessel.vesselLive);
    const dashboard = useSelector(selectVessel.dashboard);
    const history = useSelector(selectVessel.history);
    const currentTime = useSelector(selectVessel.currentTime);
    const deviceIds = useSelector(selectVessel.deviceIds);
    const update = useSelector(selectVessel.update);
    const [widgets, setWidgets] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);

    const loadData = useCallback((time?: { start_time: number, end_time: number }) => {
        if (deviceIds.length === 0)
            return;

        if (timeout)
            clearTimeout(timeout)
        setTimeout(() => {
            const load: { [id: string]: true } = {};
            const {start_time = history.startTime, end_time = history.finishTime} = time || {};
            for (const data of deviceIds) {
                const {key} = data;
                if (load[key])
                    continue;
                load[key] = true;
                setForm(prev => ({
                    ...prev,
                    loading: {...prev.loading, [key]: true},
                    sensorsData: {...prev.sensorsData, [key]: []}
                }))
                DeviceService.getSensorData({...data, start_time, end_time})
                    .then((rs: any = []) => {
                        const data = Array.isArray(rs) ? rs : []
                        setForm(prev => ({
                            ...prev,
                            loading: {...prev.loading, [key]: false},
                            sensorsData: {...prev.sensorsData, [key]: data.map(item => convertData(item))},
                        }))
                    })
                    .catch(() => {
                        setForm(prev => ({...prev, loading: {...prev.loading, [key]: false}}))
                    })
            }
            setForm(prev => ({...prev, loading: {...prev.loading, gps: true}}))
            DeviceService.getGPS({start_time, end_time})
                .then((rs: any = []) => {
                    const data = Array.isArray(rs) ? rs : [];
                    setForm(prev => ({
                        ...prev,
                        loading: {...prev.loading, gps: false},
                        sensorsData: {...prev.sensorsData, gps: data.map(item => convertData(item))},
                        currentData: rs.length > 0 ? {gps: rs[0]} : prev.currentData
                    }))
                })
                .catch(() => {
                    setForm(prev => ({...prev, loading: {...prev.loading, gps: false}}))
                })
        }, 1000)
    }, [history, deviceIds])

    useEffect(() => {
        loadData()
    }, [loadData]);

    useEffect(() => {
        if (dashboard) {
            try {
                setWidgets(JSON.parse(dashboard.content))
            } catch (e) {
            }
        }
    }, [dashboard]);

    useEffect(() => {
        const {keys} = update
        setForm(prev => {
            const {sensorsData, loading} = prev;
            for (const data of keys) {
                const {key, value} = data;
                if (sensorsData[key] && !loading[key])
                    sensorsData[key].push(convertData(value));
            }
            return {...prev, sensorsData}
        })
    }, [isLive, update]);

    useEffect(() => {
        if (Object.keys(sensorsData).length > 0) {
            let newData: any = {};
            Object.keys(sensorsData).forEach(key => {
                const value = getCurrentVesselData(sensorsData[key] || [], currentTime);
                if (value)
                    newData[key] = value;
            })
            const value = getCurrentVesselData(sensorsData.gps || [], currentTime);
            if (value)
                newData.gps = value;
            setForm(prev => ({...prev, currentData: {...newData, time: currentTime}}))
        }
    }, [currentTime, sensorsData]);


    const {ref} = useResizeDetector({
        handleHeight: false,
        refreshMode: 'debounce',
        refreshRate: 300,
        onResize: useCallback((w: number = window.innerWidth) => {
            setWidth(w);
        }, [])
    });

    return <div className={styles.dashboard}>
        <div ref={ref} className={styles.charts}>
            <div className={styles['charts-title']}>
                <Timeline loading={loading.gps} loadData={loadData}/>
            </div>
            <div id='charts' className={styles['charts-body']}>
                {widgets.map((widget: any, index: number) => <Widget key={widget.name + index} {...{
                    index,
                    widget,
                    width,
                    sensorsData,
                    currentData: isLive || Object.keys(currentData).length === 0 ? vesselLive : currentData
                }}/>)}
            </div>
        </div>
        <div className={styles.map}>
            <MapComponent {...{currentData: isLive ? vesselLive : currentData}}/>
        </div>
    </div>;
};

export default ProcessData;
