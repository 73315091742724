import React from 'react';
import styles from './style.module.scss';
import {OP_STATUS} from "../../../../../util/varibles/constants";

interface IProps {
    isApprove: boolean
    operation: {
        id: string
        tenant_id: string
    }

    handleChangeState(param: any): void
}

const ApproveControl: React.FC<IProps> = (props) => {
    if (!props.isApprove)
        return null;
    const {id, tenant_id} = props.operation;
    const params = {
        operation_id: id,
        tenant_id
    }
    return <div className={styles['container-approve']}>
        <div onClick={() => props.handleChangeState({...params, status: OP_STATUS.CANCELED})}>
            Decline
        </div>
        <div onClick={() => props.handleChangeState({...params, status: OP_STATUS.APPROVED})}>
            Approve and send to vessel
        </div>
    </div>;
};

export default ApproveControl;

