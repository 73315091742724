import React, {Component} from 'react';
import styles from "./style.module.scss";
import {ACTION_TYPE, OPERATION_TYPE, SITE_TYPE} from "../../../../../../../util/varibles/constants";
import {Divider} from "antd";
import {notify, NotifyCode} from "../../../../../../../util/varibles/message";
import {checkRoute} from "../../../../../util";
import Certain from "../../../_Component/Certain";
import {checkLimit, cloneObj} from "../../../../../../../util/varibles/global";
import Operation from "./Operation";
import {UpdateName} from "../../constants";
import AddNote from "../../../_Component/AddNote";
import Density from "../../../../../../../components/Operation/Density";
import {getStorages} from "../../../../../util/function_operation/constants";

interface IProps {
    operations: any
    store: any
    vessel: any
    routes: any
    sites: any
    units: any
    editMode: ACTION_TYPE

    updateState(state: any): void

    onUpdate(key: UpdateName, args: any): void
}

class Operations extends Component<IProps> {

    handleChange = ({...args}) => {
        const {key, index, indexDetail, value} = args;
        const {vessel, routes, units} = this.props;
        const {capacity} = vessel;
        let store = cloneObj(this.props.store);
        let operations = cloneObj(this.props.operations);
        let state: any = {}, isCalculate = false;
        const operation = operations[index];
        let {error_detail = {}, total_weight, sub_destinations, source_site_type} = operation;
        const isInternal = source_site_type === SITE_TYPE.INTERNAL;
        switch (key) {
            case 'fish_amount': {
                if (value === 0) {
                    operations[index] = {
                        ...operation,
                        error_detail: {
                            ...error_detail,
                            [key]: notify[NotifyCode.E37](['Fish amount'])
                        }
                    }
                } else {
                    delete error_detail[key]
                }
                if (isInternal) {
                    const {
                        avg_weight, id, fish_amount: amountOrder, total_weight: totalWeightOld
                    } = operations[index].sub_destinations[indexDetail];
                    const subTotal = checkLimit(0, undefined, total_weight - totalWeightOld);
                    const keyStore = id + '|-';
                    const {fish_amount: amountStore} = store[keyStore];
                    const maxAmount = amountOrder + amountStore;
                    if (Number(value) > maxAmount)
                        notify.warn(NotifyCode.W6)()
                    let fishAmount = 0, totalWeightNew = 0, fishStore = maxAmount;
                    if (subTotal > capacity) {
                        error_detail[key] = notify[NotifyCode.E1]();
                    } else {
                        fishAmount = checkLimit(0, maxAmount, Number(value));
                        totalWeightNew = avg_weight * fishAmount;

                        if ((subTotal + totalWeightNew) > capacity) {
                            notify.error(NotifyCode.E1)();
                            const total = checkLimit(0, undefined, capacity - subTotal);
                            fishAmount = Math.floor(total / avg_weight);
                            totalWeightNew = avg_weight * fishAmount;
                        }

                        fishStore -= fishAmount;
                        delete error_detail.fish_amount;
                        delete error_detail.total_weight;
                    }

                    sub_destinations.splice(indexDetail, 1, {
                        ...operations[index].sub_destinations[indexDetail],
                        [key]: fishAmount,
                        total_weight: totalWeightNew
                    })
                    store[keyStore] = {
                        ...store[keyStore],
                        fish_amount: fishStore,
                        total_weight: fishStore * avg_weight
                    }
                    operations[index] = {
                        ...operation,
                        sub_destinations,
                        fish_amount: sub_destinations.reduce((list: any, item: any) => {
                            const {fish_amount} = item;
                            return list + fish_amount;
                        }, 0),
                        total_weight: subTotal + totalWeightNew,
                        error_detail
                    }
                } else {
                    const fishAmount = checkLimit(0, undefined, Number(value));
                    sub_destinations.splice(indexDetail, 1, {
                        ...operations[index].sub_destinations[indexDetail],
                        [key]: fishAmount,
                    })
                    operations[index] = {
                        ...operation,
                        sub_destinations,
                        ...sub_destinations.reduce((list: any, item: any) => {
                            const {fish_amount, total_weight} = item;
                            list.fish_amount += fish_amount;
                            list.total_weight += total_weight;
                            return list;
                        }, {fish_amount: 0, total_weight: 0}),
                        error_detail
                    }
                }
                isCalculate = true;
                state = {operations}
                break;
            }
            case 'convert_total_weight': {
                if (isInternal) {
                    const {avg_weight, fish_amount} = operations[index].sub_destinations[indexDetail];
                    sub_destinations.splice(indexDetail, 1, {
                        ...operations[index].sub_destinations[indexDetail],
                        total_weight: avg_weight * fish_amount,
                    })
                    operations[index] = {
                        ...operations[index],
                        sub_destinations,
                        ...sub_destinations.reduce((list: any, item: any) => {
                            const {fish_amount, total_weight} = item;
                            list.fish_amount += fish_amount;
                            list.total_weight += total_weight;
                            return list;
                        }, {fish_amount: 0, total_weight: 0}),
                    }
                }
                isCalculate = true;
                state = {operations}
                break;
            }
            case 'total_weight': {
                if (value === 0) {
                    operations[index] = {
                        ...operation,
                        error_detail: {
                            ...error_detail,
                            [key]: notify[NotifyCode.E37](['Total weight'])
                        }
                    }
                } else {
                    delete error_detail[key];
                }
                if (isInternal) {
                    const {
                        avg_weight, id, fish_amount: amountOrder, total_weight: totalWeightOld
                    } = operations[index].sub_destinations[indexDetail];
                    const subTotal = checkLimit(0, undefined, total_weight - totalWeightOld);
                    const keyStore = id + '|-';
                    const {fish_amount: amountStore} = store[keyStore];
                    const maxAmount = amountOrder + amountStore;
                    const maxWeight = maxAmount * avg_weight;
                    if (Number(value * 1000000) > maxWeight)
                        notify.warn(NotifyCode.W6)()
                    let fishAmount = 0, totalWeightNew = 0, fishStore = maxAmount;
                    if (subTotal > capacity) {
                        error_detail.total_weight = notify[NotifyCode.E1]()
                    } else {
                        totalWeightNew = checkLimit(0, maxWeight, Number(value * 1000000));
                        fishAmount = Math.floor(totalWeightNew / avg_weight)

                        if ((subTotal + totalWeightNew) > capacity) {
                            notify.error(NotifyCode.E1)();
                            const total = checkLimit(0, undefined, capacity - subTotal);
                            fishAmount = Math.floor(total / avg_weight);
                            totalWeightNew = avg_weight * fishAmount;
                        }

                        fishStore -= fishAmount;
                        delete error_detail.fish_amount;
                        delete error_detail.total_weight;
                    }

                    sub_destinations.splice(indexDetail, 1, {
                        ...operations[index].sub_destinations[indexDetail],
                        [key]: totalWeightNew,
                        fish_amount: fishAmount
                    })
                    store[keyStore] = {
                        ...store[keyStore],
                        fish_amount: fishStore,
                        total_weight: fishStore * avg_weight
                    }
                    operations[index] = {
                        ...operations[index],
                        sub_destinations,
                        fish_amount: sub_destinations.reduce((list: any, item: any) => {
                            const {fish_amount} = item;
                            return list + fish_amount;
                        }, 0),
                        total_weight: subTotal + totalWeightNew,
                        error_detail
                    }
                } else {
                    const totalWeight = checkLimit(0, capacity, Number(value * 1000000));
                    sub_destinations.splice(indexDetail, 1, {
                        ...operations[index].sub_destinations[indexDetail],
                        [key]: totalWeight,
                    })
                    operations[index] = {
                        ...operations[index],
                        sub_destinations,
                        ...sub_destinations.reduce((list: any, item: any) => {
                            const {fish_amount, total_weight} = item;
                            list.fish_amount += fish_amount;
                            list.total_weight += total_weight;
                            return list;
                        }, {fish_amount: 0, total_weight: 0}),
                        error_detail
                    }
                }
                isCalculate = true;
                state = {operations}
                break;
            }
            case 'destination_id': {
                const {source_id, source_name} = operation;
                const {sites} = this.props;
                const destination = sites.find((item: any) => item.id === value);
                const {error, message} = checkRoute({id: source_id, name: source_name}, destination, routes)
                if (error === 2) {
                    error_detail.route = [{source_id: source_id, destination_id: destination.id, message}]
                } else {
                    delete error_detail.route;
                }
                operations[index] = {
                    ...operation,
                    [key]: value,
                    error_detail,
                    destination_name: destination.name,
                    sub_destinations: sub_destinations.map((item: any) => ({
                        ...item,
                        delivery_id: null,
                        delivery_name: null,
                    }))
                }
                state = {operations, units: {...units || {}, [value]: destination.units}};
                break;
            }
            case 'delivery_id': { // UnitId
                const delivery = units[operation.destination_id].find((item: any) => item.id === value);
                delete error_detail[key];
                operations[index] = {
                    ...operation,
                    sub_destinations: sub_destinations.map((item: any, indexSub: any) => indexDetail === indexSub ? {
                        ...item,
                        [key]: value,
                        delivery_name: delivery.unit_id
                    } : item),
                    error_detail
                }
                state = {operations};
                break;
            }
            default:
                return;
        }
        if (isCalculate && state.operations) {
            const {fish_amount, total_weight} = operations[index];
            const storages = getStorages({fish_amount, total_weight}, vessel);
            if (storages.length > 0) {
                const {sub_destinations} = operations[index];
                state.operations[index].sub_destinations = sub_destinations.map((item: any) => ({...item, storages}));
            }
        }
        this.props.updateState({...state, store});
    }

    handleDelete = ({...args}) => {
        const {index, indexDetail} = args;
        const {operations, store} = this.props;
        const {sub_destinations} = operations[index];
        const {id, fish_amount, total_weight} = operations[index].sub_destinations[indexDetail];
        const keyStore = id + '|-';
        store[keyStore] = {
            ...store[keyStore],
            fish_amount: store[keyStore].fish_amount + fish_amount,
            total_weight: store[keyStore].total_weight + total_weight
        }
        if (operations.length === 1 && sub_destinations.length === 1) {
            this.props.updateState({operations: [], store});
            return;
        } else if (sub_destinations.length === 1) {
            operations.splice(index, 1);
        } else {
            operations[index].sub_destinations.splice(indexDetail, 1);
            operations[index] = {
                ...operations[index],
                ...sub_destinations.reduce((list: any, item: any) => {
                    const {fish_amount, total_weight} = item;
                    list.fish_amount += fish_amount;
                    list.total_weight += total_weight;
                    return list;
                }, {fish_amount: 0, total_weight: 0}),
            }
        }
        this.props.updateState({operations, store});
    }

    handleMerge = (indexTarget: number, indexCurrent: number, current: any) => {
        if (indexTarget === indexCurrent) return;
        const {operations, vessel: {capacity}} = this.props;
        const target = {...operations[indexTarget]};
        const totalWeight = target.total_weight + current.total_weight;
        if (totalWeight > capacity) {
            notify.error(NotifyCode.E1)();
            return;
        }
        const {sub_destinations, destination_id} = current;
        const isDifferent = target.destination_id !== destination_id;
        const subOperationsNew = sub_destinations.reduce((list: any, itemNew: any) => {
            const index = list.findIndex((itemOld: any) => itemOld.id === itemNew.id && itemOld.delivery_id === itemNew.delivery_id);
            if (index !== -1) {
                const {fish_amount, total_weight} = itemNew;
                list[index].fish_amount += fish_amount;
                list[index].total_weight += total_weight;
            } else {
                list.push(isDifferent ? {...itemNew, delivery_id: null, delivery_name: null} : itemNew);
            }
            return list;
        }, [...target.sub_destinations])
        Object.assign(operations[indexTarget], {
                ...target,
                sub_destinations: subOperationsNew,
                fish_amount: target.fish_amount + current.fish_amount,
                total_weight: totalWeight
            }
        )
        operations.splice(indexCurrent, 1);
        this.props.updateState({operations})
    }


    render() {
        const {operations, sites, units, editMode, onUpdate} = this.props;

        return <div className={styles['operations']}>
            {operations.length === 0 && <div className={styles['line']} data-lev='empty'>No trip</div>}
            <div className={[styles['body'], 'scroll-small'].join(' ')}>
                <div className={styles['line']} data-lev='header'>
                    <div>Started unit</div>
                    <div className='pl-3'>Deliv. site</div>
                    <div className='pl-3'>Deliv. unit</div>
                    <div className='text-center'>Amount</div>
                    <div className='text-center'>Total weight</div>
                    <div/>
                </div>
                {operations.map((item: any, index: number) => <div key={index} className={styles['container-op']}>
                    <div className={styles['op-title']}>
                        <div className='pl-2' data-lev='title'>
                            <div>Trip {index + 1}</div>
                            <Density
                                isEmpty
                                className={styles.tanks}
                                type={OPERATION_TYPE.TRANSPORT}
                                storages={item.sub_destinations[0].storages}
                            />
                        </div>
                        <div data-lev='action'>
                            <Certain
                                value={item.certain_mode}
                                onChange={certain_mode => onUpdate(UpdateName.NOT_CALCULATE, {index, certain_mode})}
                            />
                            <Divider type='vertical'/>
                            <AddNote
                                title={item.operation_code.length > 0 ? `#${item.operation_code}` : `Trip ${index + 1}`}
                                value={item.note}
                                onChange={note => onUpdate(UpdateName.NOT_CALCULATE, {index, note})}
                            />
                        </div>
                    </div>
                    <Operation key={index} {...{
                        data: item,
                        index,
                        sites,
                        units,
                        editMode,
                        handleChange: this.handleChange,
                        handleDelete: this.handleDelete,
                        handleMerge: this.handleMerge
                    }}/>
                </div>)}
            </div>
        </div>;
    }
}

export default Operations;
