import React, {useCallback, useEffect} from 'react';
import GoogleMap, {googleMap} from "../../../../util/library/googlemap/googlemap";
import {useDispatch, useSelector} from "react-redux";
import {selectVessel} from "../../../../util/store/selectors";
import styles from './style.module.scss'
import {MAP_ELEMENT} from "../../../../util/library/googlemap/constants";
import {createMarkers} from "../../../../util/varibles/global";
import {vesselDetailActions} from "../../reducer";
import Tracking from "./Tracking";

let map: GoogleMap;

interface IProps {
    currentData: any
}

const Map: React.FC<IProps> = React.memo(({currentData}) => {
    const isTracking = useSelector(selectVessel.isTracking);
    const vessel = useSelector(selectVessel.vessel);
    const dispatch = useDispatch();

    const updateTracking = useCallback((value: boolean) => {
        dispatch(vesselDetailActions.update({isTracking: value}))
    }, [dispatch]);

    useEffect(() => {
        map = googleMap('map', {
            callback: (el) => {
                el.addListener('dragstart', () => updateTracking(false));
            }
        })
    }, [updateTracking]);

    useEffect(() => {
        const {gps} = currentData;
        if (map && vessel && gps) {
            const {Latitude, Longitude, index} = gps;
            if (index === -1)
                return

            if (isTracking) {
                map.setCenter([[Longitude, Latitude]], 8)
            }

            map.generate({
                type: MAP_ELEMENT.VESSEL,
                markers: createMarkers([{
                    ...vessel, gps, position: {latitude: Latitude, longitude: Longitude}
                }])
            })
        }
    }, [currentData, vessel, isTracking]);

    return <>
        <div id="map" className='w-full h-full'/>
        <div className={styles['menu-map']}>
            <Tracking isTracking={isTracking} updateTracking={updateTracking}/>
        </div>
    </>
}, (prev, next) => JSON.stringify(prev.currentData) === JSON.stringify(next.currentData));

export default Map;

