import React from 'react';
import TrackingOp from "../../../components/TrackingOp";
import {useDispatch} from "react-redux";
import {overviewActions} from "../reducer";

interface IProps {
}

const WrapperTrackingOp: React.FC<IProps> = () => {
    const dispatch = useDispatch();
    const onNextState = (data: any) => {
        const {operation} = data;
        dispatch(overviewActions.updateOps([operation]));
    }

    return <TrackingOp onUpdate={onNextState}/>;
};

export default WrapperTrackingOp;
