import React from 'react';
import {LAYOUT_CALENDER} from "../../../constants";
import styles from "./style.module.scss";

interface IProps {
    calendarWidth: number
}

const Layout: React.FC<IProps> = ({calendarWidth}) => {
    const style = {width: `${calendarWidth}%`}
    return <div id={LAYOUT_CALENDER} className={styles['layout-calendar']} style={style}/>;
};

export default Layout;
