import React, {useState} from 'react';
import styles from './style.module.scss';
import Icon, {ICON_NAME} from "../../../../components/Icon";
import {Tooltip} from "antd";
import {connect} from "react-redux";
import {MAP_STYLE, mapTypes} from "../../../../util/library/googlemap/constants";
import GoogleMap from "../../../../util/library/googlemap/googlemap";
import {AppState} from "../../../../util/store/store";


const mapStateToProps = (state: AppState) => ({
    map: state.dashboard.map
})

interface IProps {
    map?: GoogleMap
}

const MapType: React.FC<IProps> = ({map}) => {
    const [open, setOpen] = useState(false);

    const onClick = (e: any) => {
        setOpen(!open);
        if (!open)
            e.target.focus();
    }

    const onChange = (id: MAP_STYLE) => {
        if (map)
            map.setMapType(id);
    }

    return <div className={styles.wrapper} data-open={open}>
        <Tooltip title={open ? '' : 'Layers'} placement='topRight'>
            <div className={styles.toggle} onClick={onClick} tabIndex={1} onBlur={() => setOpen(!open)}>
                <Icon icon={open ? ICON_NAME.CLOSE : ICON_NAME.MAP} size="large"/>
            </div>
        </Tooltip>
        <div className={styles.mapTypes}>
            {mapTypes.map(item => <Tooltip key={item.id} title={item.name} placement='left'>
                <div className={styles.mapType} data-code={item.id} onMouseDown={() => onChange(item.id)}/>
            </Tooltip>)}
        </div>
    </div>;
};

export default connect(mapStateToProps, {})(MapType);
