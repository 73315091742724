import {ACTION_TYPE, KEY_STORAGE, OPERATION_TYPE} from "../../../../util/varibles/constants";
import {notify, NotifyCode} from "../../../../util/varibles/message";
import {notification} from "antd";
import {deliveryUnits} from "../../Popup/EditOp/Body/Harvest/constants";
import {ISite} from "../../../../util/varibles/interface";
import {FuncOp, getStorages} from "./constants";
import {checkRoute} from "../";

export const funcHarvest = {
    [FuncOp.BeginMerge]: (args: any) => {
        const {id, operations, harvests, sites, routes} = args;
        const {activity_log, operation, vessel} = operations[id];

        const unitsNew = JSON.parse(localStorage.getItem(KEY_STORAGE.UNITS_SELECTED) || '');
        const requestType = JSON.parse(localStorage.getItem(KEY_STORAGE.REQUIRE_OPERATION_TYPE) || '');

        const {
            site_id,
            operation_type,
            sub_operations: unitsOld,
            total_amount: totalAmountOld,
            total_weight: totalWeightOld,
            factory_id,
            factory_name
        } = operation;

        if (operation_type !== OPERATION_TYPE.HARVEST || (requestType !== undefined && requestType.indexOf(operation_type) === -1)) {
            notify.error(NotifyCode.E4)();
            return;
        }

        const {units = []} = sites.find((item: any) => item.id === site_id) || {};
        let store: any = {}, sub_operations = JSON.parse(JSON.stringify(unitsOld));
        let fish_amount = totalAmountOld;
        let total_weight = totalWeightOld;
        const {capacity} = vessel;
        store = unitsOld.reduce((list: any, {id, harvest_id = '-'}: any) => {
            const key = id + "|" + harvest_id;
            const unit = units.find((unit: any) => unit.id === id);
            list[key] = {...unit, harvest_id}
            if (harvest_id !== '-')
                list[key] = {
                    ...list[key],
                    ...harvests.find((harvest: any) => harvest.harvest_id === harvest_id)
                };
            return list;
        }, {})

        Object.keys(unitsNew).forEach((key: string) => {
            const {
                fish_amount: fishAmountNew,
                total_weight: totalNew,
                id,
                harvest_id = '-',
                site_id,
                site_name
            } = unitsNew[key];
            const index = unitsOld.findIndex((item: any) => item.id === id && item.harvest_id === harvest_id);
            const keyStore = id + '|' + harvest_id;
            const siteNew = sites.find((site: any) => site.id === site_id);
            if (!siteNew) {
                notification.error({message: 'Error', description: notify[NotifyCode.E21]([site_name])})
                return;
            }
            const unitNew = siteNew.units.find((item: any) => item.id === id);
            if (index !== -1) {
                sub_operations[index].fish_amount += fishAmountNew;
                sub_operations[index].total_weight += totalNew;
            } else {
                const {delivery_types} = sub_operations[0];
                const deliveryType = delivery_types[0];
                sub_operations.push({
                    ...unitNew || {},
                    ...unitsNew[key],
                    site_type: siteNew.type,
                    harvest_id,
                    delivery_types: [{
                        ...deliveryType,
                        fish_amount: fishAmountNew,
                        total_weight: totalNew,
                        unit: deliveryUnits[0].id
                    }]
                })
            }

            store[keyStore] = {...unitNew, ...unitsNew[key], harvest_id, fish_amount: 0, total_weight: 0}
            fish_amount += fishAmountNew;
            total_weight += totalNew;
        })
        if (total_weight > capacity) {
            notify.error(NotifyCode.E1)();
            return;
        }
        const sitesNew = sub_operations.reduce((rs: any, item: any, index: number) => {
            let {site_id, site_name, site_type, fish_amount, total_weight, id} = item;
            if (!site_id) {
                const site = sites.find((sub: ISite) => sub.units.some((unit) => unit.id === id));
                if (site) {
                    site_id = site.id;
                    sub_operations[index].site_id = site.id;
                    sub_operations[index].site_name = site.name;
                }
            }

            if (rs[site_id]) {
                rs[site_id].total_amount += fish_amount;
                rs[site_id].total_weight += total_weight;
            } else {
                const source = {id: site_id, name: site_name};
                const destination = {id: factory_id, name: factory_name}
                const {error} = checkRoute(source, destination, routes);

                rs[site_id] = {
                    id: site_id,
                    name: site_name,
                    type: site_type,
                    isRoute: error !== 2,
                    total_amount: fish_amount,
                    total_weight: total_weight
                }
            }
            return rs;
        }, {});

        const storages = getStorages({fish_amount: fish_amount, total_weight}, vessel);
        if (storages.length > 0)
            sub_operations = sub_operations.map((item: any) => ({...item, storages}));

        return {
            isShow: true,
            operationType: operation_type,
            editMode: ACTION_TYPE.EDIT,
            values: {
                store,
                activity_log,
                operation: {
                    ...operation,
                    sub_operations,
                    sites: Object.keys(sitesNew).reduce((list: any, key) => [...list, sitesNew[key]], []),
                    fish_amount,
                    total_weight,
                },
                vessel,
            },
        }
    }
}