import React from 'react';
import styles from './style.module.scss';
import {IPropsChildren} from "../../../../../util/varibles/interface";
import LoadingBox from "../../../../../components/LoadingBox";

interface IProps extends IPropsChildren {
    source: any
    loading: boolean
    className?: string
    width?: number
}

const Cards: React.FC<IProps> = ({source = [], loading, className, width = 320, children}) => {
    const style = {
        gridTemplateColumns: `repeat(auto-fill, minmax(${width}px, 1fr))`
    }

    return <div className={[styles.wrapper, className].join(' ')} style={style}>
        {source.length === 0 && <LoadingBox loading={loading} className={styles.loading}/>}
        {children}
    </div>;
};

export default Cards;
