import React from 'react';
import {CONTRACT_TYPE} from "../../../../../util/varibles/constants";
import Contract from "./Contract";
import {connect} from "react-redux";
import {IContract, IVessel} from "../../../../../util/varibles/interface";
import {TargetTypes} from "../Vessels/constants";
import {AppState} from "../../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    userTenantId: state.login.user.tenant_id,
});

interface IProps {
    contracts: IContract[]
    userTenantId: string
    startPoint: number
    vessel: IVessel
    vesselIndex: number
    width: number

    setTarget(target: TargetTypes, e: MouseEvent): void
}

const Contracts: React.FC<IProps> = (props) => {
    const {startPoint, vesselIndex, userTenantId, width, contracts, setTarget} = props;
    return contracts.reduce((items: any, item) => {
        if (item.type === CONTRACT_TYPE.FIXED)
            return [...items, <Contract key={item.id} {...{
                startPoint,
                vesselIndex,
                width,
                contract: item,
                vessel: props.vessel,
                isOwn: item.tenant_id === userTenantId,
                setTarget,
            }}/>]
        return items
    }, [])
};

export default connect(mapStateToProps, {})(Contracts);
