// declare var process: {
//     env: {
//         REACT_APP_SERVER_TYPE: string
//     }
// }

// 0 = DEV, 1 = STAGING, 2 = PRODUCT, 3 = PRODUCT-TEST
export const serverType: any = process.env.REACT_APP_SERVER_TYPE;
// export const serverType: any = 1;
// export const serverType: any = 2;

const regions = [
    'eu-west-1'
]

const client_ids = [
    // '3tms24apkn8dt6tard5hkdmdl7',                                       // DEV
    '4d0mr0195st4dduins03bhm8l1',                                       // STAGING
    '4d0mr0195st4dduins03bhm8l1',                                       // STAGING
    '6oub89onv4617svi9n8j6i4u53',                                       // PRODUCT
    '50rvt9fs1ai6s5qcpg8rhbo304',                                       // PRODUCT
];

const redirect_uris = [
    'http://localhost:3000/access_token',                               // DEV
    // 'https://staging.d2k0n2mojvf7gk.amplifyapp.com/access_token',       // STAGING
    'https://staging.d23tjabou1o7os.amplifyapp.com/access_token',       // STAGING
    'https://demo.ocean-planner.no/access_token',                       // PRODUCT
];

const domains = [
    'dev-op-planner.auth.eu-west-1.amazoncognito.com',                  // DEV AND STAGING
    'ocean-planner.auth.eu-west-1.amazoncognito.com'                    // PRODUCT
]

const userPoolIds = [
    'eu-west-1_32njIKuUq',                                              // Dev
    'eu-west-1_pjwIJH4eE',                                              // Product
]

const scopes = [
    ['email', 'profile', 'openid'],                                   // Dev
    ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin']   // Product
]

const responseTypes = [
    // 'token',
    'code',
]

const authenticationFlowTypes = [
    'USER_PASSWORD_AUTH'
]

const bucketNames = [
    // 'amplify-opfrontend-staging-174338-deployment',
    // 'amplify-opfrontend-prod-190824-deployment'
    'op-backend-staging',
    'op-backend-prod'
]

const configs = [
    // DEV = 0
    {
        region: regions[0],
        bucket: bucketNames[0],
        client_id: client_ids[0],
        redirect_uri: redirect_uris[0],
        domain: domains[0],
        userPoolId: userPoolIds[0],
        scope: scopes[0],
        responseType: responseTypes[0],
        authenticationFlowType: authenticationFlowTypes[0]
    },
    // STAGING = 1
    {
        region: regions[0],
        bucket: bucketNames[0],
        client_id: client_ids[1],
        redirect_uri: redirect_uris[1],
        domain: domains[0],
        userPoolId: userPoolIds[0],
        scope: scopes[0],
        responseType: responseTypes[0],
        authenticationFlowType: authenticationFlowTypes[0]
    },
    // PRODUCT = 2
    {
        region: regions[0],
        bucket: bucketNames[1],
        client_id: client_ids[2],
        redirect_uri: redirect_uris[2],
        domain: domains[1],
        userPoolId: userPoolIds[1],
        scope: scopes[1],
        responseType: responseTypes[0],
        authenticationFlowType: authenticationFlowTypes[0]
    },
    // PRODUCT-TEST = 3
    {
        region: regions[0],
        bucket: bucketNames[1],
        client_id: client_ids[3],
        redirect_uri: redirect_uris[0],
        domain: domains[1],
        userPoolId: userPoolIds[1],
        scope: scopes[1],
        responseType: responseTypes[0],
        authenticationFlowType: authenticationFlowTypes[0]
    }
]
export const config = configs[serverType || 1]

const identityPoolId = 'eu-west-1:8c0522b9-23c2-442f-9626-673d40c3de40'

export const awsConfig = {
    Auth: {
        identityPoolId, //REQUIRED - Amazon Cognito Identity Pool ID
        region: config.region,
        userPoolId: config.userPoolId,
        userPoolWebClientId: config.client_id,
        authenticationFlowType: config.authenticationFlowType,
        oauth: {
            domain: config.domain,
            scope: config.scope,
            redirectSignIn: config.redirect_uri,
            redirectSignOut: config.redirect_uri,
            responseType: config.responseType,
        },
    },
    Storage: {
        AWSS3: {
            bucket: config.bucket,
            region: config.region,
        }
    }
}
