import React from 'react';
import styles from './style.module.scss';
import {Checkbox} from "antd";
import {TASK_TYPE, treatmentType} from "../../../../../../../util/varibles/constants";
import {IPropsChildren} from "../../../../../../../util/varibles/interface";

interface IPropLayout extends IPropsChildren {
    data: {
        type: TASK_TYPE
        [key: string]: any
    }
}

const Harvest: React.FC<IPropLayout> = (props) => {
    const {data, children} = props || {};
    return <>
        <div className={styles['header-task']}>
            <div>Task type</div>
            <div>Factory</div>
            <div>Vessel</div>
        </div>
        <div className={styles['body-task']}>
            <div>Harvest</div>
            <div>{data.destination_name}</div>
            {children}
        </div>
    </>;
}

const Treatment: React.FC<IPropLayout> = (props) => {
    const {data, children} = props || {};
    const {name = ''} = treatmentType[data.treatment_type] || {}
    return <>
        <div className={styles['header-task']}>
            <div>Task type</div>
            <div></div>
            <div>Vessel</div>
        </div>
        <div className={styles['body-task']}>
            <div>Treatment</div>
            <div>{name}</div>
            {children}
        </div>
    </>;
}

const Move: React.FC<IPropLayout> = (props) => {
    const {data, children} = props || {};
    return <>
        <div className={styles['header-task']}>
            <div>Task type</div>
            <div>Unit</div>
            <div>Vessel</div>
        </div>
        <div className={styles['body-task']}>
            <div>Move to unit</div>
            <div>{data.destination_name}</div>
            {children}
        </div>
    </>;
}

const Combine: React.FC<IPropLayout> = (props) => {
    const {data, children} = props || {};
    return <>
        <div className={styles['header-task']}>
            <div>Task type</div>
            <div></div>
            <div>Vessel</div>
        </div>
        <div className={styles['body-task']}>
            <div>Treatment</div>
            <div>{data.type_name}</div>
            {children}
        </div>
    </>;
}

const SupportName = (props: { support_vessels: any[] }) => {
    const {support_vessels} = props;
    return <div>{support_vessels.length > 0 ? support_vessels.map((item: any) => item.name).join(', ') : '-'}</div>
}

interface IProps {
    data: {
        type: TASK_TYPE
        [key: string]: any
    }
    isCheck: boolean

    handleCheck(group_id: any, value: boolean): void
}

const layouts: { [id: string]: any } = {
    [TASK_TYPE.TREATMENT]: Treatment,
    [TASK_TYPE.HARVEST]: Harvest,
    [TASK_TYPE.MOVE]: Move,
    [TASK_TYPE.COMBINE]: Combine,
}

const Task: React.FC<IProps> = (props) => {
    const {data, isCheck, handleCheck} = props;
    const {type, support_vessels = []} = data || {};

    const View = layouts[type];
    return <Checkbox
        className={styles.wrapperTask}
        checked={isCheck}
        onChange={(value: any) => handleCheck(data.group_id, value)}
    >
        <div className={styles.task}>
            <View data={data}>
                <SupportName support_vessels={support_vessels}/>
            </View>
        </div>
    </Checkbox>
};

export default Task;
