import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Checkbox} from "antd";
import stylesContainer from '../../style.module.scss';
import styles from '../style.module.scss';
import GoogleMap, {googleMap} from "../../../../util/library/googlemap/googlemap";
import {selectOpDetail} from "../../../../util/store/selectors";
import {MAP_ELEMENT} from "../../../../util/library/googlemap/constants";
import {createMarkers, createPolyline} from "../../../../util/varibles/global";

const Route: React.FC = () => {
    const [isTracking, setIsTracking] = useState(false);
    const [map, setMap] = useState<GoogleMap>();
    const data = useSelector(selectOpDetail.data)
    const {route, vessel} = data || {};

    useEffect(() => {
        setMap(googleMap('map', {
            callback: (map) => map.addListener('dragstart', () => setIsTracking(false))
        }))
    }, []);

    useEffect(() => {
        if (map && route) {
            const {
                operation: {site, factory, source, destination},
                route
            } = data;
            const polyline = createPolyline(route, 0, 3);
            const startPlace = site || source;
            const endPlace = factory || destination;
            const first = polyline.points[0];
            const last = polyline.points[polyline.points.length - 1]
            const siteMarkers = [{...startPlace, ...first}];
            const factoryMarkers = [{...endPlace, ...last}];
            map.generate({type: MAP_ELEMENT.SITE, markers: siteMarkers})
            map.generate({type: MAP_ELEMENT.FACTORY, markers: factoryMarkers})
            map.setCenter([[first.lng, first.lat], [last.lng, last.lat]])
            map.generate({
                type: MAP_ELEMENT.POLYLINE,
                seaWays: [createPolyline(route, 0, 3)]
            });
        }
    }, [map, route]);

    useEffect(() => {
        if (map && vessel) {
            const {gps, position} = vessel;
            const {Latitude = position?.latitude || 0, Longitude = position?.longitude || 0} = gps;

            if (isTracking) {
                map.setCenter([[Longitude, Latitude]], 14)
            }

            map.generate({
                type: MAP_ELEMENT.VESSEL,
                markers: createMarkers([{...vessel, gps, position: {latitude: Latitude, longitude: Longitude}}])
            })
        }
    }, [map, vessel, isTracking])


    if (!route)
        return null

    return <div className={stylesContainer.section}>
        <div className={stylesContainer['header-box']}>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <span className="mr-3">Route</span>
                    <div className="font-s16 d-flex align-items-end">
                        {`${route.name}  |  ${route.distance} nm`}
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <Checkbox {...{
                        className: 'font-s2',
                        checked: isTracking,
                        onChange: ({target}) => setIsTracking(target.checked),
                    }}>
                        Track vessel
                    </Checkbox>
                </div>
            </div>
        </div>
        <div id='map' className={styles['map-component']}/>
    </div>;
};

export default Route