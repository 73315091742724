import React from 'react';
import styles from "./style.module.scss";
import {
    ACTION_TYPE,
    OPERATION_TYPE,
    treatmentType,
    USER_ROLE,
    VESSEL_TYPE,
    vesselTypes
} from "../../../util/varibles/constants";
import {connect} from "react-redux";
import Checklist from "./Checlist";
import S3Image from "../../../components/S3Image";
import {checkPermission, durationTxt} from "../../../util/varibles/global";
import {IVessel} from "../../../util/varibles/interface";
import {ACTION} from "../../../util/varibles/permission";
import {vesselDetailActions} from "../reducer";
import Icon, {ICON_NAME} from "../../../components/Icon";
import {Button} from "antd";
import {openPopup} from "../../../components/Popup/Component/WrapperPopup";
import PopupEditVessel from "../../VesselManagementPage/PopupEditVessel";
import {AppState} from "../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    vessel: state.vesselDetail.vessel,
    vesselLive: state.vesselDetail.vesselLive,
    roles: state.login.user.roles,
    userTenantId: state.login.user.tenant_id
});

interface IProps {
    actions: any
    roles: USER_ROLE[]
    userTenantId: string
    vessel: any
    vesselLive: any

    getVessel(vesselId: string): void

    updateVessel(payload: IVessel): void
}

interface IProps {
}

const Detail: React.FC<IProps> = (props) => {
    const {actions, roles, userTenantId, vessel, vesselLive} = props;
    const {
        image_url,
        contact,
        width,
        length,
        speed,
        capacity,
        cleaning_time,
        harvest_capacity,
        factory_discharge,
        iot_name,
        type,
        operation_type,
        vessel_owner,
        region_names,
        checklists,
        vessel_owner_id,
    } = vessel

    const handleEdit = () => {
        const el = openPopup(<PopupEditVessel
            editMode={ACTION_TYPE.EDIT}
            title={`Update ${vessel.name}`}
            vessel={vessel}
            onSave={props.updateVessel}
            onClose={() => el.remove()}
        />)
    }

    if (!vessel || !vesselLive) return null;

    if (!checklists) return null;
    const {Latitude, Longitude, Speed, Heading} = vesselLive.gps
    let operationType = operation_type.reduce((list: any, opType: any, key: any) => {
        switch (opType) {
            case OPERATION_TYPE.HARVEST: {
                return [...list, <span key={key} className={styles['operation-type']}>Harvest</span>]
            }
            case OPERATION_TYPE.TREATMENT: {
                const {operation_sub_types} = vessel
                return [...list, <span key={key} className={styles['operation-type']}>
                        {operation_sub_types.map((opSub: any) => (treatmentType[opSub] || {name: ''}).name).join(', ')}
                    </span>];
            }
            default:
                return list
        }
    }, []);
    const isPermission = checkPermission([USER_ROLE.SUPER_ADMIN], roles) || (actions[ACTION.VESSEL.UPDATE] && userTenantId === vessel_owner_id);

    return <div className={styles['container-detail']}>
        <div className={styles.detail}>
            <div>
                <div className={styles['component-detail']}>
                    <p className={'font-s1'}>Vessel image</p>
                    <S3Image {...{imageUrl: image_url, height: 200}} />
                </div>
                <div className={styles['component-detail']}>
                    <p className={'font-s1'}>Vessel owner</p>
                    <div className={styles['line-info']}>
                        <span>{vessel_owner}</span>
                    </div>
                </div>
                <div className={styles['component-detail']}>
                    <p className={'font-s1'}>Region</p>
                    {region_names.map((region: any, key: any) => {
                        return <div key={key} className={styles['line-info']}>
                            <span>{region}</span>
                        </div>
                    })}
                </div>
            </div>
            <div>
                <div className={styles['component-detail']}>
                    <div className={styles['component-detail-header']}>
                        <span className={'font-s1'}>Vessel info.</span>
                        {isPermission && <div className='d-flex justify-content-end'>
                            <Button className='bt-ghost' onClick={handleEdit} title='Edit'>
                                <Icon icon={ICON_NAME.EDIT}/>
                            </Button>
                        </div>}
                    </div>
                    <div>
                        <div className={styles['line-info']}>
                            <span>Vessel type:</span>
                            <span>{vesselTypes[type - 1].name}</span>
                        </div>
                        <div className={styles['line-info']}>
                            <span>Contact number:</span>
                            <span>{contact}</span>
                        </div>
                        <div className={styles['line-info']}>
                            <span>Width:</span>
                            <span>{width} meters</span>
                        </div>
                        <div className={styles['line-info']}>
                            <span>Length:</span>
                            <span>{length} meters</span>
                        </div>
                        <div className={styles['line-info']}>
                            <span>Speed:</span>
                            <span>{speed} knots</span>
                        </div>
                        {type !== VESSEL_TYPE.SERVICE && <>
                            <div className={styles['line-info']}>
                                <span>Capacity:</span>
                                <span>{Math.round(capacity / 1000) / 1000} tons</span>
                            </div>
                            <div className={styles['line-info']}>
                                <span>Harvesting Speed:</span>
                                <span>{harvest_capacity} fish/hour</span>
                            </div>
                            <div className={styles['line-info']}>
                                <span>Discharging speed:</span>
                                <span>{factory_discharge} ton/hour</span>
                            </div>
                        </>}
                        <div className={styles['line-info']}>
                            <span>Cleaning time:</span>
                            <span>{durationTxt(cleaning_time)}</span>
                        </div>
                        <div className={styles['line-info']}>
                            <span>OPV serial number:</span>
                            <span>{iot_name}</span>
                        </div>
                        <div className={styles['line-info']}>
                            <span>Operation type:</span>
                            <span>{operationType}</span>
                        </div>
                    </div>
                </div>
                <div className={styles['component-detail']}>
                    <p className={'font-s1'}>Checklists</p>
                    <div className={styles.checklists}>
                        {checklists.map((data: any) => <Checklist key={data.id} {...{data}} />)}
                    </div>
                </div>
                <div className={styles['component-detail']}>
                    <p className={'font-s1'}>Current position</p>
                    <div className={styles['line-info']}>
                        <span>Speed/heading:</span>
                        <span>{Math.round(Speed * 100) / 100} knots / {Math.round(Heading)}&#xb0;</span>
                    </div>
                    <div className={styles['line-info']}>
                        <span>Latitude/ Longitude:</span>
                        <span>{Longitude.toFixed(6)}/ {Latitude.toFixed(6)}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default connect(mapStateToProps, {
    updateVessel: vesselDetailActions.updateVessel,
    getVessel: vesselDetailActions.getVesselRequest,
})(Detail);
