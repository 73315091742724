import React from 'react';
import styles from './style.module.scss';
import {Button} from "antd";
import {IPropsChildren} from "../../../../util/varibles/interface";

interface IProps extends IPropsChildren {
    className?: string
    border?: boolean
    cancel?: {
        text?: string
        click?(): void
    }
    ok?: {
        text?: string
        disabled?: boolean
        loading?: boolean
        danger?: boolean
        click?(): void
    }
}

const Footer: React.FC<IProps> = (props) => {
    const {
        className,
        cancel = {},
        ok = {},
        children = <>
            <Button className='bt-default' onClick={cancel.click}>{cancel.text || 'Cancel'}</Button>
            <Button
                className='bt-primary'
                htmlType='submit'
                onClick={ok.click}
                disabled={ok.disabled}
                loading={ok.loading}
            >
                {ok.text || 'Save'}
            </Button>
        </>
    } = props;

    const classname = [styles.footer, className].join(' ');

    return <div className={classname} data-border={props.border}>
        {children}
    </div>;
};

export default Footer;