import React, {useEffect, useState} from 'react';
import {
    ACTION_TYPE,
    AVAILABLE_TIME_TYPE,
    OPERATION_TYPE,
    operationType,
    propsEmpty,
    treatmentTypes,
    VESSEL_TYPE,
    vesselType,
    vesselTypes
} from "../../../util/varibles/constants";
import {Checkbox, Col, Drawer, Form, Input, Row, Select, Upload} from "antd";
import {FormInstance} from "antd/lib/form";
import styles from './style.module.scss';
import {Storage} from 'aws-amplify';
import S3Image from "../../../components/S3Image";
import {IVessel} from "../../../util/varibles/interface";
import Header from '../../../components/Popup/Component/Header';
import {Symbol} from "../../../util/varibles/template";
import {showErrorResponse} from "../../../util/varibles/global";
import {datetime} from "../../../util/library/datetime";
import Footer from "../../../components/Popup/Component/Footer";
import {createVesselFetch, updateVesselFetch} from "../../../util/services/vessel";
import {notify, NotifyCode} from "../../../util/varibles/message";

const {Option} = Select;

interface IProps {
    editMode: ACTION_TYPE
    vessel?: IVessel
    title: string

    onSave(vessel: IVessel): void

    onClose?(): void
}

const PopupEditVessel: React.FC<IProps> = (props) => {
    const {editMode, vessel, title, onClose, onSave} = props;
    const formRef: any = React.createRef<FormInstance>();
    const [visible, setVisible] = useState(true);
    const [type, setType] = useState<any>();
    const [isDisable, setIsDisable] = useState(false);
    const [file, setFile] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [isTreatment, setIsTreatment] = useState(false);
    const [isUpload, setIsUpload] = useState(false);

    useEffect(() => {
        if (vessel) {
            setType(vessel.type);
            if (vessel.operation_type.includes(OPERATION_TYPE.TREATMENT)) {
                setIsTreatment(true)
            }
        }
    }, [vessel]);

    const handleChangeVesselType = (value: any) => {
        setType(value);
    }

    const onBack = () => {
        setType(vesselType)
        setIsDisable(false)
    }

    const checkOperationType = (e: any) => {
        if (!e.includes(OPERATION_TYPE.HARVEST) && type === VESSEL_TYPE.WELL_BOAT) {
            setIsDisable(true)
        } else {
            setIsDisable(false)
        }
    }

    const handleSubmit = (values: any) => {
        const {
            available_time_type,
            speed = 0,
            capacity = 0,
            harvestingSpeed = 0,
            factoryDischargingSpeed = 0,
            waitingUnitDischargingSpeed = 0,
            cleaningTime = 0,
            length = 0,
            width = 0,
            operation_sub_types = [],
            operation_type = [OPERATION_TYPE.EVENT, OPERATION_TYPE.TREATMENT],
            contact = '',
            volume,
            ...rest
        } = values;

        const {storages = []} = vessel || {};

        const data = {
            ...rest,
            contact,
            available_time_type: available_time_type ? AVAILABLE_TIME_TYPE.ONLY_AVAILABLE_TIME : AVAILABLE_TIME_TYPE.ALL,
            speed: Number(speed),
            capacity: Number(capacity) * 1000000,
            harvest_capacity: Number(harvestingSpeed),
            factory_discharge: Number(factoryDischargingSpeed),
            waiting_unit_capacity: Number(waitingUnitDischargingSpeed),
            length: Number(length),
            width: Number(width),
            cleaning_time: cleaningTime * 60 * 1000,
            operation_type,
            operation_sub_types,
            type: Number(type)
        }

        if (volume) {
            data.storages = [{...storages[0] || {}, value: Number(volume)}];
        }

        if (file) {
            const name = file.name.split('.');
            name[name.length - 2] = name[name.length - 2] + '_' + datetime().format('YYYYMMDD_HHmmSSZZ');
            const key = 'vessel/images/' + name.join('.');
            const image_url = 'vessel/images/' + name.join('.');
            Storage.put(key, file, {contentType: 'image/jpeg'})
                .then(() => handleSend({...data, image_url}))
                .catch((err: any) => showErrorResponse('Upload file failed', err));
        } else {
            if (editMode === ACTION_TYPE.EDIT) {
                handleSend({...vessel, ...data})
            } else {
                handleSend({...data, image_url: '-'})
            }
        }
    }

    const handleSend = (data: any) => {
        let fetch: any, code: NotifyCode, message = '';
        if (editMode === ACTION_TYPE.CREATE) {
            fetch = createVesselFetch;
            code = NotifyCode.S1;
            message = 'Create';
        } else {
            fetch = updateVesselFetch;
            code = NotifyCode.S2;
            message = 'Update';
        }
        setLoading(true);
        new Promise(resolve => resolve(fetch(data)))
            .then((rs: any) => {
                onSave(rs);
                setLoading(false);
                notify.success(code)([rs.name]);
                handleClose();
            })
            .catch((error) => {
                setLoading(false);
                showErrorResponse(message + ' failed', error);
            })
    }

    const handleClose = () => {
        setVisible(false);
        if (onClose)
            onClose()
    }

    const propsUpload = {
        fileList: file ? [file] : [],
        multiple: false,
        onRemove: () => {
            setFile(file)
        },
        beforeUpload: (file: any) => {
            setFile(file)
            return false;
        },
    };
    const {name: typeName = ''} = vesselType[type] || {}
    const {image_url} = vessel || {};
    const {
        storages = [],
        operation_type,
        operation_sub_types,
        harvest_capacity,
        factory_discharge,
        waiting_unit_capacity,
        capacity = 0,
        cleaning_time = 0
    } = vessel || {};
    const {value = null} = storages[0] || {};

    return <Drawer
        {...propsEmpty}
        closable={false}
        className={styles['popup-edit-vessel']}
        open={visible}
        width='100%'
        height='100%'
        placement='bottom'
        onClose={handleClose}
        title={<Header
            title={<span className='font-w5 font-s1'>{title ? title : '-'}</span>}
            onClose={handleClose}
        />}
    >
        <div className={styles['select-vessel']}>
            {type === undefined ? <div className={styles['select-vessel-type']}>
                <div className={styles['header']}>
                    <p>Add new vessel</p>
                    <p>Select vessel type you would like to add</p>
                </div>
                <div className={styles['container-vessel-type']}>
                    {vesselTypes.map(({id, name}) => <div key={id} {...{
                        className: styles['vessel-type'],
                        onClick: () => handleChangeVesselType(id)
                    }} >
                        <span>{name}</span>
                    </div>)}
                </div>
            </div> : <Form
                ref={formRef}
                onFinish={handleSubmit}
                name='vessel'
                layout='vertical'
                initialValues={{
                    ...vessel || {},
                    volume: value,
                    operation_sub_types: operation_sub_types ? operation_sub_types : [],
                    operation_type: operation_type ? operation_type : [],
                    cleaningTime: Math.round(cleaning_time / 60000),
                    capacity: Math.round(capacity / 1000) / 1000,
                    harvestingSpeed: harvest_capacity,
                    factoryDischargingSpeed: factory_discharge,
                    waitingUnitDischargingSpeed: waiting_unit_capacity,
                }}
            >
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        {editMode === ACTION_TYPE.CREATE && <Row gutter={[24, 24]} onClick={onBack}>
                            <Col span={24}>
                                <div className={styles['icon-back']}/>
                            </Col>
                        </Row>}
                        <Form.Item
                            label={<span className='font-s15'>Avatar</span>}
                            valuePropName="fileList"
                            getValueFromEvent={(e: any) => console.log("upload", e)}
                            extra="JPG, GIF or PNG. Max size of 800K"
                        >
                            {(!isUpload && editMode === ACTION_TYPE.EDIT) && <S3Image {...{
                                imageUrl: image_url || '',
                                height: 200
                            }} />}
                            <Upload
                                {...propsUpload}
                                onChange={() => setIsUpload(true)}
                                name="image_url"
                                listType="picture-card"
                            >
                                <div>
                                    <div style={{marginTop: 0}}>Choose file</div>
                                </div>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <p className="font-s16 font-w4">{typeName}</p>
                            </Col>
                        </Row>
                        <Form.Item label={<span className='font-s15'>Name</span>}
                                   name="name"
                                   rules={[{
                                       required: true,
                                       message: `"Vessel name" is required`
                                   }]}
                        >
                            <Input className='border-r-40'/>
                        </Form.Item>
                        {editMode === ACTION_TYPE.EDIT &&
                            <Form.Item label={<span className='font-s15'>Type</span>}
                                       name="type"
                                       rules={[{
                                           required: true,
                                           message: `"Type" is required`
                                       }]}
                            >
                                <Select
                                    getPopupContainer={() => {
                                        let element: any
                                        element = document.getElementsByClassName(styles['popup-edit-vessel'])[0]
                                        return element
                                    }}
                                    onChange={handleChangeVesselType}
                                >
                                    <Option value={1}>Process vessel</Option>
                                    <Option value={2}>Wellboat</Option>
                                    <Option value={3}>Service vessel</Option>
                                </Select>
                            </Form.Item>}
                        <Form.Item
                            label={<span className='font-s15'>Contact number</span>}
                            name="contact"
                        >
                            <Input className='border-r-40'/>
                        </Form.Item>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={<span className='font-s15'>Length</span>}
                                           name="length"
                                           rules={[{required: true, message: `"Length" is required`}]}
                                >
                                    <Input suffix={<span
                                        className='font-s15 font-w4 font-c-suffix'>meter</span>}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={<span className='font-s15'>Width</span>}
                                           name="width"
                                           rules={[{required: true, message: `"Width" is required`}]}
                                >
                                    <Input suffix={<span
                                        className='font-s15 font-w4 font-c-suffix'>meter</span>}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            className={styles['checkbox-custom']}
                            label={<span className='font-s15'>Operation type</span>}
                            name="operation_type"
                            rules={[{required: true, message: `"Operation type" is required`}]}
                        >
                            <Checkbox.Group style={{width: '100%'}} onChange={checkOperationType}>
                                {type !== VESSEL_TYPE.SERVICE &&
                                    <Checkbox value={OPERATION_TYPE.HARVEST}>Harvest</Checkbox>}
                                <Checkbox value={OPERATION_TYPE.EVENT}>Event</Checkbox>
                                {type === VESSEL_TYPE.WELL_BOAT &&
                                    <Checkbox value={OPERATION_TYPE.TRANSPORT}>Smolt
                                        transport</Checkbox>}
                                {(type === VESSEL_TYPE.WELL_BOAT || type === VESSEL_TYPE.SERVICE) && <>
                                    <Checkbox
                                        onChange={() => setIsTreatment(!isTreatment)}
                                        value={OPERATION_TYPE.TREATMENT}
                                    >
                                        {operationType[OPERATION_TYPE.TREATMENT].name}
                                    </Checkbox>
                                    <Checkbox value={OPERATION_TYPE.SERVICE}>{operationType[OPERATION_TYPE.SERVICE].name}</Checkbox>
                                </>}
                            </Checkbox.Group>
                        </Form.Item>
                        {isTreatment && <Form.Item className={styles['checkbox-custom']} name="operation_sub_types">
                            <Checkbox.Group style={{width: '100%'}}>
                                {treatmentTypes.map((item: any) =>
                                    <Checkbox key={item.id} value={item.id}>{item.name}</Checkbox>)}
                            </Checkbox.Group>
                        </Form.Item>}
                        {type !== VESSEL_TYPE.SERVICE &&
                            <Form.Item label={<span className='font-s15'>Capacity</span>}
                                       name="capacity"
                                       rules={[{
                                           required: true,
                                           message: `"Capacity" is required`
                                       }]}
                            >
                                <Input suffix={<span className='font-s15 font-w4 font-c-suffix'>tons</span>}/>
                            </Form.Item>}
                        {type !== VESSEL_TYPE.SERVICE && <Row gutter={24}>
                            {type === VESSEL_TYPE.WELL_BOAT ? <>
                                <Col span={24}>
                                    <Form.Item
                                        label={<span className='font-s15'>Harvesting speed</span>}
                                        name="harvestingSpeed"
                                        rules={[{
                                            required: !isDisable,
                                            message: `"Harvesting speed" is required`
                                        }]}
                                    >
                                        <Input
                                            suffix={<span className='font-s15 font-w4 font-c-suffix'>fish/h</span>}
                                            disabled={isDisable}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={<span
                                            className='font-s15'>Factory discharging speed</span>}
                                        name="factoryDischargingSpeed"
                                        rules={[{
                                            required: !isDisable,
                                            message: `"Factory discharging speed" is required`
                                        }]}
                                    >
                                        <Input
                                            suffix={<span className='font-s15 font-w4 font-c-suffix'>ton/h</span>}
                                            disabled={isDisable}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={<span
                                            className='font-s15'>Waiting unit discharging speed</span>}
                                        name="waitingUnitDischargingSpeed"
                                        rules={[{
                                            required: !isDisable,
                                            message: `"Unit discharging speed" is required`
                                        }]}
                                    >
                                        <Input
                                            suffix={<span className='font-s15 font-w4 font-c-suffix'>ton/h</span>}
                                            disabled={isDisable}
                                        />
                                    </Form.Item>
                                </Col>
                            </> : <>
                                <Col span={12}>
                                    <Form.Item
                                        label={<span className='font-s15'>Harvesting speed</span>}
                                        name="harvestingSpeed"
                                        rules={[{
                                            required: true,
                                            message: `"Harvesting speed" is required`
                                        }]}
                                    >
                                        <Input suffix={<span
                                            className='font-s15 font-w4 font-c-suffix'>fish/h</span>}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={<span className='font-s15'>Discharging speed</span>}
                                        name="factoryDischargingSpeed"
                                        rules={[{
                                            required: true,
                                            message: `"Discharging speed" is required`
                                        }]}
                                    >
                                        <Input
                                            suffix={<span className='font-s15 font-w4 font-c-suffix'>ton/h</span>}
                                        />
                                    </Form.Item>
                                </Col>
                            </>}
                        </Row>}
                        <Form.Item label={<span className='font-s15'>Speed</span>}
                                   name="speed"
                                   rules={[{required: true, message: `"Speed" is required`}]}
                        >
                            <Input suffix={<span
                                className='font-s15 font-w4 font-c-suffix'>knots</span>}/>
                        </Form.Item>
                        {type !== VESSEL_TYPE.SERVICE && <Form.Item
                            label={<span className='font-s15'>Volume</span>}
                            name="volume"
                        >
                            <Input
                                suffix={<span className='font-s15 font-w4 font-c-suffix'>{Symbol.m3}</span>}/>
                        </Form.Item>}
                        <Form.Item
                            label={<span className='font-s15'>Cleaning time</span>}
                            name="cleaningTime"
                            rules={[{required: true, message: `"Cleaning time" is required`}]}
                        >
                            <Input suffix={<span
                                className='font-s15 font-w4 font-c-suffix'>minute</span>}/>
                        </Form.Item>
                        <Form.Item
                            label={<span className='font-s15'>MMSI</span>}
                            name="mmsi"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item name="available_time_type" valuePropName="checked">
                            <Checkbox>
                                Use available time only
                            </Checkbox>
                        </Form.Item>

                    </Col>
                </Row>
                <Footer className={styles.footer} cancel={{click: handleClose}} ok={{loading}}/>
            </Form>}
        </div>
    </Drawer>;
};

export default PopupEditVessel;
