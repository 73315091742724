import React from 'react';
import styles from "./style.module.scss";
import {DatePicker} from "antd";
import {datetime} from "../../../../../../util/library/datetime";
import {propContainer} from "../../../../../../util/varibles/constants";

interface IProps {
    time: any

    onChange(value: any): void
}

const SelectTime: React.FC<IProps> = ({time, onChange}) => {

    return <div className={styles.wrapper}>
        <DatePicker variant={"borderless"}{...{
            ...propContainer,
            className: styles.picker,
            showTime: {format: 'HH:mm', minuteStep: 15},
            format: 'DD MMM, HH:mm',
            onChange: (value: any) => onChange(value.valueOf()),
            inputReadOnly: true,
            allowClear: false,
            value: datetime(time).value,
            suffixIcon: null
        }}/>
    </div>;
};

export default SelectTime;
