import callApi from "../api/callApi";
import {urlVesselSide} from "../api/v1";
import WSService from "../../web-socket/web-socket-service";

interface IGetDashboardOfVesselSide {
    vessels: string[]
}

export function getDashboardOfVesselSideFetch(body: IGetDashboardOfVesselSide, signal?: any) {
    return callApi(urlVesselSide + '/dashboard', {
        method: "POST",
        body: JSON.stringify(body),
        signal
    })
}

interface IUpdateStateOpOfVesselSide {
    operation_id: string
    tenant_id: string
    vessel_id: string
    time: number
    checklists: any
}

export function updateStateOpOfVesselSideFetch(body: IUpdateStateOpOfVesselSide, signal?: any) {
    const connection_id = WSService.getConnectId();
    return callApi(urlVesselSide + '/update/state', {
        method: "PUT",
        body: JSON.stringify({...body, connection_id}),
        signal
    })
}

interface IUpdateStatusOpOfVesselSide {
    operation_id: string
    tenant_id: string
    status: string
}

export function updateStatusOpOfVesselSideFetch(body: IUpdateStatusOpOfVesselSide) {
    const connection_id = WSService.getConnectId();
    return callApi(urlVesselSide + '/update/status', {
        method: "PUT",
        body: JSON.stringify({...body, connection_id}),
    })
}

interface IUpdateTimeStepVesselSide {
    operation_id: string
    tenant_id: string
    time: number
    index: number
}

export function updateTimeStepVesselSideFetch(body: IUpdateTimeStepVesselSide, signal?: any) {
    const connection_id = WSService.getConnectId();
    return callApi(urlVesselSide + '/edit/state', {
        method: "PUT",
        body: JSON.stringify({...body, connection_id}),
        signal
    })
}

interface ICrewSend {
    vessels: {
        vessel_id: string
        operations: any[]
    }[]
}

export function crewSendFetch(body: ICrewSend, signal?: any) {
    const connection_id = WSService.getConnectId();
    return callApi(urlVesselSide + '/confirm', {
        method: "POST",
        body: JSON.stringify({...body, connection_id}),
        signal
    })
}

interface IOrderUnits {
    operation_id: string
    tenant_id: string
    unit_order: {
        unit_id: string,
        "id": string
    }[]
}

export function orderUnitsFetch(body: IOrderUnits, signal?: any) {
    const connection_id = WSService.getConnectId();
    return callApi(urlVesselSide + '/order', {
        method: "PUT",
        body: JSON.stringify({...body, connection_id}),
        signal
    })
}
