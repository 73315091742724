import React, {useEffect, useState} from 'react';
import styles from "../../style.module.scss";
import {Switch} from "antd";
import {connect, useDispatch} from "react-redux";
import {diseasedZone} from "../../../../../util/varibles/constants";
import {BarentsWatchService} from "../../../../../util/barentswatch/api";
import {DashboardSetting, PreferenceSetting} from "../../../../../util/varibles/userSetting";
import {convertDisease} from "../../../../../util/varibles/global";
import {VIEW_MODE} from "../../../../../util/varibles/permission";
import {loginActions} from "../../../../LoginPage/reducer";
import {getSetting} from "../../../../../util/varibles/defaultSetting";
import {datetime} from "../../../../../util/library/datetime";
import GoogleMap from "../../../../../util/library/googlemap/googlemap";
import {dashboardActions} from "../../../reducer";
import {MAP_ELEMENT} from "../../../../../util/library/googlemap/constants";
import {AppState} from "../../../../../util/store/store";

const controller: { [diseasedType: string]: AbortController } = {}
const key: {
    [diseasedType: string]: {
        week: string
        zones: any[]
    }
} = {}

const mapStateToProps = (state: AppState) => {
    const viewMode = getSetting(state.login.user.setting, PreferenceSetting.VIEW_MODE);
    const {diseasedZone = {}} = state.dashboard.filter || {}
    return {
        viewMode,
        filter: diseasedZone,
        map: state.dashboard.map
    }
};

interface IProps {
    data: any
    filter: any
    map?: GoogleMap
    viewMode: VIEW_MODE
}

const DiseasedZone: React.FC<IProps> = (props) => {
    const {id, name} = props.data;
    const {filter = {}, map, viewMode} = props;
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const week = [datetime().year, datetime().week].join('/');
        if (week !== key[id]?.week) {
            setLoading(true)
            if (controller[id])
                controller[id].abort();

            controller[id] = new AbortController();
            new Promise(resolve => resolve(BarentsWatchService.getIlaControlAreaV2(id, week, {signal: controller[id].signal})))
                .then((rs: any) => {
                    const zones = rs.map((item: any) => convertDisease(item));
                    setLoading(false);
                    key[id] = {week, zones}
                })
                .catch(() => {
                    setLoading(false);
                })
        }
        setVisible(filter[id] && viewMode === VIEW_MODE.PLANNER);

        return () => {
            if (controller[id])
                controller[id].abort();
        };
    }, [id, filter, viewMode]);

    useEffect(() => {
        const {zones} = key[id] || {};
        if (zones)
            map?.generate({
                type: MAP_ELEMENT.DISEASED_ZONE,
                id,
                zones,
                visible,
                event: {
                    click: (data, type) => {
                        dispatch(dashboardActions.showInfo({data, targetType: type}))
                    }
                }
            });
    }, [id, map, visible, key[id], dispatch]);

    const onChange = (checked: any) => {
        const value = {...filter, [id]: checked};
        dispatch(dashboardActions.updateFilter({diseasedZone: value}));
        dispatch(loginActions.updateUserSetting([{[DashboardSetting.DISEASED_ZONE]: value}]));
    }

    const {color = ''} = diseasedZone[id] || {};
    const style: any = {'--diseased-color': color};

    return <div key={id} className={styles.line} style={style}>
        <div className={styles.diseasedLine}>
            <div className='diseased-zone' data-code={id}/>
            <div className='font-s15'>{name}</div>
        </div>
        <Switch
            loading={loading}
            checked={filter[id]}
            onChange={(checked) => onChange(checked)}
        />
    </div>
};

export default connect(mapStateToProps, {})(DiseasedZone);

