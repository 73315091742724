import React from 'react';
import {Checkbox} from "antd";
import {IVessel} from "../../../../util/varibles/interface";
import {connect, useDispatch} from "react-redux";
import styles from "../style.module.scss";
import LoadingBox from "../../../../components/LoadingBox";
import {DashboardSetting} from "../../../../util/varibles/userSetting";
import {loginActions} from "../../../LoginPage/reducer";
import {dashboardActions} from "../../reducer";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState) => {
    const {vessels: filter = []} = state.dashboard.filter || {}
    return {
        filter,
        loading: state.dashboard.loadingVessels,
        vessels: state.dashboard.vessels,
    }
};

interface IProps {
    filter: any[],
    loading: boolean
    vessels: IVessel[]
}

const Vessel: React.FC<IProps> = (props) => {
    const {filter = [], loading, vessels} = props;
    const dispatch = useDispatch();

    const onChange = (data: any) => {
        dispatch(dashboardActions.updateFilter({vessels: data}));
        dispatch(loginActions.updateUserSetting([{[DashboardSetting.VESSELS]: data}]));
    }

    const renderContent = () => {
        if (loading || vessels.length === 0)
            return <LoadingBox loading={loading} message="No data"/>

        return <Checkbox.Group className={[styles.checkbox, 'checkbox-fill'].join(' ')} value={filter}
                               onChange={onChange}>
            {vessels.map(({id, name}) => <Checkbox key={id} value={id}>{name}</Checkbox>)}
        </Checkbox.Group>
    }

    return <div className='pb-12'>
        {renderContent()}
        {filter.length > 0 && <div className='link mt-10 ml-5' onClick={() => onChange([])}>Clear</div>}
    </div>;
};

export default connect(mapStateToProps, {})(Vessel);
