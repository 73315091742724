import {LEVEL_DENSITY, levelDensity} from "../../../OperationSettingPage/Density/constants";
import {calculateDensity} from "../../Popup/EditOp/Body/constants";
import {IActivityLog, IUnitStorage, IVessel} from "../../../../util/varibles/interface";
import {OPERATION_MODE, STORAGE_TYPE} from "../../../../util/varibles/constants";

export const FuncOp = {
    BeginMerge: 'begin-merge',
    BeginCreate: 'begin-create',

    UncheckBiology: 'uncheck-biology',
    UpdateActivityLog: 'update-activity-log'
}

export function getDensity(density: number[], value: number) {
    let i = 0, length = density.length;
    for (i; i < length; i++) {
        if (value > density[i])
            continue;

        break;
    }

    if (i === 0) {
        return levelDensity[LEVEL_DENSITY.LOW]
    } else if (i === length) {
        return levelDensity[LEVEL_DENSITY.HIGH]
    } else {
        return levelDensity[LEVEL_DENSITY.MEDIUM][i - 1] || {}
    }
}

interface ICalculateDensity {
    fish_amount: number
    total_weight: number
    round_number?: number
    storages?: IUnitStorage[]
}

export const getStorages = (args: ICalculateDensity, vessel: IVessel) => {
    const {fish_amount, total_weight, round_number = 1, storages = []} = args;

    const list = storages.length === 0 ? (vessel.storages || []).filter(item => item.type === STORAGE_TYPE.TANK) : storages;
    if (list.length === 0)
        return [{value: 0}];

    const count = list.length;
    const amount = Math.round(fish_amount / round_number / count);
    const total = Math.round(total_weight / round_number / count);
    let subAmount = 0, subTotal = 0;

    return list.map((item, i) => {
        if (i < count) {
            subAmount += amount;
            subTotal += total;
        } else {
            return {
                ...item,
                fish_amount: fish_amount - subAmount,
                total_weight: total_weight - subTotal,
                density: calculateDensity(total_weight - subTotal, item.value)
            }
        }
        return {
            ...item,
            fish_amount: amount,
            total_weight: total,
            density: calculateDensity(total, item.value)
        }
    });
}

export const getTimeByOpMode = {
    [OPERATION_MODE.PLAN]: (step: IActivityLog) => step.est_start_time,
    [OPERATION_MODE.ETA]: (step: IActivityLog) => step.real_start_time || step.eta || step.est_start_time,
}