import React, {useState} from 'react';
import {connect} from "react-redux";
import stylesParent from "../../style.module.scss";
import {Input} from "antd";
import styles from "./style.module.scss";
import {SearchOutlined} from "@ant-design/icons";
import LoadingBox from "../../../../../components/LoadingBox";
import {getStatusOfOp} from "../../../../../util/varibles/global";
import Operation from "./Operation";
import {AppState} from "../../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    operations: state.dashboard.operations,
    loading: state.dashboard.loadingOp
});

interface IProps {
    operations: any
    loading: boolean
}

const Operations: React.FC<IProps> = ({operations, loading}) => {
    const [search, setSearch] = useState('');

    const handleKeyDown = (e: any) => {
        if (e.key === 'Escape') {
            setSearch('')
        }
    };

    const handleSearch = (e: any) => setSearch(e.target.value.trim());

    const value = search.toLowerCase();
    const renders = operations.reduce((list: any, data: any) => {
        const {
            operation: {operation_code, current_process},
            activity_log
        } = data;

        const statusTxt = getStatusOfOp({
            operation: data.operation,
            current_process,
            activity_log,
        });

        if (operation_code.toLowerCase().indexOf(value) !== -1)
            return [...list, <Operation key={operation_code} {...{data}}/>]
        if (statusTxt.toLowerCase().indexOf(value) !== -1)
            return [...list, <Operation key={operation_code} {...{data}}/>]
        return list;
    }, []);

    return <>
        <div className={stylesParent.header}>
            Upcoming operations
        </div>
        <div className={stylesParent.body}>
            <div className={styles.wrapperSearch}>
                <Input
                    className={styles.search}
                    value={search}
                    onChange={handleSearch}
                    onKeyDown={handleKeyDown}
                    placeholder='Search for vessel name, Operation code, Operation stat...'
                    suffix={<SearchOutlined style={{fontSize: '18px'}}/>}
                />
            </div>
            <div className={stylesParent.operations}>
                {renders.length === 0 ?
                    <LoadingBox loading={loading} message="There is no ongoing operation today."/> : renders}
            </div>
        </div>
    </>;
};

export default connect(mapStateToProps, {})(Operations);

