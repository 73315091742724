import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ACTION_TYPE, getRoleNames, userRole} from "../../../util/varibles/constants";
import styles from '../style.module.scss';
import {Button, Modal, Table, Tag, Tooltip} from "antd";
import {UnorderedListOutlined} from "@ant-design/icons";
import InputSearch from "../../../components/Basic/InputSearch";
import Icon, {ICON_NAME} from "../../../components/Icon";
import Menu from "../../../components/Menu";
import Header from "../../../components/Popup/Component/Header";
import ChangePassword from "../Component/ChangePassword";
import {IFactory, ISite} from "../../../util/varibles/interface";
import {openPopup} from "../../../components/Popup/Component/WrapperPopup";
import PopupEditUser from "../PopupEditUser";
import DeleteUser from "./DeleteUser";
import {IUser} from "../../LoginPage/constants";
import {AppState} from "../../../util/store/store";


const mapStateToProps = (state: AppState) => ({
    customers: state.user.customers,
    sites: state.user.sites,
    factories: state.user.factories,
    users: state.user.users,
    loadingUser: state.user.loadingUser,
});

interface IProps {
    customers: any
    sites: ISite[]
    factories: IFactory[]
    users: IUser[]
    loadingUser: boolean

    openInfo(key: string, list: any): void
}

interface IState {
    name: string
    list: any[]
    filter: any | {
        customers: any[],
        roles: any []
    }
}

class Users extends Component<IProps, IState> {
    state: IState = {
        name: '',
        list: [],
        filter: {
            customers: [],
            roles: []
        }
    }

    columns = (filter = {customers: [], roles: []}) => [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Roles',
            dataIndex: 'roles',
            key: 'roles',
            filters: filter.roles,
            filterIcon: (filtered: boolean) => <div className={styles['icon-filter']} data-on={filtered}/>,
            onFilter: (value: any, record: any) => record.roles.indexOf(value) === 0,
            render: (roles: any) => <div className={styles.roles}>
                {(roles || []).map((role: any) => {
                    let color = "geekblue";
                    if (role === 'admin') {
                        color = 'volcano';
                    }
                    return <Tag className='m-0' color={color} key={role}>
                        {getRoleNames([role])}
                    </Tag>;
                })}
            </div>
        },
        {
            title: 'Regions',
            key: 'regions',
            render: (data: any) => {
                const {regions, tenant_id} = data;
                if ((regions || []).length === 0)
                    return null;

                const regionsIds = new Set(regions);
                const {customers} = this.props;
                const customer = customers.find((item: any) => item.tenant_id === tenant_id)
                if (!customer)
                    return null;

                const {regions: regionsOrigin = []} = customer;
                const dataOfRegions: any = []
                for (let i = 0; i < regionsOrigin.length; i++) {
                    const {id} = regionsOrigin[i];
                    if (regionsIds.has(id)) {
                        regionsIds.delete(id);
                        dataOfRegions.push(regionsOrigin[i]);
                    }
                    if (dataOfRegions.size === 0)
                        break;
                }

                return (dataOfRegions || []).map((item: any) => item.name).join(', ')
            },
        },
        {
            title: 'Customer',
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            filterIcon: (filtered: boolean) => <div className={styles['icon-filter']} data-on={filtered}/>,
            filters: filter.customers,
            onFilter: (value: any, record: any) => record.tenant_id.indexOf(value) === 0,
            width: 140
        },
        {
            title: 'Vessel',
            key: 'vessels',
            width: 100,
            render: (data: any) => {
                const {vessels, tenant_id} = data;
                if ((vessels || []).length === 0)
                    return null;

                const vesselsIds = new Set(vessels);
                const {customers} = this.props;
                const customer = customers.find((item: any) => item.tenant_id === tenant_id)
                if (!customer)
                    return null;

                const {vessels: vesselsOrigin = []} = customer;
                const dataOfVessels: any = []
                for (let i = 0; i < vesselsOrigin.length; i++) {
                    const {id} = vesselsOrigin[i];
                    if (vesselsIds.has(id)) {
                        vesselsIds.delete(id);
                        dataOfVessels.push(vesselsOrigin[i]);
                    }
                    if (dataOfVessels.size === 0)
                        break;
                }

                return <div className={styles['col-vessels']}>
                    <div><Tooltip
                        title={dataOfVessels.map((item: any) => item.name).join(', ')}>{vessels.length}</Tooltip>
                    </div>
                    <Button
                        size='small'
                        icon={<UnorderedListOutlined/>}
                        onClick={() => this.props.openInfo('vessel', dataOfVessels)}
                    />
                </div>
            },
        },
        {
            title: 'Sites',
            dataIndex: 'sites',
            key: 'sites',
            width: 100,
            render: (list: any) => {
                if ((list || []).length === 0)
                    return null;

                const sitesIds = new Set(list);
                const {sites = []} = this.props;
                const dataOfSites: any = []
                for (let i = 0; i < sites.length; i++) {
                    const {id} = sites[i];
                    if (sitesIds.has(id)) {
                        sitesIds.delete(id);
                        dataOfSites.push(sites[i]);
                    }
                    if (sitesIds.size === 0)
                        break;
                }

                return <div className={styles['col-vessels']}>
                    <div><Tooltip title={dataOfSites.map((item: any) => item.name).join(', ')}>{list.length}</Tooltip>
                    </div>
                    <Button
                        size='small'
                        icon={<UnorderedListOutlined/>}
                        onClick={() => this.props.openInfo('site', dataOfSites)}
                    />
                </div>
            },
        },
        {
            title: 'Factories',
            dataIndex: 'factories',
            key: 'factories',
            width: 100,
            render: (list: any) => {
                if ((list || []).length === 0)
                    return null;

                const factoriesIds = new Set(list);
                const {factories = []} = this.props;
                const dataOfFactories: any = []
                for (let i = 0; i < factories.length; i++) {
                    const {id} = factories[i];
                    if (factoriesIds.has(id)) {
                        factoriesIds.delete(id);
                        dataOfFactories.push(factories[i]);
                    }
                    if (factoriesIds.size === 0)
                        break;
                }

                return <div className={styles['col-vessels']}>
                    <div>
                        <Tooltip title={dataOfFactories.map((item: any) => item.name).join(', ')}>
                            {list.length}
                        </Tooltip>
                    </div>
                    <Button
                        size='small'
                        icon={<UnorderedListOutlined/>}
                        onClick={() => this.props.openInfo('factory', dataOfFactories)}
                    />
                </div>
            },
        },
        {
            width: 60,
            render: (data: any) => <Menu
                items={[
                    {
                        key: 'change-password',
                        label: <div className='menu-line' onClick={() => this.changePassword(data)}>
                            <Icon icon={ICON_NAME.LOCK}/>
                            <div>Change password</div>
                        </div>
                    },
                    {
                        key: 'edit',
                        label: <div
                            className='menu-line'
                            onClick={() => this.handleEdit(ACTION_TYPE.EDIT, data)}
                        >
                            <Icon icon={ICON_NAME.EDIT}/>
                            <div>Edit user</div>
                        </div>
                    },
                    {
                        key: 'delete',
                        label: <DeleteUser data={data}/>
                    }
                ]}
                placement='bottomRight'
            >
                <div className='three-dot'/>
            </Menu>
        }
    ]

    constructor(props: IProps) {
        super(props);
        const {users} = this.props;
        this.state = {name: '', ...this.updateList(users, '')};
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        const {users} = nextProps;
        if (JSON.stringify(this.props.users) !== JSON.stringify(users)) {
            const {name} = nextState;
            this.setState(this.updateList(users, name.toLowerCase()))
            return false;
        }
        return true
    }

    handleFilter = (value: any) => {
        const {users} = this.props;
        const data = value.toLowerCase();
        this.setState({
            name: value,
            list: users.filter((item: any) => (item.email || '').toLowerCase().includes(data)),
        })
    }

    handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Escape') {
            const {users} = this.props
            this.setState({name: '', list: users})
        }
    };

    changePassword = (data: any) => {
        const modal = Modal.confirm({
            className: styles['modal-change-password'],
            icon: null,
            maskClosable: true,
            keyboard: true,
            title: <Header title={`Change password ${data.email}`} onClose={() => modal.destroy()}/>,
            content: <ChangePassword user={data} onClose={() => modal.destroy()}/>,
            centered: true,
            onCancel: () => modal.destroy()
        })
    }

    updateList = (users: any, value: string) => {
        const {list, customers, roles} = users.reduce((rs: any, item: any) => {
            if ((item.email || '').toLowerCase().includes(value)) {
                rs.list.push(item);
                rs.customers[item.tenant_id] = item.tenant_name;
                item.roles.forEach((sub: string) => rs.roles[sub] = true);
            }
            return rs;
        }, {list: [], customers: {}, roles: {}});

        return {
            list,
            filter: {
                customers: Object.keys(customers).reduce((list: any, key) => [...list, {
                    text: customers[key],
                    value: key
                }], []).sort((a: any, b: any) => a.text.localeCompare(b.text)),
                roles: Object.keys(userRole).reduce((list: any, key: any) => roles[key] ? [...list, {
                    text: getRoleNames([key]),
                    value: key
                }] : list, []),
            }
        }
    }

    handleEdit = (actionType: ACTION_TYPE, data?: IUser) => {
        const el = openPopup(<PopupEditUser
            editMode={actionType}
            user={data}
            visible={true}
            onClose={() => el.remove()}
        />)
    }

    render() {
        const {loadingUser} = this.props;
        const {name, list, filter = {customers: [], roles: []}} = this.state;

        return <div className={styles.slide}>
            <div className={styles['container-action-tab']}>
                <InputSearch placeHolder='Search by name' value={name}
                             onChange={(e: any) => this.handleFilter(e.target.value)}
                />
                <Tooltip title="Add user">
                    <Button
                        className='bt-primary bt-plus'
                        shape="circle"
                        onClick={() => this.handleEdit(ACTION_TYPE.CREATE)}
                    />
                </Tooltip>
            </div>
            <div className={styles.list}>
                <Table
                    loading={loadingUser}
                    pagination={false}
                    columns={this.columns(filter)}
                    dataSource={list}
                    rowKey='username'
                />
            </div>
        </div>;
    }
}

export default connect(mapStateToProps, {})(Users);
