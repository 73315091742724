import React from 'react';
import LeftControl from "./LeftControl";
import TopControl from "./TopControl";
import RightControl from "./RightControl";
import styles from './style.module.scss';
import {EL_DASHBOARD} from "./constants";
import {ACTION} from "../../../util/varibles/permission";

interface IProps {
    permission: any
}

const Control: React.FC<IProps> = ({permission}) => {
    return <div id={EL_DASHBOARD.CONTROL} className={styles.control}>
        {permission[ACTION.DASHBOARD.LEFT_CONTROL] && <LeftControl/>}
        {permission[ACTION.DASHBOARD.TOP_CONTROL] && <TopControl permission={permission}/>}
        {permission[ACTION.DASHBOARD.RIGHT_CONTROL] && <RightControl/>}
    </div>;
};

export default Control;
