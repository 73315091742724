import React, {Component} from 'react';
import styles from './style.module.scss';
import {Storage} from "aws-amplify";
import {downloadBlob} from "../../../../../../util/varibles/global";

interface IProps {
    files: any

    onDelete(file: any): void
}

class Files extends Component<IProps> {
    handleOpenFile = (file: any) => {
        const {isNew, name} = file;
        if (isNew) {
            const element = document.createElement("a");
            element.href = URL.createObjectURL(file.file);
            element.download = name;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            element.remove();
        } else {
            const {key} = file;
            Storage.get(`operations/${key}`, {download: true})
                .then((blob: any) => downloadBlob(blob.Body, name))
        }
    }

    render() {
        const {files} = this.props;
        return <div className={styles.wrapperFiles}>
            <div className={styles.files}>
                {files.map((item: any) => <div className={styles.file} key={item.key}>
                    <span onClick={() => this.handleOpenFile(item)}>{item.name}</span>
                    <div className={styles['file-remove']} onClick={() => this.props.onDelete(item)}>
                        <div className={styles['icon-close']}/>
                    </div>
                </div>)}
            </div>
        </div>;
    }
}

export default Files;