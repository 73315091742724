import React, {useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import styles from "../Operations/Operation/style.module.scss";
import {convertPxToTimeByStep} from "../../../util";
import {CONTRACT_TYPE, OPERATION_MODE} from "../../../../../util/varibles/constants";
import {notification} from "antd";
import {ILevelByVessel, IOperations, IVessel} from "../../../../../util/varibles/interface";
import {notify, NotifyCode} from "../../../../../util/varibles/message";
import {planValidation, VALIDATION} from "../../../util/validation";
import {planOpActions} from "../../../reducer";
import {AppState} from "../../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    userTenantId: state.login.user.tenant_id || '',
    operations: state.planOperation.operations,
    level: state.planOperation.level,
    opMode: state.login.user.tenant.operation_mode || OPERATION_MODE.PLAN
});

interface IProps {
    userTenantId: string
    operations: IOperations
    data: any
    name: string
    vessel: IVessel
    level: ILevelByVessel
    opMode: OPERATION_MODE

    setTime(time: any): void

    setLoading(loading: boolean): void

    updateAvailableTime(payload: any): any
}

let targetY = 0;

interface IProps {
}

const DragLine: React.FC<IProps> = (props) => {
    const {
        data,
        vessel,
        operations,
        level,
        name,
        userTenantId,
        opMode,
        setTime,
        setLoading,
        updateAvailableTime
    } = props;

    const {start_time: start, finish_time: finish} = data;
    const dragStart = (e: any) => {
        e.stopPropagation();
        const {pageY} = e;
        if (props.name === 'start')
            document.addEventListener('mousemove', dragMoveStart);
        else
            document.addEventListener('mousemove', dragMoveFinish);

        document.addEventListener('mouseup', dragEnd);
        targetY = pageY;
    }

    const dragMoveStart = useCallback((e: any) => {
        const {pageY} = e;
        const duration = convertPxToTimeByStep(pageY - targetY);
        setTime({start_time: start + duration, finish_time: finish})
    }, [start, setTime])

    const dragMoveFinish = useCallback((e: any) => {
        const {pageY} = e;
        const duration = convertPxToTimeByStep(pageY - targetY);
        setTime({start_time: start, finish_time: finish + duration});
    }, [finish, setTime])

    const dragEnd = useCallback((e: any) => {
        document.removeEventListener('mousemove', dragMoveStart);
        document.removeEventListener('mousemove', dragMoveFinish);
        document.removeEventListener('mouseup', dragEnd);
        const {pageY} = e;
        const duration = convertPxToTimeByStep(pageY - targetY);
        let {id, start_time, finish_time} = data;

        if (name === 'start') {
            start_time = data.start_time + duration;
        } else {
            finish_time = data.finish_time + duration;
        }

        let {message} = planValidation[VALIDATION.AVAILABLE_TIME]({...data, start_time, finish_time}, {
            operations,
            userTenantId,
            opMode,
            level
        })

        if (message.length === 0) {
            const {contracts, available_times} = vessel;
            let isError = contracts.some((item: any) => item.type === CONTRACT_TYPE.FIXED
                && item.tenant_id !== userTenantId
                && start_time <= item.finish_time
                && item.start_time <= finish_time)
            if (isError) {
                message = notify[NotifyCode.E6]();
            } else {
                isError = available_times.some((item: any) => item.id !== id
                    && start_time <= item.finish_time
                    && item.start_time <= finish_time)

                if (isError) {
                    message = notify[NotifyCode.E6]()
                }
            }
        }

        if (message.length > 0) {
            notification.error({message: 'Error', description: message});
            const {start_time, finish_time} = data;
            setTime({start_time, finish_time})
        } else
            updateAvailableTime({
                ...data,
                start_time: start_time.valueOf(),
                finish_time: finish_time.valueOf()
            })
    }, [dragMoveStart, dragMoveFinish, data, name, operations, level, userTenantId, opMode, updateAvailableTime, vessel, setTime])

    useEffect(() => {
        return () => {
            document.removeEventListener('mousemove', dragMoveStart);
            document.removeEventListener('mousemove', dragMoveFinish);
            document.removeEventListener('mouseup', dragEnd);
        };
    }, [dragMoveStart, dragMoveFinish, dragEnd]);

    return <div {...{
        className: styles['line'],
        'data-line-type': props.name,
        onMouseDown: (e) => dragStart(e)
    }} />
};

export default connect(mapStateToProps, {
    updateAvailableTime: planOpActions.updateAvailableTime
})(DragLine);

