import React, {useState} from 'react';
import stylesOp from "../style.module.scss";
import {KeyOfStep, OP_STATUS, OPERATION_TYPE} from "../../../../../util/varibles/constants";
import Header from "../../../Component/Header";
import Note from "../../../Component/Note";
import FreshWaterDuration from '../../../Component/FreshWaterDuration'
import ActivityLogs2 from '../../../ActivitiesLog/ActivityLogs2'
import SortingInfo from '../../../Component/SortingInfo'
import {convertActivityLog} from "../../../../../util/varibles/global";
import Units from "../../../Component/Units";
import StepControl from "../../../Component/StepControl";
import Icon, {ICON_NAME} from "../../../../Icon";
import {IPropsOp} from "../index";

interface IProps extends IPropsOp {

}

const Treatment: React.FC<IProps> = ({data, step, active, actions, changeStep, extend, onUpdate}) => {
    const [isShowActivityLog, setIsShowActivityLog] = useState(false)
    const {status, site, note, tasks: operationTask, current_process} = data.operation;
    const {name: siteName = ""} = site || {}
    const {activity_log = [],} = data;
    const {key = '', unit_id = undefined, duration = 0} = activity_log[step] || {};
    const activityLog = convertActivityLog[OPERATION_TYPE.TREATMENT](activity_log);

    return <>
        <Header
            data={data}
            step={step}
            active={active}
            icon={<Icon icon={ICON_NAME.TREATMENT}/>}
            extend={extend}
            isShowActivityLog={isShowActivityLog}
            changeShowActivityLog={() => setIsShowActivityLog(!isShowActivityLog)}
        >
            <div className={[stylesOp['operation-route'], 'd-flex'].join(' ')}>
                <div>{siteName}</div>
            </div>
        </Header>
        <div className={stylesOp['operation-info']}>
            <Units data={data} activityLog={activityLog} step={step} changeStep={changeStep} onUpdate={onUpdate}/>
            <div className={stylesOp['operation-content']}>
                <StepControl data={data} actions={actions} step={step} changeStep={changeStep} onUpdate={onUpdate}/>
                {isShowActivityLog
                    ? <div className={stylesOp.activityLog}>
                        <ActivityLogs2
                            current_process={current_process}
                            activity_log={activityLog}
                            activity_log_root={activity_log}
                        />
                    </div>
                    : (status === OP_STATUS.PROCESSING && <div className={stylesOp['operation-step-content']}>
                        {(key === KeyOfStep.TREATING_FRESHWATER || key === KeyOfStep.TREATING_FRESH_WELLFIGHTER) &&
                        <FreshWaterDuration duration={duration}/>}
                        {key.includes(KeyOfStep.SORTING) &&
                        <SortingInfo unit_id={unit_id} tasks={operationTask}/>}
                        <Note note={note}/>
                    </div>)}
            </div>
        </div>
    </>;
};

export default Treatment;