import React, {Component} from 'react';
import styles from './style.module.scss';
import Task from "../Task";
import LineMiddle from "../../../_Component/LineMiddle";
import stylesContainer from "../style.module.scss";
import Title from "./Title";
import {UpdateName} from "../../constants";

interface IProps {
    list: any
    site: any
    unit: any
    vessel: any
    listOfTask: any
    groupIndex: number

    onUpdate(key: UpdateName, args: any): void
}

class Unit extends Component<IProps> {
    render() {
        const {
            list,
            site,
            unit,
            vessel,
            listOfTask,
            groupIndex,
            onUpdate
        } = this.props;

        if (!unit)
            return <div/>;

        return <div className={[styles['group'], stylesContainer['group']].join(' ')}>
            <Title {...{
                list,
                site,
                unit,
                vessel,
                onUpdate,
            }}/>
            <div className={stylesContainer['line-middle']} data-level='unit'>
                <LineMiddle {...{containerId: 'service-operation', unitId: unit.id, index: list[0].index}} />
            </div>
            <div className={[styles['line-middle'], stylesContainer['line-middle']].join(' ')} data-level='group'>
                <LineMiddle {...{containerId: 'service-operation', unitId: unit.id, index: groupIndex + 1}} />
            </div>
            {list.map((item: any, subIndex: number) => <Task key={subIndex} {...{
                data: item,
                vessel,
                listOfTask,
                site,
                unit,
                onUpdate,
            }}/>)}
        </div>
    }
}

export default Unit;