import React, {createRef, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import stylesContainer from "../style.module.scss";
import {Input, Select, Space} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import AddExternalSite from "../_Component/AddExternalSite";
import Filter from "./Filter";
import AvgWeight from "../_Component/AvgWeight";
import LiceSort from "../_Component/LiceSort";
import {loginActions} from "../../../LoginPage/reducer";
import {PlanSetting} from "../../../../util/varibles/userSetting";

interface IProps {
    siteType: any
    searchValue: any
    weightFilter: [number | null, number | null],
    sites: any
    unitsSelected: any
    liceSort: null | string

    onSearch(value: any): void

    onClear(): void
}

const {Option} = Select;

const HeaderUnits: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const rootRef = createRef<HTMLDivElement>()

    const handleChangeLice = (value: any) => {
        props.onSearch({liceSort: value})
        dispatch(loginActions.updateUserSetting([{[PlanSetting.BIOLOGY_LICE_SORT]: value}]))
    }

    useEffect(() => {
        if (rootRef.current) {
            const element = document.getElementById('units');
            if (element) {
                const {offsetWidth, clientWidth} = element
                rootRef.current.style.width = `calc(100% - ${offsetWidth - clientWidth}px)`
            }
        }
    }, [rootRef.current]);

    const {unitsSelected, sites, weightFilter, liceSort, onSearch, onClear} = props;
    const count = Object.keys(unitsSelected).length;

    return <div ref={rootRef} className={stylesContainer['tab-header']}>
        <div className={stylesContainer['header-left']} data-operaion-type='units'>
            <LiceSort lice={liceSort} onChange={handleChangeLice}/>
            {count > 0 && <div className={stylesContainer.clear}>
                <span>{count} unit selected</span>
                <div className={stylesContainer.close} onClick={onClear}>
                    <Icon icon={ICON_NAME.CLOSE} size='small'/>
                </div>
            </div>}
            <Space.Compact className={[stylesContainer['search-group']].join(' ')}>
                <Select allowClear placeholder='Site type'
                        onChange={(value: any = '') => onSearch({siteType: value})}
                        style={{width: '100px'}}
                >
                    <Option value="external">External</Option>
                    <Option value="internal">Internal</Option>
                </Select>
                <Input
                  style={{width: 'calc(100% - 100px)'}}
                  placeholder="Search site"
                  allowClear
                  onChange={({target: {value}}: any) => onSearch({searchValue: value})}
                  suffix={<SearchOutlined className='text-comment'/>}
                />
            </Space.Compact>
            <AvgWeight weight={weightFilter} onChange={value => onSearch({weightFilter: value})}/>
        </div>
        <div className={stylesContainer['header-right']}>
            <Filter {...{sites}}/>
            <AddExternalSite/>
        </div>
    </div>;
};

export default HeaderUnits;