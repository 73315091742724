import React, {Component} from 'react';
import Sorting from "./Sorting";
import {TREATMENT_TYPE, treatmentType} from "../../../../../../util/varibles/constants";
import {convertNumberTimeToStringTime} from "../../../../../../util/varibles/global";

interface IProps {
    data: any
    isSorting: boolean
    isChild: boolean
}

class Treatment extends Component<IProps> {

    renderContent = (type: TREATMENT_TYPE) => {
        switch (type) {
            case TREATMENT_TYPE.HYDROGENPEROXIDE:
            case TREATMENT_TYPE.FRESH_WELLFIGHTER:
            case TREATMENT_TYPE.FRESHWATER:
            case TREATMENT_TYPE.SALTWATER: {
                const {duration} = this.props.data;
                return <div className='ml-1'>({convertNumberTimeToStringTime(duration)})</div>
            }
            case TREATMENT_TYPE.SORTING: {
                const {data, isChild} = this.props;
                return <Sorting data={data} isChild={isChild}/>
            }
            default:
                return null
        }
    }

    render() {
        const {treatment_type} = this.props.data;
        const {name = ''} = treatmentType[treatment_type] || {};
        return <>
            <div data-lev='name'>{name}</div>
            {this.renderContent(treatment_type)}
        </>;
    }
}

export default Treatment;