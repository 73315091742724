import React, {Component} from 'react';
import styles from './style.module.scss';
import {Select} from "antd";
import {deliveryUnits, initDeliveryType} from "../constants";
import {ACTION_TYPE} from "../../../../../../../util/varibles/constants";
import Menu from "../../../../../../../components/Menu";

interface IProps {
    options: any
    delivery_types: any
    avg_weight: any
    editMode: any
    isInternal: boolean

    handleUpdate({...args}): void

    handleDeleteOperation(): void
}

class DeliveryTypes extends Component<IProps> {

    handleChange = ({...args}) => {
        const {delivery_types, isInternal} = this.props;
        const {key, value, indexDeliveryType} = args;
        switch (key) {
            case 'id': {
                const {id, name} = value;
                this.props.handleUpdate({
                    key: 'delivery_types',
                    value: delivery_types.map((item: any, index: number) => index !== indexDeliveryType ? item : {
                        ...item, id, name
                    })
                })
                break;
            }
            case 'value': {
                if (!value.target.validity.valid)
                    return;
                const data = value.target.value;

                const {avg_weight} = this.props;
                const {unit, default: isDefault} = delivery_types[indexDeliveryType];
                if (isDefault) {
                    const value = unit === deliveryUnits[0].id ? Math.round(data * 1000000) : Math.round(data);
                    this.props.handleUpdate({
                        key: 'delivery_types',
                        value,
                        indexDeliveryType,
                        isDeliveryTypeDefault: true
                    })
                } else {
                    const deliveryTypeDefault = delivery_types.find((item: any) => item.default);
                    const newDefault = {...deliveryTypeDefault}
                    const {total_weight: totalWeightRoot, fish_amount: fishAmountRoot} = deliveryTypeDefault
                    let total_weight = 0, fish_amount = 0;
                    if (unit === deliveryUnits[0].id) {
                        const {total_weight: dataOld} = delivery_types[indexDeliveryType];
                        total_weight = Math.round(data * 1000000);
                        const maxValue = dataOld + totalWeightRoot;
                        if (total_weight > maxValue) {
                            total_weight = maxValue - avg_weight;
                            newDefault.total_weight = avg_weight;
                            newDefault.fish_amount = isInternal ? 1 : 0;
                        } else {
                            newDefault.total_weight = maxValue - total_weight;
                            newDefault.fish_amount = isInternal ? Math.round(newDefault.total_weight / avg_weight) : 0;
                        }
                        fish_amount = isInternal ? Math.round(total_weight / avg_weight) : 0;
                    } else {
                        const {fish_amount: dataOld} = delivery_types[indexDeliveryType];
                        fish_amount = isInternal ? Math.round(data) : 0;
                        const maxValue = dataOld + fishAmountRoot;
                        if (fish_amount > maxValue) {
                            fish_amount = isInternal ? (maxValue - avg_weight) : 0;
                            newDefault.total_weight = avg_weight;
                            newDefault.fish_amount = isInternal ? 1 : 0;
                        } else {
                            newDefault.fish_amount = isInternal ? maxValue - fish_amount : 0;
                            newDefault.total_weight = newDefault.fish_amount * avg_weight;
                        }
                        total_weight = fish_amount * avg_weight;
                    }
                    this.props.handleUpdate({
                        key: 'delivery_types',
                        value: delivery_types.map((item: any, index: number) => {
                            if (index === indexDeliveryType) {
                                return {...item, total_weight, fish_amount}
                            } else if (item.default) {
                                return newDefault;
                            } else {
                                return item
                            }
                        })
                    })
                }
                break;
            }
            case 'convert': {
                if (isInternal) {
                    const {avg_weight} = this.props;
                    this.props.handleUpdate({
                        key: 'delivery_types',
                        value: delivery_types.map((item: any, index: number) => index !== indexDeliveryType ? item : {
                            ...item,
                            total_weight: item.fish_amount * avg_weight
                        })
                    })
                }
                break;
            }
            default:
                this.props.handleUpdate({
                    key: 'delivery_types',
                    value: delivery_types.map((item: any, index: number) => index !== indexDeliveryType ? item : {
                        ...item,
                        [key]: value
                    })
                })
                return;
        }
    }

    handleDelete = (indexDeliveryType: number) => {
        const {fish_amount} = this.props.delivery_types[indexDeliveryType];
        const {avg_weight} = this.props;
        this.props.handleUpdate({
            key: 'delivery_types',
            value: this.props.delivery_types.reduce((list: any, item: any, index: number) => {
                if (item.default) {
                    item.fish_amount += fish_amount;
                    item.total_weight = item.fish_amount * avg_weight
                    return [...list, item]
                } else if (index !== indexDeliveryType) {
                    return [...list, item]
                }
                return list;
            }, [])
        })
    }

    handleSplit = () => {
        this.props.handleUpdate({
            key: 'delivery_types',
            value: [...this.props.delivery_types, initDeliveryType]
        })
    }

    renderMenu = (isDefault: boolean, index: number) => {
        return isDefault ? [
            ...(this.props.editMode === ACTION_TYPE.CREATE ? [{
                key: 'delete',
                label: <div onClick={this.props.handleDeleteOperation}><span>Delete unit</span></div>
            }
            ] : []), {
                key: 'split',
                label: <div onClick={this.handleSplit}><span>Split</span></div>
            }
        ] : [{
            key: 'delete',
            label: <div onClick={() => this.handleDelete(index)}><span>Delete sub delivery type</span></div>
        }]
    }

    render() {
        const {Option} = Select;
        const {options, delivery_types, isInternal} = this.props;
        const filterOptions = Object.keys(options).reduce((list: any, key: any) => {
            const index = delivery_types.findIndex((item: any) => item.id === key)
            if (index === -1)
                return [...list, {
                    name: options[key].name,
                    view: <Option key={key} {...{value: key, delivery_type: options[key]}}>
                        {options[key].name}
                    </Option>
                }]
            return list
        }, []);

        return <div className={styles['container_delivery_types']}>
            {delivery_types.map((item: any, indexDeliveryType: number) =>
                <div key={indexDeliveryType} className={styles.deliveryType}>
                    <div className={styles.deliveryTypeControl}>
                        <Select{...{
                            showSearch: true,
                            value: item.id,
                            popupMatchSelectWidth: false,
                            popupClassName: 'no-scroll',
                            onSelect: (value: any, option: any) => this.handleChange({
                                key: 'id',
                                value: option.delivery_type,
                                indexDeliveryType
                            })
                        }}>
                            {(options[item.id] ? [...filterOptions, {
                                name: options[item.id].name,
                                view: <Option key={item.id} {...{value: item.id, delivery_type: options[item.id]}}>
                                    {options[item.id].name}
                                </Option>
                            }] : filterOptions).sort((a: any, b: any) => a.name.localeCompare(b.name))
                                .map((item: any) => item.view)}
                        </Select>
                        <input className='text-center' pattern="[0-9]*\.?[0-9]*"
                               value={item.unit === deliveryUnits[0].id ? Math.round(item.total_weight / 1000) / 1000 : item.fish_amount}
                               onChange={(e: any) => this.handleChange({
                                   key: 'value',
                                   value: e,
                                   indexDeliveryType
                               })}
                               onBlur={() => this.handleChange({
                                   key: 'convert',
                                   indexDeliveryType
                               })}
                        />
                        <Select {...{
                            value: item.unit || deliveryUnits[1].id,
                            popupClassName: 'no-scroll',
                            popupMatchSelectWidth: false,
                            onChange: (value: any) => this.handleChange({
                                key: 'unit',
                                value: value,
                                indexDeliveryType
                            })
                        }}>
                            {isInternal
                                ? deliveryUnits.map(item => <Option key={item.id}
                                                                    value={item.id}>{item.name}</Option>)
                                : <Option key={deliveryUnits[0].id}
                                          value={deliveryUnits[0].id}>{deliveryUnits[0].name}</Option>}
                        </Select>
                    </div>
                    <Menu items={this.renderMenu(item.default, indexDeliveryType)} placement="bottomRight">
                        <div className='three-dot' onClick={e => e.preventDefault()}/>
                    </Menu>
                </div>)}
        </div>;
    }
}

export default DeliveryTypes;
