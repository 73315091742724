import React from 'react';
import {IDifferenceLogs} from "../../../../../util/varibles/interface";
import styles from './style.module.scss';
import {
    certainType,
    changeLog,
    eventType,
    KeyChangeLog,
    listOfStep,
    MONTH_DATE_FORMAT,
    SHORT_MONTH_DATETIME_FORMAT,
    SHORT_TIME_FORMAT
} from "../../../../../util/varibles/constants";
import {datetime} from "../../../../../util/library/datetime";
import {convertNumberTimeToStringTime, uppercaseFirstLetter} from "../../../../../util/varibles/global";
import Units from "./Units";

export const view = {
    simple: (key: KeyChangeLog, viewOld: any, viewNew: any) => {
        const {name} = changeLog[key] || {};
        return <>{name} changing from <b>{viewOld}</b> to <b>{viewNew}</b>.</>
    }
}

interface IProps {
    data: IDifferenceLogs
}

const Differences: React.FC<IProps> = (props) => {
    const {[KeyChangeLog.TASKS]: tasks, [KeyChangeLog.SUB_OPS]: sub_ops, ...data} = props.data || {};

    return <div className={styles.differences}>
        {Object.keys(data || {}).map(key => {
            switch (key) {
                case KeyChangeLog.START_TIME:
                case KeyChangeLog.FINISH_TIME: {
                    const {new: value, old} = data[key] || {};
                    const timeA = datetime(old).format(MONTH_DATE_FORMAT);
                    const timeB = datetime(value).format(MONTH_DATE_FORMAT);
                    const format = timeA === timeB ? SHORT_TIME_FORMAT : SHORT_MONTH_DATETIME_FORMAT
                    const viewOld = datetime(old).format(format)
                    const viewNew = datetime(value).format(format)
                    return <div key={key} className={styles.difference}>
                        {view.simple(key, viewOld, viewNew)}
                    </div>
                }
                case KeyChangeLog.DURATION: {
                    const {new: value, old} = data[key] || {};
                    const viewOld = convertNumberTimeToStringTime(old)
                    const viewNew = convertNumberTimeToStringTime(value)
                    return <div key={key} className={styles.difference}>
                        {view.simple(key, viewOld, viewNew)}
                    </div>
                }
                case KeyChangeLog.CERTAIN_MODE: {
                    const {new: value, old} = data[key] || {};
                    const {name: viewOld} = certainType[old];
                    const {name: viewNew} = certainType[value];
                    return <div key={key} className={styles.difference}>
                        {view.simple(key, viewOld, viewNew)}
                    </div>
                }
                case KeyChangeLog.EVENT_TYPE: {
                    const {new: value, old} = data[key] || {};
                    const {name: viewOld} = eventType[old];
                    const {name: viewNew} = eventType[value];
                    return <div key={key} className={styles.difference}>
                        {view.simple(key, viewOld, viewNew)}
                    </div>
                }
                case KeyChangeLog.PARALLEL: {
                    const {new: value} = data[key] || {};
                    const {name} = changeLog[key];
                    const actionText = value ? 'Turn on' : "Turn off";
                    return <div key={key} className={styles.difference}>
                        {uppercaseFirstLetter([actionText, name].join(' '))}
                    </div>
                }
                case KeyChangeLog.NOTE: {
                    const {new: value, old} = data[key] || {}
                    if ((value || '').length > 0 && (old || '').length > 0)
                        return <div key={key} className={styles.difference}>
                            {view.simple(key, old, value)}
                        </div>
                    else {
                        const {name} = changeLog[key];
                        const viewText = value || old;
                        if ((value || '').length > 0) {
                            return <div key={key} className={styles.difference}>
                                Add {name.toLowerCase()}: <b>{viewText}</b>
                            </div>
                        } else {
                            return <div key={key} className={styles.difference}>
                                Remove {name.toLowerCase()}
                            </div>
                        }
                    }
                }
                case KeyChangeLog.VESSEL_NAME: {
                    const {new: value = '', old = ''} = data[key] || {};
                    return <div key={key} className={styles.difference}>
                        {view.simple(key, old, value)}
                    </div>
                }
                case KeyChangeLog.CLEANING: {
                    const {new: value} = data[key];
                    const {name} = changeLog[key];
                    return <div key={key} className={styles.difference}>
                        {uppercaseFirstLetter([value ? 'Add' : "Remove", name].join(' '))}
                    </div>
                }
                case KeyChangeLog.DELAY_TIME: {
                    const {new: value} = data[key] || {};
                    const {key: stepKey = '', time_late = 0} = value || {};
                    const timeText = convertNumberTimeToStringTime(time_late);
                    const {name: stepName} = listOfStep[stepKey] || {};
                    const {name} = changeLog[key] || {};
                    return <div key={key} className={styles.difference}>
                        {name}: <b>{stepName} ({timeText})</b>.
                    </div>
                }
                case KeyChangeLog.ROUTER_ID: {
                    return <div key={key} className={styles.difference}>
                        Route has been changed
                    </div>
                }
                case KeyChangeLog.FILES: {
                    const {new: value = [], old = []} = data[key];
                    if (value.length > 0 && old.length > 0) {
                        return <div key={key} className={styles.difference}>
                            Attached file has been updated
                        </div>
                    } else {
                        return <div key={key} className={styles.difference}>
                            {value.length > 0 ? "Add" : 'Deleted'} attachments
                        </div>
                    }
                }
                default: {
                    console.log(key, data[key]);
                    return null
                }
            }
        })}
        <Units tasks={tasks} sub_ops={sub_ops}/>
    </div>;
};

export default Differences;
