import React, {Component} from 'react';
import {connect} from 'react-redux';
import {KEY_TAB, OPERATION_TYPE, SITE_TYPE} from "../../../../util/varibles/constants";
import {UnitModel} from "../constants";
import HeaderSmoltComponent from "./HeaderSmoltComponent";
import stylesContainer from "../style.module.scss";
import styles from './style.module.scss';
import {ISite, IStateGlobal, IUnit} from "../../../../util/varibles/interface";
import {PlanSetting} from "../../../../util/varibles/userSetting";
import Cards from "../_Component/Cards";
import Card from "../_Component/Cards/Card";
import Location from "../_Component/Location";
import UnitDiseases from "../_Component/UnitDiseases";
import FishDisease from "../../../../components/Location/FishDisease";
import FishAndWeight from "../_Component/FishAndWeight";
import {openPopup} from "../../../../components/Popup/Component/WrapperPopup";
import Site from "../../Popup/Site";
import UnitLice from "../../../../components/Operation/UnitLice";

interface IState {
    units: any
    unitsSelected: any
    searchValue: string
    weightFilter: [number | null, number | null],
    smoltFilter: any[],
    smoltProdAreaFilter: any[]
    liceSort: string | null
}

const initState: IState = {
    liceSort: null,
    units: [],
    unitsSelected: {},
    searchValue: '',
    weightFilter: [null, null],
    smoltFilter: [],
    smoltProdAreaFilter: [],
}

const mapStateToProps = (state: IStateGlobal) => {
    const {
        [PlanSetting.SMOLT_SITE]: smoltFilter = [],
        [PlanSetting.SMOLT_PROD_AREA]: smoltProdAreaFilter = [],
        [PlanSetting.SMOLT_LICE_SORT]: liceSort = null
    } = state.login.user.setting || {};

    return {
        setting: {
            liceSort,
            smoltFilter,
            smoltProdAreaFilter,
        },
    }
};

interface IProps {
    loading: boolean
    smoltSites: any
    clear: any
    setting: {
        liceSort: null | string
        smoltFilter: any
        smoltProdAreaFilter: any
    }

    onDragStart(e: any, units: any, requestType: OPERATION_TYPE[]): void

    onDragEnd(e: any): void
}

class SmoltSite extends Component<IProps> {
    state = {...initState}

    constructor(props: IProps) {
        super(props);
        const {smoltSites} = this.props;
        const units = this.onFilter({...initState, ...this.props.setting}, smoltSites);
        this.state = {...initState, ...this.props.setting, units};
    }

    componentDidMount() {
        this.props.clear(this.clear);
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        const {smoltSites, setting} = nextProps;
        if (JSON.stringify(this.props.smoltSites) !== JSON.stringify(smoltSites)
            || JSON.stringify(this.props.setting) !== JSON.stringify(setting)) {
            this.setState({units: this.onFilter({...nextState, ...setting}, smoltSites)});
        }
        return this.state !== nextState
    }

    handleCheck = (isCheck: boolean, data: any) => {
        let {unitsSelected}: any = this.state;
        if (isCheck) {
            unitsSelected = {...unitsSelected, ...data};
        } else {
            Object.keys(data).forEach(key => {
                const {[key]: old, ...value} = unitsSelected;
                unitsSelected = value;
            })
        }
        this.setState({unitsSelected})
    }

    handleSearch = (value: any) => {
        const {smoltSites} = this.props;
        const units: any = this.onFilter({...this.state, ...value}, smoltSites);
        this.setState({...value, units})
    };

    onFilter = (state: any, sites: any) => {
        const {searchValue, weightFilter, liceSort, smoltFilter = [], smoltProdAreaFilter = []} = state;
        const countSite = smoltFilter.length;
        const countProdArea = smoltProdAreaFilter.length;
        const listOfSiteFilter = new Set(smoltFilter);
        const listOfProdAreaFilter = new Set(smoltProdAreaFilter);
        const [minWeight, maxWeight] = weightFilter;
        const liceInSites: ISite[] = [];
        const normal = sites.reduce((list: any[], site: ISite) => {
            const {id, name, prod_area = ''} = site
            const isSearch = searchValue.length === 0
                || (searchValue.length > 0 && name.indexOf(searchValue.toLowerCase()) !== -1)
            if (!isSearch) return list;

            const isSite = (countSite === 0 || listOfSiteFilter.has(id))
                && (countProdArea === 0 || listOfProdAreaFilter.has(prod_area))
            if (!isSite) return list;

            const {lice} = site.diseases || {};
            if (lice && lice[liceSort]) {
                liceInSites.push(site)
                return list;
            }

            return [...list, ...site.units.reduce((items: any, unit: IUnit) => {
                const {avg_weight = 0, fish_amount} = unit;
                const isMin = minWeight ? (minWeight <= avg_weight && fish_amount) : true;
                if (!isMin) return items;

                const isMax = maxWeight ? (maxWeight >= avg_weight && fish_amount) : true;
                if (!isMax) return items;

                return [...items, UnitModel(unit, site)]
            }, [])]
        }, [])
        return [
            ...liceInSites
                .sort((a: ISite, b: ISite) => {
                    const {[liceSort]: liceA} = a.diseases.lice || {};
                    const {[liceSort]: liceB} = b.diseases.lice || {};
                    return liceB.value - liceA.value
                })
                .reduce((rs: any, site) => [...rs, ...site.units.reduce((items: any, unit: IUnit) => {
                    const {avg_weight, fish_amount} = unit;
                    const isMin = minWeight ? (minWeight <= avg_weight && fish_amount) : true;
                    const isMax = maxWeight ? (maxWeight >= avg_weight && fish_amount) : true;

                    if (!isMin || !isMax)
                        return items;
                    return [...items, UnitModel(unit, site)]
                }, [])], []),
            ...normal
        ];
    }

    handleFilter = (data: any) => {
        const {smoltFilter, smoltProdAreaFilter} = data;
        const {smoltSites} = this.props;

        const units = this.onFilter({...this.state, smoltFilter, smoltProdAreaFilter}, smoltSites)

        this.setState({smoltFilter, smoltProdAreaFilter, units})
    }

    handleDragStart = (e: any) => {
        const {unitsSelected} = this.state;
        this.props.onDragStart(e, unitsSelected, [OPERATION_TYPE.TRANSPORT])
    }

    handleOpenSite = (unit: IUnit) => {
        const el = openPopup(<Site
            site={{id: unit.site_id || '', name: unit.site_name || ''}}
            unitId={unit.id}
            onClose={() => el.remove()}
        />)
    }

    clear = () => {
        this.setState({unitsSelected: {}})
    }

    render() {
        const {units, searchValue, liceSort, weightFilter, unitsSelected} = this.state;
        const {loading, smoltSites = [], onDragEnd} = this.props;
        if (smoltSites.length === 0)
            return null;

        return <div id={KEY_TAB.SMOLT} className={stylesContainer['wrapper-tab']}>
            <HeaderSmoltComponent {...{
                searchValue,
                liceSort,
                weightFilter,
                unitsSelected,
                handleSearch: this.handleSearch,
                handleFilter: this.handleFilter,
                onClear: this.clear,
                sites: smoltSites
            }} />
            <div className={stylesContainer['tab-body']}>
                <Cards className={styles.units} source={units} loading={loading}>
                    {units.map((unit: any) => {
                        const {unit_id, site_name, fish_amount, type, diseases, lice} = unit;
                        const isCheck = !!unitsSelected[unit.id + '|-'];
                        const isInternal = type === SITE_TYPE.INTERNAL;
                        const isActive = !(isInternal && fish_amount <= 0);

                        return <Card
                            data={unit}
                            isActive={isActive}
                            key={unit.id}
                            checked={isCheck}
                            style={{maxHeight: '120px'}}
                            onChange={() => this.handleCheck(!isCheck, {[unit.id + '|-']: unit})}
                            onDragStart={this.handleDragStart}
                            onDragEnd={onDragEnd}
                            onContext={() => this.handleOpenSite(unit)}
                        >
                            <div className={stylesContainer.line}>
                                <div className={stylesContainer.infoLine}>
                                    <Location source={[site_name, `, ${unit_id}`]}/>
                                    {isInternal && <FishAndWeight data={unit}/>}
                                </div>
                                <div className={stylesContainer.infoLine} data-right={true}>
                                    <UnitLice lice={lice}/>
                                    <FishDisease diseases={diseases} units={[unit]} short={true}/>
                                    <UnitDiseases data={unit.unit_diseases}/>
                                </div>
                            </div>
                        </Card>
                    })}
                </Cards>
            </div>
        </div>;
    }
}

export default connect(mapStateToProps, {})(SmoltSite);
