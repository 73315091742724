import React, {createRef, useEffect, useState} from 'react';
import styles from "./style.module.scss";
import {patternFloat2, patternInteger, SITE_TYPE} from "../../../../../../../util/varibles/constants";
import {Button} from "antd";
import DragEl from "../../../_Component/DragEl";
import {checkLimit, formatNumber, getElById} from "../../../../../../../util/varibles/global";
import stylesContainer from "../style.module.scss";
import {PlusCircleOutlined} from "@ant-design/icons";
import Icon, {ICON_NAME} from "../../../../../../../components/Icon";
import Menu from "../../../../../../../components/Menu";
import {calculateRound, UpdateName} from "../../constants";
import {IVessel} from "../../../../../../../util/varibles/interface";
import {notify, NotifyCode} from "../../../../../../../util/varibles/message";
import {getStorages} from "../../../../../util/function_operation/constants";
import {openPopup} from "../../../../../../../components/Popup/Component/WrapperPopup";

const disableSelect = (event: any) => {
    event.preventDefault();
}

interface IProps {
    site: any
    unit: any
    vessel: IVessel
    isLock: boolean

    onUpdate(key: UpdateName, args: any): void
}

let dragEl: any, parentEl: any;

const Title: React.FC<IProps> = (props) => {
    const rootRef = createRef<HTMLDivElement>()
    const {site, unit, isLock, vessel} = props;
    const isInternal = site.type === SITE_TYPE.INTERNAL;
    const {total_weight = null, fish_amount, avg_weight = '-', total_weight_text} = unit;
    const [root, setRoot] = useState<HTMLElement | null>();
    const [totalWeightText, setTotalWeightText] = useState('');

    useEffect(() => {
       setTotalWeightText(total_weight_text || (total_weight ? Math.round(total_weight / 1000) / 1000 : ''))
    }, [total_weight_text, total_weight])

    useEffect(() => {
        const el = rootRef.current;
        if (el) {
            setRoot(el.parentElement);
            parentEl = document.getElementById('treatment-operation');
        }
        return () => {
            document.body.style.cursor = 'unset';
            window.removeEventListener('selectstart', disableSelect);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
    }, [rootRef.current]);

    const handleMouseDown = () => {
        window.addEventListener('selectstart', disableSelect);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        const {unit_id} = unit;

        dragEl = openPopup(<DragEl {...{
            data: [
                {label: 'Site', value: site.name},
                {label: 'Unit', value: unit_id},
            ]
        }}/>)
        dragEl.style.position = 'absolute';
        dragEl.style.zIndex = '2000';
        dragEl.style.pointerEvents = 'none';
        dragEl.style.display = 'none';
    }

    const handleMouseMove = (e: any) => {
        parentEl.dataset.isDrag = 'group';
        if (root)
            root.dataset.isDrag = 'true';

        const {pageX, pageY} = e;
        dragEl.style.display = 'block';
        const {offsetHeight, offsetWidth} = dragEl;
        dragEl.style.display = 'none';
        const left = checkLimit(0, window.innerWidth - offsetWidth - 5, pageX);
        const top = checkLimit(0, window.innerHeight - offsetHeight, pageY);
        dragEl.style.left = left - 10 + 'px';
        dragEl.style.top = top - 10 + 'px';
        dragEl.style.display = 'block';
        document.body.style.cursor = 'grabbing';
    }

    const handleChangeFishAmount = (e: any) => {
        const valueNew = e.target.value.trim();
        const reg = new RegExp(patternInteger);
        if (!reg.test(valueNew)) {
            notify.error(NotifyCode.E9)();
            return;
        }
        const params = {
            ...unit,
            fish_amount: +valueNew,
        }
        const {fish_amount = 0, total_weight = 0, storages} = params;
        params.avg_weight = fish_amount > 0 ? Math.round(total_weight / fish_amount) : 0;
        params.storages = getStorages({fish_amount, total_weight, storages}, vessel);
        props.onUpdate(UpdateName.UPDATE_UNIT, {unit: params});
    }

    const handleMouseUp = (e: any) => {
        if (dragEl) {
            dragEl.remove();
        }
        document.body.style.cursor = 'unset';
        delete parentEl.dataset.isDrag;
        if (root)
            delete root.dataset.isDrag;
        window.removeEventListener('selectstart', disableSelect);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);

        if (!e.target)
            return;

        const {index} = e.target.dataset;
        if (index === undefined)
            return;
        e.target.dataset.hover = false;

        const [position] = index.split('-');
        const {id} = props.unit;
        props.onUpdate(UpdateName.UPDATE_UNIT_POSITION, {targetId: id, index: +position});
    }

    const handleChangeTotalWeight = (e: any) => {
        const valueNew = e.target.value.trim();
        const reg = new RegExp(patternFloat2);
        if (!reg.test(valueNew)) {
            notify.error(NotifyCode.E9)();
            return;
        }
        const params = {
            ...unit,
            total_weight_text: valueNew,
            total_weight: valueNew * 1000000,
        }
        const {fish_amount = 0, total_weight = 0, storages} = params;
        params.avg_weight = fish_amount > 0 ? Math.round(total_weight / fish_amount) : 0;
        params.round_number = calculateRound(total_weight, vessel);
        params.storages = getStorages({fish_amount, total_weight, storages}, vessel);
        props.onUpdate(UpdateName.UPDATE_UNIT, {unit: params});
    }

    return <div ref={rootRef} className={[styles.title, stylesContainer.title].join(' ')} onMouseDown={handleMouseDown}>
        <div className={styles.left}>
            <label>{[site.name, unit.unit_id].join(', ')}</label>
        </div>
        <div className={styles.right}>
            {!isInternal ? <div className={styles.info}>
                <input
                    className={styles.input}
                    value={unit.fish_amount || ''}
                    onChange={handleChangeFishAmount}
                    placeholder='Amount'
                />
                <div/>
                <div className={styles.weight}>
                    <input
                        className={styles.input}
                        value={totalWeightText}
                        onChange={handleChangeTotalWeight}
                        placeholder='Total weight'
                    />
                    <div className={styles.suffix}>t</div>
                </div>
            </div> : <div className={styles.info}>
                <div className='pr-3'>{formatNumber(fish_amount)}</div>
                <div className='pr-3'>{formatNumber(avg_weight)} gr</div>
                <div className='pr-3'>{formatNumber(totalWeightText)} t</div>
            </div>}
            {isLock
                ? <div className={stylesContainer.lock}>
                    <div className={stylesContainer.message}> - Has been implemented</div>
                    <div className={stylesContainer.icon}>
                        <Icon icon={ICON_NAME.LOCK}/>
                    </div>
                </div>
                : <>
                    <Menu items={[
                        {
                            key: 'delete',
                            label: <div
                                className='menu-line'
                                data-danger='true'
                                onClick={() => props.onUpdate(UpdateName.DELETE_UNIT, {unit})}
                            >
                                <Icon icon={ICON_NAME.DELETE} danger/>
                                <span>Delete unit</span>
                            </div>
                        }
                    ]} getPopupContainer={() => getElById('treatment-operation')}>
                        <div className='three-dot'/>
                    </Menu>
                    <Button {...{
                        shape: "circle",
                        type: 'text',
                        size: 'small',
                        className: 'bt-add',
                        icon: <PlusCircleOutlined style={{fontSize: '19px'}}/>,
                        onClick: () => props.onUpdate(UpdateName.ADD_TASK, {unit})
                    }}/>
                </>}
        </div>
    </div>;
};

export default Title;