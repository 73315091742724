import callApi, {api} from "../api/callApi";
import {urlDuration, urlOperation} from "../api/v1";
import {OPERATION_TYPE} from "../varibles/constants";
import WSService from "../../web-socket/web-socket-service";
import {ILocation} from "../varibles/interface";

interface IOperationConfirm {
    delete_operations: string[]
    vessels: {
        vessel_id: string
        operations: any[]
    }
}

export function createOperationsFetch(body: IOperationConfirm) {
    const connection_id = WSService.getConnectId();
    return callApi(urlOperation + '/confirm', {
        method: 'POST',
        body: JSON.stringify({...body, connection_id}),
    })
}

export function saveOperationsFetch(body: IOperationConfirm) {
    const connection_id = WSService.getConnectId();
    return callApi(urlOperation + '/confirm', {
        method: 'PUT',
        body: JSON.stringify({...body, connection_id}),
    })
}

export function resetOperationsFetch() {
    return callApi(urlOperation + '/reset', {
        method: 'DELETE'
    })
}

export function getOperationFetch(tenant_id: any, id: any) {
    return callApi(urlOperation + '/' + tenant_id + '/' + id, {
        method: 'GET'
    });
}

interface IGetActivityLogParams {
    is_weather: boolean
    is_activity_log: boolean
    is_waiting_unit: boolean

    vessel_id: string
    router_id: string

    priority: number
    est_start_time: number
    operation_type: number
    total_amount: number
    total_weight: number

    start_place: {
        id: string
        field: string
        latitude: number
        longitude: number
    },
    source: {
        id: string
        time: number
        latitude: number
        longitude: number
    },
    destination: {
        id: string
        time: number
        latitude: number
        longitude: number
    }
}

export function getActivityLogFetch(body: IGetActivityLogParams, controller?: AbortController) {
    const signal = controller ? controller.signal : undefined;

    return callApi(urlDuration, {
        method: "POST",
        body: JSON.stringify(body),
        signal
    });
}

export function updateActivityLogFetch(body: any, id: any) {
    return callApi(urlOperation + "/" + id, {
        method: "PUT",
        body: JSON.stringify(body)
    })
}

export function getScheduleOfWeeksFetch(start_time: number, finish_time: number) {
    return callApi(urlOperation + '/history', {
        method: 'PUT',
        body: JSON.stringify({
            start_time,
            finish_time
        })
    });
}

export function getScheduleOfWeeksFetch2(start_time: number, finish_time: number) {
    return callApi(urlOperation + '/history', {
        method: 'POST',
        body: JSON.stringify({
            start_time,
            finish_time
        })
    });
}

export function deleteOperationFetch(orderId: string) {
    const connection_id = WSService.getConnectId();
    return callApi(urlOperation + `/${orderId}`, {
        method: 'DELETE',
        body: JSON.stringify({connection_id})
    })
}

interface IParamsUpdateChecklist {
    operation_id: string
    step: any
    content: any
    group_id: string
    version: string
    result?: any
}

export function updateChecklistFetch(params: IParamsUpdateChecklist) {
    return callApi(urlOperation + '/checklist', {
        method: 'PUT',
        body: JSON.stringify(params)
    })
}

export function getOperationAtDashboardFetch() {
    return callApi(urlOperation + "/dashboard", {
        method: "GET"
    })
}

interface IParamsAutoPan {
    vessels: string[],
    harvests: {
        harvest_id: string,
        site_id: string,
        factory_id: string,
        unit_id: string,
        avg_weight: number,
        fish_amount: number,
        packing_date: number
    }[]
}

export function autoPlanFetch(params: IParamsAutoPan) {
    return callApi(urlOperation + '/plan', {
        method: 'POST',
        body: JSON.stringify(params)
    })
}

export function revertAutoPlanFetch() {
    return callApi(urlOperation + '/plan/revert', {
        method: 'GET'
    })
}

export function getOperationWeatherByIdFetch(operationId: string) {
    return callApi(urlOperation + '/weather/' + operationId, {
        method: 'GET'
    })
}

interface IParamGetOperationWeather {
    operation_type: OPERATION_TYPE
    source_id?: string
    destination_id?: string
    pois: ILocation[]
    activity_log: {
        est_start_time: number
    }[]
}

export function getOperationWeatherFetch(param: IParamGetOperationWeather) {
    return callApi(urlOperation + '/weather', {
        method: 'POST',
        body: JSON.stringify(param)
    })
}

export function updateOperationNoteFetch(param: any, operationId: string) {
    return callApi(urlOperation + '/note/' + operationId, {
        method: 'PUT',
        body: JSON.stringify(param)
    })
}

interface IAddOperationMMiddle {
    insert_operation: any
    operations: any[]
}

export function addOperationMMiddleFetch(params: IAddOperationMMiddle) {
    return callApi(urlOperation + '/insert', {
        method: 'POST',
        body: JSON.stringify(params)
    })
}

interface IUpdateOperationStatus {
    operation_id: string,
    tenant_id: string,
    status: string
}

export function updateOperationStatusFetch(params: IUpdateOperationStatus) {
    const connection_id = WSService.getConnectId();
    return callApi(urlOperation + '/update', {
        method: 'PUT',
        body: JSON.stringify({...params, connection_id})
    })
}

interface IGetMessagesOfOperation {
    id: string,
    index: number
}

export function getMessagesOfOperationFetch(body: IGetMessagesOfOperation) {
    return callApi(urlOperation + '/chat', {
        method: 'PUT',
        body: JSON.stringify(body)
    })
}

interface ISendMessageOfOperation {
    id: string,
    tenant_id: string,
    message: string,
    timestamp: number,
    username: string,
    role: string
}

export function sendMessageOfOperationFetch(body: ISendMessageOfOperation) {
    return callApi(urlOperation + '/chat', {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

interface IEditMessageOfOperation {
    id: string
    chat_id: string
    message: string
}

export function editMessageOfOperationFetch(body: IEditMessageOfOperation) {
    return callApi(urlOperation + '/chat/edit', {
        method: 'PUT',
        body: JSON.stringify(body)
    })
}

interface IDeleteOperations {
    operation_ids: string[]
    vessels: {
        vessel_id: string
        vessel_owner_id: string
    }[],
    start_time: number
    finish_time: number
}

export function deleteOperationsFetch(body: IDeleteOperations) {
    const connection_id = WSService.getConnectId();
    return callApi(urlOperation + '/delete', {
        method: 'POST',
        body: JSON.stringify({...body, connection_id})
    })
}

interface IGetOperationIds {
    vessel_ids: string[]
    start_time: number
    finish_time: number
}

export function getOperationIdsFetch(body: IGetOperationIds) {
    return callApi(urlOperation + '/ids', {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export function getCanceledOperationFetch() {
    return callApi(urlOperation + '/cancel', {
        method: 'POST'
    })
}

interface IAddDelayTime {
    operation_id: string
    tenant_id: string
    time_late: number
    step: number
}

export function addDelayTimeFetch(body: IAddDelayTime) {
    const connection_id = WSService.getConnectId();
    return callApi(urlOperation + '/update/ongoing', {
        method: 'PUT',
        body: JSON.stringify({...body, connection_id})
    })
}

export const OpService = {
    getCanceled: () => api.post<{operations: any}>(urlOperation + '/cancel')
}