import React, {Component} from 'react';
import WrapperTrackingOp from "./WrapperTrackingOp";
import WrapperMessage from "./WrapperMessage";
import styles from './style.module.scss';

class MessagePage extends Component {
    render() {
        return <div className={[styles.wrapper, 'm-header bgPage'].join(' ')}>
            <WrapperMessage/>
            <WrapperTrackingOp/>
        </div>;
    }
}


export default MessagePage;
