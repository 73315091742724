import React, {useEffect} from 'react';
import styles from "./style.module.scss";
import {Switch} from "antd";
import {connect, useDispatch} from "react-redux";
import GoogleMap from "../../../../util/library/googlemap/googlemap";
import {dashboardActions} from "../../reducer";
import {MAP_ELEMENT} from "../../../../util/library/googlemap/constants";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    tracking: state.dashboard.tracking,
    target: state.dashboard.target,
    map: state.dashboard.map
});

let interval: any;

interface IProps {
    tracking: boolean
    target: any
    map?: GoogleMap
}

const TrackVessel: React.FC<IProps> = ({tracking, target, map}) => {
    const {targetType} = target || {};
    const dispatch = useDispatch();

    useEffect(() => {
        if ((!targetType || !tracking) && map) {
            clearInterval(interval);
            map.closeDialogs();
        }
        return () => {
            clearInterval(interval);
        }

    }, [tracking, map, targetType]);

    const onChange = (checked: boolean) => {
        dispatch(dashboardActions.updateTracking(checked));
        const update = () => {
            const {data} = target;
            if (!data) {
                clearInterval(interval);
                return;
            }

            const {id, gps: {Latitude, Longitude}} = data;
            const position = new google.maps.LatLng(Latitude, Longitude);
            if (map) {
                map.closeDialogs(id);
                map.setCenter([[Longitude, Latitude]], 7);
                map.generate({
                    type: MAP_ELEMENT.DIALOG,
                    data,
                    contentType: MAP_ELEMENT.VESSEL,
                    position,
                    currentTime: Date.now(),
                    lock: true
                })
            }
        }

        if (!map)
            return;
        if (checked) {
            update();
            interval = setInterval(() => update(), 20000);
        } else {
            clearInterval(interval);
        }
    };

    return <div className={styles.wrapper} data-show={targetType === MAP_ELEMENT.VESSEL}>
        <span>Track vessel: </span>
        <Switch {...{
            checked: tracking,
            onChange,
        }}/>
    </div>;
};

export default connect(mapStateToProps, {})(TrackVessel);

