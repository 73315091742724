import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './style.module.scss';
import {connect, useDispatch} from "react-redux";
import NavUnderline from "../NavUnderline";
import {IStateGlobal} from "../../../../../util/varibles/interface";
import {PlanSetting} from "../../../../../util/varibles/userSetting";
import {INTEGRATION_MODE, KEY_TAB} from "../../../../../util/varibles/constants";
import defaultSetting from "../../../../../util/varibles/defaultSetting";
import {loginActions} from "../../../../LoginPage/reducer";

const mapStateToProps = (state: IStateGlobal) => {
    const {tenant} = state.login.user;
    const {canceled} = state.planOperation;
    const {[PlanSetting.BIOLOGY_TAB]: planStoreTabs = defaultSetting[PlanSetting.BIOLOGY_TAB]} = state.login.user.setting || {};

    return {planStoreTabs, tenant, canceled}
}

interface IProps {
    planStoreTabs: any
    tenant: any
    smoltSites: any
    canceled: any
}

const Header: React.FC<IProps> = ({planStoreTabs, tenant, smoltSites = [], canceled}) => {
    const tab = (planStoreTabs === KEY_TAB.SMOLT && smoltSites.length === 0) ? KEY_TAB.PRODUCTION : planStoreTabs;
    const [header, setHeader]: any = useState([]);
    const {integration = {}} = tenant || {};
    const {type = ''} = integration || {};
    const dispatch = useDispatch();
    const root = useRef(null);

    useEffect(() => {
        const count = canceled.filter(({isShow = true}: any) => isShow);
        setHeader([
            {
                id: KEY_TAB.PRODUCTION,
                name: 'Biology',
                width: '165px',
                visible: true
            },
            {
                id: KEY_TAB.HARVEST,
                name: 'STHP',
                width: '125px',
                visible: type === INTEGRATION_MODE.STHP_FILE
            },
            {
                id: KEY_TAB.SMOLT,
                name: 'Smolt',
                width: '130px',
                visible: smoltSites.length > 0
            },
            {
                id: KEY_TAB.CANCEL,
                name: `Canceled operation ${count.length > 0 ? `(${count.length})` : ''}`,
                width: '180px',
                visible: true
            },
        ]);
    }, [tenant, smoltSites, canceled, type]);

    useEffect(() => {
        setTimeout(() => {
            const el = document.getElementById('container-tabs');
            if (el) {
                const {childNodes} = el;
                childNodes.forEach((item: any) => {
                    const {id} = item;
                    item.dataset.active = id === tab
                })
            }
        }, 230)
    }, [tab, smoltSites]);

    const handleChangeTab = useCallback((value: KEY_TAB) => {
        if (value !== tab)
            dispatch(loginActions.updateUserSetting([{planStoreTabs: value}]));
    }, [tab, dispatch]);

    return <div ref={root} className={styles['tabs-control']} data-tab={tab}>
        {header.map(({id, name, width, visible}: any) => visible && <div key={id} {...{
            id: 'tab-' + id,
            className: styles['tabs-nav'],
            'data-active': id === tab,
            'data-tab': id,
            onClick: () => handleChangeTab(id),
            style: {width}
        }}>
            {name}
        </div>)}
        <NavUnderline target={tab}/>
    </div>;
};

export default connect(mapStateToProps, {})(Header);

