import React, {Component} from 'react';
import styles from "./style.module.scss";
import {KeyOfTask, TREATMENT_TYPE} from "../../../../../../../../util/varibles/constants";
import Sorting from "./Sorting";
import {IKeyUpdate} from "../../constants";
import {UpdateName} from "../../../constants";

interface IProps {
    data: any
    site: any
    unit: any
    factory: any
    listOfTask: any
    isLock: boolean

    handleChange(list: IKeyUpdate[]): void

    handleChangePosition(args: any): void

    onChangeGlobal(key: UpdateName, args: any): void
}

class Treatment extends Component<IProps> {

    render() {
        const {treatment_type} = this.props.data;
        const {isLock} = this.props;

        switch (treatment_type) {
            case TREATMENT_TYPE.HYDROGENPEROXIDE:
            case TREATMENT_TYPE.FRESH_WELLFIGHTER:
            case TREATMENT_TYPE.SALTWATER:
            case TREATMENT_TYPE.FRESHWATER: {
                const {handleChange} = this.props;
                const {durationTxt} = this.props.data;
                return <input
                    disabled={isLock}
                    className={[styles['input-custom'], 'w-full max-w-160'].join(' ')}
                    placeholder='In duration (4d 6h 45m)'
                    value={durationTxt || ''}
                    onChange={(e: any) => handleChange([{key: KeyOfTask.DURATION, value: e.target.value}])}
                    onBlur={() => handleChange([{key: KeyOfTask.CHECK_DURATION}])}
                />
            }
            case TREATMENT_TYPE.SORTING: {
                const {
                    data,
                    site,
                    unit,
                    factory,
                    listOfTask,
                    handleChange,
                    handleChangePosition,
                    onChangeGlobal
                } = this.props;
                return <Sorting
                    data={data}
                    site={site}
                    unit={unit}
                    isLock={isLock}
                    factory={factory}
                    listOfTask={listOfTask}
                    handleChange={handleChange}
                    handleChangePosition={handleChangePosition}
                    onChangeGlobal={onChangeGlobal}
                />
            }
            default:
                return null
        }
    }
}

export default Treatment;