import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import {ACTION_TYPE, OP_STATUS, OPERATION_TYPE, SITE_TYPE} from "../../../../../../../util/varibles/constants";
import WrapperOperation from "../Component/WrapperOperation";
import stylesContainer from "../style.module.scss";
import HarvestView from "../../../../../_Component/ContentOperation/HarvestView";
import {IOperation, ISite} from "../../../../../../../util/varibles/interface";
import {IPropsOp} from "../index";
import Icon, {ICON_NAME} from "../../../../../../../components/Icon";
import {AppState} from "../../../../../../../util/store/store";

const icon = <Icon icon={ICON_NAME.HARVEST}/>

const mapStateToProps = (state: AppState) => ({
    sites: state.planOperation.sites,
    harvests: state.planOperation.harvests,
});

interface IProps extends IPropsOp {
    sites: ISite[]
    harvests: IOperation[]
    operationType: OPERATION_TYPE
    isOwn: boolean
    isVesselOwn: boolean
    permission: any
    status: OP_STATUS

    openEdit(payload: any): void
}

const Harvest: React.FC<IProps> = (props) => {
    const {isOwn, sites, harvests, data, openEdit} = props;

    const edit = useCallback(() => {
        const {sub_operations, site_id, site_name} = data.operation;
        const sub = [...sub_operations];
        const store = sub_operations.reduce((list: any, item: any, index: number) => {
            const {id, harvest_id = '-'} = item;
            let siteId: any;
            if (!item.site_id) {
                const site = sites.find((sub: ISite) => sub.units.some((unit) => unit.id === id))
                if (site) {
                    siteId = site.id || site_id;
                    sub[index].site_id = site.id
                    sub[index].site_name = site.name
                } else
                    siteId = site_id
            } else
                siteId = item.site_id;
            const {
                units = [],
                type = SITE_TYPE.INTERNAL
            } = sites.find((item: any) => item.id === siteId) || {};
            const key = id + '|' + harvest_id;
            list[key] = {
                ...units.find((unit: any) => unit.id === id),
                site_id,
                site_name,
                site_type: type,
                harvest_id
            }
            if (harvest_id !== '-')
                list[key] = {...list[key], ...harvests.find((harvest: any) => harvest.harvest_id === harvest_id)};

            return list;
        }, {})
        if (Object.keys(store).length === 0)
            return

        openEdit({
            isShow: true,
            operationType: OPERATION_TYPE.HARVEST,
            editMode: ACTION_TYPE.EDIT,
            values: {
                operation: {...data.operation, sub_operations: sub},
                activity_log: data.activity_log,
                store,
                vessel: data.vessel
            }
        })
    }, [data, sites, harvests, openEdit]);

    return <WrapperOperation {...{...props, icon, isGetActivityLog: true, edit}}>
        <div className={[stylesContainer['content-wrapper'], stylesContainer.layout].join(' ')}>
            <HarvestView
                status={props.status}
                data={data}
                isOwn={isOwn}
            />
        </div>
    </WrapperOperation>;
};

export default connect(mapStateToProps, {})(Harvest);
