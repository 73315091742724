import React, {useCallback, useEffect, useState} from 'react';
import {CONTRACT_TYPE, propsModal, VIEW_SMOOTH} from "../../../../util/varibles/constants";
import {Button, Drawer, Tabs} from "antd";
import styles from "./style.module.scss";
import {ModeTabVesselPopup} from "./constants";
import PopupRequest from "./PopupRequest";
import Requests from "./Requests";
import {openPopup} from "../../../../components/Popup/Component/WrapperPopup";
import Header from "../../../../components/Popup/Component/Header";
import Footer from "../../../../components/Popup/Component/Footer";
import {getRequestSportVesselsFetch, SpotService} from "../../../../util/services/spot";
import {getElById, showErrorResponse} from "../../../../util/varibles/global";
import {IVessel} from "../../../../util/varibles/interface";
import VesselTable from "./VesselTable";

interface IProps {
    visible?: boolean
    contractId?: string

    onClose?(): void
}

const PopupVessels: React.FC<IProps> = (props) => {
    const {contractId} = props;
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [visible, setVisible] = useState<boolean>(props.visible || true);
    const [spotVessels, setSpotVessels] = useState<IVessel[]>([]);
    const [requests, setRequests] = useState({});
    const [loadingSpotVessel, setLoadingSpotVessel] = useState(false);
    const [loadingRequests, setLoadingRequests] = useState(false);
    const [tabView, setTabView] = useState<string>(contractId ? ModeTabVesselPopup.List : ModeTabVesselPopup.Requests);

    useEffect(() => {
        setLoadingSpotVessel(true);
        SpotService.getSpots()
            .then((rs: any) => {
                setLoadingSpotVessel(false);
                setSpotVessels(rs.vessels || [])
            })
            .catch(error => {
                showErrorResponse('Get data failed', error);
                setLoadingSpotVessel(false);
            })
        getRequests();
    }, []);

    const getRequests = useCallback(() => {
        setLoadingRequests(true);
        new Promise(resolve => resolve(getRequestSportVesselsFetch()))
            .then((rs: any) => {
                setLoadingRequests(false);
                setRequests(rs)
                if (contractId)
                    setTimeout(() => {
                        const el = getElById(contractId);
                        if (el) {
                            el.scrollIntoView(VIEW_SMOOTH)
                        }
                    }, 300)
            })
            .catch(error => {
                showErrorResponse('Get list of request spot vessel failed', error);
                setLoadingRequests(false);
            })
    }, [contractId])

    const handleSendRequest = (contractType: CONTRACT_TYPE) => {
        const el = openPopup(<PopupRequest {...{
            selectedRowKeys,
            spotVessels,
            contractType,
            onOk: () => {
                getRequests();
                setTabView(ModeTabVesselPopup.Requests)
            },
            onClose: () => el.remove()
        }}/>)
    }


    const handleClose = () => {
        setVisible(false);
        if (props.onClose)
            props.onClose();
    }


    const rowSelection = {
        selectedRowKeys,
        onChange: (value: any) => setSelectedRowKeys(value),
    };

    return <Drawer
        {...propsModal}
        className={styles['popup-vessels']}
        title={<Header title='Available vessels' size='large' onClose={handleClose}/>}
        width='100%'
        open={visible}
        onClose={handleClose}
    >
        <div className={styles.body}>
            <Tabs
                type="card"
                size='small'
                activeKey={tabView}
                onChange={setTabView}
                items={[
                    {
                        key: ModeTabVesselPopup.List,
                        label: 'Vessels',
                        children: <VesselTable
                            rowSelection={rowSelection}
                            loading={loadingSpotVessel}
                            spotVessels={spotVessels}
                            setSpotVessels={setSpotVessels}
                        />
                    },
                    {
                        key: ModeTabVesselPopup.Requests,
                        label: 'List of requests',
                        children: <Requests loading={loadingRequests} list={requests} onOk={getRequests}/>
                    }
                ]}
            />
        </div>
        <Footer className={styles.footer}>
            <Button className='bt-default' onClick={handleClose}>
                Cancel
            </Button>
            <Button className='bt-primary' type='primary'
                    onClick={() => handleSendRequest(CONTRACT_TYPE.SINGLE)}
                    disabled={selectedRowKeys.length === 0 || loadingSpotVessel}
            >
                Send request
            </Button>
        </Footer>
    </Drawer>
};

export default PopupVessels;
