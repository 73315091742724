import {notify, NotifyCode} from "./message";
import {patternFloat2} from "./constants";

export const formValidation = {
    require: (name: string) => {
        return {
            required: true,
            message: `"${name}" is required`
        }
    },
    confirmPassword: () => ({getFieldValue}: any) => ({
        validator(_: any, value: any) {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        },
    })
}

export const Rule = {
    Require: (name: string) => ({required: true, message: notify[NotifyCode.E32]([name])}),
    Requires: (name: string) => ({required: true, message: notify[NotifyCode.E29]([name])}),
    Number: () => ({pattern: new RegExp(patternFloat2), message: notify[NotifyCode.E9]()})
}