import React from 'react';
import stylesContainer from "../../style.module.scss";
import {getSubOfTransport} from "../../constants";
import {
    getOperationStatus,
    OP_STATUS,
    OPERATION_MODE,
    OPERATION_TYPE,
    STATE_TRANSPORT
} from "../../../../../util/varibles/constants";
import ActivityLog from "../../Component/AcitivtyLog";
import TransportInfo from "./TransportInfo";
import Header from "../../Header";
import Status from "../../Component/Status";
import ApproveControl from "../../Component/ApproveControl";
import FishDisease from "../../../../Location/FishDisease";
import stylesOp from "../../../../../pages/OperationDetailPage/_Component/Operation/style.module.scss";
import Density from "../../../../Operation/Density";
import {getTimeByOpMode} from "../../../../../pages/PlanOperationPage/util/function_operation/constants";
import {IOperation} from "../../../../../util/varibles/interface";
import Approver from "../../Component/Approver";
import BoxOpInfo from "../../Component/BoxOpInfo";

interface IProps {
    isEdit: boolean
    isOwn: boolean
    isApprove: boolean
    data: IOperation
    loading: any
    weather: any
    opMode: OPERATION_MODE

    handleUpdateActivityLog(activityLog: any): void

    handleUpdateNote(note: string): void

    onClose(): void

    handleChangeState(params: any): void
}

const Transport: React.FC<IProps> = (props) => {
    const {
        data,
        loading,
        weather,
        isEdit,
        opMode,
        isOwn,
        isApprove,
        handleChangeState,
        onClose,
    } = props;

    const {operation, activity_log, vessel} = data;

    const startTime = getTimeByOpMode[opMode](activity_log[0]);
    const finishTime = getTimeByOpMode[opMode](activity_log[activity_log.length - 1]);

    const {
        current_process,
        total_weight,
        sub_operations,
        sub_destinations = [],
        status
    } = operation;

    const {sub, total} = getSubOfTransport(sub_operations, sub_destinations);
    const statusText = status === OP_STATUS.PROCESSING ? STATE_TRANSPORT[current_process] : getOperationStatus(status);

    return <>
        <Header {...{
            onClose,
            isOwn,
            operation,
            loading,
            weather,
            keyWeather: `${startTime}|${finishTime}`
        }}>
            <div className={stylesContainer.title}>
                <div className={stylesContainer['header-id']}>
                    <div className='ml-3'>
                        <Status {...{status, statusText}}/>
                    </div>
                    <div className='ml-3'>
                        Total weight:
                        <b className='ml-1'>{Math.round(total_weight / 1000) / 1000} t</b>
                    </div>
                </div>
            </div>
        </Header>

        <div className={stylesContainer.body}>
            <ActivityLog {...{
                isEdit,
                activity_log,
                vessel,
                operation,
                opMode,
                handleUpdate: props.handleUpdateActivityLog
            }} />

            <BoxOpInfo data={data} isEdit={isEdit} handleUpdateNote={props.handleUpdateNote}>
                <ApproveControl {...{isApprove, operation, handleChangeState}}/>
                <Approver isOwn={isOwn} operation={operation}/>
                <FishDisease diseases={operation.diseases || []} units={operation.sub_operations} wrapperClass='mb-5'/>
                <div className={stylesOp['info-line']}>
                    <Density type={OPERATION_TYPE.TRANSPORT} storages={sub_destinations[0]?.storages}/>
                </div>
                <TransportInfo {...{data: sub, total}}/>
            </BoxOpInfo>
        </div>
    </>
};

export default Transport;