import React, {ReactNode} from 'react';
import {ILevelByOp, IOperations, IVessel} from "../../../../../util/varibles/interface";
import Operation from "./Operation";
import {TargetTypes} from "../Vessels/constants";

interface IProps {
    startPoint: number
    index: number
    width: number
    calendarId: string
    level?: ILevelByOp
    operations: IOperations
    vessels: IVessel[]
    opFocusId?: string

    dragOperation(target: any): any

    setTarget(target: TargetTypes, e: MouseEvent): void

    openEdit(payload: any): void
}

const Operations: React.FC<IProps> = ({level = {}, operations, ...args}) => {

    return <>
        {Object.keys(level).reduce((rs: ReactNode[], key) => {
            const item = operations[key] || {};

            if (!item || !item.operation)
                return rs;

            const {id} = item.operation || {};
            return [...rs, <Operation key={item.operation.id} {...{
                ...args,
                data: item,
                level: level[id],
            }}/>]
        }, [])}
    </>;
};

export default Operations;
