import React from 'react';
import {IStateGlobal, IUnitStorage} from "../../../util/varibles/interface";
import styles from './style.module.scss';
import {connect} from "react-redux";
import {OPERATION_TYPE} from "../../../util/varibles/constants";
import Storage from "./Storage";

const mapStateToProps = (state: IStateGlobal, props: IProps) => {
    const {[props.type]: tenantDensity} = state.login.user.tenant.density || {}
    return {
        tenantDensity
    }
};

interface IProps {
    className?: string
    type: OPERATION_TYPE
    isEmpty?: boolean
    tenantDensity?: number[]
    storages?: IUnitStorage[]
}

const Density: React.FC<IProps> = ({className, isEmpty = false, tenantDensity = [], storages = []}) => {
    if (tenantDensity.length === 0 || storages.length === 0)
        return null

    return <div className={[styles.density, className].join(' ')}>
        {storages.map((item, index) => {
            return <Storage key={index} isEmpty={isEmpty} tenantDensity={tenantDensity} storage={item}/>
        })}
    </div>;
};

export default connect(mapStateToProps, {})(Density);
