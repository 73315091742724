import React, {useEffect, useState} from 'react';
import Header from "./Header";
import Units from "../Units";
import Planned from "../Planned";
import SmoltSite from "../SmoltSite";
import {FISH_TYPE, OPERATION_TYPE} from "../../../../util/varibles/constants";
import {useSelector} from "react-redux";
import {selectPlan} from "../../../../util/store/selectors";

interface IProps {
    setClear(key: string, value: any): void

    onDragStart(e: any, units: any, requestType: OPERATION_TYPE[]): void

    onDragEnd(e: any): void
}

const BoxSites: React.FC<IProps> = ({setClear, onDragStart, onDragEnd}) => {
    const [{productionSites, smoltSites}, setSites] = useState({productionSites: [], smoltSites: []});
    const sites = useSelector(selectPlan.sites);
    const loading = useSelector(selectPlan.loadingSites)

    useEffect(() => {
        const {productionSites, smoltSites} = sites.reduce((rs: any, item: any) => {
            if (item.site_type === FISH_TYPE.SMOLT) {
                rs.smoltSites.push(item)
            } else {
                rs.productionSites.push(item)
            }
            return rs;
        }, {productionSites: [], smoltSites: []});

        setSites({productionSites, smoltSites})
    }, [sites])

    const common = {
        onDragStart,
        onDragEnd,
        loading
    }

    return <>
        <Header smoltSites={smoltSites}/>
        <Units {...{...common, clear: (ref: any) => setClear('unit', ref), sites: productionSites}}/>
        <Planned {...{...common, clear: (ref: any) => setClear('harvest', ref)}}/>
        <SmoltSite {...{...common, clear: (ref: any) => setClear('smolt', ref), smoltSites}}/>
    </>;
};

export default BoxSites;
