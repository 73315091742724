import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Empty, Select} from "antd";
import styles from './style.module.scss';
import {MAP_ELEMENT} from "../../../../util/library/googlemap/constants";
import GoogleMap from "../../../../util/library/googlemap/googlemap";
import {dashboardActions} from "../../reducer";
import {VIEW_MODE} from "../../../../util/varibles/permission";
import {getSetting} from "../../../../util/varibles/defaultSetting";
import {PreferenceSetting} from "../../../../util/varibles/userSetting";
import {AppState} from "../../../../util/store/store";

const {Option, OptGroup} = Select;

const mapStateToProps = (state: AppState) => {
    const viewMode = getSetting(state.login.user.setting, PreferenceSetting.VIEW_MODE);

    return {
        viewMode,
        vessels: state.dashboard.vessels,
        sites: state.dashboard.sites,
        map: state.dashboard.map
    }
};

interface IProps {
    vessels: any
    sites: any
    viewMode: VIEW_MODE
    map?: GoogleMap

    showInfo(payload: any): void
}

const SearchMarker: React.FC<IProps> = (props) => {
    const {map, sites, vessels, viewMode} = props;
    const [value, setValue] = useState('');
    const [options, setOptions] = useState([]);

    const onSearch = (searchText: string) => {
        const value = searchText.toLowerCase();
        const {vessels, sites} = props;
        const data = [
            {label: 'Vessels', source: vessels, type: MAP_ELEMENT.VESSEL},
            {label: 'Sites', source: sites, type: MAP_ELEMENT.SITE}
        ]
        const options: any = [];
        data.forEach(({label, source, type}) => {
            const opts = source.reduce((list: any, item: any) => {
                if (item.name.toLowerCase().includes(value))
                    list = [...list, {label: item.name, value: [item.id + '|' + type].join('|')}];
                return list;
            }, [])
            if (opts.length > 0)
                options.push({label, options: opts})
        })
        setValue('');
        setOptions(options);
    };

    const onChange = (value: any) => {
        if (value) {
            const [id, type] = value.split('|');
            const targetType = +type;
            if (targetType === MAP_ELEMENT.VESSEL) {
                const vessel = vessels.find((item: any) => item.id === id);
                if (vessel) {
                    props.showInfo({
                        target: {
                            vessel_id: vessel.id,
                            vessel,
                            operation: {},
                            isInterval: true,
                            targetType
                        }
                    });
                    const {Latitude, Longitude} = vessel.gps;
                    map?.setCenter([[Longitude, Latitude]], 12);
                }
            } else {
                const site = sites.find((item: any) => item.id === id);
                if (site) {
                    props.showInfo({target: {data: site, targetType}})
                    const {latitude, longitude} = site.position;
                    map?.setCenter([[longitude, latitude]], 12);
                }
            }
        } else {
            props.showInfo({});
            setValue('');
            setOptions([]);
        }
    }

    const onBlur = () => {
        if (value.length === 0)
            setOptions([]);
    }

    if (viewMode !== VIEW_MODE.PLANNER)
        return null;

    return <Select {...{
        className: styles['search-box'],
        showSearch: true,
        suffixIcon: null,
        allowClear: true,
        filterOption: false,
        onSearch,
        onChange,
        onBlur,
        placeholder: "Search for vessel, site by name",
        notFoundContent: value ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> : null
    }}>
        {options.map((group: any) => <OptGroup
            key={group.label}
            label={<span className='font-w5 font-s4'>{group.label}</span>}
        >
            {group.options.map((item: any) =>
                <Option key={item.value} value={item.value}>{item.label}</Option>)}
        </OptGroup>)}
    </Select>
};


export default connect(mapStateToProps, {
    showInfo: dashboardActions.showInfo,
})(SearchMarker);
