import React, {Component} from 'react';
import styles from "./style.module.scss";
import Task from "./index";
import {IPropsChildren} from "../../../../../../util/varibles/interface";

interface IProps extends IPropsChildren {
    tasks: any
    isChild: boolean
}

class Condition extends Component<IProps> {
    render() {
        const {tasks, isChild} = this.props;
        return <div className={styles['condition']}>
            <div>
                {this.props.children}:
            </div>
            <div className={styles['condition-tasks']}>
                {tasks.map((item: any, index: number) => <Task
                    key={item.group_id}
                    data={{...item, index}}
                    isSorting={true}
                    isChild={isChild}
                />)}
            </div>
        </div>;
    }
}

export default Condition;