import React, {Component} from 'react';
import {OPERATION_TYPE, transportSteps} from "../../../../util/varibles/constants";
import {durationTxtByStartAndFinish, formatNumber, uppercaseFirstLetter} from "../../../../util/varibles/global";
import Operation from "../../_Component/Operation";
import Vessel from "../../_Component/Vessel";
import stylesContainer from "../../style.module.scss";
import stylesOp from '../../_Component/Operation/style.module.scss';
import TransportInfo from "../../../../components/Popup/PopupOpInfo/Info/Transport/TransportInfo";
import {getSubOfTransport} from "../../../../components/Popup/PopupOpInfo/constants";
import Route from "../../_Component/Route";
import FishDisease from "../../../../components/Location/FishDisease";
import {VIEW_MODE} from "../../../../util/varibles/permission";
import SectionRight from "../../_Component/SectionRight";
import WrapperActivityLog from "../../_Component/WrapperActivityLog";
import Density from "../../../../components/Operation/Density";
import {IOperation} from "../../../../util/varibles/interface";
import {getTotalAmountAndTotalWeight} from "../../../PlanOperationPage/util";
import ViewNote from "../../_Component/ViewNote";
import PlannedTime from "../../_Component/PlannedTime";
import LiceList from "../../../../components/Operation/LiceList";

export interface IPropsOp {
    data: IOperation
    isOwn: boolean
    viewMode: VIEW_MODE
}

class Transport extends Component<IPropsOp> {

    render() {
        const {
            source,
            destination,
            sub_operations,
            sub_destinations,
            diseases,
            note = ''
        } = this.props.data.operation;

        const {total_weight} = getTotalAmountAndTotalWeight(sub_operations);
        const {storages = []} = sub_operations[0] || {};

        const {activity_log} = this.props.data;
        const est_start_time = activity_log[0].est_start_time;
        const est_finish_time = activity_log[activity_log.length - 1].est_start_time;

        const activityLog = activity_log.reduce((list: any, item: any, index: number) => {

            const value = {...item, steps: [transportSteps[index]], index};
            return [...list, {id: index, list: [value]}];
        }, []);

        const {capacity = 0} = this.props.data.vessel || {};
        const {sub, total} = getSubOfTransport(sub_operations, sub_destinations);
        const isNote = note.length === 0;

        return <>
            <div>
                <Operation {...{isNote}}>
                    <div className={stylesOp['info-line']}>
                        <span>Operation type:</span>
                        <span>Transport</span>
                    </div>
                    <div className={stylesOp['info-line']}>
                        <span>Smolt site, site: </span>
                        <span>
									{uppercaseFirstLetter(source.name)}
                            <span
                                className={[stylesContainer['arrow-right'], 'mr-1'].join(' ')}>&#8594;</span>
                            {uppercaseFirstLetter(destination.name)}
                                </span>
                    </div>
                    <PlannedTime activity_log={activity_log}/>
                    <div className={stylesOp['info-line']}>
                        <span>Planned duration: </span>
                        <span>{durationTxtByStartAndFinish(est_start_time, est_finish_time)}</span>
                    </div>
                    <div className={stylesOp['info-line']}>
                        <span>Est. sum weight: </span>
                        <span>
                                    {formatNumber(Math.round(total_weight / 1000) / 1000)} t
                            {` (${Math.round(total_weight / capacity * 100)}%)`}
                                </span>
                    </div>
                    <FishDisease diseases={diseases || []} units={sub_operations} wrapperClass='mb-5'/>
                    <LiceList diseases={diseases || []}/>
                    <div className={stylesOp['info-line']}>
                        <Density type={OPERATION_TYPE.TRANSPORT} storages={storages}/>
                    </div>
                    <TransportInfo {...{data: sub, total}}/>
                    <ViewNote data={this.props.data} isOwn={this.props.isOwn} note={note}/>
                </Operation>
                <Vessel/>
                <Route/>
            </div>
            <SectionRight>
                <WrapperActivityLog
                    isOwn={this.props.isOwn}
                    data={this.props.data}
                    activity_log={activityLog}
                    activity_log_root={activity_log}
                />
            </SectionRight>
        </>;
    }
}

export default Transport;
