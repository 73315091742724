import React, {Component} from 'react';
import Condition from "./Condition";
import styles from "./style.module.scss";
import {operatorObject} from "../../../../../../pages/WeatherPage/util";

interface IProps {
    data: any
    isChild: boolean
}

class Sorting extends Component<IProps> {
    renderFirst = (isChild: boolean) => {
        const {sub_tasks = {}} = this.props.data;
        const {operator, value} = this.props.data.conditions[0];
        const key = operator[0] + value;
        const {labelHtml = ''} = operatorObject[operator[0]] || {};
        return <Condition
            key={key}
            tasks={sub_tasks[key] || []}
            isChild={isChild}
        >
            <div className={styles['operator']}>
                {labelHtml}
            </div>
            <div>{value}g</div>
        </Condition>
    }

    render() {
        const {data, isChild} = this.props;
        const {sub_tasks = {}, conditions = []} = data;

        const groupByCondition = conditions.length > 0 ? conditions.reduce((list: any, item: any, index: number) => {
            const {value, operator} = item;
            if (conditions[index + 1]) {
                const {value: valueNext, operator: operatorNext} = conditions[index + 1];
                const key = operator[1] + value + '&&' + operatorNext[0] + valueNext;
                const {labelHtml: labelA = ''} = operatorObject[operator[1]] || {};
                const {labelHtml: labelB = ''} = operatorObject[operatorNext[0]] || {};
                list.push(<Condition
                    key={key}
                    tasks={sub_tasks[key] || []}
                    isChild={isChild}
                >
                    <div className={styles['operator']}>
                        {labelA}
                    </div>
                    <div>{value}g</div>
                    <div className='ml-1'>and</div>
                    <div className={styles['operator']}>
                        {labelB}
                    </div>
                    <div>{valueNext}g</div>
                </Condition>)
            } else {
                const key = operator[1] + value;
                const {labelHtml = ''} = operatorObject[operator[1]] || {};
                list.push(<Condition
                    key={key}
                    tasks={sub_tasks[key] || []}
                    isChild={isChild}
                >
                    <div className={styles['operator']}
                    >
                        {labelHtml}
                    </div>
                    <div>{value}g</div>
                </Condition>)
            }
            return list;
        }, [this.renderFirst(isChild)]) : [];

        return <>
            <div data-lev='detail'>
                <div className={styles['conditions']}>
                    {groupByCondition}
                </div>
            </div>
        </>;
    }
}

export default Sorting;