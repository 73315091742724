import React, {Component} from 'react';
import {TASK_TYPE, taskTypes} from "../../../../../../util/varibles/constants";
import {formatNumber} from "../../../../../../util/varibles/global";

interface IProps {
    data: any
    isSorting: boolean
}

class MoveToUnit extends Component<IProps> {
    static defaultProps = {
        isSorting: false
    }

    renderAmount = () => {
        const {isSorting} = this.props;
        if (isSorting) {
            return null
        } else {
            const {unit} = this.props.data.sub_operation;
            switch (unit) {
                case 't': {
                    const {total_weight} = this.props.data.sub_operation;
                    return ' - ' + formatNumber(Math.round(total_weight / 1000) / 1000) + 't';
                }
                case 'fish': {
                    const {fish_amount} = this.props.data.sub_operation;
                    return ' - ' + formatNumber(fish_amount) + ' fish'
                }
                default: {
                    const {percent, fish_amount, fish_amount_original} = this.props.data.sub_operation;
                    const value = percent ? percent : Math.round(fish_amount / fish_amount_original * 100);
                    return ' - ' + value + '%';
                }
            }
        }
    }

    render() {
        const {destination_name} = this.props.data;
        const {name = ''} = taskTypes[TASK_TYPE.MOVE];

        return <>
            <div data-lev='name'>{name}</div>
            <div className='ml-1'>
                ({destination_name + this.renderAmount()})
            </div>
        </>;
    }
}

export default MoveToUnit;