import React, {useCallback, useEffect, useState} from 'react';
import {Drawer} from "antd";
import styles from './style.module.scss';
import {propsModal} from "../../../util/varibles/constants";
import GoogleMap, {googleMap} from "../../../util/library/googlemap/googlemap";
import {IActivityLog, IOperation, IRoute, IStateGlobal} from "../../../util/varibles/interface";
import {PreferenceSetting} from "../../../util/varibles/userSetting";
import defaultSetting from "../../../util/varibles/defaultSetting";
import {connect, useDispatch} from "react-redux";
import Header from "../Component/Header";
import Route from "./Route";
import Duration from "./Duration";
import Footer from "../Component/Footer";
import {getRoutesByLocation} from "../../../util/services/route";
import {showErrorResponse} from "../../../util/varibles/global";
import {destinationKeys, sourceKeys} from "../../../pages/PlanOperationPage/util";
import {planOpActions} from "../../../pages/PlanOperationPage/reducer";
import {updateActivityLogFetch} from "../../../util/services/operation";

const mapStateToProps = (state: IStateGlobal) => {
    const {
        priorityTime = 1,
        sitesTime = {},
        factoriesTime = {},
        [PreferenceSetting.AUTO_PLAN]: autoPlanConfig = defaultSetting[PreferenceSetting.AUTO_PLAN]
    } = state.login.user.setting || {};
    return {
        setting: {
            priorityTime,
            sitesTime,
            factoriesTime,
            autoPlanConfig
        }
    }
};

export type TRouteExtend = IRoute & {
    activity_log?: IActivityLog[],
    activityLogRoot?: IActivityLog[],
    error?: any,
    index: number
}

interface IProps {
    setting: {
        priorityTime: any
        sitesTime: any
        factoriesTime: any
        autoPlanConfig: any
    }
    data: IOperation
    isSave?: boolean

    onSave(data: IOperation): void

    onClose(): void
}

let controller = new AbortController();
let map: GoogleMap;

const PopupEditDuration: React.FC<IProps> = (props) => {
    const {data} = props;
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingRoute, setLoadingRoute] = useState(false);
    const [loadingDuration, setLoadingDuration] = useState(false);
    const [routeId, setRouteId] = useState('');
    const [routes, setRoutes] = useState<{ [id: string]: TRouteExtend }>({});
    const dispatch = useDispatch();

    useEffect(() => {
        map = googleMap('map')

        const {operation_type, router_id} = data.operation;
        const {
            key: sourceKey = '',
            name: sourceKeyName = '',
            map_id: sourceKeyMap = ''
        } = sourceKeys[operation_type];
        const {
            key: destinationKey = '',
            name: destinationKeyName = '',
            map_id: destinationKeyMap = ''
        } = destinationKeys[operation_type];
        const {
            [sourceKey]: sourceId,
            [sourceKeyName]: sourceName,
            [destinationKey]: destinationId,
            [destinationKeyName]: destinationName,
        }: any = data.operation;

        setRouteId(router_id);
        setLoadingRoute(true);
        new Promise(resolve => resolve(getRoutesByLocation(sourceId, destinationId)))
            .then((response: any) => {
                const list = response.reduce((rs: any, item: IRoute, index: number) => {
                    const {id} = item;
                    const selected = id === router_id;
                    if (selected)
                        rs[id] = {...item, activity_log: data.activity_log, activityLogRoot: data.activity_log, index}
                    else {
                        rs[id] = {...item, index}
                    }
                    return rs;
                }, {})
                setRoutes(list)
                const {points = []} = list[router_id] || {};

                map.generate({
                    type: sourceKeyMap,
                    markers: [{
                        id: sourceId,
                        name: sourceName,
                        lat: points[0]?.latitude,
                        lng: points[0]?.longitude
                    }]
                })
                map.generate({
                    type: destinationKeyMap,
                    markers: [{
                        id: destinationId,
                        name: destinationName,
                        lat: points[points.length - 1].latitude,
                        lng: points[points.length - 1].longitude
                    }]
                })
                map.setCenter([
                    [points[0]?.longitude, points[0]?.latitude],
                    [points[points.length - 1]?.longitude, points[points.length - 1]?.latitude]
                ])
                setLoadingRoute(false);
            })
            .catch(error => {
                setLoadingRoute(false);
                showErrorResponse('Get routes failed', error);
            })

        return () => {
            if (controller)
                controller.abort();
            props.onClose();
        };
    }, [data]);

    const handleClose = useCallback(() => {
        setVisible(false);
        props.onClose();
    }, [])

    const handleChangeActivityLog = (activity_log: IActivityLog[]) => {
        setRoutes({
            ...routes,
            [routeId]: {...routes[routeId], activity_log}
        })
    };

    const handleChangeRoute = (key: string) => {
        const route = routes[key];
        setRouteId(key);
        const {activity_log = []} = route;

        if (activity_log.length === 0 && !loadingDuration) {
            setLoadingDuration(true);
            const {id} = data.operation;
            dispatch(planOpActions.getActivityLog({
                source: [{
                    ...data,
                    operation: {
                        ...data.operation,
                        router_id: key
                    }
                }],
                properties: {
                    [id]: {start: data.activity_log[0].est_start_time}
                },
                success: (ops: any) => {
                    setLoadingDuration(false);
                    const {activity_log} = ops[id];
                    setRoutes({...routes, [key]: {...route, activity_log, activityLogRoot: activity_log}})
                },
                failure: (error: any) => {
                    setLoadingDuration(false);
                    showErrorResponse('Get duration failed', error);
                }
            }))
        }
    }

    const handleSave = useCallback(() => {
        const {activity_log = []} = routes[routeId];
        const newValue = {
            ...data,
            operation: {...data.operation, router_id: routeId},
            activity_log
        };
        if (props.isSave) {
            setLoading(true);
            new Promise(resolve => resolve(updateActivityLogFetch({
                router_id: routeId,
                activity_log
            }, data.operation.id)))
                .then(() => {
                    setLoading(false);
                    props.onSave(newValue);
                    handleClose();
                })
                .catch(error => {
                    showErrorResponse('Update failed', error);
                    setLoading(false);
                })
        } else {
            props.onSave(newValue)
            handleClose();
        }
    }, [routes, routeId]);


    const {site_id, site_name, sites = []} = props.data.operation;
    const listOfSite = sites.length === 0 ? [{id: site_id, name: site_name}] : sites;

    return <Drawer
        {...propsModal}
        className={styles.popup}
        title={<Header title='Route & Duration' onClose={handleClose}/>}
        placement="right"
        width='100%'
        open={visible}
        onClose={handleClose}
    >
        <div className={styles.content}>
            <Route key='route' {...{
                data,
                routes,
                routeId,
                sites: listOfSite,
                map,
                loading: loadingRoute || loadingDuration,
                setRoute: handleChangeRoute
            }}/>
            <div className={styles.duration}>
                <Duration key='duration' {...{
                    data,
                    route: routes[routeId],
                    sites: listOfSite,
                    loading: loadingRoute || loadingDuration,
                    updateActivityLog: handleChangeActivityLog
                }}/>
                <Footer
                    className={styles.footer}
                    cancel={{click: handleClose}}
                    ok={{
                        loading: loading || loadingRoute || loadingDuration,
                        click: handleSave
                    }}
                />
            </div>
        </div>
    </Drawer>
};

export default connect(mapStateToProps, {})(PopupEditDuration);
