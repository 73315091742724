import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import {IProductionArea, ISite} from "../../../../util/varibles/interface";
import {Checkbox} from "antd";
import styles from '../style.module.scss';
import LoadingBox from "../../../../components/LoadingBox";
import {getProdAreaName} from "../../../../util/varibles/global";
import {DashboardSetting} from "../../../../util/varibles/userSetting";
import {loginActions} from "../../../LoginPage/reducer";
import {dashboardActions} from "../../reducer";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState) => {
    const {productionAreas} = state.dashboard.filter || {};
    return {
        filter: productionAreas,
        loading: state.dashboard.loadingProductionAreas,
        sites: state.dashboard.sites,
        productionAreas: state.dashboard.productionAreas,
    }
};

interface IProps {
    filter: any
    loading: boolean
    sites: ISite[]
    productionAreas: IProductionArea[]
}

const ProductionAreas: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const [productionIds, setProductionIds] = useState<Set<string>>(new Set());
    const {
        filter = [],
        loading,
        sites,
        productionAreas,
    } = props;

    useEffect(() => {
        const ids = new Set(sites.reduce((list: string[], item: any) => {
            const {diseases = []} = item || {}
            const {production_area = {}} = diseases[0] || {};
            const id = item.prod_area || production_area.id;
            return [...list, `${id}`];
        }, []));
        ids.delete('undefined');
        setProductionIds(ids);
    }, [sites]);


    const onChange = (data: any) => {
        dispatch(dashboardActions.updateFilter({productionAreas: data}));
        dispatch(loginActions.updateUserSetting([{[DashboardSetting.PRODUCT_AREAS]: data}]));
    };

    const renderContent = () => {
        if (loading || productionAreas.length === 0)
            return <LoadingBox loading={loading} message="No data"/>

        return <Checkbox.Group className={[styles.checkbox, 'checkbox-fill'].join(' ')} value={filter}
                               onChange={onChange}>
            {productionAreas.reduce((list: any, item) => productionIds.has(`${item.id}`)
                ? [...list, <Checkbox key={item.id} value={item.id.toString()}>{getProdAreaName(item)}</Checkbox>]
                : list, [])}
        </Checkbox.Group>
    }

    return <div className='pb-12'>
        {renderContent()}
        {filter.length > 0 && <div className='link mt-10 ml-5' onClick={() => onChange([])}>Clear</div>}
    </div>;
};

export default connect(mapStateToProps, {})(ProductionAreas);
