import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import {ACTION_TYPE, OP_STATUS, OPERATION_TYPE, SITE_TYPE} from "../../../../../../../util/varibles/constants";
import WrapperOperation from "../Component/WrapperOperation";
import stylesContainer from "../style.module.scss";
import TreatmentView from "../../../../../_Component/ContentOperation/TreatmentView";
import {ISite} from "../../../../../../../util/varibles/interface";
import {IPropsOp} from "../index";
import Icon, {ICON_NAME} from "../../../../../../../components/Icon";
import {AppState} from "../../../../../../../util/store/store";

const icon = <Icon icon={ICON_NAME.TREATMENT}/>

const mapStateToProps = (state: AppState) => ({
    sites: state.planOperation.sites
});

interface IProps extends IPropsOp {
    sites: ISite[]
    operationType: OPERATION_TYPE

    isOwn: boolean
    isVesselOwn: boolean
    permission: any
    status: OP_STATUS

    openEdit(payload: any): void
}

const Treatment: React.FC<IProps> = (props) => {
    const {isOwn, sites, data, openEdit} = props;

    const edit = useCallback(() => {
        const {operation, vessel, activity_log} = data;

        const {site_id, site_name, tasks} = operation;
        const unitsOp = tasks.reduce((rs: any, item: any) => {
            const {id = ''} = item.sub_operation || {};
            rs[id] = true;
            return rs;
        }, {})
        const {units = [], type = SITE_TYPE.INTERNAL} = sites.find((item: any) => item.id === site_id) || {};
        const store = Object.keys(unitsOp).reduce((list: any, id: any) => {
            const key = id + '|-';
            list[key] = {
                ...units.find((unit: any) => unit.id === id),
                site_id,
                site_name,
                site_type: type,
            }
            return list;
        }, {});

        openEdit({
            isShow: true,
            operationType: OPERATION_TYPE.TREATMENT,
            editMode: ACTION_TYPE.EDIT,
            values: {
                activity_log,
                operation,
                vessel,
                store
            }
        })
    }, [data, sites, openEdit])

    return <WrapperOperation {...{...props, icon, edit}}>
        <div className={[stylesContainer['content-wrapper'], stylesContainer.layout].join(' ')}>
            <TreatmentView
                status={props.status}
                data={data}
                isOwn={isOwn}
            />
        </div>
    </WrapperOperation>;
};


export default connect(mapStateToProps, {})(Treatment);