import React from 'react';
import {formatNumber} from "../../../../../../../util/varibles/global";
import {TOperation} from "../../../../../../../util/varibles/interface";
import styles from "./style.module.scss";
import TravellingToSite from "../../../_Component/TravellingToSite";
import Cleaning from "../../../_Component/Clening";
import {UpdateName} from "../../constants";

interface IProps {
    op: TOperation
    indexHarvest: number

    onUpdate(key: UpdateName, args: any): void
}

const OpTotal: React.FC<IProps> = ({op, indexHarvest, onUpdate}) => {
    return <div className={styles.line} data-lev='total'>
        <div className='font-w4'>Sum amount/Sum total weight:</div>
        <div className='text-right pr-16'>
            {formatNumber(op.total_amount)}
        </div>
        <div className='text-right pr-16'>
            {formatNumber(Math.round(op.total_weight / 1000) / 1000) + ' t'}
        </div>
        <div className='flex justify-end gap-[20px] items-center'>
            <TravellingToSite op={op} indexHarvest={indexHarvest} onChange={onUpdate}/>
            <Cleaning op={op} indexHarvest={indexHarvest} onChange={onUpdate}/>
        </div>
    </div>
};

export default OpTotal;
