import React, {Component} from 'react';
import {connect} from 'react-redux';
import stylesContainer from "../style.module.scss";
import {Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import Filter from "./Filter";
import AddExternalSite from "../_Component/AddExternalSite";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import AvgWeight from "../_Component/AvgWeight";
import LiceSort from "../_Component/LiceSort";
import {PlanSetting} from "../../../../util/varibles/userSetting";
import {loginActions} from "../../../LoginPage/reducer";

const mapStateToProps = null

interface IProps {
    searchValue: any
    weightFilter: [number | null, number | null],
    sites: any;
    unitsSelected: any
    liceSort: null | string

    handleSearch(value: any): void

    onClear(): void

    updateUserSetting(payload: any[]): void
}

class HeaderSmoltComponent extends Component<IProps> {

    handleChangeLice = (value: any) => {
        this.props.handleSearch({liceSort: value})
        this.props.updateUserSetting([{[PlanSetting.SMOLT_LICE_SORT]: value}])
    }

    render() {
        const {weightFilter, liceSort, sites, unitsSelected, handleSearch, onClear} = this.props;
        const count = Object.keys(unitsSelected).length;

        return <div className={stylesContainer['tab-header']}>
            <div className={stylesContainer['header-left']} data-operaion-type='spot'>
                <LiceSort lice={liceSort} onChange={this.handleChangeLice}/>
                {count > 0 && <div className={stylesContainer.clear}>
                    <span>{count} unit selected</span>
                    <div className={stylesContainer.close} onClick={onClear}>
                        <Icon icon={ICON_NAME.CLOSE} size='small'/>
                    </div>
                </div>}
                <Input
                    className={[stylesContainer['search']].join(' ')}
                    placeholder="Search smolt site"
                    allowClear
                    onChange={({target: {value}}: any) => handleSearch({searchValue: value})}
                    suffix={<SearchOutlined className='text-comment'/>}
                />
                <AvgWeight weight={weightFilter} onChange={value => handleSearch({weightFilter: value})}/>
            </div>
            <div className={stylesContainer['header-right']}>
                <Filter {...{sites}}/>
                <AddExternalSite/>
            </div>
        </div>;
    }
}

export default connect(mapStateToProps, {
    updateUserSetting: loginActions.updateUserSetting
})(HeaderSmoltComponent);