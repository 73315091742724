import React, {useCallback, useState} from 'react';
import {IOperation} from "../../../../util/varibles/interface";
import {Modal, Select} from "antd";
import Header from "../../../../components/Popup/Component/Header";
import {operationType, propsModal} from "../../../../util/varibles/constants";
import Footer from "../../../../components/Popup/Component/Footer";
import LoadingBox from "../../../../components/LoadingBox";
import {searchUserFetch} from "../../../../util/services/chat";
import {GlobalService} from "../../../../util/services/global";
import {showErrorResponse} from "../../../../util/varibles/global";
import {notify, NotifyCode} from "../../../../util/varibles/message";

let timeout: any;
const initUsers = {options: [], loading: false, isMore: false, values: []};

interface IProps {
    data: IOperation

    onClose(): void
}

const PopupSendMail: React.FC<IProps> = ({data, onClose}) => {
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState(initUsers);

    const handleClose = useCallback(() => {
        setVisible(false);
        onClose();
    }, [onClose],);


    const handleSend = useCallback(() => {
        setLoading(true);
        const {values} = users;
        if (values.length === 0) {
            notify.error(NotifyCode.E30)();
            return;
        }

        const {id, tenant_id} = data.operation;
        const {id: vessel_id} = data.vessel;
        new Promise(resolve => resolve(GlobalService.sendMail({
            id,
            tenant_id,
            vessel_id,
            recipients: values.map((item: any) => item.label)
        })))
            .then(() => {
                notify.success(NotifyCode.S13)();
                setLoading(false);
                handleClose();
            })
            .catch((error) => {
                setLoading(false);
                showErrorResponse('Send mail failed', error)
            })
    }, [users, data, handleClose]);

    const handleSearch = (key: string) => {
        const value = key.trim();
        if (timeout) {
            clearTimeout(timeout)
        }
        if (value.length === 0) {
            setUsers((prev) => ({...initUsers, values: prev.values}))
            return;
        }

        timeout = setTimeout(() => {
            setUsers((prev) => ({...prev, loading: true}))
            new Promise((resolve) => {
                resolve(searchUserFetch({key: value, index: 1}));
            }).then((rs: any) => {
                const {users, next} = rs;
                setUsers((prev) => ({
                    ...prev,
                    loading: false,
                    options: users.map((item: any) => ({label: item.username, value: item.id})),
                    isMore: next,
                }));
            }).catch(() => {
                setUsers((prev) => ({...prev, loading: false}))
            })
        }, 250)
    }

    const handleSelectUser = (isSelect: boolean, value: any, event: any) => {
        const {label} = event;

        setUsers((prev) => {
            const {values}: any = prev;
            return {
                ...prev,
                values: isSelect
                    ? [...values, {id: value, label: label || value}]
                    : values.filter((item: any) => item.id !== value)
            }
        })
    }

    const {name: opType} = operationType[data.operation.operation_type];

    return <Modal
        {...propsModal}
        open={visible}
        title={<Header
            title={`Send the PDF file of ${opType} #${data.operation.operation_code}`}
            onClose={handleClose}
        />}
        onCancel={handleClose}
    >
        <div className='pl-16'>Email address</div>
        <Select
            mode='tags'
            className='w-full mt-1 select-mode-tag'
            onSearch={handleSearch}
            placeholder={'Choose people to send mail'}
            onSelect={(value: any, event: any) => handleSelectUser(true, value, event)}
            onDeselect={(value: any, event: any) => handleSelectUser(false, value, event)}
            value={users.values.map((item: any) => item.id)}
            showSearch
            filterOption={false}
            popupMatchSelectWidth={false}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            notFoundContent={<LoadingBox loading={users.loading}/>}
            options={users.options}
        />
        <Footer
            className='pt-15'
            ok={{
                text: 'Send mail',
                click: handleSend,
                loading
            }}
            cancel={{
                click: handleClose
            }}
        />
    </Modal>;
};

export default PopupSendMail;