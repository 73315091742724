import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ACTION_TYPE, OPERATION_TYPE} from "../../../../../../util/varibles/constants";
import {checkRoute} from "../../../../util";
import {notify, NotifyCode} from "../../../../../../util/varibles/message";
import styles from './style.module.scss';
import Operations from "./Operations";
import Footer from "../../Footer";
import {createTransport, createTransports, getSubData} from "./constants";
import {IOperations, IRoute, ISite, IStateGlobal} from "../../../../../../util/varibles/interface";
import Units from "../../_Component/Units";
import {updateData, UpdateName} from "../constants";
import {planOpActions} from "../../../../reducer";
import {IUpdateOp} from "../../../../constants";
import {IGetActivityLog} from "../../../../saga";

const mapStateToProps = (state: IStateGlobal) => ({
    userTenantId: state.login.user.tenant_id,
    sites: state.planOperation.sites,
    routes: state.planOperation.routes,
    operations: state.planOperation.operations,
});

const initState = {
    operations: [],
    store: {},
    units: {},
    count: 0,
    isCalculate: false,
    loading: false
}

interface IProps {
    userTenantId: string
    popupOperation: any
    sites: ISite[]
    routes: IRoute[]
    store: any
    editMode: ACTION_TYPE
    isFull: boolean
    operations: IOperations
    loading?:boolean

    getActivityLog(payload: IGetActivityLog): void

    onSave(payload: IUpdateOp, action?: Function): void

    onClose(): void;
}

interface IState {
    operations: any
    store: any
    units: any
    count: number
    isCalculate: boolean
    loading: boolean
}

class Transport extends Component<IProps, IState> {
    state = initState;

    constructor(props: IProps) {
        super(props);
        this.state = this.generateState(this.props);
    }

    shouldComponentUpdate = (nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean => {
        if (JSON.stringify(this.props.popupOperation) !== JSON.stringify(nextProps.popupOperation)) {
            this.setState(this.generateState(nextProps))
        }

        return this.state !== nextState
    }

    generateState = (props: IProps): IState => {
        const {sites, store, editMode, isFull} = props;
        const {operation, vessel} = props.popupOperation.values;

        if (editMode === ACTION_TYPE.CREATE) {
            const {sub_operations} = operation
            const {operations, store: newStore} = createTransports({
                data: sub_operations,
                store,
                vessel,
                isFull,
                properties: {tenant_id: this.props.userTenantId}
            });

            return {
                isCalculate: true,
                loading: false,
                operations,
                store: {...newStore},
                units: {},
                count: operations.length
            };
        } else {
            let error_detail: any = {};
            if (operation.total_weight > vessel.capacity) {
                error_detail.weight = notify[NotifyCode.E1]()
            }
            const {destination_id} = operation;
            const destination: any = sites.find((item: any) => item.id === destination_id) || {};
            const units = {[destination_id]: destination.units}
            const operations = JSON.parse(JSON.stringify([{...operation, error_detail}]));
            return {isCalculate: false, loading: false, operations, store, units, count: operations.length};
        }
    }

    addOperation = (unit: any) => {
        const {id, avg_weight, fish_amount} = unit;
        const {operations, store, count}: any = this.state;
        const keyStore = id + '|-';
        const {vessel} = this.props.popupOperation.values;
        const common = {vessel, keyStore, count, store};

        const {operation, store: newStore} = createTransport({
            ...common,
            fish_amount,
            avg_weight,
            properties: {tenant_id: this.props.userTenantId}
        })

        operations.push(operation)
        this.setState({operations, store: {...newStore}, count: count + 1})
    }

    handleSubmit = () => {
        const {action} = this.props.popupOperation;
        if (this.state.isCalculate) {
            const isError = this.checkError();
            if (isError) return;

            const {operations: dataNew}: any = this.state;
            const {editMode} = this.props;
            const {activity_log, vessel} = this.props.popupOperation.values;

            const {id: opId = 0} = this.props.popupOperation.values.operation;
            const properties: any = {[opId]: {start: activity_log[0].est_start_time}};
            const operations = dataNew.map((item: any, index: number) => {
                const {sub_operations, sub_destinations} = getSubData(item);
                properties[index] = {
                    ...properties[index] || {},
                    start_place: dataNew[index - 1]
                        ? {start_place_id: dataNew[index - 1].factory_id, start_place_field: 'factory',}
                        : undefined
                }

                return {
                    operation: {...item, sub_operations, sub_destinations,},
                    vessel
                }
            })

            this.setState({loading: true});
            this.props.getActivityLog({
                source: operations,
                properties,
                success: (ops) => {
                    this.setState({loading: false});
                    this.props.onSave(editMode === ACTION_TYPE.CREATE ? {add: ops} : {update: ops}, action);
                },
                failure: () => {
                    this.setState({loading: false});
                }
            })
        } else {
            const operation = this.state.operations[0];
            const {id} = this.state.operations[0];
            this.props.onSave({update: {[id]: {...this.props.operations[id], operation}}, isCheck: false}, action);
        }
    };

    checkError = () => {
        const {operations}: any = this.state;
        let isError = false;
        const operationsAfterCheck = operations.reduce((list: any, item: any) => {
            let {
                sub_destinations,
                error_detail = {},
                source_id,
                source_name,
                destination_id,
                destination_name
            } = item;
            if (Object.keys(error_detail).length > 0) {
                isError = true
                return [...list, item];
            }

            let error = sub_destinations.some((sub: any) => sub.fish_amount === 0 || sub.total_weight === 0)
            if (error) {
                isError = true;
                error_detail.fish_amount = notify[NotifyCode.E37](['Fish amount, total weight']);
            }

            if (destination_id) {
                error = sub_destinations.some((sub: any) => !sub.delivery_id)
                if (error) {
                    isError = true
                    error_detail.delivery_id = 'Deliv. unit is required field';
                }
                const {routes} = this.props;
                const validRoute = checkRoute({id: source_id, name: source_name}, {
                    id: destination_id,
                    name: destination_name
                }, routes);
                if (validRoute.error === 2) {
                    isError = true;
                    error_detail.route = [{source_id: source_id, destination_id, message: validRoute.message}];
                }
            } else {
                isError = true
                error_detail.destination_id = 'Deliv. site is required field';
            }

            return [...list, {...item, error_detail}];
        }, []);
        if (isError)
            this.setState({operations: operationsAfterCheck})
        return isError;
    }

    updateState = (state: any) => {
        this.setState({...state, isCalculate: true});
        this.forceUpdate();
    }

    handleUpdate = (key: UpdateName, args: any) => {
        const action = updateData[OPERATION_TYPE.TRANSPORT][key];
        if (action)
            this.setState(action({state: this.state, props: this.props, args}));
    }

    render() {
        const {store, units, operations}: any = this.state;
        const {routes, sites, editMode} = this.props;
        const {vessel} = this.props.popupOperation.values;

        return <>
            <div className={styles['container-body']}>
                <Units {...{
                    store,
                    editMode,
                    addOperation: this.addOperation,
                    nameRow: (data: any) => <span>{[data.site_name, data.unit_id].join(', ')}</span>
                }}>
                    <span>Smolt site</span>
                </Units>
                <div className={styles['title-table']}>
                    Delivery info.
                </div>
                <Operations {...{
                    operations,
                    store,
                    vessel,
                    routes,
                    sites,
                    units,
                    editMode,
                    updateState: this.updateState,
                    onUpdate: this.handleUpdate
                }}/>
            </div>
            <Footer {...{
                loading: this.state.loading || this.props.loading,
                onClose: this.props.onClose,
                editMode, handleSubmit: this.handleSubmit,
            }}/>
        </>;
    }
}

export default connect(mapStateToProps, {
    getActivityLog: planOpActions.getActivityLog
})(Transport);