import React, {useRef, useState} from 'react';
import {FormInstance} from "antd/lib/form";
import {Form, Input, Modal, Switch} from "antd";
import {propsModal} from "../../../util/varibles/constants";
import Header from "../../../components/Popup/Component/Header";
import styles from "./style.module.scss";
import {IUnit} from "../../../util/varibles/interface";
import Footer from "../../../components/Popup/Component/Footer";

interface IProps {
    visible?: boolean
    data: IUnit

    onSave(unit: IUnit): void

    onClose(): void
}

const PopupEditUnit: React.FC<IProps> = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const formRef = useRef<FormInstance>(null);

    const handleClose = () => {
        setVisible(false);
        props.onClose()
    }

    const handleSubmit = (values: any) => {
        props.onSave({...props.data, ...values});
        handleClose();
    }

    return <Modal
        {...propsModal}
        width={500}
        title={<Header title={`Edit unit: ${props.data.unit_id}`} onClose={handleClose}/>}
        open={visible}
    >
        <Form ref={formRef} initialValues={props.data} onFinish={handleSubmit} layout='vertical' requiredMark={false}>
            <Form.Item label={<span className='font-s15'>Unit Id</span>}
                       name="unit_id"
                       rules={[{
                           required: true,
                           message: `"Unit Id" is required`
                       }]}
            >
                <Input className='border-r-40'/>
            </Form.Item>
            <Form.Item
                label='	Mortality risk:'
                className={styles['checkbox-flex']}
                name="mortality_risk"
                valuePropName="checked"
            >
                <Switch checkedChildren="High" unCheckedChildren="Low"/>
            </Form.Item>
            <Form.Item label={<span className='font-s15'>Count</span>}
                       name="fish_amount"
            >
                <Input className='border-r-40'/>
            </Form.Item>
            <Form.Item label={<span className='font-s15'>Avg. weight</span>}
                       name="avg_weight"
            >
                <Input
                    className='border-r-40 pl-16'
                    suffix={<span className='font-s15 font-w4 font-c-suffix'>kg</span>}
                />
            </Form.Item>
            <Footer cancel={{click: handleClose}}/>
        </Form>
    </Modal>;
};

export default PopupEditUnit;