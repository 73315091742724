import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ACTION_TYPE, OPERATION_TYPE, propsModal, vesselTypes} from "../../../../util/varibles/constants";
import {Modal} from "antd";
import Header from "./Header";
import Harvest from "./Body/Harvest";
import styles from './style.module.scss';
import Transport from "./Body/Transport";
import Event from "./Body/Event";
import Treatment from "./Body/Treatment";
import Service from "./Body/Service";
import {planOpActions} from "../../reducer";
import {IUpdateOp} from "../../constants";
import {ICON_NAME} from "../../../../components/Icon";
import {AppState} from "../../../../util/store/store";

const initState = {
    isShow: false,
    operationType: OPERATION_TYPE.HARVEST,
    propsTitle: {
        icon: ICON_NAME.HARVEST,
        title: ''
    },
    content: '',
    style: {},
    width: 500,
    className: ''
}

const mapStateToProps = (state: AppState) => ({
    popupOperation: state.planOperation.popupOperation
});

interface IProps {
    popupOperation: any

    updateOps(payload: IUpdateOp): void

    togglePopupOperation(params: any): void
}

interface IState {
    isShow: boolean
    propsTitle: any
    content: any
    operationType: any
    centered?: boolean
    className?: any
    width?: any
}

class PopupOperation extends Component<IProps, IState> {
    state = initState;

    renderContent = (operationType: OPERATION_TYPE, props: any) => {
        const {isShow, editMode, isAllowChange = false, isFull = false, values} = props;
        if (!isShow) {
            this.setState({...this.state, isShow: false});
            return;
        }

        const {store, vessel} = values;

        const state = {...initState, isShow: true, operationType};

        const propsTitle = {vessel, operationType, isAllowChange, title: '', isDrag: true, icon: ''}

        const common = {
            editMode,
            popupOperation: props,
            onSave: this.handleSave,
            onClose: this.handleClose
        }

        switch (operationType) {
            case OPERATION_TYPE.HARVEST: {
                const {name, capacity} = vessel;
                const {isInternal = true} = props;
                propsTitle.title = name.trim() + ', ' + Math.round(capacity / 1000) / 1000 + 't';
                propsTitle.icon = ICON_NAME.HARVEST;
                const content = <Harvest {...{...common, store, isFull, isInternal}} />
                const className = styles.harvest;
                this.setState({...state, propsTitle, content, width: 1200, className})
                break;
            }
            case OPERATION_TYPE.TREATMENT: {
                let className, content, width;
                const {name, type} = vessel;
                const {shortName = ''} = vesselTypes[type - 1] || {};
                propsTitle.title = [name, shortName].join(' - ');
                propsTitle.icon = ICON_NAME.TREATMENT;
                className = styles.treatment
                content = <Treatment {...{...common, store}}/>
                width = 1200

                this.setState({...state, propsTitle, content, width, className})
                break;
            }
            case OPERATION_TYPE.EVENT: {
                propsTitle.title = editMode === ACTION_TYPE.CREATE ? 'New event' : 'Edit event';
                propsTitle.icon = ICON_NAME.EVENT;
                const content = <Event {...{...common, values}} />
                this.setState({
                    ...state,
                    propsTitle,
                    content,
                    width: 480,
                    className: ''
                })
                break;
            }
            case OPERATION_TYPE.TRANSPORT: {
                const {name, capacity} = vessel;
                propsTitle.title = name + ', ' + Math.round(capacity / 1000) / 1000 + 't';
                propsTitle.icon = ICON_NAME.TRANSPORT;
                const content = <Transport {...{...common, store, isFull}} />
                const className = styles.harvest;
                this.setState({...state, propsTitle, content, width: 1200, className})
                break;
            }
            case OPERATION_TYPE.SERVICE: {
                const {name, capacity} = vessel;
                propsTitle.title = name + ', ' + Math.round(capacity / 1000) / 1000 + 't';
                propsTitle.icon = ICON_NAME.SERVICE;
                const content = <Service {...{...common, store}} />
                const className = styles.harvest;
                this.setState({...state, propsTitle, content, width: 1200, className});
                break;
            }
            default:
                this.setState(initState);
        }
    }

    handleChangeOperationType = (operationType: OPERATION_TYPE) => {
        this.setState({operationType}, () => this.renderContent(operationType, this.props.popupOperation))
    }

    shouldComponentUpdate = (nextProps: Readonly<IProps>): boolean => {
        const {popupOperation} = nextProps;
        if (JSON.stringify(this.props.popupOperation) !== JSON.stringify(popupOperation)) {
            this.renderContent(popupOperation.operationType, popupOperation);
        }
        return true;
    }

    handleSave = (params: IUpdateOp, action?: Function) => {
        this.props.updateOps(params);
        if (action) {
            action();
        }
        setTimeout(this.handleClose, 100);

    }

    handleClose = () => {
        this.props.togglePopupOperation({isShow: false})
    }

    render() {
        const {isShow, operationType, content, propsTitle, width, className} = this.state;
        const {limitOperationTypes = []} = this.props.popupOperation;

        const props: any = {
            ...propsModal,
            open: isShow,
            title: <Header
                {...propsTitle}
                operationType={operationType}
                limitOperationTypes={limitOperationTypes}
                changeOperationType={this.handleChangeOperationType}
                onClose={this.handleClose}
            />,
            width,
            wrapClassName: [styles.popup, className].join(' '),
            styles: {body: {padding: 0, height: '100%'}},
            onCancel: this.handleClose,
        }
        return <Modal {...props}>
            {content}
        </Modal>;
    }
}

export default connect(mapStateToProps, {
    updateOps: planOpActions.updateOps,
    togglePopupOperation: planOpActions.togglePopupOperation
})(PopupOperation);
