import React, {Component} from 'react';
import styles from './style.module.scss';
import {Button} from "antd";
import {UnitModel} from "../../../../BiologyBrowser/constants";
import {UpdateName} from "../../Body/constants";
import PopupUnit from "./PopupUnit";
import {ISite} from '../../../../../../util/varibles/interface';
import {openPopup} from "../../../../../../components/Popup/Component/WrapperPopup";
import Icon, {ICON_NAME} from "../../../../../../components/Icon";

interface IProps {
    sites: ISite[]
    except: any
    isEmpty?: boolean

    onUpdate(key: UpdateName, args: any): void
}

class AddUnit extends Component<IProps> {
    static defaultProps = {
        isEmpty: false
    }

    handleOpenPopup = () => {
        const {sites, except, isEmpty} = this.props;
        const modalNode = openPopup(<PopupUnit {...{
            sites,
            except,
            isShow: true,
            isEmpty,
            onAdd: (data: any) => {
                const {sites} = this.props;
                const selected = new Set(data);
                const units = sites.reduce((list: any, site: any) => [
                    ...list,
                    ...site.units.reduce((subList: any, unit: any) => {
                        const key = unit.id;
                        if (selected.has(key)) {
                            selected.delete(key);
                            return [...subList, UnitModel(unit, site)]
                        }
                        return subList
                    }, [])
                ], []);
                this.props.onUpdate(UpdateName.ADD_UNIT, {units})
            },
            onClose: () => modalNode.remove()
        }}/>)
    }

    render() {
        return <div className={styles['container-add-unit']}>
            <Button className='bt-primary pl-10 pr-10' data-icon={true} onClick={this.handleOpenPopup}>
                <Icon icon={ICON_NAME.ADD} size='small'/>
                <span className='ml-5'>Add unit</span>
            </Button>
        </div>;
    }
}

export default AddUnit;