import {
    CENTER_DEFAULT,
    IElements,
    initElOnMap,
    MAP_ELEMENT,
    MAP_STYLE,
    mapElement,
    mapType,
    TCreateElement,
    ZOOM_DEFAULT
} from "./constants";
import {ILatLng} from "../../varibles/interface";
import {Loader} from "@googlemaps/js-api-loader";
import {API_KEY} from "../../varibles/constants";
import {aubergineMapStyle, darkMapStyle, silverMapStyle} from "./map_style";
import {closeAllInfoWindow} from "./Dialog";
import {clearLines} from "./line";
import {getBoundsZoomLevel} from "../../varibles/global";
import {selectOneSetting} from "../../store/selectors";
import {GlobalSetting} from "../../varibles/userSetting";
import {store} from "../../store/store";

const loader = new Loader({
    apiKey: API_KEY,
    version: "weekly",
    libraries: ["places"]
});

interface IProps {
    mapOpt?: {
        zoom?: number
        center?: ILatLng
        googleMapStyle?: MAP_STYLE
    }

    callback?(map: GoogleMap): void
}

export function googleMap(id: string, props?: IProps): GoogleMap {
    const state = store.getState();
    const mapStyle = selectOneSetting(GlobalSetting.MAP_STYLE)(state);
    const {mapOpt = {}} = props || {}

    return new GoogleMap(id, {
        ...props || {},
        mapOpt: {...mapOpt, googleMapStyle: mapOpt?.googleMapStyle || mapStyle || MAP_STYLE.AUBERGINE}
    });
}

class GoogleMap {
    private map?: google.maps.Map;
    private elements: IElements = initElOnMap;
    private tasks: ((map: google.maps.Map) => void)[] = [];

    constructor(id: string, props: IProps = {}) {
        loader.importLibrary('maps').then(({Map, StyledMapType}) => {
            const {
                zoom = ZOOM_DEFAULT,
                center = CENTER_DEFAULT,
                googleMapStyle = MAP_STYLE.AUBERGINE,
            } = props.mapOpt || {};

            const silverStyle = new StyledMapType(silverMapStyle, {name: mapType[MAP_STYLE.SILVER].name});
            const darkStyle = new StyledMapType(darkMapStyle, {name: mapType[MAP_STYLE.DARK].name});
            const aubergineStyle = new StyledMapType(aubergineMapStyle, {name: mapType[MAP_STYLE.AUBERGINE].name});

            const map = new Map(document.getElementById(id) as HTMLElement, {
                zoom,
                center,
                mapTypeControl: false,
                fullscreenControl: false,
                zoomControl: false,
                scaleControl: false,
                streetViewControl: false,
                gestureHandling: 'auto',
                minZoom: 2,
                clickableIcons: false,
                maxZoom: 20
            });

            map.mapTypes.set(MAP_STYLE.SILVER, silverStyle);
            map.mapTypes.set(MAP_STYLE.DARK, darkStyle);
            map.mapTypes.set(MAP_STYLE.AUBERGINE, aubergineStyle);
            map.setMapTypeId(googleMapStyle);
            this.map = map;

            if (props.callback)
                props.callback(this)
            if (this.tasks.length > 0) {
                this.tasks.forEach(item => item(map));
                this.tasks = [];
            }
        });
    }

    addListener = (name: string, action: Function) => {
        this.addTask(map => map.addListener(name, action))
    }

    generate = (props: TCreateElement) => {
        this.addTask(map => {
            const {type, event, ...args} = props;
            const {key, create} = mapElement[type];
            const data = {
                ...args,
                elements: this.elements,
                getElement: this.getElement,
                event: event || {}
            };
            this.elements[key] = create({...data, map});
        })
    }

    updateMarkerPosition = (type: MAP_ELEMENT, id: string, position: any) => {
        const {key} = mapElement[type];
        const {[key]: list} = this.elements;
        list[id].element.setPosition(position);
    }

    closeDialogs = (exceptIds?: string[]) => {
        closeAllInfoWindow(this.elements.dialog, exceptIds);
    }

    clearSeaways = (exceptIds: string[] = []) => clearLines(this.elements.polyLines, exceptIds);

    setMapType = (id: MAP_STYLE) => {
        this.addTask(map => map.setMapTypeId(id))
    };

    clearMarkers = (type: MAP_ELEMENT.SITE | MAP_ELEMENT.FACTORY | MAP_ELEMENT.VESSEL | '' = '', exceptIds: string[] = []) => {
        closeAllInfoWindow(this.elements.dialog, []);
        const ids = new Set(exceptIds);
        let list: any;
        if (type === '') {
            const {
                [mapElement[MAP_ELEMENT.SITE].key]: siteMarkers,
                [mapElement[MAP_ELEMENT.FACTORY].key]: factoryMarkers,
                [mapElement[MAP_ELEMENT.VESSEL].key]: vesselMarkers
            } = this.elements;
            list = {...siteMarkers, ...factoryMarkers, ...vesselMarkers}
        } else {
            const {key} = mapElement[type];
            list = this.elements[key];
        }

        Object.keys(list).forEach(key => {
            if (!ids.has(key))
                list[key].element.setMap(null)
        });
    }

    setCenter = (list: (number[])[], zoom?: number) => {
        if (list.length === 0)
            return;

        if (list.length === 1) {
            this.addTask((map: google.maps.Map) => {
                const [lng, lat] = list[0];
                const position = new google.maps.LatLng(lat, lng);
                map.setCenter(position)
            })
            return
        }
        this.addTask((map: google.maps.Map) => {
            const bounds = new google.maps.LatLngBounds();
            list.forEach(([lng, lat]) => bounds.extend(new google.maps.LatLng(lat, lng)))
            map.fitBounds(bounds);
            if (list.length === 1) {
                if (zoom)
                    setTimeout(() => this.map?.setZoom(zoom), 100)
            } else {
                const zoom = getBoundsZoomLevel(bounds, map.getDiv());
                map.setZoom(zoom);
            }
        })
    }

    addTask(job: (map: google.maps.Map) => void) {
        if (this.map) {
            job(this.map)
        } else {
            this.tasks.push(job)
        }
    }

    getElement = (type: MAP_ELEMENT, id?: string) => {
        const {key} = mapElement[type];
        if (id)
            return this.elements[key][id]
        else
            return this.elements[key]
    }

    get Map() {
        return this.map
    }
}

export default GoogleMap
