export interface VesselManagementState {
    ownVessels: any[]
    hiredVessels: any[]
    loadingList: boolean
    loading: {
        approve: boolean
    },
}

export const initialState: VesselManagementState = {
    ownVessels: [],
    hiredVessels: [],
    loadingList: false,
    loading: {
        approve: false
    },
}

export const SEND_OFFER_REQUEST = 'SEND_OFFER_REQUEST';
export const UPDATE_OFFER_REQUEST = 'UPDATE_OFFER_REQUEST';
export const SAVE_VESSEL_VESSEL_MANAGEMENT_REQUEST = 'SAVE_VESSEL_VESSEL_MANAGEMENT_REQUEST';
export const UPDATE_CONTRACT_REQUEST = 'UPDATE_CONTRACT_REQUEST';

