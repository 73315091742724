import React, {useEffect, useState} from 'react';
import styles from './style.module.scss';
import stylesParent from '../style.module.scss';
import {ArrowLeftOutlined} from "@ant-design/icons";
import ViewVessels from "./ViewVessels";
import {IActivityLog, IOperation, IOperations, IVessel} from "../../../../../../../util/varibles/interface";
import {useDispatch, useSelector} from "react-redux";
import {planOpActions} from "../../../../../reducer";
import {UpdateName} from "../../constants";
import Tasks from "./Tasks";
import {OPERATION_TYPE, TASK_TYPE} from "../../../../../../../util/varibles/constants";
import Footer from "../../../Footer";
import {IState} from "../index";
import {IUpdateOp, SupportType} from "../../../../../constants";
import {selectPlan} from "../../../../../../../util/store/selectors";
import {generateOpSupport} from "../constants";

let control: AbortController;

interface IProps {
    state: IState
    vessel: IVessel
    setState: Function

    handleUpdate(key: UpdateName, args: any): void

    onBack(): void

    onSubmit(finish: Function): void

    onSave(payload: IUpdateOp, action?: Function): void

    onClose(): void
}

interface IData {
    activity_log: IActivityLog[]
    support_vessels: IVessel[]
    loading: boolean
}

interface IForm {
    selectedVessels: any
    valid: any
}

const initData: IData = {
    activity_log: [],
    support_vessels: [],
    loading: false,
}

const initForm: IForm = {
    selectedVessels: {},
    valid: {}
}

const SupportVessel: React.FC<IProps> = (props) => {
    const {state, vessel, setState} = props;
    const {operation, supportTasks, files = [], startTime} = state;
    const [{support_vessels, loading}, setData] = useState<IData>(initData);
    const [checked, setChecked] = useState<Set<string>>(new Set());
    const [{selectedVessels, valid}, setForm] = useState<IForm>(initForm);
    const vessels = useSelector(selectPlan.vessels);
    const dispatch = useDispatch();

    const handleCheck = (group_id: any, value: any) => {
        const {checked} = value.target;

        setChecked(prev => {
            const currentData = new Set(prev)
            if (checked)
                currentData.add(group_id);
            else
                currentData.delete(group_id);
            return currentData;
        })
    }

    const handleSelect = (value: any) => {
        props.handleUpdate(UpdateName.SELECT_VESSEL_SP, {...value, listOfChecked: checked})
    }

    const handleSubmit = () => {
        props.onSubmit((data: IOperation) => {
            if (control)
                control.abort();
            control = new AbortController();
            const {id: opId} = data.operation;
            if (control)
                control.abort();
            control = new AbortController();
            setState({loading: true});
            dispatch(planOpActions.getActivityLog({
                source: [data],
                properties: {[data.operation.id]: {start: startTime}},
                signal: control,
                success: (ops: IOperations, extended: any) => {
                    setState({loading: false});
                    const {activity_log} = ops[opId];
                    const {support_vessels, tasks} = extended[0];
                    console.log(support_vessels)
                    delete data.operation.support;
                    const newOp = {
                        ...data,
                        activity_log,
                        operation: {...data.operation, tasks, support: SupportType.MAIN, related_id: opId}
                    };

                    const value = (`${opId}`).length > 13 ? {update: {[opId]: newOp}} : {add: {[opId]: newOp}}

                    const supportOps = generateOpSupport({
                        support_vessels,
                        vessels,
                        tasks,
                        operation: data.operation,
                    })
                    value.add = {...value.add || {}, ...supportOps}
                    const listOfFile = files.length > 0 ? {[opId]: files} : {};
                    props.onSave({...value, files: listOfFile});
                },
                failure: () => {
                    setState({loading: false});
                }
            }))
        })
    }

    useEffect(() => {
        const data = {operation: {...operation, support_vessel: true}, vessel, activity_log: []};

        if (control)
            control.abort();
        control = new AbortController();
        setForm(initForm);
        setChecked(new Set());
        setData(prev => ({...prev, loading: true}))
        dispatch(planOpActions.getActivityLog({
            source: [data],
            properties: {[data.operation.id]: {start: startTime, support_vessel: true}},
            signal: control,
            success: (ops: IOperations, extended: any) => {
                const {activity_log} = ops[data.operation.id];
                const {support_vessels} = extended[0];
                setData(prev => ({...prev, activity_log, support_vessels, loading: false}))
            },
            failure: () => setData(initData)
        }))
    }, []);

    useEffect(() => {
        setForm(supportTasks.reduce((rs: any, item: any) => {
            const {type} = item;
            if (type === null)
                return rs;
            const isCheck = checked.has(item.group_id);
            if (isCheck) {
                const {support_vessels: sp_vessel = []} = item;
                sp_vessel.forEach((sub: any) => rs.selectedVessels[sub.id] = (rs.selectedVessels[sub.id] || 0) + 1);
                if (type === TASK_TYPE.TREATMENT) {
                    const key = [OPERATION_TYPE.TREATMENT, item.treatment_type].join('_')
                    rs.valid[key] = true;
                } else if (type === TASK_TYPE.HARVEST) {
                    rs.valid[OPERATION_TYPE.HARVEST] = true;
                } else if (type === TASK_TYPE.COMBINE) {
                    const {sub_tasks} = item;
                    sub_tasks.forEach((sub: any) => {
                        const key = [OPERATION_TYPE.TREATMENT, sub.treatment_type].join('_')
                        rs.valid[key] = true;
                    })
                } else {
                    rs.valid[type] = true;
                }
            }
            return rs;
        }, {selectedVessels: {}, valid: {}}))
    }, [supportTasks, checked]);

    return <div id='support-vessel' className={stylesParent['container-support-vessel']}>
        <div className={styles['container-title']}>
            <div className={styles['bt-back']} onClick={props.onBack}>
                <ArrowLeftOutlined/>
            </div>
            <div className={styles.title}>Task that need support vessel</div>
        </div>
        <Tasks supportTasks={supportTasks} checked={checked} handleCheck={handleCheck}/>
        <div className={styles['container-support-vessel']}>
            <div className={styles.title}>
                Available vessel for support ({support_vessels.length})
            </div>
            <ViewVessels
                loading={loading}
                source={support_vessels}
                checked={checked}
                valid={valid}
                vessels={selectedVessels}
                onSelect={handleSelect}
            />
        </div>
        <Footer {...{loading: state.loading, handleSubmit, onClose: props.onClose,}}/>
    </div>;
};

export default SupportVessel