import React, {useCallback, useEffect, useState} from 'react';
import {IFactory, IOperation, ISite, IVessel} from "../../../util/varibles/interface";
import GoogleMap from "../../../util/library/googlemap/googlemap";
import {connect, useSelector} from "react-redux";
import {dashboardActions} from "../reducer";
import {createMarkers} from "../../../util/varibles/global";
import {MAP_ELEMENT, mapElement} from "../../../util/library/googlemap/constants";
import {VIEW_MODE} from "../../../util/varibles/permission";
import {getSetting} from "../../../util/varibles/defaultSetting";
import {PreferenceSetting} from "../../../util/varibles/userSetting";
import {AppState} from "../../../util/store/store";
import {selectLocation} from "../../../util/store/location/constants";
import {LOCATION_TYPE} from "../../../util/varibles/constants";

const mapStateToProps = (state: AppState) => {
    const {factories, activeSite, productionArea, productionAreas = [], vessels = []} = state.dashboard.filter || {};
    const viewMode = getSetting(state.login.user.setting, PreferenceSetting.VIEW_MODE);
    return {
        map: state.dashboard.map,
        isActiveSite: activeSite,
        isFactory: factories,
        isProductionArea: productionArea,
        productionAreaFilter: productionAreas,
        vesselFilter: vessels,
        productionAreas: state.dashboard.productionAreas,
        sites: state.dashboard.sites,
        factories: state.dashboard.factories,
        vessels: state.dashboard.vessels,
        operations: state.dashboard.operations,
        target: state.dashboard.target,
        viewMode
    }
}

interface IProps {
    map?: GoogleMap
    isActiveSite: boolean
    isFactory: boolean
    isProductionArea: boolean
    productionAreas: any[]
    productionAreaFilter: any[]
    vesselFilter: string[]
    target: any
    sites: ISite[]
    factories: IFactory[]
    vessels: IVessel[]
    operations: IOperation[]
    viewMode: VIEW_MODE

    showInfo(payload: any): void

    getWeather(payload: { type: LOCATION_TYPE, id: string }): void
}

const Layout: React.FC<IProps> = (props) => {
    const {
        map,
        isActiveSite,
        isFactory,
        isProductionArea,
        productionAreaFilter,
        vesselFilter,
        operations,
        productionAreas,
        sites,
        factories,
        vessels,
        target,
        viewMode,
        showInfo,
        getWeather
    } = props;

    const [siteActiveIds, setSiteActiveIds] = useState<Set<string>>(new Set());
    const [markerSites, setMarkerSites] = useState([]);
    const [markerFactories, setMarkerFactories] = useState([]);
    const [markerVessels, setMarkerVessels] = useState<any[]>([]);
    const [markerPois, setMarkerPois] = useState([]);
    const locations = useSelector(selectLocation.locations);

    const eventClick = useCallback((type: MAP_ELEMENT) => ({
        click: (data: any) => {
            showInfo({data, targetType: type});
            const {locationType} = mapElement[type];
            if (locationType)
                getWeather({type: locationType, id: data.id});
        }
    }), [showInfo, getWeather])

    useEffect(() => setMarkerSites(createMarkers(sites)), [sites]);
    useEffect(() => setMarkerFactories(createMarkers(factories)), [factories]);
    useEffect(() => setMarkerPois(createMarkers(locations)), [locations]);
    useEffect(() => {
        const siteIds = new Set(operations.reduce((rs: any[], item) => {
            const {site, source, destination, sites} = item.operation;
            return [
                ...rs,
                (site || {}).id,
                (source || {}).id,
                (destination || {}).id,
                ...(sites || []).map((sub: any) => sub.id)
            ];
        }, []));
        siteIds.delete(undefined);
        setSiteActiveIds(siteIds);
    }, [operations])

    useEffect(() => {
        map?.addListener('click', () => {
            map?.closeDialogs();
            showInfo({});
        })
    }, [map, showInfo]);

    useEffect(() => {
        if (!map)
            return;
        const {markers: markersOfSelected = []} = target.data || {};
        const ids = new Set(markersOfSelected.map((item: any) => item.id));
        const markers = markerPois.map((item: any) => ({
            ...item,
            visible: ids.size === 0 || ids.has(item.id)
        }));
        const type = MAP_ELEMENT.POI;
        map.generate({type, markers, event: eventClick(type)});
    }, [map, markerPois, eventClick]);

    useEffect(() => {
        if (!map)
            return;
        const areaIds = new Set(productionAreaFilter);
        const {markers: markersOfSelected = []} = target.data || {};
        const ids = new Set(markersOfSelected.map((item: any) => item.id));
        const markers = markerSites.map((item: ISite) => {
            const {id, prod_area = 0} = item;
            const isInArea = (areaIds.size === 0 || areaIds.has(prod_area));
            const visible = (isActiveSite ? (siteActiveIds.has(id) && isInArea) : isInArea) && (ids.size === 0 || ids.has(item.id));
            return {...item, visible}
        });
        const type = MAP_ELEMENT.SITE;
        map.generate({type, markers, event: eventClick(type)});
    }, [map, isActiveSite, siteActiveIds, productionAreaFilter, markerSites, target]);

    useEffect(() => {
        if (!map)
            return;
        const {markers: markersOfSelected = []} = target.data || {};
        const ids = new Set(markersOfSelected.map((item: any) => item.id));
        const markers = markerFactories.map((item: any) => ({
            ...item,
            visible: isFactory && (ids.size === 0 || ids.has(item.id))
        }));
        const type = MAP_ELEMENT.FACTORY;
        map.generate({type, markers});
    }, [map, isFactory, target, markerFactories]);

    useEffect(() => {
        let isFlag = false;
        const filterIds = new Set(vesselFilter);
        const list = vessels.map(item => {
            const visible = filterIds.size === 0 || filterIds.has(item.id);
            if (visible) {
                isFlag = true;
            }
            return {...item, visible};
        });
        if (!isFlag)
            setMarkerVessels(vessels.map(item => ({...item, visible: true})))
        else
            setMarkerVessels(list)
    }, [viewMode, vesselFilter, target, vessels]);

    useEffect(() => {
        const type = MAP_ELEMENT.VESSEL;
        map?.generate({type, markers: markerVessels, event: eventClick(type)});
    }, [map, markerVessels, showInfo]);

    useEffect(() => {
        map?.generate({
            type: MAP_ELEMENT.PRODUCTION_AREA,
            zones: productionAreas,
            visible: isProductionArea
        });
    }, [map, isProductionArea, productionAreas]);

    return <div id='map'/>;
};

export default connect(mapStateToProps, {
    showInfo: dashboardActions.showInfo,
    getWeather: dashboardActions.getWeatherRequest
})(Layout);