import React, {useEffect, useState} from 'react';
import styles from "./style.module.scss";
import {connect, useDispatch} from "react-redux";
import {ILevelByVessel, IOperation, IOperations, IVessel} from "../../../util/varibles/interface";
import {
    ACTION_TYPE,
    AVAILABLE_TIME_TYPE,
    aWeekMillisecond,
    OP_STATUS,
    OPERATION_MODE,
    OPERATION_TYPE
} from "../../../util/varibles/constants";
import {CALENDAR_ID} from "../constants";
import Header from "./Header";
import Weeks from "../../PlanOperationPage/Plan/Calendar/Weeks";
import Vessel from "../../PlanOperationPage/Plan/Calendar/Vessels/Vessel";
import VesselName from "../../PlanOperationPage/Plan/Calendar/Vessels/VesselName";
import Operations from "../../PlanOperationPage/Plan/Calendar/Operations";
import AvailableTimes from "../../PlanOperationPage/Plan/Calendar/AvailableTimes";
import Contracts from "../../PlanOperationPage/Plan/Calendar/Contracts";
import LazyLoading from "../../PlanOperationPage/Plan/Calendar/LazyLoading";
import Menu from "../../../components/Menu";
import EmptyVessels from "../../PlanOperationPage/Plan/Calendar/Vessels/EmptyVessels";
import {datetime} from "../../../util/library/datetime";
import {EL_RULE} from "../../PlanOperationPage/Plan/Calendar/constants";
import {
    changeLoadingOp,
    cloneObj,
    getElById,
    getStartTimeFromDate,
    jumpToToday,
    showErrorResponse
} from "../../../util/varibles/global";
import {MENU_OP, menuOp, menuOptions} from "./constants";
import {renderMenu} from "../../PlanOperationPage/Plan/Calendar/Operations/actions";
import {TargetTypes} from "../../PlanOperationPage/Plan/Calendar/Vessels/constants";
import {openPopup} from "../../../components/Popup/Component/WrapperPopup";
import PopupEdit from "../Popup/PopupEdit";
import Layout from "../../PlanOperationPage/Plan/Calendar/Vessels/Layout";
import {AppState} from "../../../util/store/store";
import {overviewActions} from "../reducer";
import {checkValidOperation, generate} from "../../PlanOperationPage/util";
import {VALIDATION_STATUS} from "../../PlanOperationPage/util/validation";
import {crewSendFetch} from "../../../util/services/vessel-side";
import {notification} from "antd";
import {template, TemplateCode} from "../../../util/varibles/template";
import {loginActions} from "../../LoginPage/reducer";
import {notify, NotifyCode} from "../../../util/varibles/message";

const mapStateToProps = (state: AppState) => ({
    tenant_id: state.login.user.tenant_id,
    weeks: state.overview.weeks,
    vessels: state.overview.vessels,
    loading: state.overview.loadingData,
    opMode: state.login.user.tenant.operation_mode || OPERATION_MODE.PLAN,
    operations: state.overview.operations,
    level: state.overview.level,
    default_vessel: state.login.user.default_vessel,
})

const width = 100;

interface IProps {
    tenant_id: string
    operations: IOperations
    level: ILevelByVessel
    weeks: any
    vessels: IVessel[]
    loading: boolean
    opMode: OPERATION_MODE
    default_vessel?: string

    addOps(operations: IOperation[]): void

    deleteOps(payload: string[]): void

    updateOps(operations: IOperations): void
}

const CalendarVessel: React.FC<IProps> = (props) => {
    const [vessel, setVessel] = useState<IVessel>();
    const {weeks, operations, level, vessels, opMode, default_vessel} = props;
    const [countDay, setCountDay] = useState(0);
    const [menu, setMenu]: any[] = useState([]);
    const dispatch = useDispatch();

    const startTime = weeks[0].time;
    const finishTime = weeks[weeks.length - 1].time + aWeekMillisecond - 1;


    useEffect(() => {
        setCountDay(datetime(finishTime).diff(startTime, 'day'));
    }, [startTime, finishTime])

    useEffect(() => {
        const vessel = vessels.find(item => item.id === default_vessel) || vessels[0];
        setVessel(vessel);
        jumpToToday(CALENDAR_ID);
    }, [vessels, default_vessel]);


    const handleAddEvent = (date: number) => {
        if (!vessel)
            return;
        const start = getStartTimeFromDate({date, operations, level, opMode, vessel});
        openEdit({
            editMode: ACTION_TYPE.CREATE,
            values: {
                activity_log: [{est_start_time: start}, {est_start_time: null}],
                operation: {
                    operation_type: OPERATION_TYPE.EVENT
                },
                vessel
            }
        })
    }

    const openEdit = (params: any) => {
        const {editMode, values} = params;
        const el = openPopup(<PopupEdit
            visible={true}
            editMode={editMode}
            data={values}
            onClose={() => el.remove()}
        />)
    }

    const setTarget = (target: TargetTypes, e: any) => {
        e.stopPropagation();
        if (!vessel || e.button !== 2) {
            setMenu([])
            return;
        }
        let list: any[] = [];
        const {type} = target;
        switch (type) {
            case EL_RULE.VESSEL: {
                const {time} = target;
                const {isOwn, permission, available_time_type} = vessel;
                if (isOwn || permission || available_time_type === AVAILABLE_TIME_TYPE.ALL) {
                    list.push({
                        key: 'event',
                        label: <div onMouseDown={() => handleAddEvent(time)}>Add event</div>
                    })
                }
                break;
            }
            case EL_RULE.OPERATION: {
                const {data, edit} = target;
                const {status, operation_type} = data.operation;

                const start = data.activity_log[0].est_start_time;
                const isActive = status !== OP_STATUS.PROCESSING
                    && status !== OP_STATUS.FINISHED
                    && start > Date.now();

                const common = {
                    dispatch,
                    operations,
                    opMode
                }
                list = renderMenu(menuOptions[operation_type].map((type: MENU_OP) => menuOp[type]({
                    ...data,
                    isActive
                }, {...common, edit})))
                break;
            }

        }
        setMenu(list);
    }

    const handleDragOp = (op: IOperation) => {
        const {id} = op.operation;
        const old = cloneObj(operations[id]);
        if (op.operation.operation_type !== OPERATION_TYPE.EVENT) {
            notify.error(NotifyCode.E34)()
            props.deleteOps([id]);
            setTimeout(() => props.addOps([old]), 4)
            return;
        }
        const {status, message} = checkValidOperation({
            operations,
            level,
            data: op,
            factories: [],
            opMode
        });
        if (status === VALIDATION_STATUS.VALID) {
            const vessels = [
                {
                    vessel_id: op.vessel.id,
                    vessel_owner_id: op.vessel.tenant_id,
                    operations: [generate[op.operation.operation_type](op)]
                }
            ]
            props.deleteOps([id]);
            setTimeout(() => {
                props.addOps([op]);
                setTimeout(() => {
                    const el = getElById(id + '-loading');
                    changeLoadingOp(el, true);
                }, 1)
            }, 4)
            new Promise(resolve => resolve(crewSendFetch({vessels})))
                .then((rs: any) => {
                    const {new_operations, old_operations} = rs;
                    const {vessel} = op;

                    const {newCode, newOps} = new_operations.reduce((rs: any, item: any) => {
                        rs.newCode = [...rs.newCode, `#${item.operation.operation_code}`];
                        rs.newOps = [...rs.newOps, {...item, vessel}];
                        return rs;
                    }, {newCode: [], newOps: []});

                    const {oldCode, oldOps} = old_operations.reduce((rs: any, item: any) => {
                        rs.oldCode = [...rs.oldCode || [], `#${item.operation.operation_code}`];
                        rs.oldOps = [...rs.oldOps || [], {...item, vessel}];
                        return rs;
                    }, {oldCode: [], oldOps: []});

                    notification.success({
                        message: 'Update successfully',
                        description: template[TemplateCode.T2]('', newCode, oldCode)
                    });
                    const el = getElById(id + '-loading');
                    changeLoadingOp(el, false);
                    props.updateOps(oldOps);
                    dispatch(loginActions.updateOpsOfVessel([...newOps, ...oldOps]));
                })
                .catch(error => {
                    const el = getElById(id + '-loading');
                    changeLoadingOp(el, false);
                    showErrorResponse('Update failed', error)
                    props.deleteOps([id]);
                    setTimeout(() => props.addOps([old]), 5)
                    console.log(error);
                    showErrorResponse('Error', error);
                })
        } else {
            notification.error({message: 'Update failed', description: message});
            props.deleteOps([id]);
            setTimeout(() => props.addOps([old]), 4)
        }
    }

    if (!vessel)
        return <>
            <Header/>
            <div id={CALENDAR_ID} className={styles.calendar}>
                <Weeks startTime={startTime} finishTime={finishTime}/>
                <EmptyVessels/>
            </div>
        </>

    const {id: vesselId, contracts, available_times} = vessel;

    return <>
        <Header/>
        <div id={CALENDAR_ID} className={styles.calendar}>
            <Weeks startTime={startTime} finishTime={finishTime}/>
            <Menu
                items={menu}
                visible={menu.length === 0 ? false : undefined}
                trigger={['contextMenu']}
            >
                <div className={styles.vessels}>
                    <Vessel
                        key={vesselId}
                        width={width}
                        vessel={vessel}
                        index={0}
                        startTime={startTime}
                        countDay={countDay}
                        calendarId={CALENDAR_ID}
                        setTarget={setTarget}
                    >
                        <VesselName vessel={vessel}/>
                        <Operations
                            dragOperation={handleDragOp}
                            calendarId={CALENDAR_ID}
                            width={width}
                            index={0}
                            startPoint={startTime}
                            level={level[vesselId]}
                            operations={operations}
                            vessels={[vessel]}
                            setTarget={setTarget}
                            openEdit={openEdit}
                        />
                        <AvailableTimes
                            width={width}
                            available_times={available_times}
                            startPoint={startTime}
                            vesselIndex={0}
                            finishTime={finishTime}
                            vessel={vessel}
                            setTarget={setTarget}
                        />
                        <Contracts
                            vessel={vessel}
                            width={width}
                            contracts={contracts}
                            startPoint={startTime}
                            vesselIndex={0}
                            setTarget={setTarget}
                        />
                    </Vessel>
                    <LazyLoading elementId={CALENDAR_ID} calendarWidth={width}/>
                    <Layout calendarWidth={width}/>
                </div>
            </Menu>
        </div>
    </>;
};
export default connect(mapStateToProps, {
    deleteOps: overviewActions.deleteOps,
    addOps: overviewActions.addOps,
    updateOps: overviewActions.updateOps
})(CalendarVessel);
