import React, {useEffect, useState} from 'react';
import styles from "./style.module.scss";
import {Button} from "antd";
import {updateStateOpOfVesselSideFetch} from "../../../../util/services/vessel-side";
import PopupNextState from "./PopupNextState";
import {OP_STATUS, SHORT_MONTH_DATETIME_FORMAT} from "../../../../util/varibles/constants";
import PopupChecklist from "../../PopupChecklist";
import {IOperation} from "../../../../util/varibles/interface";
import {useDispatch} from "react-redux";
import {datetime} from "../../../../util/library/datetime";
import {openPopup} from "../../../Popup/Component/WrapperPopup";
import {loginActions} from "../../../../pages/LoginPage/reducer";

const openPopupConfirm = (isComplete: boolean, action: any) => {
    const opInfoEl = openPopup(<PopupNextState
        isComplete={isComplete}
        onOk={action}
        onClose={() => opInfoEl.remove()}
    />);
}


interface IProps {
    data: IOperation
    step: number,

    changeStep(step: number): void

    onUpdate?(args: any): void
}

let isClose = false;


const NextStep: React.FC<IProps> = (props) => {
    const {data, step, changeStep, onUpdate} = props;
    const {current_process, status} = data.operation;
    const isCurrent = current_process === step;
    const [loading, setLoading] = useState(false);
    const {activity_log} = data;
    const dispatch = useDispatch();

    isClose = false;

    useEffect(() => {
        return () => {
            isClose = true;
        }
    }, [])

    function onClick(isStart: boolean, isComplete = false) {

        if (isCurrent) {
            const {current_process} = data.operation;
            const {activity_log} = data;
            const {key = ''} = activity_log[current_process + 1] || {};
            const checklist = (data.operation.checklists || {})[key];

            if (checklist) {
                const el = openPopup(<PopupChecklist
                    visible={true}
                    keyStep={key}
                    data={data}
                    checklist={checklist}
                    onClose={() => el.remove()}
                    onSubmit={handleNextStep}
                />)
            } else if (isStart)
                changeState();
            else
                openPopupConfirm(isComplete, () => {
                    setLoading(true);
                    changeState();
                });
        } else
            changeStep(step + 1)
    }

    function changeState() {
        const {id, tenant_id, checklists, vessel_id} = data.operation;
        const body = {
            operation_id: id,
            tenant_id,
            vessel_id,
            time: Date.now(),
            checklists
        };
        setLoading(true);
        new Promise(resolve => resolve(updateStateOpOfVesselSideFetch(body)))
            .then((rs: any) => {
                setLoading(false);
                if (!isClose) {
                    handleNextStep(rs);
                }
            })
            .catch(() => {
                if (!isClose) {
                    setLoading(false);
                }
            })
    }

    function handleNextStep(rs: any) {
        const {operation, activity_log} = rs;
        const operationNew = {...data, activity_log, operation: {...data.operation, ...operation}};
        dispatch(loginActions.updateOpOfVessel(operationNew));
        if (onUpdate)
            onUpdate(operationNew)
        setLoading(false);
    }

    if (status === OP_STATUS.APPROVED)
        return <Button className='bt-primary h-40' loading={loading} onClick={() => onClick(true)}>
            Start operation
        </Button>

    if (status === OP_STATUS.PROCESSING) {
        const isComplete = activity_log.length - 2 === step;

        return <Button
            className={['bt-primary', styles['next-step-button']].join(' ')}
            loading={loading}
            onClick={() => onClick(false, isComplete)}
        >
            <div>{isComplete ? 'Complete' : 'Next step'}</div>
            {(isCurrent && step < (activity_log.length - 1)) &&
                <div>{datetime(activity_log[step + 1].est_start_time).format(SHORT_MONTH_DATETIME_FORMAT)}</div>}
        </Button>;
    }
    return null;
};

export default NextStep;
