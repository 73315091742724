import React from 'react';
import styles from '../style.module.scss';
import {Switch} from "antd";
import {connect, useDispatch} from "react-redux";
import {DashboardSetting} from "../../../../util/varibles/userSetting";
import {loginActions} from "../../../LoginPage/reducer";
import {dashboardActions} from "../../reducer";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState) => {
    const {factories} = state.dashboard.filter || {}
    return {
        checked: factories,
    }
};

interface IProps {
    checked: boolean
}

const Factory: React.FC<IProps> = ({checked}) => {
    const dispatch = useDispatch();

    const onChange = (visible: any) => {
        dispatch(dashboardActions.updateFilter({factories: visible}));
        dispatch(loginActions.updateUserSetting([{[DashboardSetting.FACTORIES]: visible}]))
    };

    return <div className={styles.line}>
        <div className='font-s15'>
            Factories
        </div>
        <Switch checked={checked} onChange={onChange}/>
    </div>;
};

export default connect(mapStateToProps, {})(Factory);
