import React, {Component} from 'react';
import {KEY_TAB, OPERATION_TYPE, SITE_TYPE} from "../../../../util/varibles/constants";
import HeaderUnits from "./HeaderUnits";
import {UnitModel} from "../constants";
import stylesContainer from '../style.module.scss';
import styles from './style.module.scss';
import {ISite, IStateGlobal, IUnit} from "../../../../util/varibles/interface";
import {connect} from "react-redux";
import {PlanSetting} from "../../../../util/varibles/userSetting";
import Cards from "../_Component/Cards";
import Card from "../_Component/Cards/Card";
import Location from "../_Component/Location";
import FishDisease from '../../../../components/Location/FishDisease';
import UnitDiseases from '../_Component/UnitDiseases';
import FishAndWeight from "../_Component/FishAndWeight";
import {openPopup} from "../../../../components/Popup/Component/WrapperPopup";
import Site from "../../Popup/Site";
import UnitLice from "../../../../components/Operation/UnitLice";

interface IState {
    units: any
    isGroup: boolean
    unitsSelected: any
    siteType: string
    searchValue: string
    weightFilter: [number | null, number | null],
    liceSort: null | string
    isShowAll: boolean
}

const initState: IState = {
    units: [],
    isGroup: false,
    unitsSelected: {},
    siteType: '',
    searchValue: '',
    liceSort: null,
    weightFilter: [null, null],
    isShowAll: false,
}

const mapStateToProps = (state: IStateGlobal) => {
    const {
        [PlanSetting.BIOLOGY_SITE]: biologySiteFilter = [],
        [PlanSetting.BIOLOGY_PROD_AREA]: biologyProdAreaFilter = [],
        [PlanSetting.BIOLOGY_LICE_SORT]: liceSort = null,
    } = state.login.user.setting || {};

    return {
        setting: {
            liceSort,
            biologySiteFilter,
            biologyProdAreaFilter,
        },
    }
};

interface IProps {
    setting: {
        liceSort: null | string
        biologySiteFilter: any
        biologyProdAreaFilter: any
    }
    loading: boolean
    sites: ISite[]
    clear: any

    onDragStart(e: any, units: any, requestType: OPERATION_TYPE[]): void

    onDragEnd(e: any): void
}

class Units extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const {sites} = this.props;
        const units = this.onFilter({...initState, ...this.props.setting}, sites);
        this.state = {...initState, ...this.props.setting, units};
    }

    componentDidMount() {
        this.props.clear(this.clear);
    }


    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        const {sites, setting} = nextProps

        if (JSON.stringify(this.props.sites) !== JSON.stringify(sites) || JSON.stringify(this.props.setting) !== JSON.stringify(setting)) {
            this.setState({units: this.onFilter({...nextState, ...nextProps.setting}, sites)});
        }

        return true;
    }

    handleCheck = (isCheck: boolean, data: any) => {
        let {unitsSelected}: any = this.state;
        if (isCheck) {
            unitsSelected = {...unitsSelected, ...data};
        } else {
            Object.keys(data).forEach(key => {
                const {[key]: old, ...value} = unitsSelected;
                unitsSelected = value;
            })
        }
        this.setState({unitsSelected})
    }

    handleSearch = (value: any) => {
        const {sites} = this.props;
        const units = this.onFilter({...this.state, ...value}, sites);
        this.setState({...value, units})
    };

    onFilter = (state: any, sites: ISite[]) => {
        const {
            searchValue,
            biologySiteFilter = [],
            biologyProdAreaFilter = [],
            siteType,
            liceSort,
            weightFilter
        } = state;
        const [minWeight, maxWeight] = weightFilter;
        const listOfSiteFilter = new Set(biologySiteFilter);
        const listOfProdAreaFilter = new Set(biologyProdAreaFilter);
        const countSite = biologySiteFilter.length;
        const countProdArea = biologyProdAreaFilter.length;
        const stand = sites
            .reduce((list: IUnit[], site) => {
                const isSite = (countSite === 0 || listOfSiteFilter.has(site.id))
                    && (countProdArea === 0 || listOfProdAreaFilter.has(site.prod_area));
                const isSearch = site.name.toLowerCase().indexOf((searchValue || '').toLowerCase()) !== -1;
                const isType = site.type?.indexOf(siteType) !== -1;
                if (!isSite || !isSearch || !isType)
                    return list;

                return [
                    ...list,
                    ...site.units.reduce((items: any, unit: IUnit) => {
                        const {avg_weight, fish_amount} = unit;
                        const isMin = minWeight ? (minWeight <= avg_weight && fish_amount) : true;
                        const isMax = maxWeight ? (maxWeight >= avg_weight && fish_amount) : true;

                        if (!isMin || !isMax)
                            return items;
                        return [...items, UnitModel(unit, site)]
                    }, [])]
            }, [])
        if (liceSort) {
            return stand.sort((a, b) => {
                const {lice: liceA = {}}: any = a;
                const {lice: liceB = {}}: any = b;
                const {value: valueA = 0} = liceA[liceSort] || {}
                const {value: valueB = 0} = liceB[liceSort] || {}
                return valueB - valueA
            })
        }
        return stand
    }

    handleOpenSite = (unit: IUnit) => {
        const el = openPopup(<Site
            site={{id: unit.site_id || '', name: unit.site_name || ''}}
            unitId={unit.id}
            onClose={() => el.remove()}
        />)
    }

    handleDragStart = (e: any) => {
        const {unitsSelected} = this.state;
        this.props.onDragStart(e, unitsSelected, [OPERATION_TYPE.HARVEST, OPERATION_TYPE.TREATMENT, OPERATION_TYPE.SERVICE])
    }

    clear = () => {
        this.setState({unitsSelected: {}})
    }

    render() {
        const {unitsSelected, units, siteType, searchValue, weightFilter, liceSort} = this.state;
        const {loading, sites, onDragEnd} = this.props;
        return <div id={KEY_TAB.PRODUCTION} className={stylesContainer['wrapper-tab']}>
            <HeaderUnits {...{
                sites,
                siteType,
                searchValue,
                weightFilter,
                liceSort,
                unitsSelected,
                onSearch: this.handleSearch,
                onClear: this.clear
            }}/>
            <div className={stylesContainer['tab-body']}>
                <Cards className={styles.units} source={units} loading={loading}>
                    {units.map((unit: any) => {
                        const {unit_id, site_name, fish_amount, type, diseases = [], lice} = unit;
                        const isCheck = !!unitsSelected[unit.id + '|-'];
                        const isInternal = type === SITE_TYPE.INTERNAL;
                        const isActive = !(isInternal && fish_amount <= 0);

                        return <Card
                            data={unit}
                            isActive={isActive}
                            key={unit.id}
                            checked={isCheck}
                            style={{maxHeight: '120px'}}
                            onChange={() => this.handleCheck(!isCheck, {[unit.id + '|-']: unit})}
                            onDragStart={this.handleDragStart}
                            onDragEnd={onDragEnd}
                            onContext={() => this.handleOpenSite(unit)}
                        >
                            <div className={stylesContainer.line}>
                                <div className={stylesContainer.infoLine}>
                                    <Location source={[site_name, `, ${unit_id}`]}/>
                                    {isInternal && <FishAndWeight data={unit}/>}
                                </div>
                                <div className={stylesContainer.infoLine} data-right={true}>
                                    <UnitLice lice={lice}/>
                                    <FishDisease diseases={diseases} units={[unit]} short={true}/>
                                    <UnitDiseases data={unit.unit_diseases}/>
                                </div>
                            </div>
                        </Card>
                    })}
                </Cards>
            </div>
        </div>
    }
}

export default connect(mapStateToProps, {})(Units);
