import React, {Component} from 'react';
import styles from "./style.module.scss";
import {Button} from "antd";
import {connect} from "react-redux";
import {IStateGlobal} from "../../../../../util/varibles/interface";
import {ACTION_TYPE} from "../../../../../util/varibles/constants";

const mapStateToProps = (state: IStateGlobal) => ({
    editMode: state.planOperation.popupOperation.editMode,
});

interface IProps {
    editMode: ACTION_TYPE
    saveTxt?: string
    disableSave?: boolean
    loading?: boolean

    handleSubmit(): void

    onClose(): void
}

class Footer extends Component<IProps> {
    static defaultProps = {
        disableSave: false,
    }

    render() {
        const {
            disableSave,
            editMode,
            saveTxt = editMode === ACTION_TYPE.CREATE ? 'Plan' : 'Update',
            loading,
            handleSubmit
        } = this.props;

        return <div className={styles.footer}>
            <Button {...{
                className: 'bt-primary',
                type: 'primary',
                loading,
                onClick: handleSubmit,
                disabled: disableSave
            }}>
                <span>{saveTxt}</span>
            </Button>
            <Button className='bt-default' onClick={this.props.onClose}>Cancel</Button>
        </div>;
    }
}

export default connect(mapStateToProps, {})(Footer);

