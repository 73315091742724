import {checkRoute, getFactory} from "../../../../util";
import {CERTAIN_TYPE, OPERATION_TYPE, SITE_TYPE, VESSEL_TYPE} from "../../../../../../util/varibles/constants";
import {IVessel} from "../../../../../../util/varibles/interface";
import {getStorages} from "../../../../util/function_operation/constants";

export enum DELIVERY_UNIT {
    TONS = 't',
    FISH = 'fish'
}

export const deliveryUnits = [
    {id: DELIVERY_UNIT.TONS, name: 'tons'},
    {id: DELIVERY_UNIT.FISH, name: 'fish'},
]

export const initDeliveryType = {
    id: null,
    fish_amount: '',
    total_weight: '',
    unit: DELIVERY_UNIT.TONS,
    default: false
}

export const createHarvests = ({...args}) => {
    let {data, store, factories, routes, vessel, isAutoMerge = false, isFull = false, properties} = args;
    const {type, capacity} = vessel;

    const waitingUnit = type === VESSEL_TYPE.WELL_BOAT;

    const newOperations = data.reduce((operations: any, sub: any) => {
        const {
            id,
            fish_amount,
            avg_weight,
            factory_id = '',
            harvest_id = '-',
            primary_factory,
            site_id,
            site_name,
            site_type
        } = sub;
        const site: any = {id: site_id, name: site_name, type: site_type};
        const factory = getFactory(factory_id, primary_factory, factories);
        const {error, message} = checkRoute(site, factory, routes);
        let error_detail = {}
        if (error === 2) {
            error_detail = {route: [{source_id: site.id, destination_id: factory.id, message}]};
            site.isRoute = false;
        } else
            site.isRoute = true;

        const {deliver_type = {}} = factory;
        const keyDeliveryType: any = Object.keys(deliver_type).find((key: any) => deliver_type[key].default);
        const deliveryType = deliver_type[keyDeliveryType];
        const keyStore = id + '|' + harvest_id;

        if (avg_weight < 0) {
            const {operation} = createHarvestExternal({
                store,
                keyStore,
                count: operations.length,
                site,
                factory,
                waitingUnit,
                deliver_type,
                deliveryType,
                error_detail,
                properties
            })
            operations.push(operation);
        } else {
            let amountStore = fish_amount;
            const newOperations = [];
            while (amountStore > 0) {
                const {operation, store: newStore, amountStore: newAmountStore} = createHarvest({
                    vessel,
                    store,
                    keyStore,
                    fish_amount: amountStore,
                    avg_weight,
                    count: operations.length + newOperations.length,
                    site,
                    factory,
                    waitingUnit,
                    deliver_type,
                    deliveryType,
                    error_detail,
                    properties
                })

                newOperations.push(operation);
                store = {...newStore};
                amountStore = newAmountStore;

                if (!isFull)
                    break;
            }

            if (operations.length === 0) {
                operations = [...newOperations];
            } else {
                let indexOperation = -1;
                for (let i = operations.length - 1; i >= 0; i--) {
                    if (operations[i].factory_id === factory_id) {
                        indexOperation = i;
                        break;
                    }
                }
                if (indexOperation !== -1) {
                    const {total_weight: totalWeightLeft, sub_operations: unitsLeft} = operations[indexOperation];
                    const {
                        total_weight: totalWeightRight,
                        total_amount: fishAmountRight,
                        sub_operations: unitsRight
                    } = newOperations[newOperations.length - 1];
                    if ((totalWeightLeft + totalWeightRight) < capacity) {
                        if (isAutoMerge) {
                            const indexUnitAdd = unitsLeft.findIndex((item: any) =>
                                item.unit_id === unitsRight[0].unit_id
                                && item.harvest_id === unitsRight[0].harvest_id)
                            if (indexUnitAdd !== -1) {
                                unitsLeft[indexUnitAdd] = {
                                    ...unitsLeft[indexUnitAdd],
                                    fish_amount: unitsLeft[indexUnitAdd].fish_amount + fishAmountRight,
                                    total_weight: unitsLeft[indexUnitAdd].total_weight + totalWeightRight
                                }
                            } else
                                unitsLeft.push(unitsRight[0]);
                        } else
                            unitsLeft.push(unitsRight[0]);
                        let source_site_type = SITE_TYPE.INTERNAL;
                        const sites = unitsLeft.reduce((list: any, item: any) => {
                            const {site_id, site_name, site_type, fish_amount, total_weight} = item;
                            const indexSite = list.findIndex((sub: any) => sub.id === site_id);
                            if (site_type === SITE_TYPE.EXTERNAL)
                                source_site_type = SITE_TYPE.EXTERNAL
                            if (indexSite !== -1) {
                                list[indexSite].total_amount += fish_amount;
                                list[indexSite].total_weight += total_weight;
                            } else {
                                const {error} = checkRoute({id: site_id, name: site_name}, factory, routes);
                                list.push({
                                    id: site_id,
                                    name: site_name,
                                    type: site_type,
                                    isRoute: error !== 2,
                                    total_amount: fish_amount,
                                    total_weight: total_weight
                                })
                            }
                            return list;
                        }, []);

                        operations[indexOperation] = {
                            ...operations[indexOperation],
                            sites,
                            source_site_type,
                            total_amount: operations[indexOperation].total_amount + fishAmountRight,
                            total_weight: operations[indexOperation].total_weight + totalWeightRight,
                            sub_operations: [...unitsLeft]
                        }
                        newOperations.pop();
                    }
                }
                operations = [...operations, ...newOperations]
            }
        }
        return operations;
    }, [])

    return {operations: [...newOperations], store}
}


interface ICreatHarvest {
    vessel: any
    store: any
    keyStore: any
    fish_amount: number
    avg_weight: number
    count: number
    site: any
    factory: any
    waitingUnit: boolean
    deliver_type: any
    deliveryType: any
    error_detail: any
    properties: {
        tenant_id: string,
        available_time_id: string,
        contract_id: string
    }
}

export const createHarvest = (args: ICreatHarvest) => {
    const {
        vessel,
        store,
        keyStore,
        fish_amount,
        avg_weight,
        count,
        site,
        factory,
        waitingUnit,
        deliver_type,
        deliveryType,
        properties,
        error_detail
    } = args;
    const {capacity} = vessel;
    let amountOrder = fish_amount, amountStore;
    const amountDefault = Math.floor(capacity / avg_weight);
    if (amountOrder > amountDefault) {
        amountOrder = amountDefault;
        amountStore = fish_amount - amountDefault;
    } else
        amountStore = 0;

    const totalWeightOrder = amountOrder * avg_weight;

    return {
        operation: {
            ...properties,
            operation_code: count + 1,
            site_id: site.id,
            site_name: site.name,
            source_site_type: site.type,
            factory_id: factory.id,
            factory_name: factory.name,
            is_waiting_unit: waitingUnit,
            isInternal: true,
            note: '',
            delivery_types: deliver_type,
            certain_mode: CERTAIN_TYPE.NONE,
            sub_operations: [{
                ...store[keyStore],
                avg_weight,
                fish_amount: amountOrder,
                total_weight: totalWeightOrder,
                delivery_types: [{
                    ...deliveryType,
                    fish_amount: amountOrder,
                    total_weight: totalWeightOrder,
                    unit: DELIVERY_UNIT.TONS
                }],
                storages: getStorages({
                    fish_amount: amountOrder,
                    total_weight: totalWeightOrder,
                    storages: vessel.storages
                }, vessel)
            }],
            sites: [site],
            total_amount: amountOrder,
            total_weight: totalWeightOrder,
            operation_type: OPERATION_TYPE.HARVEST,
            error_detail,
        },
        store: {
            ...store,
            [keyStore]: {
                ...store[keyStore],
                fish_amount: amountStore,
                total_weight: amountStore * avg_weight
            }
        },
        amountStore
    }
}

interface ICreateHarvestExternal {
    store: any
    keyStore: string
    count: number
    site: any
    factory: any
    waitingUnit: boolean
    deliver_type: any
    deliveryType: any
    properties: {
        tenant_id: string,
        available_time_id: string,
        contract_id: string
    }
    error_detail: any
}

export const createHarvestExternal = (args: ICreateHarvestExternal) => {
    const {
        store,
        keyStore,
        count,
        site,
        factory,
        waitingUnit,
        deliver_type,
        deliveryType,
        properties,
        error_detail
    } = args;
    return {
        operation: {
            ...properties,
            operation_code: count + 1,
            site_id: site.id,
            site_name: site.name,
            source_site_type: site.type,
            factory_id: factory.id,
            factory_name: factory.name,
            is_waiting_unit: waitingUnit,
            isInternal: false,
            delivery_types: deliver_type,
            certain_mode: CERTAIN_TYPE.NONE,
            operation_type: OPERATION_TYPE.HARVEST,
            sub_operations: [{
                ...store[keyStore],
                fish_amount: 0,
                total_weight: 0,
                delivery_types: [{...deliveryType, fish_amount: 0, total_weight: 0, unit: DELIVERY_UNIT.TONS}]
            }],
            sites: [site],
            total_amount: 0,
            total_weight: 0,
            error_detail
        },
        store
    }
}

export const opsAfterSubmit = (dataNew: any, vessel: IVessel, properties: any) => {
    const operations = dataNew.map((item: any, index: number) => {
        const {sub_operations, sites} = item;
        let totalAmount = 0, totalWeight = 0;
        const groupBySite = sub_operations.reduce((list: any, sub: any) => {
            const {site_id, fish_amount, total_weight} = sub;
            totalAmount += fish_amount;
            totalWeight += total_weight;
            if (list[site_id]) {
                list[site_id].total_amount += fish_amount;
                list[site_id].total_weight += total_weight;
            } else
                list[site_id] = {total_amount: fish_amount, total_weight}
            return list;
        }, {});
        let last: any = {};
        const sitesNew = sites.reduce((list: any, sub: any) => {
            const {id} = sub;
            if (groupBySite[id]) {
                const {isRoute} = sub;
                const site = {...sub, ...groupBySite[id]};
                if (isRoute && !last.id) {
                    last = site
                    return list;
                }
                return [...list, site]
            }
            return list
        }, []);

        properties[index] = {
            ...properties[index] || {},
            start_place: dataNew[index - 1]
                ? {start_place_id: dataNew[index - 1].factory_id, start_place_field: 'factory'}
                : undefined
        }

        return {
            operation: {
                ...item,
                total_amount: totalAmount,
                total_weight: totalWeight,
                site_id: last.id,
                site_name: last.name,
                sites: [...sitesNew, last],
            },
            vessel
        }
    });
    return {operations, properties};
}
