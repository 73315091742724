import React, {Component} from 'react';
import {TASK_TYPE, taskTypes} from "../../../../../../util/varibles/constants";

interface IProps {
    data: any
}

class Harvest extends Component<IProps> {
    render() {
        const {destination_name} = this.props.data;
        const {name = ''} = taskTypes[TASK_TYPE.HARVEST];
        return <>
            <div data-lev='name'>{name}</div>
            <div className='ml-1'>({destination_name})</div>
        </>;
    }
}

export default Harvest;