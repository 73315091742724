import React, {Component} from 'react';
import Group from "./Group";
import ActivityLog from "./ActivityLog";
import styles from './style.module.scss';
import {IActivityLog} from '../../../../util/varibles/interface';

export interface IActivityLog2 {
    id?: string
    list: ({
        index: number
        steps: string[]
        time_late?: number
    } & IActivityLog)[]
}

interface IProps {
    current_process: number
    activity_log: IActivityLog2[]
    activity_log_root: any
}

// Activity Log for treatment
class ActivityLogs2 extends Component<IProps> {
    render() {

        const {current_process, activity_log, activity_log_root} = this.props;

        const lastPoint = activity_log_root.length - 1;
        const common = {
            lastPoint,
            current_process,
        }

        const activityLog = activity_log.map((item: any) => {
            const {id, name, list} = item
            if (name) {
                return <Group key={id} {...{...common, name, list, activity_log_root}} />
            } else
                return list.map((item: any) =>
                    <ActivityLog key={item.index} {...{...common, data: item, activity_log: activity_log_root}} />)
        })

        return <div className={styles.wrapper}>
            {activityLog}
        </div>;
    }
}

export default ActivityLogs2;
