import React from 'react';
import styles from './style.module.scss';
import Icon, {ICON_NAME} from "../../Icon";
import {Symbol} from "../../../util/varibles/template";
import {Tooltip} from "antd";
import {IUnitStorage} from "../../../util/varibles/interface";
import {getDensity} from "../../../pages/PlanOperationPage/util/function_operation/constants";

interface IProps {
    tenantDensity: number[]
    isEmpty: boolean
    storage: IUnitStorage
}

const Storage: React.FC<IProps> = ({tenantDensity, isEmpty, storage}) => {
    const {value = 0} = storage;
    let name, background, content, tooltip;
    if (!value) {
        if (isEmpty) {
            tooltip = `Input vessel's volume to have operation's density.`
            name = 'N/A';
        } else
            return null;
    } else {
        content = <> - {storage.density} (t/{Symbol.m3})</>
        const {short = '', color = ''} = getDensity(tenantDensity, storage.density);
        name = short;
        background = color;
    }

    return <Tooltip title={tooltip} color='#002C4D'>
        <div className={styles.storage}>
            <Icon icon={ICON_NAME.TANK} style={{background}}/>
            <div className={styles.name}>
                {name} density {content}
            </div>
        </div>
    </Tooltip>;
};

export default Storage;
