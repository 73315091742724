import React from 'react';
import styles from './style.module.scss';
import {HEIGHT_PER_DAY} from "../../../constants";
import {durationTxtByStartAndFinish} from "../../../../../../../../util/varibles/global";

interface IProps {
    startPoint: number
    start: number
    finish: number
    style: any
}

const Duration: React.FC<IProps> = ({startPoint, start, finish, style}) => {
    const top = `${(finish - startPoint) / 86400000 * HEIGHT_PER_DAY}px`;


    return <div className={styles.duration} style={{...style, top}}>
        <span>{durationTxtByStartAndFinish(start, finish)}</span>
    </div>;
};

export default Duration;
