import React, {useState} from 'react';
import {Collapse, Drawer} from "antd";
import styles from './style.module.scss';
import {IChecklistsOfOp, IOperation} from "../../../../util/varibles/interface";
import Header from "../../../../components/Popup/Component/Header";
import {getStepNameOfChecklist} from "../../../../util/varibles/global";
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import Checklist from "../../../../components/Checklist";

const {Panel} = Collapse;

interface IProps {
    visible: boolean
    data: IOperation
    step: any

    onSave(checklists: IChecklistsOfOp): void

    onClose?(): void
}

const PopupChecklist: React.FC<IProps> = (props) => {
    const {data, step, onClose} = props;
    const {checklists = {}} = data;
    const {operation_code, operation_type} = data.operation;
    const [visible, setVisible] = useState(props.visible);
    const [loading, setLoading] = useState(false);
    const handleSubmit = (checklist: any, step: any) => {
        // const {id} = this.props.data.operation;
        // this.props.updateChecklistAction({
        //     ...checklist,
        //     operation_id: id,
        //     step
        // })
    }

    const handleClose = () => {
        setVisible(false)
        if (onClose)
            onClose();
    }

    if (!props.data.vessel) return null;

    return <Drawer {...{
        className: styles['popup-checklist'],
        width: '100%',
        title: <Header
            title={<h4 className='m-0 font-w5'>Checklists #{operation_code}</h4>}
            onClose={handleClose}
        />,
        closable: false,
        open: visible,
        onClose: handleClose
    }}>
        {
            step !== undefined &&
            <Collapse
                className={styles['checklists']}
                bordered={false}
                expandIconPosition='end'
                defaultActiveKey={`${step}`}
                expandIcon={props => props.isActive ?
                    <MinusCircleOutlined style={{fontSize: '18px'}}/> :
                    <PlusCircleOutlined style={{fontSize: '18px'}}/>
                }
            >
                {Object.keys(checklists).map((key: string) => {
                    const {step_name = ''} = checklists[key];
                    const header = getStepNameOfChecklist({step: key, step_name, operation_type})

                    return <Panel
                        key={key}
                        header={header}
                        className={styles.checklists}
                    >
                        <Checklist {...{
                            checklist: checklists[key],
                            title: header,
                            loading,
                            onSubmit: (value) => handleSubmit(value, key)
                        }} />
                    </Panel>
                })}
            </Collapse>
        }
    </Drawer>;
};


export default PopupChecklist;
