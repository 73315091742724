import React from 'react';
import {getOperationStatus, OP_STATUS, OPERATION_MODE, OPERATION_TYPE} from "../../../../../util/varibles/constants";
import stylesContainer from "../../style.module.scss";
import ActivityLog from "../../Component/AcitivtyLog";
import Header from "../../Header";
import Status from "../../Component/Status";
import ApproveControl from "../../Component/ApproveControl";
import HarvestTable from "./HarvestTable";
import FishDisease from "../../../../Location/FishDisease";
import Duration from "../../Component/Duration";
import {getStepNameOfOp} from "../../../../../util/varibles/global";
import stylesOp from "../../../../../pages/OperationDetailPage/_Component/Operation/style.module.scss";
import Density from "../../../../Operation/Density";
import {getTimeByOpMode} from "../../../../../pages/PlanOperationPage/util/function_operation/constants";
import {IOperation} from "../../../../../util/varibles/interface";
import Approver from "../../Component/Approver";
import BoxOpInfo from "../../Component/BoxOpInfo";

interface IProps {
    isEdit: boolean
    isOwn: boolean
    isApprove: boolean
    data: IOperation
    loading: any
    weather: any
    opMode: OPERATION_MODE

    handleUpdateActivityLog(activityLog: any): void

    handleUpdateNote(note: string): void

    onClose(): void

    handleChangeState(params: any): void
}

const Harvest: React.FC<IProps> = (props) => {
    const {
        data,
        loading,
        weather,
        isEdit,
        opMode,
        isOwn,
        isApprove,
        handleChangeState,
        onClose,
    } = props;
    const {operation, activity_log, vessel} = data;

    const startTime = getTimeByOpMode[opMode](activity_log[0]);
    const finishTime = getTimeByOpMode[opMode](activity_log[activity_log.length - 1]);

    const {current_process, total_weight, status} = operation;
    const statusText = status === OP_STATUS.PROCESSING ? getStepNameOfOp[OPERATION_TYPE.HARVEST]({
        current_process,
        operation,
        activity_log
    }) : getOperationStatus(status);

    return <>
        <Header {...{
            onClose,
            isOwn,
            operation,
            loading,
            weather,
            keyWeather: `${startTime}|${finishTime}`
        }}>
            <div className={stylesContainer.title}>
                <div className={stylesContainer['header-id']}>
                    <Status {...{status, statusText}}/>
                    <div>
                        Total weight:
                        <b className='ml-5'>{Math.round(total_weight / 1000) / 1000} t</b>
                    </div>
                </div>
            </div>
        </Header>

        <div className={stylesContainer.body}>
            <ActivityLog {...{
                isEdit,
                activity_log,
                vessel,
                operation,
                opMode,
                handleUpdate: props.handleUpdateActivityLog
            }} />
            <BoxOpInfo data={data} isEdit={isEdit} handleUpdateNote={props.handleUpdateNote}>
                <Duration activity_log={activity_log}/>
                <ApproveControl {...{isApprove, operation, handleChangeState}}/>
                <Approver isOwn={isOwn} operation={operation}/>
                <FishDisease diseases={operation.diseases || []} units={operation.sub_operations} wrapperClass='mb-5'/>
                <div className={stylesOp['info-line']}>
                    <Density type={OPERATION_TYPE.HARVEST} storages={operation.sub_operations[0]?.storages}/>
                </div>
                <HarvestTable {...{operation}}/>
            </BoxOpInfo>
        </div>
    </>
};

export default Harvest;
