import React, {createRef, useEffect, useState} from 'react';
import Treatment from "./Treatment";
import MoveToUnit from "./MoveToUnit";
import Harvest from "./Harvest";
import styles from './style.module.scss'
import Combine from "./Combine";
import {TASK_TYPE} from "../../../../../../util/varibles/constants";
import {cloneObj} from "../../../../../../util/varibles/global";

interface IProps {
    data: any
    isSorting: boolean
    isChild: boolean
}

const Task: React.FC<IProps> = (props) => {
    const {type, treatment_type, support_vessels = []} = props.data;
    const {isSorting = false, isChild} = props;
    const [isFocus, setIsFocus] = useState(false);
    const rootRef = createRef<HTMLDivElement>();

    useEffect(() => {
        return () => {
            document.removeEventListener("mousedown", onClickOutside)
        }
    }, []);

    const renderContent = () => {
        const attr = cloneObj(props)
        switch (type) {
            case TASK_TYPE.TREATMENT: {
                return <Treatment {...{...attr}}/>
            }
            case TASK_TYPE.MOVE: {
                return <MoveToUnit {...{...attr}}/>
            }
            case TASK_TYPE.HARVEST: {
                return <Harvest {...{...attr}}/>
            }
            case TASK_TYPE.COMBINE:
                return <Combine {...{...attr}}/>
            default:
                return null
        }
    }

    const onMouseDown = () => {
        setIsFocus(true)
        document.addEventListener("mousedown", onClickOutside)
    }

    const onClickOutside = (e: any) => {
        if (!rootRef.current?.contains(e.target)) {
            setIsFocus(false)
            document.removeEventListener("mousedown", onClickOutside)
        }
    }

    const attr: any = {
        className: styles['container-task'],
        "data-is-sorting": isSorting,
        "data-is-child": isChild,
        "data-task-type": type,
        "data-treatment-type": treatment_type,
        "data-is-focus": isFocus,
    }

    if (!isChild) {
        attr.tabIndex = 1;
        attr.onMouseDown = onMouseDown;
    }

    return <div ref={rootRef} {...attr}>
        {renderContent()}
        {support_vessels.length > 0 && <div className={styles['vessel-support']}>
            <div data-lev='label'>Vessel support:</div>
            <div data-lev='vessel-name'> {support_vessels.map((item: any) => item.name).join(', ')}</div>
        </div>}
    </div>
};

export default Task;