import React, {Component} from 'react';
import {connect} from 'react-redux';
import {IFactory, IRoute} from "../../../../../../../util/varibles/interface";
import {Select} from "antd";
import styles from "./Treatment/style.module.scss";
import {IKeyUpdate} from "../constants";
import {checkRoute} from "../../../../../util";
import {KeyOfTask} from '../../../../../../../util/varibles/constants';
import {AppState} from "../../../../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    routes: state.planOperation.routes,
    factories: state.planOperation.factories,
})

interface IProps {
    data: any
    site: any
    routes: IRoute[]
    factories: IFactory[]

    handleChange(list: IKeyUpdate[]): void
}

class Harvest extends Component<IProps> {
    handleChange = (value: any) => {
        const {routes} = this.props;
        const {data, site} = this.props;
        const {sub_operations} = data;
        const {deliver_type = {}} = value;
        const deliveryType = Object.keys(deliver_type).find(key => deliver_type[key].default) || '';
        const {fish_amount, total_weight} = sub_operations[0]
        const list = [
            {key: KeyOfTask.DESTINATION_ID, value: value.id},
            {key: KeyOfTask.DESTINATION_FIELD, value: 'factory'},
            {key: KeyOfTask.DESTINATION_NAME, value: value.name},
            {key: KeyOfTask.DELIVERY_TYPES, value: deliver_type || {}},
            {
                key: KeyOfTask.SUB_OPERATIONS, value: [{
                    ...sub_operations[0],
                    delivery_types: [{...deliver_type[deliveryType], fish_amount, total_weight}]
                }]
            },
            {
                key: KeyOfTask.FACTORY, value: {
                    destination_id: value.id,
                    destination_name: value.name,
                    delivery_types: value.deliver_type || {},
                    delivery_type: deliver_type[deliveryType]
                }
            }
        ];
        const {error, message} = checkRoute(site, value, routes);
        const {error: errorOfTask} = this.props.data;
        delete errorOfTask.factory;
        list.push({key: KeyOfTask.ERROR, value: error})
        if (error === 2) {
            list.push({key: KeyOfTask.ERROR, value: {...errorOfTask || {}, 'route': message}})
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const {route = null, ...args} = data.error || {};
            list.push({key: KeyOfTask.ERROR, value: args})
        }
        this.props.handleChange(list)
    }

    render() {
        const {factories} = this.props;
        const {destination_id} = this.props.data;
        const {Option} = Select;

        return <>
            <Select
                className={[styles['select-custom'], 'w-full max-w-160'].join(' ')}
                placeholder="Select a factory"
                variant={"borderless"}
                value={destination_id}
                popupClassName={styles['dropdown-custom']}
                onSelect={(value: any, option: any) => this.handleChange(option.factory)}
                popupMatchSelectWidth={false}
            >
                {factories.map((item: any) => <Option key={item.id} value={item.id} factory={item}>
                    {item.name}
                </Option>)}
            </Select>
        </>;
    }
}

export default connect(mapStateToProps, {})(Harvest);