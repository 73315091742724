import React, {Component} from 'react';
import styles from './style.module.scss';
import Task from "../Task";
import {OPERATION_TYPE} from "../../../../../../../util/varibles/constants";
import stylesContainer from "../style.module.scss";
import Title from "./Title";
import {Tooltip} from "antd";
import AddNote from "../../../_Component/AddNote";
import LineMiddle from "../../../_Component/LineMiddle";
import {UpdateName} from "../../constants";
import Density from "../../../../../../../components/Operation/Density";
import {IUnit} from "../../../../../../../util/varibles/interface";

interface IProps {
    list: any
    site: any
    unit: IUnit
    factory: any
    vessel: any
    listOfTask: any
    groupIndex: number
    isHaveSort: boolean
    isLock: boolean
    currentUnitId?: string

    onUpdate(key: UpdateName, args: any): void
}

class Round extends Component<IProps> {

    handleSaveNote = (note: any) => {
        const {unit} = this.props;
        this.props.onUpdate(UpdateName.UPDATE_UNIT_NOTE, {unit: {...unit, note}});
    }

    render() {
        const {
            list,
            site,
            unit,
            vessel,
            factory,
            listOfTask,
            groupIndex,
            isHaveSort,
            isLock,
            currentUnitId,
            onUpdate
        } = this.props;

        if (!unit)
            return <div/>;

        const {id = '', round_number = 1, note = ''} = unit || {};
        const round = round_number + (round_number > 1 ? ' round' : ' rounds')

        return <div className={[styles.group, stylesContainer.group].join(' ')} data-lock={isLock}>
            <Title {...{list, site, unit, vessel, isLock, onUpdate}}/>
            <div className={styles.subtitle}>
                <div className={[styles.left, 'font-w4'].join(' ')}>
                    <div>{round}</div>
                    <Density
                        isEmpty
                        className={styles.tanks}
                        type={OPERATION_TYPE.TREATMENT}
                        storages={unit.storages}
                    />
                </div>
                <div className={styles.right}>
                    <Tooltip title={note}>
                        <div className={styles.note}>{note}</div>
                    </Tooltip>
                    {!isLock && <AddNote title={unit.unit_id} value={note} onChange={this.handleSaveNote}/>}
                </div>
            </div>
            {!isLock && <div className={stylesContainer['line-middle']} data-level='unit'>
                <LineMiddle {...{containerId: 'treatment-operation', unitId: id, index: list[0].index}} />
            </div>}
            {(!isLock || id === currentUnitId) &&
                <div className={[styles['line-middle'], stylesContainer['line-middle']].join(' ')}
                     data-level='group'>
                    <LineMiddle {...{containerId: 'treatment-operation', unitId: id, index: groupIndex + 1}} />
                </div>}

            {list.map((item: any, subIndex: number) => <Task key={subIndex} {...{
                data: item,
                factory,
                isHaveSort,
                listOfTask,
                site,
                unit,
                isLock,
                handleUpdateTask: (task) => onUpdate(UpdateName.UPDATE_TASK, {task}),
                handleDeleteTask: (index) => onUpdate(UpdateName.DELETE_TASK, {index}),
                handleChangePosition: (args: any) => onUpdate(UpdateName.UPDATE_TASK_POSITION, args),
                onChangeGlobal: onUpdate,
            }}/>)}
        </div>
    }
}

export default Round;