import React from 'react';
import {Dropdown} from "antd";
import styles from './style.module.scss';
import {propContainer} from "../../util/varibles/constants";
import {ItemType} from "antd/es/menu/interface";

interface IProps {
    items: ItemType[] | any[]
    visible?: boolean
    disabled?: boolean
    placement?: 'bottom' | 'bottomLeft' | 'bottomRight' | 'top' | 'topLeft' | 'topRight' | string
    children?: React.ReactElement
    trigger?: ('click' | 'hover' | 'contextMenu')[]
    autoFocus?: boolean

    getPopupContainer?(): void

    onClick?({item, key, keyPath, domEvent}: any): void

    onVisibleChange?(visible: boolean): void

    container?(): React.ReactElement | Element | any
}

const Menu: React.FC<IProps> = (props) => {
    const attr: any = {};
    if (props.container)
        attr.getPopupContainer = props.container;

    if (!props.visible !== undefined)
        attr.open = props.visible;

    if (props.placement)
        attr.placement = props.placement

    if (props.getPopupContainer) {
        attr.getPopupContainer = props.getPopupContainer;
    }

    if (props.onVisibleChange)
        attr.onOpenChange = props.onVisibleChange;


    const {items} = props;
    return <Dropdown
        {...propContainer}
        {...attr}
        autoFocus={props.autoFocus}
        disabled={props.disabled || false}
        overlayClassName={styles.dropdown}
        trigger={props.trigger || ['click']}
        onClick={props.onClick}
        menu={{items}}
    >
        {props.children}
    </Dropdown>;
};

export default Menu;
