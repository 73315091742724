import React, {Component} from 'react';
import WrapperOperation from "./Component/WrapperOperation";
import styles from "./style.module.scss";
import {OP_STATUS, OPERATION_TYPE} from "../../../../../../util/varibles/constants";
import {IPropsOp} from "./index";

interface IProps extends IPropsOp {
    operationType: OPERATION_TYPE
    status: OP_STATUS
}

class Unavailable extends Component<IProps> {
    render() {
        return <WrapperOperation {...{...this.props, isOwn: false, minHeight: 0, contentDisable: true}}>
            <div {...{className: styles['content-wrapper']}}>
                <div className='text-truncate'>Unavailable</div>
            </div>
        </WrapperOperation>;
    }
}

export default Unavailable;
