import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import Header from "../Header";
import {Modal} from "antd";
import styles from "./style.module.scss";
import ReactDOM from 'react-dom/client';
import WrapperStore from "../../../WrapperStore";
import {propsModal} from "../../../../util/varibles/constants";

export function openPopup(children: ReactNode): HTMLDivElement {
    const el = document.createElement('div');
    const root = ReactDOM.createRoot(el as HTMLElement);
    root.render(<WrapperStore>{children}</WrapperStore>);
    const container = document.getElementsByClassName('view')[0] || document.body;
    container.append(el);
    return el;
}

interface IProps {
    visible?: boolean
    width?: number
    title?: string | ReactNode
    radius?: number | string
    style?: any
    className?: string
    destroyOnClose?: boolean
    children?: ReactNode

    onClose?(): void
}

const WrapperPopup: React.FC<IProps> = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const style: any = props.style || {};
    const {destroyOnClose = true, onClose} = props;

    useEffect(() => setVisible(props.visible), [props.visible]);

    const handleClose = useCallback(() => {
        setVisible(false);
    }, [])

    if (props.radius) {
        style['--radius'] = props.radius
    }

    return <Modal
        {...propsModal}
        width={props.width}
        style={style}
        className={[styles.popup, props.className].join(' ')}
        open={visible}
        title={<Header title={props.title} onClose={onClose || handleClose}/>}
        maskClosable
        destroyOnClose={destroyOnClose}
        onCancel={onClose || handleClose}
    >
        {props.children}
    </Modal>;
};

export default WrapperPopup;
