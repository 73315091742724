import React, {Component} from 'react';
import styles from './style.module.scss';
import {IPropsChildren} from "../../../../../util/varibles/interface";

interface IProps extends IPropsChildren {
    colPerRow: number
    columns: any[]
    titleClassName: string
}

class ListHeader extends Component<IProps> {
    render() {
        const {colPerRow, titleClassName, columns} = this.props;
        const style = {gridColumn: `1 / span ${colPerRow}`, gridTemplateColumns: `repeat(${colPerRow},1fr)`}
        const list = [];
        const detail = columns.map((item: any, i: number) => <div key={i} className={item.className}>
            {item.title}
        </div>)
        for (let i = 0; i < colPerRow; i++) {
            list.push(<div key={i} className={[styles['header-title'], titleClassName].join(' ')}>
                {i === 0 ? this.props.children : <div/>}
                {detail}
            </div>)
        }

        return <div className={styles['header-stores']} style={style}>
            {list}
        </div>;
    }
}

export default ListHeader;