import React from 'react';
import TrackingOp from "../../../components/TrackingOp";

interface IProps {
}

const WrapperTrackingOp: React.FC<IProps> = () => {
    return <TrackingOp/>;
};

export default WrapperTrackingOp;
