import {api} from "../api/callApi";
import {urlSite, urlSitesService} from "../api/v1";
import {ISite, IUnitDisease, TOperation} from "../varibles/interface";

interface IUpdateWaitingUnitOfSite {
    site_id: string
    is_waiting_unit?: boolean
    mortality_risk?: boolean
    unit_diseases?: any
}

interface IGetFishAmountOfUnit {
    start_time: number
    units: {
        id: string
    }[]
}

interface IGetUnitChart {
    id: string
    year: string
}

interface IDetail {
    id: string
    year: string
}

export interface ISiteExtend extends ISite {
    operations: TOperation[]
}

export const SiteService = {
    gets: () => api.get<ISite[]>(urlSite),
    getBySAdmin: () => api.post<ISite[]>(urlSite + '/admin'),
    addExternal: (site: ISite) => api.post<ISite[]>(urlSite, site),
    getForPlan: () => api.get<{ sites: ISite[], harvests: any[] }>(urlSite + '/plan'),
    updateByPlanner: (body: IUpdateWaitingUnitOfSite) => api.post<IUnitDisease[]>(urlSite + '/setting/unit', body),
    getSimulation: () => api.get(urlSitesService + '/simulation'),
    updateSimulation: (body: any) => api.post(urlSitesService + '/simulation', body),
    getAmountOfUnit: (body: IGetFishAmountOfUnit) => api.get(urlSite + '/time', body),
    getUnitChart: (body: IGetUnitChart) => api.post(urlSite + '/chart', body),
    detail: (body: IDetail) => api.post<ISiteExtend>(urlSite + '/detail', body),
    getSiteOfOp: (body: {id: string, operation_id: string}) => api.post<ISiteExtend>(urlSite + '/chart/operation', body),
}