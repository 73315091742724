import {createFactoryMarker, createPoiMarker, createPointsMarker, createSiteMarker, createVesselMarker} from "./marker";
import {createDiseasedZones, createProductionAreas} from "./zone";
import {createDialog} from "./Dialog";
import {DISEASED_ZONE, LOCATION_TYPE} from "../../varibles/constants";
import {createLines} from "./line";

export const CENTER_DEFAULT = {lat: 61.592657, lng: 6.317605};
export const ZOOM_DEFAULT = 7;

export const colors = ["FF8D68", "56A513", "1B6399", "63316D", "FFC900", "AD55E3", "D06643", "8E8E8E", "2F8ED5", "C5DFBA"];

export enum MAP_STYLE {
    AUBERGINE = 'aubergine',
    ROADMAP = 'roadmap',
    DARK = 'dark',
    SILVER = 'silver',
    SATELLITE = 'satellite',
}

export const mapType = {
    [MAP_STYLE.AUBERGINE]: {id: MAP_STYLE.AUBERGINE, name: 'Default'},
    [MAP_STYLE.ROADMAP]: {id: MAP_STYLE.ROADMAP, name: 'Road map'},
    [MAP_STYLE.DARK]: {id: MAP_STYLE.DARK, name: 'Dark'},
    [MAP_STYLE.SILVER]: {id: MAP_STYLE.SILVER, name: 'Silver'},
    [MAP_STYLE.SATELLITE]: {id: MAP_STYLE.SATELLITE, name: 'Satellite'},
}

export const mapTypes = [
    mapType[MAP_STYLE.AUBERGINE],
    mapType[MAP_STYLE.ROADMAP],
    mapType[MAP_STYLE.DARK],
    mapType[MAP_STYLE.SILVER],
    mapType[MAP_STYLE.SATELLITE],
]

export const getMetersPerPx = (latitude: number, zoomLevel: number) =>
    156543.03392 * Math.cos(latitude * Math.PI / 180) / Math.pow(2, zoomLevel);

export const limitShowZoom = 10;

export enum MAP_ELEMENT {
    SITE,
    FACTORY,
    VESSEL,
    POINT,
    DISEASED_ZONE,
    PRODUCTION_AREA,
    POLYLINE,
    DIALOG,
    POI,
}

type TPropsCreate = IPropsMap & (
    ICreateSiteMarker
    | ICreateFactoryMarker
    | ICreateVesselMarker
    | ICreatePointMarker
    | ICreateDiseasedZone
    | ICreateProductionArea
    | ICreateSeaWays
    | ICreateDialog)

interface IMapElement {
    [elementType: string]: {
        id: MAP_ELEMENT
        key: string
        locationType?: LOCATION_TYPE
        keyEl?: string
        create(props: TPropsCreate): any
    }
}

export const mapElement: IMapElement = {
    [MAP_ELEMENT.SITE]: {
        id: MAP_ELEMENT.SITE,
        key: 'sites',
        keyEl: 'element',
        locationType: LOCATION_TYPE.SITE,
        create: createSiteMarker,
    },
    [MAP_ELEMENT.FACTORY]: {
        id: MAP_ELEMENT.FACTORY,
        key: 'factories',
        keyEl: 'element',
        locationType: LOCATION_TYPE.FACTORY,
        create: createFactoryMarker,
    },
    [MAP_ELEMENT.POI]: {
        id: MAP_ELEMENT.POI,
        key: 'pois',
        keyEl: 'element',
        locationType: LOCATION_TYPE.POI,
        create: createPoiMarker,
    },
    [MAP_ELEMENT.VESSEL]: {
        id: MAP_ELEMENT.VESSEL,
        key: 'vessels',
        keyEl: 'element',
        create: createVesselMarker,
    },
    [MAP_ELEMENT.POINT]: {
        id: MAP_ELEMENT.POINT,
        key: 'points',
        create: createPointsMarker
    },
    [MAP_ELEMENT.DISEASED_ZONE]: {
        id: MAP_ELEMENT.DISEASED_ZONE,
        key: 'diseasedZones',
        keyEl: 'elements',
        create: createDiseasedZones
    },
    [MAP_ELEMENT.PRODUCTION_AREA]: {
        id: MAP_ELEMENT.PRODUCTION_AREA,
        key: 'productionAreas',
        keyEl: 'element',
        create: createProductionAreas
    },
    [MAP_ELEMENT.POLYLINE]: {
        id: MAP_ELEMENT.POLYLINE,
        key: 'polyLines',
        keyEl: 'seaWay',
        create: createLines
    },
    [MAP_ELEMENT.DIALOG]: {
        id: MAP_ELEMENT.DIALOG,
        key: 'dialog',
        keyEl: 'element',
        create: createDialog
    },

}

export interface IPropsMap {
    map: google.maps.Map | null
    elements: IElements
    event: IEventElement

    getElement(type: MAP_ELEMENT, id?: string): any
}

export interface ICreateMarker {
    markers: any
    event?: IEventElement
}
export interface ICreateSiteMarker extends ICreateMarker {
}

export interface ICreateFactoryMarker extends ICreateMarker {
}

export interface ICreateVesselMarker extends ICreateMarker {
}

export interface ICreatePoiMarker extends ICreateMarker {
}

export interface ICreatePointMarker extends ICreateMarker {
    id: string  // route id
    color: string
    isEdit?: boolean
}
export interface ICreateDiseasedZone {
    id: DISEASED_ZONE
    zones: any[]
    visible?: boolean
    event?: IEventElement
}

export interface ICreateProductionArea {
    zones: any[]
    visible?: boolean
    event?: IEventElement
}

export interface ICreateSeaWays {
    seaWays: any
    event?: IEventElement
}

export interface ICreateDialog {
    data: any
    contentType: MAP_ELEMENT
    position: google.maps.LatLng
    currentTime?: number
    lock?: boolean
    event?: IEventElement
}

export type TCreateElement =
    ({ type: typeof MAP_ELEMENT.SITE } & ICreateSiteMarker)
    | ({ type: typeof MAP_ELEMENT.FACTORY } & ICreateFactoryMarker)
    | ({ type: typeof MAP_ELEMENT.POI } & ICreatePoiMarker)
    | ({ type: typeof MAP_ELEMENT.VESSEL } & ICreateVesselMarker)
    | ({ type: typeof MAP_ELEMENT.POINT } & ICreatePointMarker)
    | ({ type: typeof MAP_ELEMENT.DISEASED_ZONE } & ICreateDiseasedZone)
    | ({ type: typeof MAP_ELEMENT.PRODUCTION_AREA } & ICreateProductionArea)
    | ({ type: typeof MAP_ELEMENT.POLYLINE } & ICreateSeaWays)
    | ({ type: typeof MAP_ELEMENT.DIALOG } & ICreateDialog)


export interface IDialog {
    [id: string]: {
        element: any
        lock: boolean
        info: any,
        type: MAP_ELEMENT
    }
}

export interface IElements {
    sites: {
        [id: string]: { element: google.maps.Marker, info: any }
    }
    factories: {
        [id: string]: { element: google.maps.Marker, info: any }
    },
    pois: {
        [id: string]: { element: google.maps.Marker, info: any }
    }
    vessels: {
        [id: string]: { element: google.maps.Marker, info: any }
    }
    polyLines: {
        [id: string]: { seaWay: google.maps.Polyline, info: any }
    }
    diseasedZones: {
        [diseaseType: string]: {
            [zoneId: string]: { elements: google.maps.Polygon[], info: any }
        }
    }
    productionAreas: {
        [id: string]: { element: google.maps.Polyline, info: any }
    }
    dialog: IDialog
    points: {
        [id: string]: google.maps.Marker[]
    }

    [index: string]: any
}

export const initElOnMap: IElements = {
    sites: {},
    factories: {},
    pois: {},
    vessels: {},
    points: {},
    polyLines: {},
    diseasedZones: {},
    productionAreas: {},
    dialog: {},
}

export interface IEventElement {
    click?: (data: any, e?: any) => void
    dragstart?: (data: any, e?: any) => void
    dragend?: (data: any, e?: any) => void
    rightclick?: (data: any, e?: any) => void
}

export const labelFont = {fontSize: "11px", color: '#FFF', fontWeight: '400'};

const createIconSite = ({...args}) => {
    const {labelOrigin, size = 7, color} = args;
    return {
        fillOpacity: 1,
        scale: 1,
        strokeColor: '#FFFFFF',
        strokeWeight: 2,
        fillColor: color,
        labelOrigin,
        path: `M 0 0 m -${size} 0 a ${size} ${size} 0 1 0 ${size * 2} 0 a ${size} ${size} 0 1 0 -${size * 2} 0`
    }
};

const createIconFactory = ({...args}) => {
    const {labelOrigin, size = 6} = args;
    return {
        fillOpacity: 1,
        scale: 1,
        strokeColor: '#FFFFFF',
        strokeWeight: 2,
        fillColor: '#58BD28',
        labelOrigin,
        path: `M -${size} -${size} L ${size} -${size} L ${size} ${size} L -${size} ${size} Z`,
    }
};

export const vesselColors = [
    {strokeColor: '#FF8D68', fillColor: '#FFD24C'}, //Process vessel
    {strokeColor: '#1B6399', fillColor: '#5EB0ED'}, //Wellboat
    {strokeColor: '#EE2A2A', fillColor: '#FF988F'}, //Treatment
]

const createIconVessel = ({...args}) => {
    const {width, length, metersPerPx, rotation, vesselType} = args;
    const minus = length / 10 / metersPerPx;
    const x = width / 2 / metersPerPx;
    const y = length / 2 / metersPerPx;
    let path;
    if (x < 2 || y < 2)
        path = 'M -4 0 L -4 -6 L 0 -11 L 4 -6 L 4 9 L 0 5 L -4 9 Z';
    else
        path = `M -${x} 0
            L -${x} -${y - x}
            L 0 -${y + minus} 
            L ${x} -${y - x} 
            L ${x} ${y - minus} 
            L -${x} ${y - minus} 
            Z`
    return {fillOpacity: 1, scale: 1, path, strokeWeight: 2, rotation, ...vesselColors[vesselType - 1]}
};

const createIconPoint = ({color, size = 6}: { color: string, size?: number }) => {
    return {
        fillOpacity: 1,
        scale: 1,
        strokeColor: color,
        fillColor: color,
        path: `M 0 0 m -${size} 0 a ${size} ${size} 0 1 0 ${size * 2} 0 a ${size} ${size} 0 1 0 -${size * 2} 0`
    }
}

const createIconPoi = ({labelOrigin, size = 14}: {
    labelOrigin: string,
    width?: number,
    size?: number
}) => {
    const x = size * 90 / 100 / 2;
    const y = size / 2;
    const space = size * 25 / 100;

    return {
        fillOpacity: 1,
        scale: 1,
        strokeColor: '#F6F5F4',
        strokeWeight: 2,
        fillColor: '#F69C6F',
        labelOrigin,
        path: `M 0 ${-y}, ${x} ${space - y}, ${x} ${size - space - y}, 0 ${y}, ${-x} ${y - space},${-x} ${space + y - size} Z`,
    }
}

interface IMarkerIcon {
    id: MAP_ELEMENT,
    zIndex: number

    getIcon({...args}: any): any
}

export const markerIcon: { [key: string]: IMarkerIcon } = {
    [MAP_ELEMENT.SITE]: {id: MAP_ELEMENT.SITE, zIndex: 3, getIcon: createIconSite},
    [MAP_ELEMENT.FACTORY]: {id: MAP_ELEMENT.FACTORY, zIndex: 4, getIcon: createIconFactory,},
    [MAP_ELEMENT.VESSEL]: {id: MAP_ELEMENT.VESSEL, zIndex: 5, getIcon: createIconVessel},
    [MAP_ELEMENT.POINT]: {id: MAP_ELEMENT.POINT, zIndex: 1, getIcon: createIconPoint},
    [MAP_ELEMENT.POI]: {id: MAP_ELEMENT.POI, zIndex: 2, getIcon: createIconPoi}
}

export const colorProductionArea: any = {
    green: '#5CA76A',
    yellow: '#F6D585',
    red: '#E78382'
}

export const clearElements = (list: any) => list.map(clearElement);
export const clearElement = (item: any) => item.setMap(null);

export function inside(point: number[], vs: number[][]) {

    const x = point[0], y = point[1];

    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        const xi = vs[i][0], yi = vs[i][1];
        const xj = vs[j][0], yj = vs[j][1];

        const intersect = ((yi > y) !== (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }

    return inside;
}