import React, {useState} from 'react';
import styles from "../style.module.scss";
import Filter from "./Filter";
import AutoPlan from "./AutoPlan";
import DisplayMode from "./DisplayMode";
import Search from "./Filter/Search";
import AvgWeight from "../_Component/AvgWeight";

interface IProps {
    search(args: any): void

    getSelected(): any

    clear(): void
}

const Header: React.FC<IProps> = ({search, getSelected, clear}) => {
    const [weight, setWeight] = useState<[number | null, number | null]>([null, null]);

    const handleWeightFilter = (value: [number | null, number | null]) => {
        setWeight(value)
        if (search)
            search({weightFilter: value})
    }

    return <div className={styles['tab-header']}>
        <div className={styles['header-left']} data-operaion-type='harvest'>
            <Search search={search}/>
            <AvgWeight weight={weight} onChange={handleWeightFilter}/>
        </div>
        <div className={styles['header-right']}>
            <Filter/>
            <DisplayMode/>
            <AutoPlan getSelected={getSelected} clear={clear}/>
        </div>
    </div>
};

export default Header;
