import React, {Component} from 'react';
import Treatment from "./Treatment";
import MoveToUnit from "./MoveToUnit";
import Harvest from "./Harvest";
import styles from './style.module.scss';
import {TASK_TYPE} from "../../../../../../util/varibles/constants";

interface IProps {
    data: any
}

class Combine extends Component<IProps> {
    renderContent = (data: any) => {
        const props = {data, isChild: true}
        switch (data.type) {
            case TASK_TYPE.TREATMENT:
                return <Treatment {...{...props, isSorting: false}}/>
            case TASK_TYPE.MOVE:
                return <MoveToUnit {...{...props}}/>
            case TASK_TYPE.HARVEST:
                return <Harvest {...{...props}}/>
            default:
                return null
        }
    }

    render() {
        const {sub_tasks = []} = this.props.data;
        return <>
            <div data-lev='name'>{this.props.data.type_name}:</div>
            <div className={styles['container-combine']}>
                {sub_tasks.map((item: any, i: number) => <div className={styles['combine']} key={item.group_id}>
                    {i > 0 && <div data-lev='dot'>,</div>}
                    {this.renderContent(item)}
                </div>)}
            </div>
        </>;
    }
}

export default Combine;