import {CERTAIN_TYPE, OPERATION_TYPE, SITE_TYPE} from "../../../../../../util/varibles/constants";
import {notify, NotifyCode} from "../../../../../../util/varibles/message";
import {IUnit} from "../../../../../../util/varibles/interface";

export enum KeyOfTask {
    ID = 'id',
    CAPABILITIES = 'capabilities',
    DURATION = 'duration',
    ERROR = 'error',
}

export interface IKeyUpdate {
    key: KeyOfTask
    value?: any
}

export interface IGenerateOp {
    store: any
    keyStore: string[]
    site: any
    properties: any
    error_detail?: any
}

export const generateOp = (args: IGenerateOp) => {
    const {site, store, keyStore, properties, error_detail = {}} = args;

    const tasks: any = keyStore.reduce((list: any, key: string) => {
        return [...list, {
            type: null,
            sub_operation: convertUnit(store[key]),
            error: {},
        }]
    }, [])

    return {
        operation: {
            ...properties,
            operation_type: OPERATION_TYPE.SERVICE,
            site_id: site.id,
            site_name: site.name,
            isInternal: site.type === SITE_TYPE.INTERNAL,
            tasks,
            error_detail,
            certain_mode: CERTAIN_TYPE.NONE,
        },
        store
    }
}

export function convertUnit(data: any) {
    const {id, unit_id, site_id, site_name} = data
    return {
        id,
        unit_id,
        site_id,
        site_name
    }
}

export function checkOp(tasks: any) {
    let isErrorGlobal = false;
    const tasksNew = tasks.map((item: any) => {
        const {type = null, error} = item;
        if (Object.keys(error).length > 0) {
            isErrorGlobal = true;
            return item;
        }

        if (type === null) {
            isErrorGlobal = true;
            return {...item, error: {empty: notify[NotifyCode.E11]()}};
        }
        const {isError, task: taskNew} = checkTask(item);
        if (isError)
            isErrorGlobal = true;
        return taskNew;
    })
    return {
        isError: isErrorGlobal,
        tasks: [...tasksNew]
    }
}

function checkTask(task: any) {

    return {isError: false, task}
}

export function addTask(tasks: any, unit: IUnit) {
    const {id: target_id} = unit;
    let index = 0;
    for (let i = 0; i < tasks.length; i++) {
        const {id} = tasks[i].sub_operation;

        if (id !== target_id)
            continue;
        index = i + 1;
    }

    const task = {
        type: null,
        sub_operation: convertUnit(unit),
    };
    tasks.splice(index, 0, task);
    return {tasks};
}
