import React, {Component} from 'react';
import {connect} from 'react-redux';
import {OPERATION_MODE, OPERATION_TYPE} from "../../../../../../util/varibles/constants";
import Harvest from "./Harvest";
import Unavailable from "./Unavailable";
import Event from "./Event";
import Transport from "./Transport";
import Treatment from "./Treatment";
import {ILevel, IOperation, IVessel} from "../../../../../../util/varibles/interface";
import Service from "./Service";
import {TargetTypes} from "../../Vessels/constants";
import {AppState} from "../../../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    userTenantId: state.login.user.tenant_id,
    opMode: state.login.user.tenant.operation_mode || OPERATION_MODE.PLAN
});

export interface IPropsOp {
    userTenantId: string
    data: IOperation
    startPoint: number
    index: number
    level: ILevel
    opMode: OPERATION_MODE
    width: number
    calendarId: string
    vessels: IVessel[]

    dragOperation(target: any): any

    setTarget(target: TargetTypes, e: MouseEvent): void

    openEdit(payload: any): void
}

class Operation extends Component<IPropsOp> {
    isOwn = false;
    modal: any;

    constructor(props: IPropsOp) {
        super(props);
        const {tenant_id} = this.props.data.operation;
        const {userTenantId} = this.props;
        this.isOwn = tenant_id === userTenantId;
    }

    render() {
        const {operation_type, status} = this.props.data.operation;
        const {isOwn: isVesselOwn = false, permission} = this.props.data.vessel;
        const operationType = (!(isVesselOwn || permission) && !this.isOwn) ? OPERATION_TYPE.UNAVAILABLE : operation_type;

        const props = {
            ...this.props,
            isOwn: this.isOwn,
            isVesselOwn,
            permission,
            operationType,
            status,
        }

        switch (operationType) {
            case OPERATION_TYPE.HARVEST:
                return <Harvest {...props}/>
            case OPERATION_TYPE.TREATMENT:
                return <Treatment {...props}/>
            case OPERATION_TYPE.EVENT:
                return <Event {...props}/>
            case OPERATION_TYPE.TRANSPORT:
                return <Transport {...props}/>
            case OPERATION_TYPE.SERVICE:
                return <Service {...props}/>
            default:
                return <Unavailable {...props}/>
        }
    }
}

export default connect(mapStateToProps, {})(Operation);
