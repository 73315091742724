import React, {useEffect, useState} from 'react';
import {IOperation, IOperations} from "../../../../util/varibles/interface";
import {propContainer, propsModal, SHORT_MONTH_DATETIME_FORMAT} from "../../../../util/varibles/constants";
import Header from "../../../../components/Popup/Component/Header";
import styles from "./style.module.scss";
import {DatePicker, Modal} from "antd";
import {getTimeByOpMode} from "../../util/function_operation/constants";
import {useDispatch, useSelector} from "react-redux";
import {selectOpMode} from "../../../../util/store/selectors";
import {datetime} from "../../../../util/library/datetime";
import {Dayjs} from "dayjs";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import Footer from "../../../../components/Popup/Component/Footer";
import {SiteService} from "../../../../util/services/site";
import {showErrorResponse} from "../../../../util/varibles/global";
import {updateUnitToTask} from "../EditOp/Body/Treatment/constants";
import {planOpActions} from "../../reducer";
import {notify, NotifyCode} from "../../../../util/varibles/message";

let controllerRequest = new AbortController();

interface IProps {
    data: IOperation

    onClose(): void
}

const PopupDuplicate: React.FC<IProps> = (props) => {
    const {data} = props
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(true);
    const [value, setValue] = useState<Dayjs | null>();
    const [loading, setLoading] = useState(false);
    const opMode = useSelector(selectOpMode)
    const start = getTimeByOpMode[opMode](data.activity_log[0]);

    useEffect(() => {
        return () => {
            controllerRequest.abort();
        };
    }, []);


    function handleClose() {
        setVisible(false)
        props.onClose();
    }

    const handleSubmit = () => {
        const {sub_operations} = data.operation;
        const params = {
            start_time: datetime(value).time,
            units: sub_operations.map(({id}) => ({id}))
        }
        controllerRequest.abort();
        setLoading(true);
        SiteService.getAmountOfUnit(params)
            .then((result: any) => {
                const {newSubs, units} = sub_operations.reduce((rs: any, item) => {
                    const {fish_amount, avg_weight} = result.find((sub: any) => sub.id === item.id)
                    const unit = {...item, fish_amount, avg_weight};
                    rs.units[item.id] = unit;
                    rs.newSubs = [...rs.newSubs, unit];
                    return rs
                }, {newSubs: [], units: {}})
                const newData = {
                    ...data,
                    operation: {
                        ...data.operation,
                        tasks: updateUnitToTask(sub_operations, units, data.operation.tasks),
                        sub_operations: newSubs
                    },
                }
                controllerRequest = new AbortController();
                dispatch(planOpActions.getActivityLog({
                    source: [newData],
                    properties: {[newData.operation.id]: {start: datetime(value).startOf('minute').time}},
                    signal: controllerRequest,
                    success: (ops: IOperations) => {
                        dispatch(planOpActions.updateOps({add: ops, isFocus: newData.operation.id}))
                        handleClose();
                        setLoading(false)
                    },
                    failure: (error: any) => {
                        showErrorResponse('Create activity log failed', error)
                        setLoading(false)
                    }
                }))
            })
            .catch(error => {
                setLoading(false)
                showErrorResponse(notify[NotifyCode.E39](['units']), error)
            })
    }

    return <Modal {...{
        ...propsModal,
        open: visible,
        title: <Header title={`Duplicate operation #${data.operation.operation_code}`} onClose={handleClose}/>,
        width: 500,
        wrapClassName: styles.popup,
        onCancel: handleClose,
    }}>
        <div className={styles.startOld}>
            <Icon icon={ICON_NAME.CALENDAR}/>
            <span>{datetime(start).format(SHORT_MONTH_DATETIME_FORMAT)}</span>
        </div>
        <div className={styles.lineLabel}>Place into a time</div>
        <DatePicker
            {...propContainer}
            className={styles.picker}
            allowClear={false}
            showTime={{format: 'HH:mm', minuteStep: 15}}
            format={'DD MMM, HH:mm'}
            placeholder='Select date, time'
            value={value}
            onChange={value => {
                const minus = Math.ceil(datetime(value).minute / 15) * 15;
                setValue(datetime(value).set({minute: minus}).value)
            }}
        />
        <Footer
            border
            ok={{text: 'Duplicate', disabled: !value, loading, click: handleSubmit}}
            cancel={{click: handleClose}}
        />
    </Modal>;
};

export default PopupDuplicate;
