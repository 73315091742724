import {IProductionArea} from "../../util/varibles/interface";
import {VIEW_MODE} from "../../util/varibles/permission";
import GoogleMap from "../../util/library/googlemap/googlemap";

export enum FILTER_TYPES {
    FACTORY = 'dashboardFactoryFilter',
    ACTIVE_SITE = 'dashboardActiveSiteFilter',
    SITE = 'dashboardSiteFilter',
    PRODUCTION_AREA = 'dashboardProductionAreaFilter',
    VESSEL = 'dashboardVesselFilter'
}

export interface DashboardState {
    operations: any[]
    vessels: any[]
    sites: any[]
    factories: any[]
    productionAreas: IProductionArea[]

    weather: any
    target: any
    filter: any
    map?: GoogleMap

    history: {
        time: number,
        data: any[]
    }

    popupInfo: {
        isShow: boolean
        values?: any
    },

    tracking: boolean
    loadingWeather: boolean
    loadingOp: boolean
    loadingProductionAreas: boolean
    loadingVessels: boolean
}

export const initialState: DashboardState = {
    operations: [],
    vessels: [],
    sites: [],
    factories: [],
    productionAreas: [],

    weather: {},
    target: {},
    filter: {
        factories: true,
        productionArea: true
    },

    history: {
        time: 0,
        data: []
    },

    popupInfo: {
        isShow: false
    },

    tracking: false,
    loadingWeather: false,
    loadingOp: false,
    loadingProductionAreas: false,
    loadingVessels: false
};