import React, {useState} from 'react';
import stylesOp from "../style.module.scss";
import {eventType, OP_STATUS} from "../../../../../util/varibles/constants";
import Header from "../../../Component/Header";
import Note from "../../../Component/Note";
import ActivityLogs from '../../../ActivitiesLog/ActivityLogs/ActivityLogs'
import StepControl from "../../../Component/StepControl";
import Icon, {ICON_NAME} from "../../../../Icon";
import {IPropsOp} from "../index";

interface IProps extends IPropsOp {

}

const Event: React.FC<IProps> = ({data, step, active, actions, changeStep, extend, onUpdate}) => {
    const [isShowActivityLog, setIsShowActivityLog] = useState(false)
    const {status, event_type, note} = data.operation;
    const {name = ''} = eventType[event_type] || {}

    return <>
        <Header
            data={data}
            step={step}
            icon={<Icon icon={ICON_NAME.EVENT}/>}
            extend={extend}
            active={active}
            isShowActivityLog={isShowActivityLog}
            changeShowActivityLog={() => setIsShowActivityLog(!isShowActivityLog)}
        >
            <div className={[stylesOp['operation-route'], 'd-flex'].join(' ')}>
                <div>{name}</div>
            </div>
        </Header>
        <div className={stylesOp['operation-info']}>
            <div className={stylesOp['operation-content']}>
                <StepControl
                    data={data}
                    actions={actions}
                    step={step}
                    changeStep={changeStep}
                    onUpdate={onUpdate}
                />
                {isShowActivityLog
                    ? <div className={stylesOp.activityLog}>
                        <ActivityLogs data={data}/>
                    </div>
                    : (status === OP_STATUS.PROCESSING && <div className={stylesOp['operation-step-content']}>
                        <Note note={note}/>
                    </div>)}
            </div>
        </div>
    </>;
};

export default Event;