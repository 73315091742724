import {DISEASED_ZONE} from "../varibles/constants";
import request from "./request";
import {createParamsForGet} from "../varibles/global";

const host = 'https://www.barentswatch.no/bwapi';

const urlV1 = [host, 'v1'].join('/');
const urlV2 = [host, 'v2'].join('/');

const urlGeoDataV1 = [urlV1, 'geodata'].join('/');
// const urlGeoDataV2 = [urlV2, 'geodata'].join('/');
const urlFishHealthV1 = [urlV1, 'geodata', 'fishhealth'].join('/');
const urlFishHealthV2 = [urlV2, 'geodata', 'fishhealth'].join('/');

interface IGetSites {
    organization: number
}

export const BarentsWatchService = {
    getIlaControlAreaV1: (diseasedZone: DISEASED_ZONE, forsknr: string, week: string, options?: any) => {
        return request([urlFishHealthV1, diseasedZone + 'zone', forsknr, week].join('/'), options)
    },
    getIlaControlAreaV2: (diseasedZone: DISEASED_ZONE, week: string, options?: any) => {
        return request([urlFishHealthV2, diseasedZone + 'zone', week].join('/'), options);
    },
    getSitesNaUy: (year: number, weekNo: number, args: IGetSites) => {
        return request([urlFishHealthV1, 'locality', year, weekNo].join('/') + createParamsForGet(args));
    },
    getSiteNaUyFetch: (localityId: number, year: number, weekNo: number) => {
        return request([urlFishHealthV1, 'locality', localityId, year, weekNo].join('/'));
    },
    getProductionAreas: (options?: any) => {
        return request([urlGeoDataV1, 'productionarea'].join('/'), options).then(rs => {
            if (Array.isArray(rs)) {
                return rs.map(item => {
                    const {id, name, colors, geometry: {coordinates}} = item;
                    const {color} = colors[colors.length - 1]
                    return {
                        id,
                        name,
                        coordinates: coordinates[0],
                        color
                    }
                })
            }
            return []
        });
    }
}
