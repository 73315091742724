import React from 'react';
import styles from '../style.module.scss';
import {EL_DASHBOARD} from "../constants";
import Toggle from "./Toggle";
import {Collapse} from 'antd';
import ProductionArea from "./ProductionArea";
import Factory from "./Factory";
import ProductionAreas from "./ProductionAreas";
import DiseasedZones from "./DiseasedZones";
import Vessel from "./Vessel";
import ActiveSite from "./ActiveSite";
import {LeftOutlined} from "@ant-design/icons";
import {connect, useDispatch} from "react-redux";
import {DashboardSetting, PreferenceSetting} from "../../../../util/varibles/userSetting";
import {loginActions} from "../../../LoginPage/reducer";
import {getSetting} from "../../../../util/varibles/defaultSetting";
import {VIEW_MODE} from "../../../../util/varibles/permission";
import {AppState} from "../../../../util/store/store";

const {Panel} = Collapse;

const mapStateToProps = (state: AppState) => {
    const sectionFilter = getSetting(state.login.user.setting, DashboardSetting.SECTION_FILTER);
    const viewMode = getSetting(state.login.user.setting, PreferenceSetting.VIEW_MODE);
    return {sectionFilter, viewMode}
};


interface IProps {
    sectionFilter: string[]
    viewMode: VIEW_MODE
}

const RightControl: React.FC<IProps> = ({sectionFilter, viewMode}) => {
    const dispatch = useDispatch();
    if (viewMode !== VIEW_MODE.PLANNER)
        return null;

    return <>
        <div id={EL_DASHBOARD.RIGHT_CONTROL} className={styles.controlRight}>
            <div className={styles.header}>
                <div data-lev='title'>Map Filter</div>
            </div>
            <div className={styles.body}>
                <Collapse
                    onChange={(value: any) => dispatch(loginActions.updateUserSetting([{[DashboardSetting.SECTION_FILTER]: value}]))}
                    bordered={false}
                    defaultActiveKey={sectionFilter}
                    expandIconPosition='end'
                    expandIcon={({isActive}) => <LeftOutlined rotate={isActive ? -90 : 0}/>}
                >
                    <Panel className={styles.section} header="Production areas, sites, factories" key="1">
                        <ProductionArea/>
                        <Factory/>
                        <ActiveSite/>
                    </Panel>
                    <Panel className={[styles.section, styles.grey].join(' ')} header="Select site by production area"
                           key="2">
                        <ProductionAreas/>
                    </Panel>
                    <Panel forceRender className={styles.section} header="Disease zones" key="3">
                        <DiseasedZones/>
                    </Panel>
                    <Panel className={[styles.section, styles.grey].join(' ')} header="Vessels" key="4">
                        <Vessel/>
                    </Panel>
                </Collapse>
            </div>
        </div>
        <Toggle/>
    </>;
};

export default connect(mapStateToProps, {})(RightControl);
