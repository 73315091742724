import React from 'react';
import styles from '../style.module.scss';
import MapType from "../../Component/MapType";
import TrackVessel from "../../Component/TrackVessel";
import SearchMarker from "../../Component/SearchMarker";
import {ACTION} from "../../../../util/varibles/permission";

interface IProps {
    permission: any
}

const TopControl: React.FC<IProps> = ({permission}) => {
    return <div className={styles.controlTop}>
        {permission[ACTION.DASHBOARD.TRACKING_VESSEL] && <TrackVessel/>}
        {permission[ACTION.DASHBOARD.SEARCH] && <SearchMarker/>}
        {permission[ACTION.DASHBOARD.MAP_STYLE] && <MapType/>}
    </div>;
};

export default TopControl;
