import {all, call, put, takeEvery} from 'redux-saga/effects'
import {SiteService} from "../../util/services/site";
import {FactoryService} from "../../util/services/factory";
import {getOperationAtDashboardFetch} from "../../util/services/operation";
import {getPositionVessels2Fetch, getVesselsAndVesselContractFetch} from "../../util/services/vessel";
import {BarentsWatchService} from "../../util/barentswatch/api";
import {dashboardActions} from "./reducer";
import {IFactory, IProductionArea, ISite} from "../../util/varibles/interface";
import {PayloadAction} from "@reduxjs/toolkit";
import {PoiService} from "../../util/services/poi";
import {LOCATION_TYPE} from "../../util/varibles/constants";

function* getDashboardWatcher() {
    try {
        const {operations} = yield call(getOperationAtDashboardFetch);
        yield put(dashboardActions.getDataSuccess(operations));
    } catch (error) {
        yield put(dashboardActions.getDataFailure());
        console.log(error)
    }
}

function* getVesselsWatcher(props: PayloadAction<Function | undefined>): any {
    try {
        const contracts = yield call(getVesselsAndVesselContractFetch);
        const iotNames = (contracts || []).map((item: any) => item.iot_name);
        const positions: any[] = iotNames.length > 0 ? yield call(getPositionVessels2Fetch, iotNames) : [];

        const vessels = (positions || []).reduce((list: any, gps: any) => {
            const {time, ...args} = gps;
            const [value] = time.split('.');
            const gpsTime = value[value.length - 1] === 'Z' ? new Date(value.replace(' ', 'T')).getTime() :
                new Date(value.replace(' ', 'T') + 'Z').getTime();

            const vessel = contracts.find((item: any) => item.id === gps.vessel_id);
            const index = vessel ? list.findIndex((item: any) => item.id === vessel.id) : -1;
            const data = {...vessel, gps: {...args, time: gpsTime}};
            if (index === -1)
                return [...list, data];
            else {
                list[index] = data;
                return list
            }
        }, []);


        const callback = props.payload;
        if (callback)
            callback(vessels);

        yield put(dashboardActions.getVesselsSuccess(vessels))
    } catch (error) {
        yield put(dashboardActions.getVesselsFailure());
        console.log(error);
    }
}

function* getSitesWatcher() {
    try {
        const sites: ISite[] = yield call(SiteService.gets);
        yield put(dashboardActions.getSitesSuccess(sites))
    } catch (error) {
        yield put(dashboardActions.getSitesFailure());
        console.log(error)
    }
}

function* getFactoriesWatcher() {
    try {
        const factories: IFactory[] = yield call(FactoryService.gets);
        yield put(dashboardActions.getFactoriesSuccess(factories))
    } catch (error) {
        yield put(dashboardActions.getFactoriesFailure());
        console.log(error)
    }
}

function* getWeatherWatcher(args: PayloadAction<{ type: LOCATION_TYPE, id: string }>): Generator {
    try {
        const params = args.payload;
        const result: any = yield call(PoiService.weather, params) || {}
        yield put(dashboardActions.getWeatherSuccess(result.weather || {}))
    } catch (error) {
        console.log(error);
        yield put(dashboardActions.getWeatherFailure())
    }
}

function* getProductionAreasWatcher(): Generator<any, any, IProductionArea[]> {
    try {
        const productionAreas = yield call(BarentsWatchService.getProductionAreas) || [];
        yield put(dashboardActions.getProductionAreasSuccess(productionAreas));
    } catch (error) {
        console.log(error);
        yield put(dashboardActions.getProductionAreasFailure())
    }
}

export default function* dashboardSaga() {
    yield all([
        takeEvery(dashboardActions.getDataRequest.type, getDashboardWatcher),
        takeEvery(dashboardActions.getVesselsRequest.type, getVesselsWatcher),
        takeEvery(dashboardActions.getSitesRequest.type, getSitesWatcher),
        takeEvery(dashboardActions.getFactoriesRequest.type, getFactoriesWatcher),
        takeEvery(dashboardActions.getWeatherRequest.type, getWeatherWatcher),
        takeEvery(dashboardActions.getProductionAreasRequest.type, getProductionAreasWatcher)
    ])
}
