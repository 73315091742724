import React, {useEffect, useState} from 'react';
import {OPERATION_TYPE} from "../../../../util/varibles/constants";
import Harvest from "./Harvest";
import Treatment from "./Treatment";
import Event from "./Event";
import Transport from "./Transport";
import Service from "./Service";
import {IOperation} from "../../../../util/varibles/interface";
import {checkLimit} from "../../../../util/varibles/global";
import stylesOp from "./style.module.scss";

export interface IPropsOp {
    data: IOperation,
    step: number
    active: boolean
    actions: any

    extend(): void

    changeStep(step: number): void

    onUpdate?(args: any): void
}

const content = {
    [OPERATION_TYPE.HARVEST]: (props: IPropsOp) => <Harvest {...props} />,
    [OPERATION_TYPE.TREATMENT]: (props: IPropsOp) => <Treatment {...props} />,
    [OPERATION_TYPE.EVENT]: (props: IPropsOp) => <Event {...props} />,
    [OPERATION_TYPE.TRANSPORT]: (props: IPropsOp) => <Transport {...props} />,
    [OPERATION_TYPE.SERVICE]: (props: IPropsOp) => <Service {...props} />,
    [OPERATION_TYPE.UNAVAILABLE]: () => null
}

interface IProps {
    data: IOperation
    active: boolean
    actions: any

    extend(): void

    onUpdate?(args: any): void
}

const Operation: React.FC<IProps> = ({data, active, actions, extend, onUpdate}) => {
    const {current_process} = data.operation;
    const [step, setStep] = useState(current_process);

    useEffect(() => {
        setStep(current_process);
    }, [current_process]);


    const changeStep = (step: number) => {
        setStep(checkLimit(0, undefined, step));
    }

    const props = {
        data,
        step,
        actions,
        active,
        changeStep,
        extend,
        onUpdate
    }

    const {operation_type} = data.operation;
    return <div className={stylesOp.wrapper} data-active={active}>
        {content[operation_type](props)}
    </div>
};

export default Operation;