import React, {Component} from 'react';
import styles from "./style.module.scss";
import {notification} from "antd";
import Unit from "./Unit";
import {ACTION_TYPE} from "../../../../../../util/varibles/constants";
import {IPropsChildren} from "../../../../../../util/varibles/interface";

interface IProps extends IPropsChildren {
    store: any
    editMode: ACTION_TYPE
    gridTemplateColumns: string

    nameRow(item: any): any

    addOperation(unit: any): void
}

class Units extends Component<IProps> {
    static defaultProps = {
        gridTemplateColumns: '1fr 2fr 1fr 1fr 50px'
    }
    handleAdd = (unit: any, isInternal: boolean) => {
        const {unit_id, fish_amount} = unit;
        if (isInternal && fish_amount <= 0) {
            notification.error({message: 'Error', description: `${unit_id} has no more fish`});
            return;
        }
        this.props.addOperation(unit);
    }

    render() {
        const {store, editMode, gridTemplateColumns} = this.props;
        return <div className={styles.units}>
            {Object.keys(store).map((key: any) => <Unit key={store[key].id + store[key].harvest_id} {...{
                gridTemplateColumns,
                data: store[key],
                editMode,
                handleAdd: this.handleAdd,
            }}>
                {this.props.nameRow(store[key])}
            </Unit>)}
        </div>;
    }
}

export default Units;
