import React, {Component} from 'react';
import {KeyOfTask, TASK_TYPE, taskTypes, treatmentType} from "../../../../../../../util/varibles/constants";
import Treatment from "./Treatment";
import Harvest from "./Harvest";
import MoveToUnit from "./MoveToUnit";
import {IKeyUpdate} from "../constants";
import styles from './style.module.scss';
import {convertStringTimeToNumberTime} from "../../../../../../../util/varibles/global";
import {UpdateName} from "../../constants";
import {notify, NotifyCode} from "../../../../../../../util/varibles/message";

interface IProps {
    data: any
    site: any
    unit: any
    factory: any
    parentIndex: any
    isLock: any
    isSorting: any
    listOfTask: any

    handleChange(list: IKeyUpdate[]): void

    handleChangePosition(args: any): void

    onChangeGlobal(key: UpdateName, args: any): void
}

class Combine extends Component<IProps> {
    handleChangeTask = (list: IKeyUpdate[], task: any, index: any) => {
        list.forEach(({key, value}) => {
            switch (key) {
                case KeyOfTask.DURATION: {
                    const durationTxt = value.replace(/ {2,}/g, ' ').toLowerCase();

                    task = {...task, durationTxt};
                    const {duration: messageError = undefined} = task.error || {};
                    if (messageError) {
                        const duration = convertStringTimeToNumberTime(durationTxt.trim());
                        if (duration !== undefined) {
                            task.duration = duration;
                            delete task.error.duration;
                        }
                    }
                    break;
                }
                case KeyOfTask.CHECK_DURATION: {
                    const {durationTxt = ''} = task;
                    if (durationTxt.length === 0)
                        task.error = {...task.error || {}, duration: notify[NotifyCode.E16]()};
                    else {
                        const duration = convertStringTimeToNumberTime(durationTxt);
                        if (duration !== undefined) {
                            task.duration = duration;
                            const {duration: messageError = undefined} = task.error || {};
                            if (messageError)
                                delete task.error.duration;
                        } else
                            task.error = {...task.error || {}, duration: notify[NotifyCode.E19]()};
                    }

                    break;
                }
                case KeyOfTask.CONDITIONS:
                case KeyOfTask.SUB_TASKS:
                case KeyOfTask.DESTINATION_ID:
                case KeyOfTask.DESTINATION_NAME:
                case KeyOfTask.DESTINATION_FIELD:
                case KeyOfTask.DELIVERY_TYPES:
                case KeyOfTask.SUB_OPERATIONS:
                case KeyOfTask.SUB_OPERATION:
                case KeyOfTask.ERROR: {
                    task[key] = value;
                    break;
                }
                default:
                    return null
            }
        })

        const {sub_tasks} = this.props.data;
        this.props.handleChange([{
            key: KeyOfTask.SUB_TASKS,
            value: sub_tasks.map((item: any, i: number) => i === index ? task : item)
        }]);
    }

    renderSub = (data: any, index: any) => {
        const {site, unit, factory, isLock, listOfTask} = this.props;
        const common = {
            data,
            site,
            unit,
            factory,
            isLock,
            listOfTask,
            handleChange: (change: any) => this.handleChangeTask(change, data, index),
        }
        switch (data.type) {
            case TASK_TYPE.TREATMENT: {
                const {handleChangePosition, onChangeGlobal} = this.props;
                return <Treatment key={index} {...{...common, handleChangePosition, onChangeGlobal}}/>
            }
            case TASK_TYPE.HARVEST: {
                return <Harvest key={index} {...{...common}}/>
            }
            case TASK_TYPE.MOVE: {
                const {isSorting} = this.props;
                return <MoveToUnit key={index} {...{...common, isSorting}}/>
            }
            default:
                return null
        }
    }

    render() {
        const {sub_tasks = []} = this.props.data;
        return <div className={styles['container-combine']}>
            {sub_tasks.map((item: any, index: any) => {
                const {name = ''} = treatmentType[item.treatment_type] || taskTypes[item.type] || {};
                const {error} = item;
                let isError = false, styleError = {height: '0'}
                if (Object.keys(error).length > 0) {
                    styleError.height = (Object.keys(error).length * 20 + 3) + 'px';
                    isError = true;
                }
                return <>
                    <div className={styles['combine-line']} key={index} data-error={isError}>
                        <div data-lev='task-name'>{name}</div>
                        {this.renderSub(item, index)}
                    </div>
                    <div className={styles['task-error']} key={index + 'error'} style={styleError}>
                        {Object.keys(error).map(key =>
                            <div className={styles['error-line']} key={key}
                                 dangerouslySetInnerHTML={{__html: error[key]}}/>)}
                    </div>
                </>
            })}
        </div>;
    }
}

export default Combine;