import React, {createRef, useEffect} from 'react';
import styles from "./style.module.scss";
import {checkLimit} from "../../../../../../../util/varibles/global";
import stylesContainer from "../style.module.scss";
import LineMiddle from "../../../_Component/LineMiddle";
import Icon, {ICON_NAME} from "../../../../../../../components/Icon";
import Menu from "../../../../../../../components/Menu";
import DragEl from '../../../_Component/DragEl';
import {openPopup} from "../../../../../../../components/Popup/Component/WrapperPopup";
import {IPropsChildren} from "../../../../../../../util/varibles/interface";

const disableSelect = (event: any) => {
    event.preventDefault();
}

interface IProps extends IPropsChildren {
    data: any;
    isError: boolean
    contentDrag: any;
    isAllowDrag?: boolean
    isEmptyTasks?: boolean
    parentIndex?: number

    onDelete?(): void

    onChangePosition(args: any): void
}

let dragEl: HTMLDivElement | any, parentEl: HTMLDivElement | any, levelMode = 'all';
const WrapperTask: React.FC<IProps> = (props) => {
    const {isError, isAllowDrag, isEmptyTasks = false, parentIndex = null} = props;
    const {elementId, id, index, sub_operation} = props.data;
    const rootRef = createRef<HTMLDivElement>();

    const propsWrapper: any = {
        id: elementId,
        className: [styles['wrapper-task'], stylesContainer['wrapper-task']].join(' '),
        'data-is-empty': isEmptyTasks,
        'data-error': isError,
        'data-allow-drag': isAllowDrag
    }

    useEffect(() => {
        parentEl = document.getElementById('service-operation')
        return () => {
            document.body.style.cursor = 'unset';
            window.removeEventListener('selectstart', disableSelect);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
    }, []);

    const
      handleMouseDown = () => {
          window.addEventListener('selectstart', disableSelect);
          document.addEventListener('mousemove', handleMouseMove);
          document.addEventListener('mouseup', handleMouseUp);

          const {contentDrag} = props;
          dragEl = openPopup(<DragEl {...{data: contentDrag}}/>);
          dragEl.style.position = 'absolute';
          dragEl.style.zIndex = '2000';
          dragEl.style.pointerEvents = 'none';
          dragEl.style.display = 'none';
          levelMode = 'all';
      }

    const handleMouseMove = (e: any) => {
        parentEl.dataset.isDrag = levelMode;
        if (rootRef.current)
            rootRef.current.dataset.isDrag = "true";

        const {pageX, pageY} = e;
        dragEl.style.display = 'block';
        const {offsetHeight, offsetWidth} = dragEl;
        dragEl.style.display = 'none';
        const left = checkLimit(0, window.innerWidth - offsetWidth - 5, pageX);
        const top = checkLimit(0, window.innerHeight - offsetHeight, pageY);
        dragEl.style.left = left - 10 + 'px';
        dragEl.style.top = top - 10 + 'px';
        dragEl.style.display = 'block';
        document.body.style.cursor = 'grabbing';
    }

    const handleMouseUp = (e: any) => {
        if (dragEl) {
            dragEl.remove();
            dragEl = undefined;
        }
        document.body.style.cursor = 'unset';
        delete parentEl.dataset.isDrag;
        delete rootRef.current?.dataset.isDrag;
        window.removeEventListener('selectstart', disableSelect);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        if (!e.target)
            return;
        const {index} = e.target.dataset;
        if (index === undefined)
            return;
        e.target.dataset.hover = false;

        const params = {
            newPosition: index,
            oldPosition: [props.data.index, props.parentIndex].join('-')
        };
        props.onChangePosition(params)
    }


    return <div ref={rootRef} className={styles['container-task']}>
        {(isAllowDrag && id) && <div className={stylesContainer['bt-drag']} onMouseDown={handleMouseDown}/>}
        <div {...propsWrapper}>
            {props.children}
            <div className={stylesContainer['container-menu']}>
                <Menu items={[
                    {
                        key: 'delete',
                        label: <div className='menu-line' data-danger='true' onClick={props.onDelete}>
                            <Icon icon={ICON_NAME.DELETE} danger/>
                            <span>Delete task</span>
                        </div>
                    }
                ]} placement='bottomRight'>
                    <div className='three-dot'/>
                </Menu>
            </div>
        </div>
        {isAllowDrag
          && <div className={[stylesContainer['line-middle'], styles['line-middle']].join(' ')} data-level='task'>
                <LineMiddle {...{
                    containerId: 'service-operation',
                    unitId: sub_operation.id,
                    index: index + 1,
                    parentIndex
                }} />
            </div>}
    </div>;
};

export default WrapperTask;