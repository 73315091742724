import React, {Component} from 'react';
import {connect} from 'react-redux';
import {OP_STATUS, OPERATION_MODE, OPERATION_TYPE} from "../../../../../../../../util/varibles/constants";
import {createKeyActivityLog, handleScroll, HEIGHT_PER_DAY, updateTime} from "../../../constants";
import styles from "../../style.module.scss";
import stylesTime from '../TimeLine/style.module.scss';
import stylesVessel from "../../../../Vessels/style.module.scss";
import {
    addClassName,
    checkLimit,
    getElById,
    getStartFinishByOpMode,
    getWidthByClassName,
    removeClassName
} from "../../../../../../../../util/varibles/global";
import {notification, Tooltip} from "antd";
import {EL_RULE} from "../../../../constants";
import PopupMerge from "../../../../../../Popup/OpMerge";
import TimeLine from "../TimeLine";
import OpInfo from "../../../../../../Popup/OpInfo";
import {calcWidth, checkStatus, getHeightOfOp, getNewTime} from "../constants";
import Id from "../../../../../../../../components/Operation/Id";
import OperationStatus from "../../../../../../../../components/Operation/OperationStatus";
import {IActivityLog, IOperation, IPropsChildren, IVessel} from "../../../../../../../../util/varibles/interface";
import {openPopup} from "../../../../../../../../components/Popup/Component/WrapperPopup";
import {VALIDATION_STATUS} from "../../../../../../util/validation";
import {IPropsOp} from "../../index";
import {planOpActions} from "../../../../../../reducer";
import Logo from "../../../../../../../../components/Operation/Logo";
import Loading from "../../../../Loading";
import {LAYOUT_CALENDER} from "../../../../../../constants";
import {IGetActivityLog} from "../../../../../../saga";
import {AppState} from "../../../../../../../../util/store/store";

const mapStateToProps = (state: AppState) => {
    const {operation_mode = OPERATION_MODE.PLAN} = state.login.user.tenant;

    return {
        operation_mode,
    }
};

export interface IPropsWrapperOp extends IPropsChildren, IPropsOp {
    operation_mode: OPERATION_MODE
    opFocusId: string
    vessels: IVessel[]
    status: OP_STATUS

    data: { activityLogRoot?: IActivityLog[] } & IOperation

    operationType: any
    isOwn: boolean
    isGetActivityLog: boolean
    contentDisable: boolean
    icon?: any

    edit?(): void

    setFocusId(payload: string): void

    dragOperation(target: any): any

    getActivityLog(payload: IGetActivityLog): void
}

interface IState {
    minHeight: number
    modeDrag: 'start' | 'finish' | 'move' | string
    left?: number
    key: string
    currentKey: string
    activityLogs: any
    isTimeline: boolean
}

class WrapperOperation extends Component<IPropsWrapperOp, IState> {
    static defaultProps = {
        isGetActivityLog: false,
        menuOptions: [],
        contentDisable: false,
        opFocusId: ''
    }
    state = {
        minHeight: 0,
        modeDrag: '',
        key: '',
        currentKey: '',
        activityLogs: {},
        isTimeline: true
    };
    vessels: IVessel[] = []
    vesselWidth = 0;
    rootRef: HTMLDivElement | any = React.createRef<HTMLDivElement>();
    intervalMove: any;
    targetX = 0;
    targetY = 0;
    scrollWidth = 0;
    scrollHeight = 0;
    calendarEl: any;
    layoutCalendarEl: any;
    controllerDown = new AbortController();
    controllerMove = new AbortController();
    isFetching = false;
    isMouseDown = false;
    opInfoEl: any;
    pageEl: any;

    constructor(props: IPropsWrapperOp) {
        super(props);
        const {activity_log, operation, vessel} = this.props.data;
        const key = createKeyActivityLog(operation, vessel);
        const {opFocusId} = this.props;

        this.pageEl = getElById('container-plan');

        this.state = {
            ...this.state,
            key,
            currentKey: key,
            activityLogs: {[key]: activity_log},
            isTimeline: this.props.data.operation.id === opFocusId
        };
    }

    componentDidMount() {
        this.calendarEl = getElById(this.props.calendarId);
        this.layoutCalendarEl = getElById(LAYOUT_CALENDER);
        const {operation_type, id} = this.props.data.operation;
        if (operation_type === OPERATION_TYPE.HARVEST && this.rootRef.current) {
            this.rootRef.current.addEventListener('mouseover', this.handleMouseOver);
            this.rootRef.current.addEventListener('mouseleave', this.handleMouseLeave);
        }

        if (this.props.opFocusId === id && this.pageEl)
            this.pageEl.addEventListener("mousedown", this.handleClickOutside);

        setTimeout(() => this.setState({minHeight: getHeightOfOp({element: this.rootRef.current})}), 100);
    }

    componentWillUnmount() {
        this.isFetching = false;
        this.controllerDown.abort();
        this.controllerMove.abort();
        window.clearInterval(this.intervalMove);
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
        if (this.pageEl)
            this.pageEl.removeEventListener("mousedown", this.handleClickOutside);

        if (this.layoutCalendarEl)
            this.layoutCalendarEl.dataset.isDrag = '';
    }

    shouldComponentUpdate(nextProps: Readonly<IPropsWrapperOp>, nextState: Readonly<IState>, nextContext: any): boolean {
        const {activity_log, operation} = nextProps.data

        if (JSON.stringify(this.props.data.activity_log) !== JSON.stringify(activity_log)) {
            const key = createKeyActivityLog(operation, nextProps.data.vessel);
            const {activityLogs} = this.state;
            this.setState({left: undefined, key, activityLogs: {...activityLogs, [key]: activity_log}});
            this.calendarEl.dataset.modeDrag = '';
            this.layoutCalendarEl.dataset.isDrag = '';
        }

        if (JSON.stringify(this.props.data.operation) !== JSON.stringify(operation)
            || this.props.data.status !== nextProps.data.status
            || this.props.index !== nextProps.index
            || this.props.startPoint !== nextProps.startPoint
            || this.props.width !== nextProps.width
            || JSON.stringify(this.props.level) !== JSON.stringify(nextProps.level)) {
            setTimeout(() => this.setState({minHeight: getHeightOfOp({element: this.rootRef.current})}), 100);
            return true
        }

        return JSON.stringify(this.state) !== JSON.stringify(nextState);
    }

    resetState = (params: { isTimeline?: boolean, modeDrag?: string } = {}) => {
        const {isTimeline, modeDrag = ''} = params
        const {data} = this.props;
        const {activityLogs} = this.state;
        const key = createKeyActivityLog(data.operation, data.vessel);
        const state: any = {
            modeDrag,
            left: undefined,
            key,
            currentKey: key,
            activityLogs: {...activityLogs, [key]: data.activity_log},
        }
        if (isTimeline !== undefined)
            state.isTimeline = isTimeline;
        this.setState(state);
    }

    openOperationPopup = () => {
        const {data, isOwn} = this.props;
        const {activityLogRoot} = data;
        this.opInfoEl = openPopup(<OpInfo  {...{
            isOwn,
            data,
            activityLogRoot,
        }}/>);
    }

    handleMouseOver = () => {
        const {isOwn} = this.props;
        const {isDrag = false} = this.layoutCalendarEl.dataset || {};
        if (!isDrag || this.isMouseDown || !isOwn)
            return;

        addClassName(this.rootRef.current, styles['merge-hover']);
    }

    handleMouseLeave = () => {
        const {isOwn} = this.props;
        const {isDrag = false} = this.layoutCalendarEl.dataset || {}
        if (!isDrag || this.isMouseDown || !isOwn)
            return;

        removeClassName(this.rootRef.current, styles['merge-hover']);
    }

    handleClickOutside = (e: any) => {
        if (e.target.closest(`.${stylesTime.timeline}`))
            return;

        if (this.rootRef && !this.rootRef.current.contains(e.target)
            && ((this.opInfoEl && !this.opInfoEl.contains(e.target)) || !this.opInfoEl)
            && !e.target.closest('.ant-dropdown')) {

            if (this.opInfoEl) {
                setTimeout(() => this.opInfoEl.remove(), 200);
            }

            this.setState({isTimeline: false});
            const {opFocusId} = this.props;
            if (opFocusId === this.props.data.operation.id)
                this.props.setFocusId('');

            document.removeEventListener("mousedown", this.handleClickOutside);
        }
    }

    handleMouseDown = (e: any) => {
        const {data, isOwn, edit, setTarget} = this.props;
        const loading = getElById(data.operation.id + '-loading')?.dataset.loading === 'true';
        if (loading) {
            this.isFetching = true;
        }

        if (edit && setTarget) {
            setTarget({type: EL_RULE.OPERATION, data, isOwn, edit}, e);
        }

        if (e.button === 2) {
            return
        }

        e.preventDefault();
        e.stopPropagation();

        this.isMouseDown = true;
        if (this.pageEl)
            this.pageEl.addEventListener("mousedown", this.handleClickOutside);

        window.clearInterval(this.intervalMove);
        this.controllerDown.abort();
        this.controllerMove.abort();

        this.vesselWidth = getWidthByClassName(stylesVessel.vessel);
        const {status} = this.props.data.operation;
        this.calendarEl.dataset.modeDrag = this.props.operationType;
        this.layoutCalendarEl.dataset.isDrag = true;
        document.addEventListener('mouseup', this.handleMouseUp);
        const isStatusValid = checkStatus(status)


        if (isStatusValid && this.props.isOwn && !loading) {
            document.addEventListener('mousemove', this.handleMouseMove);

            const {pageX, pageY} = e;
            const {offsetTop} = this.rootRef.current;
            const {left, top} = this.rootRef.current.getBoundingClientRect();
            this.targetX = pageX - left;
            this.targetY = offsetTop + (pageY - top);

            this.scrollWidth = this.calendarEl.scrollWidth;
            this.scrollHeight = this.calendarEl.scrollHeight;

            const {vessels} = this.props;
            this.vessels = vessels.filter(({isShow}) => isShow);
        }

        document.body.setAttribute('data-is-prevent-select', 'true');

        this.setState({modeDrag: 'down', isTimeline: true});
    }

    handleMouseMove = (e: any) => {
        window.clearInterval(this.intervalMove);
        const {offsetX, offsetY, layerX, layerY, pageX, pageY, target} = e;
        const {id = null, className = ''} = target || {};
        const isOperation = className.indexOf(stylesVessel.operation) !== -1;

        if (isOperation && id !== this.props.data.operation.id) {
            this.rootRef.current.style.display = 'none'
            const {isTimeline, modeDrag} = this.state;
            if (isTimeline || modeDrag !== 'move')
                this.setState({isTimeline: false, modeDrag: 'move'});
            return;
        }

        const state = {isTimeline: true, modeDrag: 'move'};
        this.rootRef.current.style.display = 'block';
        if (id !== 'layout-calendar') {
            this.resetState({isTimeline: true, modeDrag: 'move'});
            return;
        }

        const x = offsetX || layerX;
        const y = offsetY || layerY;
        const {operation} = this.props.data;
        const {offsetWidth} = this.rootRef.current;
        const maxWidth = this.scrollWidth - offsetWidth;

        const {newStartTime, key, currentKey, vessel, isAction} = getNewTime({target: this, x, y});

        const {activityLogs}: any = this.state;
        if (newStartTime === activityLogs[key][0].est_start_time) {
            this.setState(state);
            return;
        }

        if (isAction) {
            this.controllerMove = new AbortController();
            const {data} = this.props;
            const {id: opId} = data.operation;
            this.isFetching = true;
            this.props.getActivityLog({
                source: [{...data, vessel}],
                properties: {[id]: {start: Date.now()}},
                signal: this.controllerMove,
                success: (ops) => {
                    this.isFetching = false;
                    const {key, activityLogs}: any = this.state;
                    this.setState(prevState => ({
                        key: prevState.currentKey === currentKey ? currentKey : prevState.key,
                        activityLogs: {
                            ...activityLogs,
                            [currentKey]: updateTime(ops[opId].activity_log, 0, activityLogs[key][0].est_start_time)
                        }
                    }))
                },
                failure: () => {
                    this.isFetching = false;
                    if (this.state.modeDrag === 'move')
                        this.forceUpdate();
                }
            })
        }

        this.intervalMove = handleScroll({
            id: operation.id,
            target: this.rootRef.current,
            pageX,
            pageY,
            maxWidth,
            key,
            activityLogs,
            calendarId: this.props.calendarId,
            action: (activityLogs: any) => this.setState({activityLogs}),
            clearInterval: () => window.clearInterval(this.intervalMove)
        });

        const targetLeft = checkLimit(10, maxWidth, x - this.targetX);

        this.setState({
            ...state,
            left: targetLeft,
            key,
            currentKey,
            activityLogs: {...activityLogs, [key]: updateTime(activityLogs[key], 0, newStartTime)}
        });
    }

    handleMouseUp = (e: any) => {
        this.isMouseDown = false;
        this.calendarEl.dataset.modeDrag = '';
        this.layoutCalendarEl.dataset.isDrag = '';
        document.body.setAttribute('data-is-prevent-select', 'false');
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);

        const {modeDrag} = this.state;

        if (modeDrag !== 'move') {
            this.rootRef.current.style.display = 'block';
            this.openOperationPopup();
        } else {
            const {data} = this.props;
            const {operation} = data;
            const {activityLogs}: any = this.state;
            if (this.intervalMove)
                window.clearInterval(this.intervalMove);

            if (this.isFetching)
                this.controllerMove.abort();

            this.isFetching = false;
            const {offsetX, offsetY, layerX, layerY, pageX, pageY, target} = e;
            const {id = null, className = ''} = target || {}
            const isOperation = className.indexOf(stylesVessel['operation']) !== -1;
            if (isOperation) {
                this.handleMerge(e);
                return;
            } else if (id !== 'layout-calendar') {
                this.resetState({isTimeline: true});
                return;
            }

            const x = offsetX || layerX;
            const y = offsetY || layerY;

            const {newStartTime, currentKey, vessel, isAction, error} = getNewTime({target: this, x, y});
            if (error.length > 0) {
                if (error !== '-')
                    notification.error({message: 'Error', description: error});
                this.resetState();
                return;
            }

            let available_time_id: any = '-', contract_id: any = '-';
            if (!vessel.isOwn) {
                available_time_id = operation.available_time_id;
                contract_id = operation.contract_id;
            }
            this.rootRef.current.style.display = 'none';
            let location: any = document.elementFromPoint(pageX, pageY);
            if (location) {
                const {rule, availableTimeId, contractId} = location.dataset;
                if (rule === EL_RULE.AVAILABLE_TIME)
                    available_time_id = availableTimeId
                else if (rule === EL_RULE.CONTRACT)
                    contract_id = contractId;
            }
            this.rootRef.current.style.display = 'block';

            const valueNew = {
                ...data,
                operation: {...operation, contract_id, available_time_id, vessel_id: vessel.id},
                vessel
            }
            const {id: opId} = valueNew.operation;
            if (isAction) {
                this.isFetching = true;
                this.props.getActivityLog({
                    source: [valueNew],
                    properties: {[opId]: {start: Date.now()}},
                    success: (ops) => {
                        this.isFetching = false;
                        const key = createKeyActivityLog(ops[opId].operation, vessel);
                        const activityLogNew = updateTime(ops[opId].activity_log, 0, newStartTime);
                        const op = {
                            ...valueNew,
                            activity_log: activityLogNew,
                            activityLogRoot: {key, activity_log: activityLogNew},
                            vessel,
                        };
                        this.setState({left: undefined, modeDrag: ''}, () => this.updateOpEffect(op));
                    },
                    failure: () => {
                        this.isFetching = false;
                        if (this.state.modeDrag === 'move')
                            this.forceUpdate();
                    }
                })
            } else {
                const activity_log = updateTime(activityLogs[currentKey], 0, newStartTime);
                const op = {...valueNew, activity_log, vessel};
                this.setState({left: undefined, modeDrag: ''}, () => this.updateOpEffect(op));
            }
        }
    }

    handleMerge = (e: any) => {
        const {target} = e;
        const {id} = target;
        const {data} = this.props;
        removeClassName(target, styles['merge-hover']);
        const cancel = () => {
            this.resetState({isTimeline: true});
        }

        if (id === data.operation.id) {
            cancel();
            return;
        }

        const modalMerge = openPopup(<PopupMerge {...{
            idTarget: id,
            opDrag: data,
            onOk: () => {
                if (this.rootRef.current)
                    this.rootRef.current.style.display = 'block';
                this.setState({isTimeline: true})
            },
            onClose: () => {
                modalMerge.remove();
                if (this.rootRef.current)
                    this.rootRef.current.style.display = 'block';
                cancel();
            }
        }}/>)
    }

    updateOpEffect = (new_operation: any) => {
        this.props.dragOperation(new_operation);
    }

    render() {
        const {isOwn, startPoint, width, index, data, level, operationType, operation_mode, status} = this.props;

        const {operation, status: validationStatus = VALIDATION_STATUS.VALID, message} = data;
        const {id, parallel} = operation;
        const {modeDrag, left, key, activityLogs, isTimeline, minHeight}: any = this.state;

        const activityLog = activityLogs[key];
        const {start, finish} = getStartFinishByOpMode[operation_mode](activityLog, status);

        const height = (finish - start) / 86400000 * HEIGHT_PER_DAY;
        const top = `${(start - startPoint) / 86400000 * HEIGHT_PER_DAY}px`;
        const adjust = level.ids.length > 0 ? calcWidth[`${level.main}`](level, width, index) : {};
        const style = {
            '--min-height': minHeight + 'px',
            maxHeight: height + 'px',
            top,
            width: `calc(${width}% - 20px)`,
            left: `calc(${width * index}% + 10px)`,
            ...adjust
        };

        if (left)
            style.left = left + 'px'

        const isUnavailable = operationType === OPERATION_TYPE.UNAVAILABLE
        const isMin = height <= minHeight;
        const isHover = isMin && modeDrag !== 'move' && !isUnavailable;
        const isHide = height < 28;

        const styleTop = {
            height: isHide ? `calc((100% - 20px) / 2)` : '7px'
        }

        const props: any = {
            id,
            style,
            ref: this.rootRef,
            className: styles.operation,
            'data-hover': isHover,
            'data-status': status,
            'data-mini': isHide,
            'data-rule': EL_RULE.OPERATION,
            'data-is-own': isOwn,
            'data-operation-id': id,
            'data-editing': !!data.action_type,
            'data-operation-type': operationType,
            'data-mode-drag': modeDrag,
            'data-operation-status': validationStatus,
            'data-parallel': parallel
        };

        if (!isUnavailable) {
            props.onMouseDown = this.handleMouseDown
        }

        const propsTooltip: any = {
            title: message,
            getPopupContainer: () => getElById(id),
        }

        if (modeDrag.length > 0)
            propsTooltip.open = false;

        return <>
            <div {...props}>
                <div className={styles.banner} style={styleTop}>
                    {!isUnavailable && <div className={styles.contentBanner}>
                        <Logo
                            type={operation.operation_type}
                            status={status}
                            dataAttribute={{'data-event-type': operation.event_type}}
                        />
                        <Id {...{visible: true, operation, status}}/>
                        <OperationStatus {...{status}}/>
                    </div>}
                </div>
                {modeDrag !== 'move' && <Tooltip {...propsTooltip}>
                    <div className={styles.topLayer} data-operation-id={id}>
                        <Loading id={id + '-loading'} loading={this.isFetching}/>
                    </div>
                </Tooltip>}
                {this.props.children}
            </div>

            {isTimeline && <TimeLine {...{
                left,
                startPoint,
                isOwn,
                width,
                index,
                data,
                adjust,
                calendarId: this.props.calendarId,
                activityLog,
                updateMode: (modeDrag) => this.setState({modeDrag}),
                updateActivityLog: (data) => this.setState({activityLogs: {...activityLogs, [key]: data}})
            }} />}
        </>;
    }
}

export default connect(mapStateToProps, {
    setFocusId: planOpActions.setFocusId,
    getActivityLog: planOpActions.getActivityLog
})(WrapperOperation);
