import React from 'react';
import {durationTxtByStartAndFinish, getStatusOfOp} from "../../../varibles/global";
import styles from "./style.module.scss";

interface IProps {
    data: any
    currentTime: number
}

const Vessel: React.FC<IProps> = ({data, currentTime}) => {
    const {capacity, name, gps, operation} = data;
    let statusTxt = 'Idle'
    if (operation) {
        const {current_process, activity_log} = operation
        statusTxt = getStatusOfOp({
            operation: operation.operation,
            current_process,
            activity_log,
        });
    }
    const {Speed, Heading, Latitude, Longitude, time} = gps;
    const duration = durationTxtByStartAndFinish(time, currentTime, true);
    return <div className={styles['vessel-info']}>
        <div className={styles.header}><b>{name}</b> - {statusTxt}</div>
        <div className={styles['speed-cargo']}>
            <div>
                <p>Speed / Heading</p>
                <b>{Math.round(Speed * 100) / 100} knots / {Math.round(Heading)}&#xb0;</b>
            </div>
            <div>
                <p>Cargo</p>
                <b>0 / {Math.round(capacity / 10000) / 100} t</b>
            </div>
        </div>
        <div className={styles['line-info']}>
            <p>Position:
                <b className="ml-1" title="Latitude">{Number(Latitude).toFixed(6)}</b>,
                <b className="ml-1" title="Longitude">{Number(Longitude).toFixed(6)}</b>
            </p>
        </div>
        <div className={styles['line-comment']}>
            {duration.length > 0 ? `Position received ${duration} ago` : 'Position received just now'}
        </div>
    </div>;
};

export default Vessel;