import React, {Component} from 'react';
import stylesContainer from "../style.module.scss";
import List from "./List";
import ListHeader from "./List/ListHeader";
import styles from "./style.module.scss";
import {Checkbox} from "antd";
import {getCheckAll} from "../constants";
import Item from "./List/Item";
import {FULL_DATE_FORMAT, OPERATION_TYPE, SITE_TYPE, YEAR_WEEK_FORMAT} from "../../../../util/varibles/constants";
import {IStateGlobal} from "../../../../util/varibles/interface";
import {connect} from "react-redux";
import {PlanSetting} from "../../../../util/varibles/userSetting";
import {checkLimit} from "../../../../util/varibles/global";
import {onFilter} from "./constants";
import GroupByUnit from "./GroupByUnit";
import GroupByWeek from "./GroupByWeek";
import {datetime} from "../../../../util/library/datetime";
import {IFishChanges} from "../../constants";

export enum EL_STHP {
    PLAN,
    UNIT,
    WEEK
}

const renderEl = {
    [EL_STHP.PLAN]: {
        getKey: (harvest: any) => [harvest.id, harvest.harvest_id].join('|'),
        convertData: (harvest: any, args?: any) => {
            const {fish_amount, total_weight, count, keyCount, isCheck} = args;
            return {
                ...harvest,
                site_type: SITE_TYPE.INTERNAL,
                fish_amount,
                total_weight,
                count: count[keyCount],
                isCheck
            }
        },
        render: (args: any) => {
            const {key, item, props, onCheck, getCount, getSelected} = args;
            return <Item key={key} {...{
                data: {...item, site_type: SITE_TYPE.INTERNAL},
                className: styles['planned-item'],
                onCheck: (checked: boolean) => onCheck(checked, {[key]: item}),
                getCount,
                getSelected,
                onDragStart: (e: any, units: any) => props.onDragStart(e, units, [OPERATION_TYPE.HARVEST]),
                onDragEnd: props.onDragEnd,
            }}/>
        }
    },
    [EL_STHP.UNIT]: {
        getKey: (harvest: any) => [harvest.site_id, harvest.unit_id].join('|'),
        convertData: (harvest: any, args?: any) => {
            const {list, key, fish_amount, total_weight, isCheck, isValid} = args;
            const {site_id, site_name, unit_id, id} = harvest;
            const data = list[key];

            if (data) {
                if (isValid) {
                    data.checkAll = data.checkAll === null ? isCheck : (data.checkAll && isCheck);
                    data.indeterminate = data.indeterminate || (!data.checkAll && isCheck);
                }

                data.isActive = data.isActive || isValid;
                data.fish_amount += fish_amount;
                data.total_weight += total_weight;
                return data;
            } else {
                return {
                    isActive: isValid,
                    site_id,
                    site_name,
                    site_type: SITE_TYPE.INTERNAL,
                    id,
                    unit_id,
                    fish_amount,
                    total_weight,
                    diseases: harvest.diseases,
                    checkAll: isValid ? isCheck : null,
                    indeterminate: false,
                    child: {}
                }
            }
        },
        render: (args: any) => {
            const {key, item, props, children, onCheck} = args;
            return <GroupByUnit key={key} {...{
                item,
                onCheck,
                onDragStart: (e: any, units: any) => props.onDragStart(e, units, [OPERATION_TYPE.HARVEST]),
                onDragEnd: props.onDragEnd,
            }}>
                {children}
            </GroupByUnit>
        }
    },
    [EL_STHP.WEEK]: {
        getKey: (harvest: any) => datetime(harvest.packing_date.replace(' ', 'T')).format(YEAR_WEEK_FORMAT),
        convertData: (harvest: any, args?: any) => {
            const {list, key, fish_amount, total_weight, isCheck, isValid} = args;
            const data = list[key];

            if (data) {
                if (isValid) {
                    data.checkAll = data.checkAll === null ? isCheck : (data.checkAll && isCheck);
                    data.indeterminate = data.indeterminate || (!data.checkAll && isCheck);
                }

                data.isActive = data.isActive || isValid;
                data.fish_amount += fish_amount;
                data.total_weight += total_weight;
                return data;
            } else {
                return {
                    key,
                    isActive: isValid,
                    weekNo: datetime(harvest.packing_date.replace(' ', 'T')).week,
                    fish_amount,
                    total_weight,
                    checkAll: isValid ? isCheck : null,
                    indeterminate: false,
                    child: {}
                }
            }
        },
        render: (args: any) => {
            const {key, item, children, onCheck} = args;
            return <GroupByWeek key={key} {...{item, onCheck}}>
                {children}
            </GroupByWeek>
        }
    },
}

const mapStateToProps = (state: IStateGlobal) => {
    const {
        [PlanSetting.GROUP_BY_WEEK]: groupByWeek,
        [PlanSetting.GROUP_BY_UNIT]: groupByUnit,
        [PlanSetting.SHOW_STHP_EMPTY]: showSTHPEmpty,
        [PlanSetting.STHP_PRIOR_WEEKS]: sthpPriorWeeks = true,
        plannedSiteFilter = [],
        plannedFactoryFilter = [],
        plannedProdAreaFilter = [],
    } = state.login.user.setting || {};


    return {
        fishChanges: state.planOperation.fishChanges,
        harvests: state.planOperation.harvests,
        groupByWeek,
        groupByUnit,
        setting: {
            showSTHPEmpty,
            sthpPriorWeeks,
            plannedSiteFilter,
            plannedFactoryFilter,
            plannedProdAreaFilter,
        }
    }
};

interface IState {
    harvests: any[]
    isExpand: boolean
    checkAll: boolean
    indeterminate: boolean
    searchValue: string
    weightFilter: [number | null, number | null],
}

export const initState: IState = {
    harvests: [],
    isExpand: false,
    checkAll: false,
    indeterminate: false,
    searchValue: '',
    weightFilter: [null, null]
}

export interface IProps {
    harvests: any[]
    loading: boolean
    fishChanges: IFishChanges
    colPerRow: number
    groupByWeek: boolean,
    groupByUnit: boolean,
    setting: {
        showSTHPEmpty: boolean
        sthpPriorWeeks: boolean
        plannedSiteFilter: any
        plannedFactoryFilter: any
        plannedProdAreaFilter: any
    }

    clear(ref: any): void

    search(ref: any): void

    getSelected(ref: any): void

    onDragStart(e: any, units: any, requestType: OPERATION_TYPE[]): void

    onDragEnd(e: any): void
}

class Body extends Component<IProps, IState> {
    selected: any = {};
    state = initState;

    componentDidMount() {
        this.props.clear(this.clear)
        this.props.search(this.handleSearch)
        this.props.getSelected(() => this.selected)
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<{}>): boolean {
        const {harvests, setting, fishChanges} = nextProps;
        if (JSON.stringify(this.props.harvests) !== JSON.stringify(harvests)
            || JSON.stringify(this.props.setting) !== JSON.stringify(setting)
            || JSON.stringify(this.props.fishChanges) !== JSON.stringify(fishChanges)) {
            const harvestsNew = onFilter(nextState, nextProps);
            this.setState({harvests: harvestsNew, ...getCheckAll(harvestsNew, this.selected)})
            return false;
        }
        return true;
    }

    handleCheck = (isCheck: boolean, data: any, isGroup = false) => {
        if (isCheck) {
            const {harvests} = this.state;
            this.selected = isGroup ? {...data} : {...this.selected, ...data};
            this.setState({...getCheckAll(harvests, this.selected)})
        } else {
            Object.keys(data).forEach(key => delete this.selected[key])
            if (Object.keys(this.selected).length === 0)
                this.setState({checkAll: false, indeterminate: false})
            else
                this.setState({indeterminate: true})
        }
    }

    handleSearch = (value: any) => {
        const harvests = onFilter({...this.state, ...value}, this.props)
        this.setState({...value, harvests, ...getCheckAll(harvests, this.selected)});
    }

    handleChangeCheckAll = (e: any) => {
        const {checked} = e.target;
        if (checked) {
            const {harvests} = this.state;
            this.selected = harvests.reduce((list: any, item: any) => {
                const {id, harvest_id, fish_amount, isRoute} = item;
                if (fish_amount <= 0 || !isRoute)
                    return list;
                list[id + '|' + harvest_id] = item;
                return list;
            }, this.selected);
        } else
            this.selected = {};
        this.setState({checkAll: checked, indeterminate: false})
    }

    clear = () => {
        this.selected = {};
        this.setState({indeterminate: false})
    }

    renderContent = () => {
        const {harvests} = this.state;
        const {groupByUnit, groupByWeek} = this.props;
        const count: any = {};

        if (!groupByUnit && !groupByWeek)
            return harvests.map((item: any) => {
                const fish_amount = checkLimit(0, undefined, item.fish_amount);
                const keyCount = [datetime(item.packing_date).format(FULL_DATE_FORMAT), item.factory_id].join('|');
                const {[keyCount]: index = 0} = count;
                count[keyCount] = index + 1;
                const common = {
                    fish_amount,
                    total_weight: item.avg_weight * fish_amount,
                    isCheck: !!this.selected[item.id + '|' + item.harvest_id],
                    isValid: fish_amount > 0 && item.isRoute,
                    keyCount,
                    count
                }
                return renderEl[EL_STHP.PLAN].render({
                    key: renderEl[EL_STHP.PLAN].getKey(item),
                    item: renderEl[EL_STHP.PLAN].convertData(item, common),
                    props: this.props,
                    getCount: () => count,
                    getSelected: () => this.selected,
                    onCheck: this.handleCheck
                })
            })

        let action: EL_STHP[] = [EL_STHP.PLAN];
        if (groupByUnit)
            action = [EL_STHP.UNIT, ...action]

        if (groupByWeek)
            action = [EL_STHP.WEEK, ...action]

        const result = harvests.reduce((rs: any, item: any) => {
            const fish_amount = checkLimit(0, undefined, item.fish_amount);
            const keyCount = [datetime(item.packing_date).format(FULL_DATE_FORMAT), item.factory_id].join('|');
            const {[keyCount]: index = 0} = count;
            count[keyCount] = index + 1;
            const common = {
                fish_amount,
                total_weight: item.avg_weight * fish_amount,
                isCheck: !!this.selected[item.id + '|' + item.harvest_id],
                isValid: fish_amount > 0 && item.isRoute,
                keyCount,
                count
            }
            let list = rs;
            action.forEach(sub => {
                const key = renderEl[sub].getKey(item)
                list[key] = renderEl[sub].convertData(item, {list, key, ...common});
                list = list[key].child;
            })

            return rs;
        }, {});

        const view = (source: any = {}, action: EL_STHP[], index = 0) =>
            Object.keys(source).reduce((rs: any, key) => {
                const item = source[key];
                const args: any = {
                    key,
                    item,
                    props: this.props,
                    children: view(item.child, action, index + 1),
                    getSelected: () => this.selected,
                    getCount: () => count,
                    onCheck: this.handleCheck,
                }
                return [...rs, renderEl[action[index]].render(args)];
            }, []);

        return view(result, action);
    }

    render() {
        const {checkAll, indeterminate, harvests} = this.state;
        const {loading, colPerRow} = this.props;

        return <div className={stylesContainer['tab-body']}>
            <List {...{className: styles.body, colPerRow, loading, list: harvests}}>
                <ListHeader  {...{
                    colPerRow,
                    columns: [
                        {title: 'Id', className: 'text-truncate pr-2'},
                        {title: 'Harvest Date', className: 'text-truncate text-center'},
                        {title: 'Factory Date', className: 'text-truncate text-center'},
                        {title: 'Site, Unit', className: 'text-truncate pl-2'},
                        {title: 'Factory', className: 'text-truncate pl-2'},
                        {title: 'Count', className: 'text-truncate pr-2 text-right'},
                        {title: 'Avg Weight', className: 'text-truncate pr-2 text-right'},
                        {title: 'Biomass', className: 'text-truncate text-right'},
                    ],
                    titleClassName: styles['title-plan']
                }}>
                    <Checkbox {...{
                        checked: checkAll,
                        indeterminate,
                        disabled: loading,
                        onChange: this.handleChangeCheckAll
                    }}/>
                </ListHeader>
                {this.renderContent()}
            </List>
        </div>;
    }
}

export default connect(mapStateToProps, {})(Body);
