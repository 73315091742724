import {initialState} from "./constants";
import {MAP_ELEMENT} from "../../util/library/googlemap/constants";
import {createSlice} from "@reduxjs/toolkit";
import {generateSeaWays} from "./Control/constants";

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        getDataRequest: (state) => {
            state.loadingOp = true
        },
        getDataSuccess: (state, action) => {
            state.operations = action.payload;
            state.loadingOp = false
        },
        getDataFailure: (state) => {
            state.loadingOp = false
        },
        getSitesRequest: (state) => {
        },
        getSitesSuccess: (state, action) => {
            state.sites = action.payload
        },
        getSitesFailure: (state) => {
        },
        getFactoriesRequest: (state) => {
        },
        getFactoriesSuccess: (state, action) => {
            state.factories = action.payload;
        },
        getFactoriesFailure: (state) => {
        },
        updatePositionVessel: (state, action) => {
            const {vessels = [], target = {}, map} = state;
            const {vessel_id, ...args} = action.payload;

            let gps: any = {}
            state.vessels = vessels.map((vessel: any) => {
                if (vessel.id !== vessel_id)
                    return vessel;
                gps = {...vessel.gps, ...args};
                return {...vessel, gps}
            })

            const {Latitude, Longitude} = gps;
            const position = new google.maps.LatLng(Latitude, Longitude);
            map?.updateMarkerPosition(MAP_ELEMENT.VESSEL, vessel_id, position);

            const {id = null} = target.vessel || {};
            if (id === vessel_id)
                state.target = {...target, vessel: {...target.vessel, gps}};
        },
        getVesselsRequest: (state, action) => {
            state.loadingVessels = true
        },
        getVesselsSuccess: (state, action) => {
            state.vessels = action.payload
            state.loadingVessels = false
        },
        getVesselsFailure: (state) => {
            state.loadingVessels = false
        },
        togglePopupInfo: (state, action) => {
            const {isShow, values} = action.payload.params;
            return {...state, popupInfo: {isShow, values}}
        },
        updateOperations: (state, action) => {
            const list = action.payload;
            const {operations} = state;
            state.operations = operations.map((item: any) => {
                const {id} = item.operation;
                const target = list.find((sub: any) => sub.operation.id === id);
                if (target) {
                    const {status, current_process} = target.operation;
                    return {
                        ...item,
                        current_process,
                        operation: {...item.operation, status},
                        activity_log: target.activity_log
                    }
                }
                return item
            })
        },
        getWeatherRequest: (state, action) => {
            state.weather = {}
            state.loadingWeather = true
        },
        getWeatherSuccess: (state, action) => {
            state.weather = action.payload
            state.loadingWeather = false
        },
        getWeatherFailure: (state) => {
            state.loadingWeather = false
        },
        showInfo: (state, action) => {
            const target = action.payload;
            const {targetType = null} = target;
            const {map} = state;

            if (targetType === null) {
                state.tracking = false
            }

            if (state.target.targetType === MAP_ELEMENT.POLYLINE) {
                map?.clearSeaways();
            }

            if (targetType === MAP_ELEMENT.POLYLINE) {
                const {operation_type} = target.data.operation || {};
                const seaWays = generateSeaWays[operation_type];
                if (seaWays)
                    map?.generate({type: MAP_ELEMENT.POLYLINE, seaWays: seaWays(target.data)});

                const list = target.data.markers.reduce((rs: any, item: { type: MAP_ELEMENT, id: string }) => {
                    const {type, id} = item;
                    const {element} = map?.getElement(type, id) || {};
                    if (element) {
                        const position = element.getPosition();
                        return [...rs, [position?.lng(), position?.lat()]];
                    }
                    return rs;
                }, [])
                map?.setCenter(list, 8);
            }

            state.target = target;
        },
        getProductionAreasRequest: (state) => {
            state.loadingProductionAreas = true
        },
        getProductionAreasSuccess: (state, action) => {
            state.productionAreas = action.payload
            state.loadingProductionAreas = false
        },
        getProductionAreasFailure: (state) => {
            state.loadingProductionAreas = true
        },
        updateFilter: (state, action) => {
            state.filter = {...state.filter, ...action.payload};
        },
        loadMap: (state, action) => {
            state.map = action.payload;
        },
        updateTracking: (state, action) => {
            state.tracking = action.payload
        }
    },
})

export const dashboardActions = dashboardSlice.actions

const dashboardReducer = dashboardSlice.reducer;

export default dashboardReducer;
