import React, {createRef, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as echarts from "echarts";
import {EChartsType} from "echarts";
import {selectOneSetting} from "../../util/store/selectors";
import {GlobalSetting} from "../../util/varibles/userSetting";
import {createSeries, createYAxis} from "./constants";
import {ECBasicOption} from "echarts/types/dist/shared";
import {loginActions} from "../../pages/LoginPage/reducer";
import {chartUtil} from "../../util/library/chart-util";
import styles from "./style.module.scss";
import LoadingBox from "../LoadingBox";

interface IProps {
    weather: {
        weather: any
        typical_condition: number
    }
    loading: boolean
    minInterval?: number
    height?: number
    width?: number
}

const format: { [id: string]: string } = {
    10800000: '{hh}:{mm}'
}

const WeatherChart: React.FC<IProps> = React.memo((props) => {
    const rootRef = createRef<HTMLDivElement>();
    const {
        loading,
        weather = {
            weather: {},
            typical_condition: 0
        },
        minInterval = 86400000,
        height = 350
    } = props;
    const [chartOpt, setChartOpt] = useState<EChartsType>();
    const [isEmpty, setIsEmpty] = useState(true)
    const filter = useSelector(selectOneSetting(GlobalSetting.WEATHER));
    const dispatch = useDispatch()

    useEffect(() => {
        if (rootRef.current) {
            const el = rootRef.current;
            const existInstance = echarts.getInstanceByDom(el)
            if (existInstance) echarts.dispose(el)
            const chart = echarts.init(el);
            chart.on('legendselectchanged', ({selected}: any) => {
                dispatch(loginActions.updateUserSetting([{[GlobalSetting.WEATHER]: selected}]))
            });
            const option: ECBasicOption = {
                tooltip: chartUtil.formatTimeTooltip(),
                legend: {
                    bottom: '-5px',
                    selected: filter
                },
                grid: {
                    top: '32px',
                    right: '40px',
                    bottom: '45px',
                    left: '16px',
                    containLabel: true
                },
                xAxis: {
                    type: 'time',
                    min: 'dataMin',
                    minInterval,
                    ...chartUtil.formatTimeXAxis({formatter: format[minInterval]})
                },
                yAxis: createYAxis(filter)
            }
            chart.setOption(option, true);
            setChartOpt(chart)
        }
    }, [rootRef.current]);

    useEffect(() => {
        if (chartOpt) {
            const option: ECBasicOption = {
                yAxis: createYAxis(filter)
            }
            chartOpt.setOption(option);
        }
    }, [filter]);

    useEffect(() => {
        if (chartOpt && weather) {
            const data = weather.weather;
            const {series, yAxis} = createSeries(data);
            const option: ECBasicOption = {
                series,
                yAxis: createYAxis(filter, yAxis)
            }
            chartOpt.setOption(option);
            setIsEmpty(series.length === 0);
        }
    }, [chartOpt, weather]);

    return <div className='relative w-full'>
        <div ref={rootRef} style={{width: '100%', height: height + 'px'}}/>
        {(isEmpty || loading) && <LoadingBox
            className='h-3/4'
            imgEmpty={<div className={styles['no-weather-icon']}/>}
            loading={loading}
            level='bg'
            type='custom'
            message={<span>Sorry, system can not get weather data<br/>for this operation</span>}
        />}
    </div>
}, (prev, next) =>
    JSON.stringify(prev.weather) === JSON.stringify(next.weather)
    && prev.loading === next.loading);

export default WeatherChart;
