import React, {useCallback, useEffect} from 'react';
import TrackingOp from "../../../components/TrackingOp";
import {connect} from "react-redux";
import {IOperation} from "../../../util/varibles/interface";
import {VIEW_MODE} from "../../../util/varibles/permission";
import {MAP_ELEMENT} from "../../../util/library/googlemap/constants";
import {generateMarkers} from "../Control/constants";
import {BarentsWatchService} from "../../../util/barentswatch/api";
import {notification} from "antd";
import {convertDisease} from "../../../util/varibles/global";
import GoogleMap from "../../../util/library/googlemap/googlemap";
import {dashboardActions} from "../reducer";
import {getSetting} from "../../../util/varibles/defaultSetting";
import {PreferenceSetting} from "../../../util/varibles/userSetting";
import {IVesselOfUser} from "../../LoginPage/constants";
import {AppState} from "../../../util/store/store";

let abort = new AbortController();
let zones: any = {};
let loading = false;
let isClose = false;

const mapStateToProps = (state: AppState) => {
    const viewMode = getSetting(state.login.user.setting, PreferenceSetting.VIEW_MODE);
    return {
        vessels: state.dashboard.vessels,
        map: state.dashboard.map,
        viewMode
    }
}

interface IProps {
    viewMode: VIEW_MODE
    vessels: any
    map?: GoogleMap

    showInfo(payload: any): void
}

const WrapperTrackingOp: React.FC<IProps> = (props) => {
    const {vessels = [], map, viewMode, showInfo} = props;

    useEffect(() => {
        isClose = false;

        return () => {
            if (loading)
                abort.abort();
            showInfo({})
            loading = false;
            isClose = true;
        }
    }, [showInfo]);

    const onTracking = useCallback((data: IOperation | { operation: undefined, vessel: IVesselOfUser }) => {
        const {id: vesselId, name} = data.vessel;

        map?.clearMarkers('', [vesselId]);
        if (!data.operation) {
            showInfo({
                targetType: MAP_ELEMENT.POLYLINE,
                data: {markers: [{type: MAP_ELEMENT.VESSEL, id: vesselId}]},
            });
            return;
        }

        const vessel = vessels.find((item: any) => item.id === vesselId);

        if (vessels.length > 0 && !vessel) {
            notification.error({message: `${name} is not assigned IOT name`})
        }

        const {operation_type = '', diseases = []} = data.operation || {};
        const getMarkers = generateMarkers[operation_type];
        const markers = getMarkers ? getMarkers(data) : [];
        showInfo({
            targetType: MAP_ELEMENT.POLYLINE,
            data: {...data, markers: [...markers, {type: MAP_ELEMENT.VESSEL, id: vesselId}]},
        });

        const diseaseIds = diseases.reduce((list: any, item: any) => {
            const week = item.pk.replace('#', '/');
            (item.diseases || []).forEach((sub: any) => {
                const {type, forskNr} = sub;
                const key = [type, forskNr].join('/');
                list[key] = {...sub, week}
            })
            return list;
        }, {});

        if (Object.keys(diseaseIds).length === 0)
            return;

        if (loading)
            abort.abort();

        abort = new AbortController();
        loading = true;
        Promise.all(Object.keys(diseaseIds).map((key: any) => {
            const {week, type, forskNr} = diseaseIds[key];
            return new Promise(resolve => resolve(BarentsWatchService.getIlaControlAreaV1(type, forskNr, week, {signal: abort.signal})))
                .then((rs: any) => {
                    zones[type] = [...zones[type] || [], convertDisease(rs)]
                });
        }))
            .then(() => {
                if (isClose)
                    return;
                loading = false;
                Object.keys(zones).forEach((id: any) => map?.generate({
                    type: MAP_ELEMENT.DISEASED_ZONE,
                    id,
                    zones: zones[id],
                    event: {
                        click: (data, type) => showInfo({data, targetType: type})
                    }
                }))
            })
            .catch((err) => {
                console.log(err);
                loading = false
            })
    }, [map, vessels, showInfo]);

    useEffect(() => {
        if (viewMode !== VIEW_MODE.VESSEL) {
            if (loading)
                abort.abort();
            showInfo({});
            loading = false;
        }
    }, [viewMode, showInfo]);

    return <TrackingOp onTracking={onTracking}/>;
};

export default connect(mapStateToProps, {
    showInfo: dashboardActions.showInfo
})(WrapperTrackingOp);
