import React from 'react';
import {Select} from "antd";
import {useSelector} from "react-redux";
import {selectPlan} from "../../../../../../util/store/selectors";
import {IFactory} from "../../../../../../util/varibles/interface";

interface IProps {
    names?: { [id: string]: string }
    value: string

    onChange(value: IFactory): void
}

const {Option} = Select

const Factory: React.FC<IProps> = ({value, onChange}) => {
    const factories = useSelector(selectPlan.factories);

    return <Select
        value={value}
        variant={"borderless"}
        onSelect={(id, option: { factory: IFactory }) => onChange(option.factory)}
    >
        {factories.map(item => <Option key={item.id} value={item.id} factory={item}>
            {item.name}
        </Option>)}
    </Select>;
};

export default Factory;