import React from 'react';
import WrapperTask from "../WrapperTask";
import {Select} from "antd";
import styles from "./style.module.scss";
import {IKeyUpdate, KeyOfTask} from "../constants";
import SelectCapabilities from "../SelectEquipment";
import InputDuration from "../../../../../../../components/Basic/InputDuration";
import {convertNumberTimeToStringTime, uppercaseFirstLetter} from "../../../../../../../util/varibles/global";
import {UpdateName} from "../../constants";
import {IVessel} from '../../../../../../../util/varibles/interface';

interface IProps {
    vessel: IVessel
    data: any
    listOfTask: any
    isAllowDrag?: boolean
    parentIndex?: number

    onUpdate(key: UpdateName, args: any): void
}

const Task: React.FC<IProps> = (props) => {
    const {
        data,
        vessel,
        listOfTask,
        isAllowDrag = true,
        parentIndex,
        onUpdate
    } = props;

    const handleUpdateTask = (list: IKeyUpdate[]) => {
        let task = {};
        list.forEach(({key, value}) => {
            switch (key) {
                case KeyOfTask.ID: {
                    const {name, capabilities} = listOfTask.find((item: any) => item.id === value);
                    const {index, sub_operation} = data;
                    task = {
                        [key]: value,
                        name,
                        [KeyOfTask.CAPABILITIES]: capabilities || [],
                        sub_operation,
                        index,
                    }
                    break;
                }
                case KeyOfTask.CAPABILITIES:
                case KeyOfTask.DURATION:
                case KeyOfTask.ERROR: {
                    task = {...data, [key]: value};
                    break;
                }
                default:
                    return;
            }
        });
        onUpdate(UpdateName.UPDATE_TASK, {task});
    };

    const {[KeyOfTask.ID]: id, [KeyOfTask.CAPABILITIES]: equipments, duration, name, error = {}, index} = data;
    const {capabilities = []} = vessel || {}
    const {Option} = Select;

    const styleError = {height: '0'}
    let isError = false;
    if (Object.keys(error).length > 0) {
        styleError.height = (Object.keys(error).length * 20 + 3) + 'px';
        isError = true;
    }

    return <>
        <WrapperTask {...{
            data: {elementId: 1, ...data},
            isError,
            contentDrag: [{label: uppercaseFirstLetter(name), value: convertNumberTimeToStringTime(duration)}],
            isAllowDrag,
            index,
            parentIndex,
            onDelete: () => onUpdate(UpdateName.DELETE_TASK, {index}),
            onChangePosition: (args) => onUpdate(UpdateName.UPDATE_TASK_POSITION, args),
        }}>
            <Select
                className={styles.select}
                placeholder="Task"
                variant={"borderless"}
                value={id}
                onChange={(value: any) => handleUpdateTask([{key: KeyOfTask.ID, value}])}
                popupMatchSelectWidth={false}
            >
                {listOfTask.map(({id, name}: any) =>
                    <Option className={styles.option} key={id} value={id}>{uppercaseFirstLetter(name)}</Option>)}
            </Select>
            <SelectCapabilities
                capabilities={capabilities}
                serviceTasks={listOfTask}
                taskId={id}
                value={equipments}
                onChange={(value: any) => handleUpdateTask([{key: KeyOfTask.CAPABILITIES, value}])}
            />
            <InputDuration
                className={styles.input}
                value={duration}
                onChange={(value: any) => handleUpdateTask([{key: KeyOfTask.DURATION, value}])}
                // onError={}
            />
        </WrapperTask>
        {<div className={styles['task-error']} style={styleError}>
            {Object.keys(error).map(key => <div key={key} dangerouslySetInnerHTML={{__html: error[key]}}/>)}
        </div>}
    </>;
};

export default Task;
