import React, {Component} from 'react';
import {Tooltip} from "antd";
import styles from "./style.module.scss";
import {openNote} from "../../../../../../components/Popup/PopupOpNote";
import Icon, {ICON_NAME} from "../../../../../../components/Icon";

const propsNote = {
    align: {offset: [0, 6]},
    mouseEnterDelay: 0.03
}

interface IProps {
    value: string
    title?: string
    isTooltip: boolean
    isBubble: boolean

    onChange(value: string): void
}

class AddNote extends Component<IProps> {
    static defaultProps = {
        isTooltip: false,
        isBubble: true
    }

    handleOpenNote = (note: string = '') => {
        const {title} = this.props;
        openNote({title, note, onSave: this.props.onChange})
    }

    render() {
        const {value, isTooltip, isBubble} = this.props;
        const isNote = (value || '').trim().length > 0;
        const content = <div
            className={styles.button}
            data-is-note={isNote}
            onClick={() => this.handleOpenNote(value)}
        >
            <span>Add note</span>
            <Icon className={styles.note} icon={ICON_NAME.NOTE}/>
            {isBubble && <div className={styles.noted}/>}
        </div>;

        if (!isTooltip)
            return content

        return <Tooltip {...propsNote} title={isNote ? `Note: ${value}` : ''}>
            {content}
        </Tooltip>;
    }
}

export default AddNote;