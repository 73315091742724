import React, {Component, createRef} from 'react';
import styles from './style.module.scss';
import {checkLimit, formatNumber} from "../../../../../../../util/varibles/global";
import {Button, Input, Select} from "antd";
import {ACTION_TYPE, getParentEl} from "../../../../../../../util/varibles/constants";
import ErrorOfOperation from "../../../_Component/ErrorOfOperation";
import ReactDOM from "react-dom/client";
import {propsSelect} from "../../../../../../../util/varibles/template";
import Icon, {ICON_NAME} from "../../../../../../../components/Icon";

interface IProps {
    sites: any
    units: any
    data: any
    index: any
    isAllowDrag: boolean
    editMode: ACTION_TYPE

    handleChange: any
    handleDelete: any
    handleMerge: any
}

class Operation extends Component<IProps> {
    static defaultProps = {
        isAllowDrag: true
    };
    rootRef: any = createRef();
    btDrag: React.RefObject<HTMLDivElement> | any = React.createRef<HTMLDivElement>();
    draggingEl: HTMLDivElement | any;
    relX: number = 0;
    relY: number = 0;
    relHeight: number = 0;
    hoverEle: any;

    componentDidMount = () => {
        if (this.props.isAllowDrag)
            this.btDrag.current.addEventListener('mousedown', this.onMouseDown);
    }

    componentWillUnmount = () => {
        if (this.props.isAllowDrag)
            this.btDrag.current.removeEventListener('mousedown', this.onMouseDown);
    }

    onMouseDown = (e: React.MouseEvent | any) => {
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
        const {pageX, pageY} = e;
        const {x, y} = this.rootRef.current.getBoundingClientRect();
        this.relX = pageX - x;
        this.relY = pageY - y;
        const {offsetWidth} = this.rootRef.current;
        this.rootRef.current.style.height = 0;
        this.rootRef.current.style.opacity = 0;
        this.rootRef.current.style.visibility = 'hidden';
        this.draggingEl = document.createElement('div');
        this.draggingEl.style.position = 'fixed';
        this.draggingEl.style.display = 'block';
        this.draggingEl.style.zIndex = '1032';
        this.draggingEl.style.left = x + 'px';
        this.draggingEl.style.top = y + 'px';
        this.draggingEl.style.height = this.relHeight + 'px';
        this.draggingEl.style.width = offsetWidth + 'px';
        const root = ReactDOM.createRoot(this.draggingEl as HTMLElement);
        root.render(<Operation {...this.props} isAllowDrag={false}/>);
        const parentEl = getParentEl()
        parentEl.append(this.draggingEl);
        this.hoverEle = null;
    };

    onMouseMove = (e: React.MouseEvent | any) => {
        this.draggingEl.style.display = 'none';
        const {pageX, pageY} = e;
        let left = checkLimit(0, window.innerWidth, pageX);
        let top = checkLimit(0, window.innerHeight, pageY);
        this.draggingEl.style.left = (left - this.relX) + 'px';
        this.draggingEl.style.top = (top - this.relY) + 'px';
        this.draggingEl.style.display = 'block';
    };

    onMouseUp = (e: React.MouseEvent | any) => {
        let {pageX, pageY} = e;
        pageX = checkLimit(110, window.innerWidth, pageX);
        pageY = checkLimit(0, window.innerHeight, pageY);
        this.draggingEl.remove();
        let element: HTMLElement | any = document.elementFromPoint(pageX, pageY);
        let el = element.closest('.' + styles['operation']);
        if (el)
            this.props.handleMerge(Number(el.id), this.props.index, this.props.data);
        if (this.hoverEle)
            delete this.hoverEle.dataset.isHover;
        delete this.draggingEl;
        this.rootRef.current.style.opacity = 1;
        this.rootRef.current.style.visibility = 'visible';
        this.rootRef.current.style.height = this.relHeight + 'px';
        document.body.style.cursor = 'unset';
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
    };

    render() {
        const {Option} = Select;
        const {editMode, sites, units, index, isAllowDrag, handleChange, handleDelete} = this.props;
        const {sub_destinations, error_detail = {}, destination_id = null, source_id} = this.props.data;
        let children = sub_destinations.reduce((list: any, item: any, index: number) => {
            if (list[item.id]) {
                list[item.id].fish_amount += item.fish_amount;
                list[item.id].total_weight += item.total_weight;
                list[item.id].detail.push({...item, index});
            } else
                list[item.id] = {...item, detail: [{...item, index}]};
            return list;
        }, {});
        this.relHeight = sub_destinations.length * 40 + 4;

        children = Object.keys(children).reduce((list: any, key) => [...list, children[key]], [])

        const style = !isAllowDrag ? {opacity: 1, cursor: 'grabbing'} : {cursor: 'grab', transition: 'all .2s'};
        const error = Object.keys(error_detail).length > 0
        return <div ref={this.rootRef} className={styles['container-sub']}>
            <div {...{
                id: index,
                style: {height: this.relHeight + 'px'},
                className: styles.operation + ' no-select',
                'data-error': error,
                'data-is-dragging': !isAllowDrag
            }}>
                <div className={styles['bt-drag']} ref={this.btDrag} style={style}/>
                {children.map((unit: any, indexChild: number) => {
                    const style: any = {'--row-span': unit.detail?.length || 1}
                    return <div {...{
                        key: [unit.id, unit.destination_id].join('_'),
                        className: styles.line,
                        style,
                        'data-lev': 'sub'
                    }}>
                        <div
                            className={[styles.fullRow, 'pl-10'].join(' ')}
                            title={`${unit.unit_id}: ${formatNumber(unit.fish_amount)}`}
                        >
                            {unit.unit_id}: {formatNumber(unit.fish_amount)}
                        </div>
                        {indexChild === 0 && <div className={styles.fullRow}>
                            <Select
                                {...propsSelect}
                                className={styles.select}
                                value={destination_id}
                                onChange={(value: any) => handleChange({key: 'destination_id', index, value})}
                            >
                                {sites.reduce((list: any, item: any) => item.id !== source_id ? [...list,
                                    <Option key={item.id} value={item.id}>{item.name}</Option>] : list, [])}
                            </Select>
                        </div>}
                        {unit.detail.map((item: any, i: number) => [
                            <Select
                                {...propsSelect}
                                key={`select-${i}`}
                                className={styles.select}
                                value={item.delivery_id}
                                onChange={(value: any) => handleChange({
                                    key: 'delivery_id',
                                    index,
                                    indexDetail: item.index,
                                    value
                                })}
                            >
                                {[...units[destination_id] || []].map((item: any) =>
                                    <Option key={item.id} value={item.id}>{item.unit_id}</Option>)}
                            </Select>,
                            <Input
                                key={`input-${i}`}
                                className='text-right'
                                placeholder='Count'
                                type='number'
                                value={Number(item.fish_amount).toString()}
                                onChange={(e: any) => handleChange({
                                    key: 'fish_amount',
                                    index,
                                    indexDetail: item.index,
                                    value: e.target.value
                                })}
                            />,
                            <div className={styles.weight} key={`weight-${i}`}>
                                <Input
                                    className='text-right' placeholder='Total weight' type='number'
                                    tabIndex={0}
                                    value={Number(Math.round(item.total_weight / 1000) / 1000).toString()}
                                    onBlur={() => handleChange({
                                        key: 'convert_total_weight',
                                        index,
                                        indexDetail: item.index,
                                    })}
                                    onChange={(e: any) => handleChange({
                                        key: 'total_weight',
                                        index,
                                        indexDetail: item.index,
                                        value: e.target.value
                                    })}
                                />
                                <span>t</span>
                            </div>,
                            editMode === ACTION_TYPE.CREATE ? <Button
                                key={`action-${i}`}
                                className='bt-primary'
                                data-icon={true}
                                data-danger={true}
                                onClick={() => handleDelete({index, indexDetail: item.index})}
                            >
                                <Icon icon={ICON_NAME.DELETE}/>
                            </Button> : null
                        ])}
                    </div>
                })}
            </div>
            <ErrorOfOperation {...{
                error_detail,
                data: {source_id, destination_id},
                style: error ? {} : {display: 'none'}
            }}/>
        </div>;
    }
}

export default Operation;
