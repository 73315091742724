import React, {useState} from 'react';
import {Button, Col, Popconfirm, Row, Table} from "antd";
import {datetime} from "../../../../util/library/datetime";
import {
    CONTRACT_STATUS,
    contractPermissionType,
    propsEmpty,
    SHORT_DATETIME_FORMAT
} from "../../../../util/varibles/constants";
import styles from './style.module.scss';
import LoadingBox from "../../../../components/LoadingBox";
import {updateRequestStatusFetch} from "../../../../util/services/spot";
import {showErrorResponse} from "../../../../util/varibles/global";
import {notify, NotifyCode} from "../../../../util/varibles/message";
import {IContract} from "../../../../util/varibles/interface";

const Cancel: React.FC<{ onOk: Function, data: any }> = ({onOk, data}) => {
    const [loading, setLoading] = useState(false);
    const {spot_status, id, tenant_id,} = data;
    if (spot_status !== CONTRACT_STATUS.REQUESTING)
        return null;


    const handleOk = () => {
        setLoading(true);
        new Promise(resolve => resolve(updateRequestStatusFetch({
            contract_id: id,
            tenant_id,
            spot_status: CONTRACT_STATUS.DECLINE
        })))
            .then(() => {
                onOk()
                notify.success(NotifyCode.S8)(['Contract'])
            })
            .catch((error) => {
                showErrorResponse('Cancel failed', error);
            })
    }
    return <span className='d-flex justify-content-end'>
         <Popconfirm
             {...propsEmpty}
             title="Are you sure you want to Cancel request?"
             onConfirm={handleOk}
             okButtonProps={{style: {height: '28px'}}}
             cancelButtonProps={{style: {height: '28px'}}}
             okText="Yes"
             cancelText="No"
         >
            <Button className='bt-default' loading={loading}>Cancel</Button>
         </Popconfirm>
    </span>
}

export function renderColumnsSingleRequested(onOk: any) {
    return [
        {
            title: 'CODE',
            key: 'contract_code',
            width: '100px',
            render: (data: IContract) => {
                return <div id={data.id}>{data.contract_code}</div>
            }
        },
        {
            title: 'NAME',
            dataIndex: 'vessel_name',
            key: 'vessel_name',
            width: '170px'
        },
        {
            title: 'STATUS',
            dataIndex: 'spot_status',
            width: '120px',
            key: 'status',
            className: 'text-center',
            render: (text: string) => {
                let props: any = {
                    className: styles['contract-status'],
                }
                switch (text) {
                    case CONTRACT_STATUS.REQUESTING: {
                        props = {...props, style: {background: '#F6E9BC', color: '#665000'}}
                        return <span {...props}>Waiting</span>
                    }
                    case CONTRACT_STATUS.APPROVED: {
                        props = {...props, style: {background: '#EAF5E5', color: '#346D04'}}
                        return <span {...props}>Approved</span>
                    }
                    case CONTRACT_STATUS.DECLINE: {
                        props = {...props, style: {background: '#F15656', color: '#FFFFFF'}}
                        return <span {...props}>Decline</span>
                    }
                    default:
                        return null
                }
            }
        },
        {
            key: 'action',
            width: '245px',
            render: (record: any) => {
                return <Cancel onOk={onOk} data={record}/>
            }
        },
    ]
}

interface IProps {
    loading: boolean
    list: any

    onOk(): void
}

const Requests: React.FC<IProps> = ({loading, list, onOk}) => {

    return <div className={styles.requests}>
        {Object.keys(list).map(key => {
            const contracts = list[key]
            const {due_time, permission} = contracts[0];
            return <div key={key}>
                <div className='font-s2 font-w4 mb-5'>
                    {contractPermissionType[permission]?.name} contract
                </div>
                <Row className='mb-10'>
                    <Col span={4}>Due time:</Col>
                    <Col className='font-w5'>{datetime(due_time).format(SHORT_DATETIME_FORMAT)}</Col>
                </Row>
                <Table className={styles.vessels} rowKey='id' dataSource={contracts}
                       columns={renderColumnsSingleRequested(onOk)} pagination={false}/>
            </div>
        })}
        {Object.keys(list).length === 0 && <LoadingBox loading={loading}/>}
    </div>
};


export default Requests;
