import React, {useEffect, useState} from 'react';
import {formatNumber} from "../../../../../util/varibles/global";
import styles from "./style.module.scss";
import {Divider} from "antd";
import {IUnit} from "../../../../../util/varibles/interface";
import {useSelector} from "react-redux";
import {selectPlan} from "../../../../../util/store/selectors";
import {getKeyUnit} from "../../../util";

interface IProps {
    data: IUnit
}

const FishAndWeight: React.FC<IProps> = ({data}) => {
    const {avg_weight, fish_amount, total_weight} = data;
    const fishChanges = useSelector(selectPlan.fishChanges);
    const key = getKeyUnit(data);
    const changes = Object.keys(fishChanges[key] || {}).reduce((value: number, subKey) => value + fishChanges[key][subKey], 0);
    const [{avg, fish, total}, setData] = useState({avg: 0, fish: 0, total: 0});

    useEffect(() => {
        if (changes) {
            const newFish = fish_amount + changes
            setData({
                fish: newFish,
                avg: !!newFish ? avg_weight : 0,
                total: Math.round(newFish * avg / 1000) / 1000
            });
        } else {
            setData({
                fish: fish_amount,
                avg: !!fish_amount ? avg_weight : 0,
                total: Math.round(total_weight / 1000) / 1000
            });
        }
    }, [fish_amount, changes]);

    return <>
        <div className={styles.wrapper}>
            <span>{formatNumber(avg)} gr</span>
            <Divider type='vertical'/>
            <span>{formatNumber(fish)} fish</span>
        </div>
        <div className='font-s4'>
            {formatNumber(total)} tn
        </div>
    </>;
};

export default FishAndWeight;
