import React, {useState} from 'react';
import styles from '../style.module.scss';
import {Tooltip} from "antd";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import {getElById} from "../../../../util/varibles/global";
import {EL_DASHBOARD} from "../constants";

interface IProps {
}

const Toggle: React.FC<IProps> = () => {
    const [open, setOpen] = useState(true);

    function onClick() {
        setOpen(!open)
        const parentEl = getElById(EL_DASHBOARD.RIGHT_CONTROL);
        if (parentEl) {
            parentEl.dataset.open = !open;
        }
    }

    return <Tooltip title={open ? '' : 'Filter'} placement='topRight'>
        <div className={styles.btFilter} data-open={open} onClick={onClick}>
            {open ? <Icon icon={ICON_NAME.CLOSE}/> : <Icon icon={ICON_NAME.FILTER} size="large"/>}
        </div>
    </Tooltip>;
};

export default Toggle;
