import React, {Component} from 'react';
import styles from "./style.module.scss";
import {Input, notification, Tag, Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import {IKeyUpdate} from "../../constants";
import {KeyOfTask, patternFloat} from "../../../../../../../../util/varibles/constants";
import {formatNumber, uuid} from "../../../../../../../../util/varibles/global";
import {notify, NotifyCode} from "../../../../../../../../util/varibles/message";
import {IUnit} from "../../../../../../../../util/varibles/interface";

const initTask = (args: { sub_operation: IUnit }) => ({
    ...args,
    group_id: uuid(),
    type: null,
    sorting: true
})

interface IProps {
    data: any

    handleChange(list: IKeyUpdate[]): void
}

class ValueOfSorting extends Component<IProps> {

    state = {
        inputValue: '',
        editInputIndex: -1,
        editInputValue: '',
    }
    input: any;
    editInput: any;

    handleDelete = (index: number) => {
        const {conditions = [], sub_tasks = {}} = this.props.data;
        if (conditions.length === 1)
            this.props.handleChange([
                {key: KeyOfTask.CONDITIONS, value: []},
                {key: KeyOfTask.SUB_TASKS, value: {}},
            ]);
        else {
            const listOfKeyOld = conditions.reduce(((rs: any, item: any, i: number) => {
                const {value: currentValue, operator} = item;
                if (i === 0 && i !== index)
                    rs.push(operator[0] + currentValue);
                if (conditions[i + 1]) {
                    if (i + 1 !== index) {
                        const {value: valueNext, operator: operatorNext} = conditions[i + 1];
                        rs.push(operator[1] + currentValue + '&&' + operatorNext[0] + valueNext);
                    }
                } else
                    rs.push(operator[1] + currentValue);

                return rs;
            }), []);
            const listOfCondition = conditions.filter((item: any, i: number) => i !== index);

            this.props.handleChange([
                {key: KeyOfTask.CONDITIONS, value: listOfCondition},
                {
                    key: KeyOfTask.SUB_TASKS, value: listOfCondition.reduce((rs: any, item: any, i: number) => {
                        const {value: currentValue, operator} = item;
                        let key;
                        if (listOfCondition[i + 1]) {
                            const {value: valueNext, operator: operatorNext} = listOfCondition[i + 1];
                            key = operator[1] + currentValue + '&&' + operatorNext[0] + valueNext;
                        } else {
                            key = operator[1] + currentValue;
                        }
                        rs[key] = sub_tasks[listOfKeyOld[i + 1]]
                        return rs;
                    }, {
                        [listOfCondition[0].operator[0] + listOfCondition[0].value]: sub_tasks[listOfKeyOld[0]]
                    })
                },
            ]);
        }


    };

    handleInputChange = (e: any) => {
        const valueNew = e.target.value.trim();
        const reg = new RegExp(patternFloat);
        if (!reg.test(valueNew)) {
            notify.error(NotifyCode.E9)();
            return;
        }
        this.setState({inputValue: valueNew});
    };

    handleEditInputChange = (e: any) => {
        const valueNew = e.target.value.trim();
        const reg = new RegExp(patternFloat);
        if (!reg.test(valueNew)) {
            notify.error(NotifyCode.E9)();
            return;
        }
        this.setState({editInputValue: valueNew});
    };

    handleAdd = () => {
        const {inputValue} = this.state;
        const {conditions = []} = this.props.data;
        const {sub_operation, sub_tasks} = this.props.data;
        const valueNew = inputValue.trim();

        if (valueNew.length === 0 || valueNew === '0')
            return;

        const reg = new RegExp(patternFloat);
        if (!reg.test(valueNew)) {
            notify.error(NotifyCode.E9)();
            return;
        }

        const isEmpty = conditions.findIndex((item: any) => +item.value === +valueNew) === -1;

        if (isEmpty) {
            const conditionsNew = [...conditions, {
                value: valueNew,
                operator: ['<', '>=']
            }].sort((a: any, b: any) => a.value - b.value);
            const list = [{key: KeyOfTask.CONDITIONS, value: conditionsNew}];
            const conditionNewIndex = conditionsNew.findIndex((item: any) => +item.value === +valueNew);

            const listOfKeyOld = conditions.reduce(((rs: any, item: any, index: number) => {
                const {value: currentValue, operator} = item;
                if (index === 0) {
                    rs.push(operator[0] + currentValue);
                }
                if (conditions[index + 1]) {
                    const {value: valueNext, operator: operatorNext} = conditions[index + 1];
                    rs.push(operator[1] + currentValue + '&&' + operatorNext[0] + valueNext);
                } else {
                    rs.push(operator[1] + currentValue);
                }
                return rs;
            }), []);

            if (conditionNewIndex === 0) {
                const subTasksNew = conditionsNew.reduce((rs: any, item: any, index: number) => {
                    const {value: currentValue, operator} = item;
                    if (conditionsNew[index + 1]) {
                        const {value: valueNext, operator: operatorNext} = conditionsNew[index + 1];
                        const key = operator[1] + currentValue + '&&' + operatorNext[0] + valueNext;
                        rs[key] = sub_tasks[listOfKeyOld[index]];
                    } else {
                        const key = operator[1] + currentValue;
                        rs[key] = sub_tasks[listOfKeyOld[index]] || [
                            initTask({sub_operation})
                        ];
                    }
                    return rs;
                }, {
                    ['<' + valueNew]: [initTask({sub_operation})]
                });
                list.push({key: KeyOfTask.SUB_TASKS, value: subTasksNew})
            } else if (conditionNewIndex === conditionsNew.length - 1) {
                const subTasksNew = conditionsNew.reduce((rs: any, item: any, index: number) => {
                    if (conditionsNew[index + 1]) {
                        const {value: currentValue, operator} = item;
                        const {value: valueNext, operator: operatorNext} = conditionsNew[index + 1];
                        const key = operator[1] + currentValue + '&&' + operatorNext[0] + valueNext;
                        rs[key] = sub_tasks[listOfKeyOld[index + 1]];
                    }
                    return rs;
                }, {
                    [conditionsNew[0].operator[0] + conditionsNew[0].value]: sub_tasks[listOfKeyOld[0]],
                    ['>=' + valueNew]: [
                        initTask({sub_operation})
                    ]
                });
                list.push({key: KeyOfTask.SUB_TASKS, value: subTasksNew})
            } else {
                let indexOld = 0;
                const subTasksNew = conditionsNew.reduce((rs: any, item: any, index: number) => {
                    const {value: currentValue, operator} = item;
                    if (conditionsNew[index + 1]) {
                        const {value: valueNext, operator: operatorNext} = conditionsNew[index + 1];
                        const key = operator[1] + currentValue + '&&' + operatorNext[0] + valueNext;
                        if (indexOld !== conditionNewIndex) {
                            indexOld++;
                            rs[key] = sub_tasks[listOfKeyOld[indexOld]];
                        } else {
                            rs[key] = [initTask({sub_operation})]
                        }
                    } else {
                        const key = operator[1] + currentValue;
                        rs[key] = sub_tasks[listOfKeyOld[indexOld]];
                    }
                    return rs;
                }, {
                    [conditionsNew[0].operator[0] + conditionsNew[0].value]: sub_tasks[listOfKeyOld[0]],
                });
                list.push({key: KeyOfTask.SUB_TASKS, value: subTasksNew})
            }
            const {error} = this.props.data;
            delete error.conditions;
            list.push({key: KeyOfTask.ERROR, value: error})
            this.props.handleChange(list);
        } else {
            notification.error({
                message: 'Warn',
                description: `${valueNew}g has been added.`
            })
        }
        this.setState({inputValue: ''}, () => {
            setTimeout(() => {
                if (this.input)
                    this.input.focus()
            }, 300)
        });
    };

    handleEdit = () => {
        let {conditions = []} = this.props.data;
        const {editInputIndex, editInputValue} = this.state;
        const value = editInputValue.trim();
        if (value.length === 0)
            return;

        const isEmpty = conditions.indexOf((item: any, index: number) => +item.value === +value && index !== editInputIndex) === -1;
        if (isEmpty) {
            const {operator} = conditions[editInputIndex];
            conditions = [...conditions, {value, operator}].sort((a: any, b: any) => a.value - b.value);
            this.props.handleChange([{key: KeyOfTask.CONDITIONS, value: conditions}]);
        } else {
            notification.error({
                message: 'Warn',
                description: `${value}g has been added.`
            })
        }
        this.setState({editInputIndex: -1, editInputValue: ''});
    };


    saveInputRef = (input: any) => (this.input = input);

    saveEditInputRef = (input: any) => (this.editInput = input);

    render() {
        const {conditions = []} = this.props.data;
        const {inputValue, editInputIndex, editInputValue} = this.state;


        return <div className={styles['tags']}>
            {conditions.map((item: any, index: number) => {
                const {value} = item;

                if (editInputIndex === index) {
                    return <Input
                        ref={this.saveEditInputRef}
                        key={value}
                        size="small"
                        className={styles['tag-input']}
                        value={editInputValue}
                        onChange={this.handleEditInputChange}
                        onBlur={this.handleEdit}
                        onPressEnter={this.handleEdit}
                        suffix={inputValue ? 'g' :
                            <Tooltip title="Press enter or click outside the input area to save.">
                                <InfoCircleOutlined style={{color: 'rgba(0,0,0,.45)'}}/>
                            </Tooltip>}
                    />;
                }

                const isLongTag = value.length > 20;

                const tagElem = <Tag
                    className={styles['edit-tag']}
                    key={value}
                    closable
                    onClose={() => this.handleDelete(index)}
                >
			              <span
                              onClick={e => {
                                  this.setState({editInputIndex: index, editInputValue: value}, () => {
                                      this.editInput.focus();
                                  });
                                  e.preventDefault();
                              }}
                          >
			                {isLongTag ? `${value.slice(0, 20)}...` : (formatNumber(value) + 'g')}
			              </span>
                </Tag>;

                return isLongTag ? (
                    <Tooltip title={value} key={value}>
                        {tagElem}
                    </Tooltip>
                ) : tagElem;
            })}
            <Input
                ref={this.saveInputRef}
                type="text"
                size="small"
                className={inputValue ? [styles['tag-input'], styles['tag-input-focus']].join(' ') : styles['tag-input']}
                value={inputValue}
                onChange={this.handleInputChange}
                onBlur={this.handleAdd}
                onPressEnter={this.handleAdd}
                placeholder={inputValue ? '' : 'Set a condition '}
                suffix={inputValue ? 'g' : <Tooltip title="Press enter or click outside the input area to add.">
                    <InfoCircleOutlined style={{color: 'rgba(0,0,0,.45)'}}/>
                </Tooltip>}
            />
        </div>;
    }
}

export default ValueOfSorting;