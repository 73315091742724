import React, {useCallback, useState} from 'react';
import {OPERATION_TYPE, operationType, propsEmpty} from "../../../util/varibles/constants";
import styles from './style.module.scss';
import {updateDensitySettingFetch} from "../../../util/services/tenant";
import {notification, Popconfirm} from "antd";
import Icon, {ICON_NAME} from "../../../components/Icon";
import Conditions from "./Conditions";
import {showErrorResponse} from "../../../util/varibles/global";
import Menu from "../../../components/Menu";
import Edit from "./Edit";
import LoadingBox from "../../../components/LoadingBox";

interface IProps {
    type: OPERATION_TYPE
    density: any

    onChange(density: any): void

    onReset(type: OPERATION_TYPE): void
}

const OperationType: React.FC<IProps> = ({type, density, onChange, onReset}) => {
    const {[type]: conditions = []} = density;
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const {name = ''} = operationType[type];

    const handleDelete = useCallback(() => {
        const {[type]: old, ...newData} = density
        setLoading(true);
        new Promise(resolve => resolve(updateDensitySettingFetch({density: newData || {}})))
            .then(() => {
                setLoading(false);
                onChange(newData);
                notification.success({message: `Delete density of ${name} successfully`});
            })
            .catch(error => {
                setLoading(false);
                showErrorResponse(`Delete density of ${name} failed`, error);
            })
    }, [type, density, name, onChange]);

    const menu = [
        {
            key: 'edit',
            disabled: edit,
            label: <div className='menu-line' onClick={() => setEdit(true)}>
                <Icon icon={ICON_NAME.EDIT}/>
                <div>Edit</div>
            </div>
        }, {
            key: 'delete',
            label: <Popconfirm
                {...propsEmpty}
                title={`Are you sure delete density of ${name}?`}
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
            >
                <div className='menu-line' data-danger='true'>
                    <Icon icon={ICON_NAME.DELETE} danger/>
                    <div>Delete</div>
                </div>
            </Popconfirm>
        }]

    const handleClose = useCallback(() => {
        setEdit(false);
        onReset(type);
    }, [type, onReset]);

    const handleAdd = useCallback(() => {
        setEdit(true);
    }, []);

    const handleChange = useCallback((data: number[]) => {
        if (data.length === 0)
            onReset(type);
        else
            onChange({...density, [type]: data})
    }, [type, density, onChange, onReset]);

    return <div className={styles.opType}>
        <div className={styles.opTypeName}>
            <span>{name}</span>
            {conditions.length > 0 && <Menu items={menu} disabled={loading} placement={"bottomLeft"}>
                <div className='three-dot'/>
            </Menu>}
        </div>
        <Edit
            visible={edit}
            density={density}
            type={type}
            value={conditions}
            onChange={handleChange}
            onClose={handleClose}
        />
        {(edit || conditions.length > 0)
            ? <Conditions value={conditions}/>
            : <div className='link' onClick={handleAdd}>Define new density</div>
        }
        {loading && <LoadingBox className={styles.loading} loading={loading} level='bg'/>}
    </div>;
};

export default OperationType;
