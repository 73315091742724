import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    ACTION_TYPE,
    DELIVERY_TYPE,
    OPERATION_TYPE,
    SITE_TYPE,
    VESSEL_TYPE
} from "../../../../../../util/varibles/constants";
import styles from './style.module.scss';
import {checkRoute, getFactory} from "../../../../util";
import Units from "../../_Component/Units";
import Operations from "./Operations";
import {notify, NotifyCode} from "../../../../../../util/varibles/message";
import Footer from "../../Footer";
import {createHarvest, createHarvestExternal, createHarvests, opsAfterSubmit} from "./constants";
import {IFactory, IOperations, IRoute, IVesselConfig, TOperation} from "../../../../../../util/varibles/interface";
import {updateData, UpdateName} from "../constants";
import {planOpActions} from "../../../../reducer";
import {IUpdateOp} from "../../../../constants";
import {IGetActivityLog} from "../../../../saga";
import {AppState} from "../../../../../../util/store/store";
import {cloneObj} from "../../../../../../util/varibles/global";
import {getSetting} from "../../../../../../util/varibles/defaultSetting";
import {PreferenceSetting} from "../../../../../../util/varibles/userSetting";

const mapStateToProps = (state: AppState) => {
    const {vessel: config = {}} = getSetting(state.login.user.setting, PreferenceSetting.AUTO_PLAN);
    return {
        userTenantId: state.login.user.tenant_id,
        operations: state.planOperation.operations,
        routes: state.planOperation.routes,
        factories: state.planOperation.factories,
        config
    }
};

const initState: IState = {
    operations: [],
    store: {},
    count: 0,
    factories: [],
    isCalculate: false,
    loading: false
}

interface IProps {
    userTenantId: string
    operations: IOperations
    routes: IRoute[]
    factories: IFactory[]
    store: any
    editMode: ACTION_TYPE,
    isFull: boolean
    isInternal: boolean
    loading?: boolean
    popupOperation: any
    config: { [id: string]: IVesselConfig }

    getActivityLog(payload: IGetActivityLog): void

    onSave(payload: IUpdateOp, action?: Function): void

    onClose(): void
}

interface IState {
    operations: TOperation[],
    store: any
    count: number
    factories: IFactory[]
    isCalculate: boolean
    loading: boolean
}

class Harvest extends Component<IProps, IState> {
    state = initState;

    constructor(props: IProps) {
        super(props);
        this.state = this.generateState(this.props);
    }

    shouldComponentUpdate = (nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean => {
        if (JSON.stringify(this.props.popupOperation) !== JSON.stringify(nextProps.popupOperation)) {
            this.setState(this.generateState(nextProps))
        }

        return this.state !== nextState
    }

    generateState = (props: IProps): IState => {
        const {store, editMode, isFull, isInternal, factories, routes = []} = props;
        const {operation, vessel} = props.popupOperation.values;
        const {sub_operations} = operation;

        const factoriesFilter = sub_operations.reduce((list: IFactory[], item: any) => {
            const {factory_id, factory_name} = item;
            if (factory_id)
                return list.map(sub => sub.id === factory_id ? {...sub, name: factory_name} : sub)
            return list;
        }, factories)

        if (editMode === ACTION_TYPE.CREATE) {
            const {operation: opOld} = this.props.popupOperation.values;
            const {is_journey_to_site = false, cleaning_types = []} = props.config[vessel.type] || {};

            if (factories.length === 0) {
                notify.error(NotifyCode.E18, 'Create operation failed')();
                return initState;
            }

            const {operations, store: newStore} = createHarvests({
                data: sub_operations,
                store: cloneObj(store),
                factories,
                routes,
                vessel,
                isFull,
                isInternal,
                properties: {
                    tenant_id: this.props.userTenantId,
                    available_time_id: opOld.available_time_id,
                    contract_id: opOld.contract_id,
                    is_cleaning: new Set(cleaning_types).has(DELIVERY_TYPE.DIRECT),
                    is_journey_to_site
                }
            }) || [];
            return {
                ...initState,
                isCalculate: true,
                operations,
                store: cloneObj(newStore),
                count: operations.length,
                factories: factoriesFilter
            };
        } else {
            let error_detail: any = {};
            if (operation.total_weight > vessel.capacity)
                error_detail.total_weight = notify[NotifyCode.E1]();
            const {factory_id, sites = [], site_id, site_name, sub_operations} = operation;
            const factory: any = factories.find((item: any) => item.id === factory_id);
            const operations = cloneObj([{
                ...operation,
                sub_operations: sub_operations.map((sub: any) => {
                    const delivery_types = cloneObj(sub.delivery_types);
                    const isDefault = delivery_types.some((deliveryType: any) => deliveryType.default);
                    if (isDefault) {
                        return sub;
                    } else {
                        delivery_types[0].default = true;
                        return {...sub, delivery_types}
                    }
                }),
                error_detail,
                delivery_types: factory.deliver_type || {},
                sites: sites.length === 0 ? {id: site_id, name: site_name, isRoute: true} : sites
            }]);
            return {
                ...initState,
                operations,
                store,
                count: operations.length,
                factories: factoriesFilter
            };
        }
    }

    addOperation = (unit: any) => {
        const {
            site_id,
            site_name,
            site_type,
            id,
            primary_factory,
            harvest_id = '-',
            avg_weight,
            fish_amount,
            factory_id = ''
        } = unit;
        const {factories, routes} = this.props;

        const {operation: opOld, vessel} = this.props.popupOperation.values;
        const site: any = {id: site_id, name: site_name, type: site_type}
        const factory = getFactory(factory_id, primary_factory, factories);
        const waitingUnit = vessel.type === VESSEL_TYPE.WELL_BOAT;
        const {operations, store, count}: any = this.state;
        const {error, message} = checkRoute(site, factory, routes);
        let error_detail = {};
        if (error === 2) {
            error_detail = {route: [{source_id: site.id, destination_id: factory_id, message}]};
            site.isRoute = false;
        } else
            site.isRoute = true;
        const {deliver_type = {}} = factory;
        const keyDeliveryType: any = Object.keys(deliver_type).find((key: any) => deliver_type[key].default);
        const deliveryType = deliver_type[keyDeliveryType];
        const keyStore = id + '|' + harvest_id;
        const {isInternal} = this.props;
        const properties = {
            tenant_id: this.props.userTenantId,
            available_time_id: opOld.available_time_id,
            contract_id: opOld.contract_id
        }

        const common = {
            store,
            keyStore,
            count,
            site,
            factory,
            waitingUnit,
            deliver_type,
            deliveryType,
            error_detail,
            properties
        }
        const {operation, store: newStore} = !isInternal ? createHarvestExternal(common)
            : createHarvest({...common, fish_amount, avg_weight, vessel});

        operations.push(operation);
        this.setState({operations, store: {...newStore}, count: count + 1})
    }

    handleSubmit = () => {
        const {action} = this.props.popupOperation;

        if (this.state.isCalculate) {
            const {editMode} = this.props;
            const {activity_log, vessel} = this.props.popupOperation.values;
            const {id: opId = 0} = this.props.popupOperation.values.operation;
            const {isError, operations: dataNew} = this.checkError();
            if (isError) return;

            const {operations, properties} = opsAfterSubmit(dataNew, vessel, {
                [opId]: {
                    start: activity_log[0].est_start_time,
                    old_activity_log: activity_log
                }
            })

            this.setState({loading: true});
            this.props.getActivityLog({
                source: operations,
                properties,
                success: (ops) => {
                    this.setState({loading: false});
                    this.props.onSave(editMode === ACTION_TYPE.CREATE ? {add: ops} : {update: ops}, action);
                },
                failure: () => {
                    this.setState({loading: false});
                }
            })
        } else {
            const operation = this.state.operations[0];
            const {id = ''} = this.state.operations[0];
            this.props.onSave({update: {[id]: {...this.props.operations[id], operation}}, isCheck: false}, action);
        }
    };

    checkError = () => {
        const {operations}: any = this.state;
        const {routes} = this.props;
        let isError = false;
        const operationsAfterCheck = operations.reduce((list: any, item: any) => {
            const {factory_id, factory_name, error_detail = {}, sub_operations, sites = []} = item;

            const indexSiteHasRoute = sites.findIndex((sub: any) => checkRoute(sub, {
                id: factory_id,
                name: factory_name
            }, routes).error !== 2);
            if (indexSiteHasRoute === -1) {
                isError = true;
                error_detail.route = sites.map((item: any) => ({
                    source_id: item.id,
                    destination_id: factory_id,
                    message: notify[NotifyCode.E20]([item.name, factory_name])
                }));
            } else {
                item.sites = sites.map((sub: any, i: any) => i === indexSiteHasRoute ? {...sub, isRoute: true} : sub)
            }

            const site = sites[0];
            if (site.type === SITE_TYPE.INTERNAL) {
                const isEmpty = sub_operations.some((sub: any) => sub.fish_amount === 0 || sub.total_weight === 0)
                if (isEmpty) {
                    isError = true;
                    error_detail.fish_amount = notify[NotifyCode.E37](['Fish amount, total weight']);
                }
            } else {
                const isEmpty = sub_operations.some((sub: any) => sub.total_weight === 0)
                if (isEmpty) {
                    isError = true;
                    error_detail.fish_amount = notify[NotifyCode.E37](['Total weight']);
                }
            }
            list = [...list, {...item, error_detail}];
            return list;
        }, []);

        if (isError) {
            this.setState({operations: operationsAfterCheck})
        }
        return {isError, operations};
    }

    updateState = (state: any) => {
        this.setState({...state, isCalculate: true});
        this.forceUpdate();
    }

    handleUpdate = (key: UpdateName, args: any) => {
        const action = updateData[OPERATION_TYPE.HARVEST][key];
        if (action)
            this.setState(action({state: this.state, props: this.props, args}));
    }

    render() {
        const {operations, store, factories}: any = this.state;
        const {editMode} = this.props;
        const {vessel} = this.props.popupOperation.values
        const {routes} = this.props;

        return <>
            <div className={styles['container-body']}>
                <Units {...{
                    store,
                    editMode,
                    addOperation: this.addOperation,
                    gridTemplateColumns: '1fr 2fr 1fr 1fr 1fr 50px',
                    nameRow: (data: any) => <>
                        <span>{data.harvest_id}</span>
                        <span>{data.site_name}, {data.unit_id}</span>
                    </>,
                }} />
                <Operations {...{
                    operations,
                    store,
                    vessel,
                    factories,
                    routes,
                    editMode,
                    updateState: this.updateState,
                    onUpdate: this.handleUpdate
                }} />
            </div>
            <Footer {...{
                loading: this.state.loading || this.props.loading,
                handleSubmit: this.handleSubmit,
                onClose: this.props.onClose,
            }}/>
        </>;
    }
}

export default connect(mapStateToProps, {
    getActivityLog: planOpActions.getActivityLog
})(Harvest);
