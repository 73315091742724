import React, {useState} from 'react';
import stylesOp from "../style.module.scss";
import {KeyOfStep, OP_STATUS} from "../../../../../util/varibles/constants";
import Header from "../../../Component/Header";
import Note from "../../../Component/Note";
import StepControl from "../../../Component/StepControl";
import Icon, {ICON_NAME} from "../../../../Icon";
import {IPropsOp} from "../index";
import {formatNumber} from "../../../../../util/varibles/global";
import HarvestingInfo from "../../../Component/HarvestingInfo";
import ActivityLogs from "../../../ActivitiesLog/ActivityLogs/ActivityLogs";
import {getTotalAmountAndTotalWeight} from "../../../../../pages/PlanOperationPage/util";

interface IProps extends IPropsOp {

}

const Transport: React.FC<IProps> = ({data, step, active, actions, changeStep, extend, onUpdate}) => {
    const [isShowActivityLog, setIsShowActivityLog] = useState(false)
    const {status, source, destination, note, sub_operations} = data.operation;
    const {mame: sourceName = ''} = source || {};
    const {mame: destinationName = ''} = destination || {};
    const {total_amount, total_weight} = getTotalAmountAndTotalWeight(sub_operations);
    const {key = ''}: any = data.activity_log[step] || {};

    return <>
        <Header
            data={data}
            step={step}
            icon={<Icon icon={ICON_NAME.TRANSPORT}/>}
            extend={extend}
            active={active}
            isShowActivityLog={isShowActivityLog}
            changeShowActivityLog={() => setIsShowActivityLog(!isShowActivityLog)}
        >
            <div className={[stylesOp['operation-route'], 'd-flex'].join(' ')}>
                <div>{sourceName}</div>
                <span className={stylesOp['arrow-right']}>&#8594;</span>
                <div>{destinationName}</div>
            </div>
        </Header>
        <div className={stylesOp['operation-info']}>
            <div className={stylesOp['operation-content']}>
                <StepControl data={data} actions={actions} step={step} changeStep={changeStep} onUpdate={onUpdate}/>
                {isShowActivityLog
                    ? <div className={stylesOp.activityLog}>
                        <ActivityLogs data={data}/>
                    </div>
                    : (status === OP_STATUS.PROCESSING && <div className={stylesOp['operation-step-content']}>
                        <div>Σ amount, Σ weight: <span
                            className={stylesOp['amount-weight']}>{formatNumber(total_amount)} | {formatNumber(Math.round(total_weight / 1000) / 1000)} t</span>
                        </div>
                        {[KeyOfStep.HARVEST, KeyOfStep.DISCHARGE_FACTORY].includes(key) &&
                            <HarvestingInfo sub_operations={sub_operations}/>}
                        <Note note={note}/>
                    </div>)}
            </div>
        </div>
    </>;
};

export default Transport;