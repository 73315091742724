import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from "./style.module.scss";
import {Button} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import Task from "../index";
import stylesContainer from "../../style.module.scss";
import LineMiddle from "../../../../_Component/LineMiddle";
import {UpdateName} from "../../../constants";
import {IPropsChildren} from "../../../../../../../../util/varibles/interface";

const mapStateToProps = null;

interface IProps extends IPropsChildren{
    tasks: any
    site: any
    unit: any
    factory: any
    parentIndex: any
    listOfTask: any
    isLock: boolean

    handleAdd(): void

    handleChange(tasks: any): void

    handleChangePosition(args: any): void

    onChangeGlobal(key: UpdateName, args: any): void
}

class Condition extends Component<IProps> {
    static defaultProps = {
        tasks: []
    }

    handleChangeTask = (task: any) => {
        const {index} = task;
        const {tasks} = this.props;
        this.props.handleChange(tasks.map((item: any, i: number) => i === index ? task : item));
    }

    handleDeleteTask = (index: any) => {
        const tasks = JSON.parse(JSON.stringify(this.props.tasks));
        tasks.splice(index, 1);
        this.props.handleChange(tasks);
    }

    render() {
        const {
            site,
            unit,
            factory,
            tasks,
            parentIndex,
            listOfTask,
            isLock,
            handleAdd,
            handleChangePosition,
            onChangeGlobal
        } = this.props;

        return <div className={styles['container-sub-tasks']}>
            <div className={styles['container-condition']}>
                <div className={styles['condition']}>
                    {this.props.children}
                </div>
                <div className={styles['add-sub-task']}>
                    <Button {...{
                        shape: "circle",
                        type: 'text',
                        size: 'small',
                        className: styles['bt-add'],
                        icon: <PlusCircleOutlined style={{fontSize: '19px'}}/>,
                        onClick: handleAdd
                    }}/>
                </div>
            </div>
            <div className={styles['sub-tasks']}>
                <div className={stylesContainer['line-middle']} data-level='condition'>
                    <LineMiddle {...{containerId: 'treatment-operation', unitId: unit.id, index: 0, parentIndex}} />
                </div>
                {tasks.map((item: any, index: number) => <Task
                    key={item.group_id}
                    site={site}
                    unit={unit}
                    factory={factory}
                    data={{...item, index}}
                    parentIndex={parentIndex}
                    listOfTask={listOfTask}
                    isSorting={true}
                    isLock={isLock}
                    handleUpdateTask={this.handleChangeTask}
                    handleDeleteTask={this.handleDeleteTask}
                    handleChangePosition={handleChangePosition}
                    onChangeGlobal={onChangeGlobal}
                />)}
            </div>
        </div>;
    }
}

export default connect(mapStateToProps, {})(Condition);