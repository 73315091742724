import React, {Component, createRef} from 'react';
import styles from './style.module.scss';
import {checkLimit} from '../../../../../../../util/varibles/global';
import ErrorOfOperation from "../../../_Component/ErrorOfOperation";
import {IPropsChildren, TOperation} from "../../../../../../../util/varibles/interface";
import {openPopup} from "../../../../../../../components/Popup/Component/WrapperPopup";

export interface IProps extends IPropsChildren {
    isAllowDrag: boolean
    isAllowError: boolean

    harvest: TOperation
    indexHarvest: any

    handleMerge: any;
}

class Operation extends Component<IProps> {
    static defaultProps = {
        isAllowDrag: true,
        isAllowError: true
    };

    rootRef: any = createRef();
    btDrag: React.RefObject<HTMLDivElement> | any = React.createRef<HTMLDivElement>();
    draggingHarvest: HTMLDivElement | any;
    relX: number = 0;
    relY: number = 0;
    relHeight: number = 0;
    hoverEle: any;

    componentDidMount = () => {
        if (this.props.isAllowDrag)
            this.btDrag.current.addEventListener('mousedown', this.onMouseDown);
    }

    componentWillUnmount = () => {
        if (this.props.isAllowDrag)
            this.btDrag.current.removeEventListener('mousedown', this.onMouseDown);
    }

    onMouseDown = (e: React.MouseEvent | any) => {
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
        const {pageX, pageY} = e;
        const {x, y} = this.rootRef.current.getBoundingClientRect();
        this.relX = pageX - x;
        this.relY = pageY - y;
        const {offsetWidth} = this.rootRef.current;
        this.rootRef.current.style.height = 0;
        this.rootRef.current.style.opacity = 0;
        this.rootRef.current.style.visibility = 'hidden';
        this.draggingHarvest = openPopup(<Operation {...this.props} isAllowDrag={false} isAllowError={false}/>);
        this.draggingHarvest.style.position = 'fixed';
        this.draggingHarvest.style.display = 'block';
        this.draggingHarvest.style.zIndex = '1032';
        this.draggingHarvest.style.left = x + 'px';
        this.draggingHarvest.style.top = y + 'px';
        this.draggingHarvest.style.height = this.relHeight + 'px';
        this.draggingHarvest.style.width = offsetWidth + 'px';
        this.hoverEle = null;
    };

    onMouseMove = (e: React.MouseEvent | any) => {
        this.draggingHarvest.style.display = 'none';
        const {pageX, pageY} = e;
        let left = checkLimit(0, window.innerWidth, pageX);
        let top = checkLimit(0, window.innerHeight, pageY);
        this.draggingHarvest.style.left = (left - this.relX) + 'px';
        this.draggingHarvest.style.top = (top - this.relY) + 'px';
        this.draggingHarvest.style.display = 'block';
    };

    onMouseUp = (e: React.MouseEvent | any) => {
        let {pageX, pageY} = e;
        pageX = checkLimit(110, window.innerWidth, pageX);
        pageY = checkLimit(0, window.innerHeight, pageY);
        this.draggingHarvest.remove();
        let element: HTMLElement | any = document.elementFromPoint(pageX, pageY);
        let harvestElement = element.closest('.' + styles.operation);
        if (harvestElement)
            this.props.handleMerge(Number(harvestElement.id), this.props.indexHarvest, this.props.harvest);
        if (this.hoverEle)
            delete this.hoverEle.dataset.isHover;
        delete this.draggingHarvest;
        this.rootRef.current.style.opacity = 1;
        this.rootRef.current.style.visibility = 'visible';
        this.rootRef.current.style.height = 'unset';
        document.body.style.cursor = 'unset';
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
    };

    render() {
        const {indexHarvest, isAllowDrag, isAllowError} = this.props;
        const {
            sub_operations,
            error_detail = {},
        } = this.props.harvest;
        this.relHeight = sub_operations.length * 44 + 38;
        const style = !isAllowDrag ? {opacity: 1, cursor: 'grabbing'} : {cursor: 'grab', transition: 'all .2s'};
        const error = Object.keys(error_detail).length > 0;
        return <div ref={this.rootRef} className={styles.contentOp} style={{minHeight: this.relHeight + 'px'}}>
            <div {...{
                id: indexHarvest,
                className: styles.operation + ' no-select',
                'data-lev': 'operation',
                'data-error': error,
                'data-is-dragging': !isAllowDrag
            }}>
                <div className={styles['bt-drag']} ref={this.btDrag} style={style}/>
                {this.props.children}
            </div>
            {isAllowError && <ErrorOfOperation {...{
                error_detail,
                style: error ? {} : {display: 'none'}
            }}/>}
        </div>;
    }
}

export default Operation;
