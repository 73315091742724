import React, {useState} from 'react';
import {Popconfirm, Spin} from "antd";
import {propsEmpty} from "../../../util/varibles/constants";
import Icon, {ICON_NAME} from "../../../components/Icon";
import {LoadingOutlined} from "@ant-design/icons";
import {showErrorResponse} from "../../../util/varibles/global";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {IUser} from "../../LoginPage/constants";
import {UserService} from "../../../util/services/user";
import {useDispatch} from "react-redux";
import {userActions} from "../reducer";

const antIcon = <LoadingOutlined style={{fontSize: 16, margin: '-8px'}} spin/>

interface IProps {
    data: IUser
}

const DeleteUser: React.FC<IProps> = ({data}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleDelete = () => {
        setLoading(true);
        const params = {username: data.username};
        new Promise(resolve => resolve(UserService.delete(params)))
            .then(() => {
                setLoading(false);
                notify.success(NotifyCode.S4)([data.username]);
                dispatch(userActions.deleteUser(data.username))
            })
            .catch(error => {
                setLoading(false);
                showErrorResponse('Delete user failed', error)
            })
    }

    return <Spin
        indicator={antIcon}
        spinning={loading}
    >
        <Popconfirm
            {...propsEmpty}
            title={`Are you sure delete user "${data.email}"?`}
            onConfirm={handleDelete}
            okText="Yes"
            cancelText="No"
        >
            <div className='menu-line' data-danger='true'>
                <Icon icon={ICON_NAME.DELETE} danger/>
                <div>Delete user</div>
            </div>
        </Popconfirm>
    </Spin>;
};

export default DeleteUser;