import React, {Component} from 'react';
import Diseases from "./Diseases";
import {connect} from "react-redux";
import {permissions} from "../../util/varibles/permission";
import {diseaseActions} from "./reducer";
import {AppState} from "../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    roles: state.login.user.roles
});

interface IProps {
    roles: string[]

    getDiseases(): void
}

class DiseasePage extends Component<IProps> {
    actions = {};

    constructor(props: IProps) {
        super(props);
        this.props.getDiseases();
        this.actions = this.props.roles.reduce((rs: any, item: any) => ({...rs, ...permissions[item] || {}}), {});
    }

    render() {
        return <div className='m-header bgPage'>
            <div className='header-page'>
                <div className='title-page'>
                    <b>Disease Management</b>
                </div>
            </div>
            <div className='body-page' data-box='true'>
                <Diseases actions={this.actions}/>
            </div>
        </div>;
    }
}

export default connect(mapStateToProps, {
    getDiseases: diseaseActions.getListRequest
})(DiseasePage);