import React from 'react';
import styles from "./style.module.scss";
import {Table} from "antd";
import {TASK_TYPE, taskTypes, TREATMENT_TYPE, treatmentType} from '../../../util/varibles/constants';
import {formatNumber} from "../../../util/varibles/global";

interface IProps {
    unit_id?: string,
    tasks: any
}

const SortingInfo: React.FC<IProps> = ({unit_id, tasks}) => {
    if (!unit_id || !tasks)
        return null;

    function getTreatmentV2Destinations(tasks: any) {
        return tasks.map((task: any, index: any) => {
            let destination;
            if (task.type === TASK_TYPE.TREATMENT) {
                destination = (treatmentType[task.treatment_type] || {}).name
            } else if (task.type === TASK_TYPE.MOVE) {
                destination = 'Move to' + task.destination_name + (task.sub_operation.fish_amount && " (" + formatNumber(task.sub_operation.fish_amount) + ")")
            } else {
                destination = taskTypes[task.type].name
            }
            return destination + (index < tasks.length - 1 ? ', ' : '')
        })
    }

    const columns = [
        {
            title: 'Sort condition',
            dataIndex: 'sort_condition',
            key: 'sort_condition',
        },
        {
            title: 'Destination',
            dataIndex: 'destination',
            key: 'destination',
        },
    ];

    const dataSource: any = [];

    tasks.forEach((task: any) => {
        if (unit_id === task.sub_operation.unit_id) {
            if (task.treatment_type === TREATMENT_TYPE.SORTING) {
                Object.keys(task.sub_tasks).forEach((condition, id) => {
                    dataSource.push({
                        key: id,
                        sort_condition: condition,
                        destination: getTreatmentV2Destinations(task.sub_tasks[condition])
                    })
                })
            }
        }
    })

    return <Table dataSource={dataSource} columns={columns} bordered={true} className={styles['sorting-table']}
                  pagination={false}/>

}
export default SortingInfo;