import React, {createRef, useEffect} from 'react';
import styles from "./style.module.scss";
import {Button, Tooltip} from "antd";
import {checkLimit, getElById} from "../../../../../../../util/varibles/global";
import stylesContainer from "../style.module.scss";
import {PlusCircleOutlined} from "@ant-design/icons";
import Icon, {ICON_NAME} from "../../../../../../../components/Icon";
import Menu from "../../../../../../../components/Menu";
import AddNote from "../../../_Component/AddNote";
import DragEl from "../../../_Component/DragEl";
import {UpdateName} from "../../constants";
import {openPopup} from "../../../../../../../components/Popup/Component/WrapperPopup";

const disableSelect = (event: any) => {
    event.preventDefault();
}

interface IProps {
    site: any
    unit: any
    vessel: any

    onUpdate(key: UpdateName, args: any): void
}

let dragEl: any, parentEl: any, root: any, isClose: boolean;

const Title: React.FC<IProps> = (props) => {
    const rootRef = createRef<HTMLDivElement>()
    const {site, unit} = props;

    useEffect(() => {
        const el = rootRef.current
        if (el) {
            setTimeout(() => {
                if (!isClose) {
                    parentEl = document.getElementById('service-operation');
                    if (el && parentEl) {
                        el.addEventListener("mousedown", handleMouseDown);
                        root = el.parentElement;
                    }
                }
            }, 100)
        }
        return () => {
            isClose = true;
            document.body.style.cursor = 'unset';
            window.removeEventListener('selectstart', disableSelect);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
    }, [rootRef.current]);

    const handleSaveNote = (note: any) => {
        props.onUpdate(UpdateName.UPDATE_UNIT_NOTE, {unit: {...unit, note}});
    }

    const handleMouseDown = () => {
        window.addEventListener('selectstart', disableSelect);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        const {unit_id} = unit;
        dragEl = openPopup(<DragEl {...{
            data: [
                {label: 'Site', value: site.name},
                {label: 'Unit', value: unit_id},
            ]
        }}/>)
        dragEl.style.position = 'absolute';
        dragEl.style.zIndex = '2000';
        dragEl.style.pointerEvents = 'none';
        dragEl.style.display = 'none';
    }


    const handleMouseMove = (e: any) => {
        parentEl.dataset.isDrag = 'group';
        root.dataset.isDrag = true;

        const {pageX, pageY} = e;
        dragEl.style.display = 'block';
        const {offsetHeight, offsetWidth} = dragEl;
        dragEl.style.display = 'none';
        const left = checkLimit(0, window.innerWidth - offsetWidth - 5, pageX);
        const top = checkLimit(0, window.innerHeight - offsetHeight, pageY);
        dragEl.style.left = left - 10 + 'px';
        dragEl.style.top = top - 10 + 'px';
        dragEl.style.display = 'block';
        document.body.style.cursor = 'grabbing';
    }

    const handleMouseUp = (e: any) => {
        if (dragEl) {
            dragEl.remove();
            dragEl = undefined;
        }
        document.body.style.cursor = 'unset';
        delete parentEl.dataset.isDrag;
        delete root.dataset.isDrag;
        window.removeEventListener('selectstart', disableSelect);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        if (!e.target)
            return;
        const {index} = e.target.dataset;
        if (index === undefined)
            return;
        e.target.dataset.hover = false;

        const [position] = index.split('-');
        const {id} = unit;
        props.onUpdate(UpdateName.UPDATE_UNIT_POSITION, {targetId: id, index: +position});
    }


    if (!unit)
        return <div/>;

    const {note} = unit;

    return <div ref={rootRef} className={[styles['title'], stylesContainer['title']].join(' ')}>
        <div className={styles['left-section']}>
            <label>{[site.name, unit.unit_id].join(', ')}</label>
        </div>
        <div className={styles['right-section']}>
            <Tooltip title={note}>
                <div className={styles.note}>{note}</div>
            </Tooltip>
            <AddNote title={unit.unit_id} value={note} onChange={handleSaveNote}/>
            <Menu items={[
                {
                    key: 'delete',

                    label: <div
                      className='menu-line' data-danger='true'
                      onClick={() => props.onUpdate(UpdateName.DELETE_UNIT, {unit})}
                    >
                        <Icon icon={ICON_NAME.DELETE} danger/>
                        <span>Delete unit</span>
                    </div>
                }
            ]} getPopupContainer={() => getElById('treatment-operation')}>
                <div className='three-dot'/>
            </Menu>
            <Button {...{
                shape: "circle",
                type: 'text',
                size: 'small',
                className: 'bt-add',
                icon: <PlusCircleOutlined style={{fontSize: '19px'}}/>,
                onClick: () => props.onUpdate(UpdateName.ADD_TASK, {unit})
            }}/>
        </div>
    </div>;
};

export default Title;