import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ACTION_TYPE, OPERATION_TYPE, SITE_TYPE, VIEW_SMOOTH} from "../../../../../../util/varibles/constants";
import styles from './style.module.scss';
import {IUpdateOp} from "../../../../constants";
import {checkTreatment, groupByUnit, initState, updateUnitToTask} from "./constants";
import {IOperations, ISite, IUnit} from "../../../../../../util/varibles/interface";
import {updateData, UpdateName} from "../constants";
import {planOpActions} from "../../../../reducer";
import {IGetActivityLog} from "../../../../saga";
import {AppState} from "../../../../../../util/store/store";
import SupportVessel from "./SupportVessel";
import ChangeOperation from "./ChangeOperation";
import {notify, NotifyCode} from "../../../../../../util/varibles/message";

const mapStateToProps = (state: AppState) => ({
    userTenantId: state.login.user.tenant_id,
    sites: state.planOperation.sites,
    operations: state.planOperation.operations,
    combineTypes: state.planOperation.combineTypes,
})

export interface IProps {
    userTenantId: string
    sites: ISite[]
    combineTypes: any
    store: any
    editMode: ACTION_TYPE
    popupOperation: any
    operations: IOperations
    loading?: boolean

    getActivityLog(payload: IGetActivityLog): void

    onSave(payload: IUpdateOp, action?: Function): void

    onClose(): void
}

export interface IState {
    startTime: number
    support_vessels: any
    activity_log: any
    operation: any
    loading: boolean
    store: any
    site: ISite
    units: any
    factory: any
    step: number
    supportTasks: any
    files: any
    listOfTask: any
    isCalculate: boolean
}

class Treatment extends Component<IProps, IState> {
    currentUnitId: string | undefined;
    controllerRequest = new AbortController();

    constructor(props: IProps) {
        super(props);
        const {state, currentUnitId} = initState(this.props)
        this.state = state;
        this.currentUnitId = currentUnitId
    }

    componentWillUnmount = () => {
        this.controllerRequest.abort();
    }

    shouldComponentUpdate = (nextProps: Readonly<IProps>, nextState: Readonly<{}>): boolean => {
        if (JSON.stringify(this.props.popupOperation) !== JSON.stringify(nextProps.popupOperation)) {
            const el = document.getElementById('container-treatment');
            if (el)
                setTimeout(() => el.scrollTo(0, 0), 100)
            const {state, currentUnitId} = initState(this.props)
            this.setState(state);
            this.currentUnitId = currentUnitId;
        }

        return this.state !== nextState
    }

    handleSubmit = (activeUnits: any, finish: Function) => {
        const {operation, files}: any = this.state;
        if (!this.state.isCalculate) {
            const dataOld = this.props.operations[operation.id];
            const {units} = this.state;
            const data = {
                ...dataOld,
                operation: {
                    ...operation,
                    files: files.map((item: any) => ({key: item.key, name: item.name})),
                    sub_operations: operation.sub_operations.map((item: any) => units[item.id] || item)
                }
            };
            const listOfFile = files.length > 0 ? {[operation.id]: files} : {};
            const {action} = this.props.popupOperation;
            this.props.onSave({update: {[operation.id]: data}, isCheck: false, files: listOfFile}, action);
            return;
        }

        const {supportTasks, site, units} = this.state;
        let currentTasks = site.type === SITE_TYPE.EXTERNAL ? updateUnitToTask(activeUnits, units, operation.tasks) : operation.tasks;
        if (!currentTasks)
            return;

        const {isError, tasks: tasksAfterCheck} = checkTreatment(currentTasks);
        if (isError) {
            this.setState({operation: {...operation, tasks: [...tasksAfterCheck]}})
            return;
        }
        const sub_operations = currentTasks.reduce((list: any, item: any) => {
            const {id} = item.sub_operation || item.sub_operations[0];
            const {note = ''} = units[id] || {};
            list[id] = {...units[id], note};
            return list;
        }, {});

        const empty = Object.keys(sub_operations).some(key => {
            const {fish_amount, total_weight, unit_id} = sub_operations[key] as IUnit;
            if (!fish_amount || !total_weight)
                notify.error(NotifyCode.E40)([unit_id]);
            return !fish_amount || !total_weight
        }, [])

        if (empty)
            return;

        const {vessel} = this.props.popupOperation.values;
        const data = {
            operation: {
                ...operation,
                support_vessel: supportTasks.length > 0,
                tasks: currentTasks,
                sub_operations: Object.keys(sub_operations).reduce((list: any, key) => {
                    return [...list, sub_operations[key]]
                }, []),
                files: files.map((item: any) => ({key: item.key, name: item.name}))
            },
            vessel,
            activity_log: []
        };

        finish(data);
    }

    handleBack = () => {
        this.setState({step: 1});
        const el = document.getElementById('plan-treatment');
        if (el)
            el.scrollIntoView(VIEW_SMOOTH);
    }

    handleUpdate = (key: UpdateName, args: any) => {
        const action = updateData[OPERATION_TYPE.TREATMENT][key];
        const {state, props} = this;
        if (action)
            this.setState(action({state, props, args}));
    }

    render() {
        const {vessel} = this.props.popupOperation.values;
        const {action} = this.props.popupOperation;

        const {group, listOfUnit} = groupByUnit(this.state.operation?.tasks || [], this.currentUnitId);
        const common = {
            state: this.state,
            vessel,
            setState: (state: IState, callback?: Function) => this.setState(state, () => callback && callback()),
            handleUpdate: this.handleUpdate,
            onSubmit: (finish: Function) => this.handleSubmit(listOfUnit, finish),
            onSave: (value: IUpdateOp) => this.props.onSave(value, action),
            onClose: this.props.onClose
        }

        return <div id='container-treatment' className={styles['container-operation']}>
            <ChangeOperation {...common} listOfUnit={listOfUnit} group={group} currentUnitId={this.currentUnitId}/>
            {this.state.step === 2 && <SupportVessel {...common} onBack={this.handleBack}/>}
        </div>
    }
}

export default connect(mapStateToProps, {
    getActivityLog: planOpActions.getActivityLog
})(Treatment);