import React, {useRef, useState} from 'react';
import {Form, Input, Modal, Select} from "antd";
import {fishTypes, propsModal, siteTypes} from "../../../util/varibles/constants";
import {FormInstance} from "antd/lib/form";
import {ISite} from "../../../util/varibles/interface";
import Header from "../../../components/Popup/Component/Header";
import Footer from "../../../components/Popup/Component/Footer";

interface IProps {
    visible?: boolean
    data: ISite

    onSave(site: ISite): void

    onClose(): void
}

const PopupEditSite: React.FC<IProps> = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const formRef = useRef<FormInstance>(null);

    const handleClose = () => {
        setVisible(false);
        props.onClose()
    }

    const handleSubmit = (values: any) => {
        props.onSave({...props.data, ...values});
        handleClose();
    }

    return <Modal
        {...propsModal}
        width={500}
        title={<Header title={`Edit site: ${props.data.name}`} onClose={handleClose}/>}
        open={visible}
    >
        <Form ref={formRef} initialValues={props.data} onFinish={handleSubmit} name='user' layout='vertical'
              requiredMark={false}>
            <Form.Item
                label={<span className='font-s15 '>Name</span>}
                name="name"
                rules={[{
                    required: true,
                    message: `"Name" is required`
                }]}
            >
                <Input className='border-r-40 h-36'/>
            </Form.Item>
            <Form.Item name="type" label="Type">
                <Select
                    className='select-single'
                    placeholder='Type'
                    options={siteTypes.map(item => ({value: item.id, label: item.name}))}
                />
            </Form.Item>
            <Form.Item name='site_type' label="Fish type">
                <Select
                    className='select-single'
                    placeholder='Fish type'
                    options={fishTypes.map(item => ({value: item.id, label: item.name}))}
                />
            </Form.Item>
            <Footer cancel={{click: handleClose}}/>
        </Form>
    </Modal>;
};

export default PopupEditSite;