import React from 'react';
import styles from './style.module.scss'
import {CALENDAR_ID} from "../../constants";
import {useSelector} from 'react-redux'
import Weeks from "./Weeks";
import Vessels from "./Vessels";
import {selectPlan} from "../../../../util/store/selectors";
import {getMondayByKey, getSundayByKey} from "../../../../util/varibles/global";

interface IProps {
}

const Calendar: React.FC<IProps> = () => {
    const weeks = useSelector(selectPlan.weeks);
    const startTime = getMondayByKey(weeks[0]).time;
    const finishTime = getSundayByKey(weeks[weeks.length - 1]).time;

    return <div id={CALENDAR_ID} className={styles.calendar}>
        <Weeks startTime={startTime} finishTime={finishTime}/>
        <Vessels startTime={startTime} finishTime={finishTime}/>
    </div>
};

export default Calendar;
