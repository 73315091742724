import React, {createRef, useEffect} from 'react';
import styles from './style.module.scss';

interface IProps {
    index: any
    unitId: string
    containerId: string
    parentIndex?: any
}

let parentEl: HTMLElement | null;
const LineMiddle: React.FC<IProps> = (props) => {
    const rootRef = createRef<HTMLDivElement>()
    const {index, unitId, parentIndex = null} = props;

    useEffect(() => {
        const el = rootRef.current
        if (el) {
            parentEl = document.getElementById(props.containerId);
            el.addEventListener("mouseover", () => handleMouseEnter(el));
            el.addEventListener("mouseleave", () => handleMouseLeave(el));
        }
        return () => {
            if (el) {
                el.removeEventListener("mouseover", () => handleMouseEnter(el));
                el.removeEventListener("mouseleave", () => handleMouseLeave(el));
            }
        }
    }, [rootRef.current]);

    const handleMouseEnter = (el: HTMLDivElement) => {
        if (parentEl?.dataset.isDrag) {
            el.dataset.hover = "true";
        }
    }

    const handleMouseLeave = (el: HTMLDivElement) => {
        if (parentEl?.dataset.isDrag) {
            el.dataset.hover = "false";
        }
    }

    return <div
        ref={rootRef}
        className={styles['container-line']}
        data-mode='change'
        data-unit-id={unitId}
        data-index={[index, parentIndex].join('-')}
    >
        <div className={styles['icon-plus']}/>
    </div>;
};

export default LineMiddle;
