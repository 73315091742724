import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import {ACTION_TYPE, OP_STATUS, OPERATION_TYPE} from "../../../../../../../util/varibles/constants";
import WrapperOperation from "../Component/WrapperOperation";
import stylesContainer from "../style.module.scss";
import TransportView from "../../../../../_Component/ContentOperation/TransportView";
import {ISite} from "../../../../../../../util/varibles/interface";
import {IPropsOp} from "../index";
import Icon, {ICON_NAME} from "../../../../../../../components/Icon";
import {AppState} from "../../../../../../../util/store/store";
import {cloneObj} from "../../../../../../../util/varibles/global";

const icon = <Icon icon={ICON_NAME.TRANSPORT}/>

const mapStateToProps = (state: AppState) => ({
    sites: state.planOperation.sites
});

interface IProps extends IPropsOp {
    sites: ISite[]
    operationType: OPERATION_TYPE

    isOwn: boolean
    isVesselOwn: boolean
    permission: any
    status: OP_STATUS

    openEdit(payload: any): void
}

const Transport: React.FC<IProps> = (props) => {
    const {isOwn, sites, data, openEdit} = props;

    const edit = useCallback(() => {
        const {operation} = data;
        const {sub_operations, source_id, source_name} = operation;
        const {units = []} = sites.find((item: any) => item.id === source_id) || {};
        const store = sub_operations.reduce((list: any, {id}: any) => {
            const key = id + '|-';
            list[key] = {
                ...units.find((unit: any) => unit.id === id),
                site_id: source_id,
                site_name: source_name,
            }
            return list;
        }, {})
        if (Object.keys(store).length === 0)
            return

        openEdit({
            isShow: true,
            operationType: OPERATION_TYPE.TRANSPORT,
            editMode: ACTION_TYPE.EDIT,
            values: {
                ...cloneObj(data),
                store,
            }
        })
    }, [data, sites, openEdit]);

    return <WrapperOperation {...{...props, icon, isGetActivityLog: true, edit}}>
        <div className={[stylesContainer['content-wrapper'], stylesContainer.layout].join(' ')}>
            <TransportView
                status={props.status}
                data={data}
                isOwn={isOwn}
            />
        </div>
    </WrapperOperation>;
};

export default connect(mapStateToProps, {})(Transport);
