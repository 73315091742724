import React, {useEffect} from 'react';
import styles from '../style.module.scss';
import {Switch} from "antd";
import {connect, useDispatch} from "react-redux";
import {IProductionArea} from "../../../../util/varibles/interface";
import {DashboardSetting} from "../../../../util/varibles/userSetting";
import {loginActions} from "../../../LoginPage/reducer";
import GoogleMap from "../../../../util/library/googlemap/googlemap";
import {dashboardActions} from "../../reducer";
import {MAP_ELEMENT} from "../../../../util/library/googlemap/constants";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState) => {
    const {productionArea} = state.dashboard.filter || {};
    return {
        checked: productionArea,
        map: state.dashboard.map,
        productionAreas: state.dashboard.productionAreas
    }
};

interface IProps {
    checked: boolean
    productionAreas: IProductionArea[]
    map?: GoogleMap
}

const ProductionArea: React.FC<IProps> = ({checked, map, productionAreas}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        map?.generate({
            type: MAP_ELEMENT.PRODUCTION_AREA,
            zones: productionAreas,
            visible: checked
        });
    }, [map, checked, productionAreas]);


    const onChange = (visible: any) => {
        dispatch(dashboardActions.updateFilter({productionArea: visible}));
        dispatch(loginActions.updateUserSetting([{[DashboardSetting.PRODUCT_AREA]: visible}]))
    };

    return <div className={styles.line}>
        <div className='font-s15'>Production areas</div>
        <Switch checked={checked} onChange={onChange}/>
    </div>;
};

export default connect(mapStateToProps, {})(ProductionArea);
