import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLocation} from "../../../../../../util/store/location/constants";
import {locationActions} from "../../../../../../util/store/location/reducer";
import {Select} from "antd";
import {renderOpts} from "../../../../../../util/varibles/global";

interface IProps {
    value?: { id: string, name: string }[]

    onChange?(value: { id: string, name: string }[]): void
}

const Location: React.FC<IProps> = (props) => {
    const locations = useSelector(selectLocation.locations);
    const loading = useSelector(selectLocation.loading);
    const dispatch = useDispatch();
    const [selected, setSelected] = useState<string[]>([]);

    useEffect(() => {
        dispatch(locationActions.getPois());
    }, []);

    useEffect(() => {
        setSelected((props.value || []).map(item => item.id))
    }, [props.value]);

    const handleChange = (ids: string[]) => {
        if (props.onChange) {
            const value = ids.reduce((rs: { id: string, name: string }[], id) => {
                const item = locations.find(sub => sub.id === id);
                return item ? [...rs, {id: item.id, name: item.name}] : rs;
            }, [])
            props.onChange(value)
        }
    }

    return <Select
        className='h-36 select-mode-tag'
        mode='multiple'
        loading={loading}
        options={renderOpts(locations)}
        value={selected}
        onChange={handleChange}
    />;
};

export default Location;
