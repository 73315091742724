import React from 'react';
import {Checkbox} from "antd";
import {UpdateName} from "../../Body/constants";
import {TOperation} from "../../../../../../util/varibles/interface";

interface IProps {
    op: TOperation
    indexHarvest?: number

    onChange(key: UpdateName, args: any): void
}

const Cleaning: React.FC<IProps> = ({op, indexHarvest, onChange}) => {
    return <div>
        <Checkbox
            checked={op.is_cleaning}
            onChange={(e: any) => onChange(UpdateName.CALCULATE, {index: indexHarvest, is_cleaning: e.target.checked})}
        >
            Cleaning
        </Checkbox>
    </div>;
};

export default Cleaning;
