import React, {useCallback, useEffect, useState} from 'react';
import {Checkbox, Input} from "antd";
import {OPERATION_TYPE, operationType, treatmentTypes, vesselType} from "../../../util/varibles/constants";
import styles from './style.module.scss';
import {getElById} from "../../../util/varibles/global";
import Capabilities from "./Capabilities";
import {IVessel} from "../../../util/varibles/interface";
import Vessel from "./Vessel";
import WrapperPopup from "../Component/WrapperPopup";

let list: any = {};

export const vesselOpType: any = {
    [OPERATION_TYPE.HARVEST]: operationType[OPERATION_TYPE.HARVEST],
    [OPERATION_TYPE.TRANSPORT]: operationType[OPERATION_TYPE.TRANSPORT],
}

export const filterCapabilities = [
    [{...operationType[OPERATION_TYPE.HARVEST], id: `${OPERATION_TYPE.HARVEST}`}],
    treatmentTypes.map(item => {
        const id = [OPERATION_TYPE.TREATMENT, item.id].join('-');
        vesselOpType[id] = item;
        return {...item, id}
    }),
    [{...operationType[OPERATION_TYPE.TRANSPORT], id: `${OPERATION_TYPE.TRANSPORT}`}],
]

// ------------------------------------------------------------------------------------------------------------------

interface IProps {
    selected: string[]
    vessels: IVessel[]

    onClose(): void

    onSave(planVessels: string[]): void
}

const PopupFilterVessel: React.FC<IProps> = ({vessels, selected, onSave, onClose}) => {
    const [visible, setVisible] = useState(true);
    const [value, setValue] = useState<string[]>([]);
    const [view, setView] = useState<{ [index: string]: any[] }>({});
    const [filter, setFilter] = useState({name: '', capabilities: ''});

    useEffect(() => {
        if (vessels.length <= 0)
            return;

        const newValue: string[] = [];
        const ids = new Set(selected);
        let max = 0;
        list = vessels.reduce((rs: any, item) => {
            const {id, operation_type, operation_sub_types = [], type} = item;
            if (ids.has(id))
                newValue.push(id);

            const opType = [
                ...operation_type.reduce((types: any, sub: any) => vesselOpType[sub] ? [...types, sub] : types, []),
                ...operation_sub_types.reduce((types: any, key: any) => {
                    const sub = [OPERATION_TYPE.TREATMENT, key].join('-');
                    return vesselOpType[sub] ? [...types, sub] : types
                }, [])
            ];
            if (opType.length > max) {
                max = opType.length;
            }
            rs[type] = [...rs[type] || [], {...item, opType}];
            return rs;
        }, {});

        setView(list);
        setValue(newValue);

        setTimeout(() => {
            const heightPerItem = 41
            const key = Object.keys(list)[Object.keys(list).length - 1];
            const id = ['filter', list[key][list[key].length - 1].id].join('-');
            const target = getElById(id);
            const modal: any = document.getElementsByClassName(styles.modal)[0];
            if (target && modal) {
                modal.style.width = '100%';
                modal.style.height = '100%';
                const {offsetHeight, offsetWidth, offsetTop, offsetLeft} = target;
                const y = Math.floor((window.innerWidth - offsetLeft - offsetWidth - 24) / 380);
                if (y > 0) {
                    const x = window.innerHeight - offsetHeight - offsetTop - 24;
                    const column = Math.floor(offsetLeft / 380);
                    const z = Math.floor(((x + target.parentElement.offsetHeight) / (column + 2)) / heightPerItem) * heightPerItem;
                    modal.style.width = (offsetLeft + 788) + 'px';
                    modal.style.height = window.innerHeight - z + 'px';
                }
            }
        }, 20)
    }, [vessels, selected]);

    const handleFilter = useCallback((search: any) => {

        let filterByName: any = () => true;
        let filterByCapability: any = () => true;
        const newFilter = {...filter, ...search};
        if ((newFilter.name || '').length > 0) {
            const search = newFilter.name.trim().toLowerCase();
            const sub = search.substring(1);
            filterByName = search[0] === '*'
                ? (item: any) => item.name.toLowerCase().includes(sub)
                : (item: any) => item.name.toLowerCase().indexOf(search) === 0;
        }

        if ((newFilter.capabilities || []).length > 0) {
            const capabilities = new Set(newFilter.capabilities);
            filterByCapability = (item: any) => item.opType.some((type: any) => capabilities.has(type.toString()))
        }

        setView(Object.keys(list).reduce((rs: any, key) => {
            rs[key] = list[key].filter((vessel: any) => filterByName(vessel) && filterByCapability(vessel));
            return rs;
        }, {}));

        setFilter(newFilter);
    }, [filter]);

    const handleClose = useCallback(() => {
        setVisible(false);
        if (JSON.stringify(value) !== JSON.stringify(selected))
            onSave(value);

        if (onClose)
            setTimeout(onClose, 300)
    }, [value, selected, onSave, onClose]);

    return <WrapperPopup
        visible={visible}
        className={styles.modal}
        title={`Vessel filter ${value.length > 0 ? `(${value.length}/${vessels.length})` : ''}`}
        onClose={handleClose}
    >
        <div className={styles.filter}>
            <Input
                placeholder='Search by name'
                onChange={(e: any) => handleFilter({name: e.target.value})}
            />
            <Capabilities onChange={capabilities => handleFilter({capabilities})}/>
        </div>
        <Checkbox.Group
            className={styles.options}
            value={value}
            onChange={(ids: any) => setValue(ids)}
        >
            {Object.keys(view).reduce((rs: any, key) => {
                const {name = ''} = vesselType[key] || {};
                const count = view[key].length - 1;

                return [
                    ...rs,
                    <div className={styles.section} key={[key, 0].join('-')}>{name} ({count + 1})</div>,
                    ...view[key].map((sub: any) => <Vessel key={sub.id} data={sub}/>)
                ]
            }, [])}
            <div/>
        </Checkbox.Group>
    </WrapperPopup>;
};

export default PopupFilterVessel;
