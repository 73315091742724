import React, {Component} from 'react';
import styles from "./style.module.scss";
import {SHORT_TIME_FORMAT} from "../../../../../../../../util/varibles/constants";
import {datetime} from "../../../../../../../../util/library/datetime";
import {HEIGHT_PER_DAY} from "../../../constants";

interface IProps {
    data: any
    title?: string
    isEdit: boolean
    time: number
    startPoint: number
    index: number
    style: any

    handleMouseDown(e: any, index: number): void
}

class TimePoint extends Component<IProps> {
    render() {
        const {id, name, label} = this.props.data;
        const {title, isEdit, style, time, index, startPoint, handleMouseDown} = this.props;
        const value = time;
        const top = `${(time - startPoint) / 86400000 * HEIGHT_PER_DAY}px`;

        return <div {...{
            className: styles.timeline,
            style: {...style, top},
            'data-edit': isEdit,
            'data-operation-id': id,
            'data-name': name,
        }}>
            <span {...{
                title,
                onClick: (e) => e.stopPropagation(),
                onMouseDown: (e) => handleMouseDown(e, index)
            }}>
                {label}: {datetime(value).format(SHORT_TIME_FORMAT)}
            </span>
        </div>
    }
}

export default TimePoint;
