import {all, call, put, takeEvery} from 'redux-saga/effects'
import {getOperationFetch} from "../../util/services/operation";
import {notification} from "antd";
import {getPositionVessels2Fetch} from "../../util/services/vessel";
import {OPERATION_TYPE, PATHNAME} from "../../util/varibles/constants";
import {opDetailActions} from "./reducer";
import {nextPage} from "../../util/library/Router";

function* getOperationWatcher({...args}): Generator<any, any, any> {
    try {
        const {tenant_id, id} = args.payload;
        const operation = yield call(getOperationFetch, tenant_id, id);
        const {operation: {sub_operations, operation_type}, vessel, vessel: {iot_name}} = operation;
        if (operation_type !== OPERATION_TYPE.EVENT) {
            const total = sub_operations.reduce((total: any, item: any) => {
                total.totalWeight += item.total_weight;
                total.totalAmount += item.fish_amount;
                return total;
            }, {totalWeight: 0, totalAmount: 0});
            operation.operation = {...operation.operation, ...total};
        }
        if (iot_name && iot_name !== '-') {
            const gps = yield call(getPositionVessels2Fetch, [iot_name])
            if (gps.length > 0) {
                const {time, ...args} = gps[0];
                const [value] = time.split('.');
                const gpsTime = value[value.length - 1] === 'Z' ? new Date(value.replace(' ', 'T')).getTime() :
                    new Date(value.replace(' ', 'T') + 'Z').getTime();

                operation.vessel = {...vessel, gps: {...args, time: gpsTime}}
            }
        }
        yield put(opDetailActions.getOpSuccess(operation))
    } catch (error: any) {
        yield put(opDetailActions.getOpFailure())
        if (error.response && error.response.status === 445) {
            nextPage(PATHNAME.HOME);
            notification['error']({
                message: 'Error',
                description: 'Operation not found',
            });
        }
    }
}

export default function* operationDetailSaga() {
    yield all([
        takeEvery(opDetailActions.getOpRequest.type, getOperationWatcher),
    ])
}
