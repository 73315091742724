import React, {useEffect} from 'react';
import styles from "./style.module.scss";
import {KEY_STORAGE, OPERATION_TYPE} from "../../../util/varibles/constants";
import DragView from "./_Component/DragView";
import {deleteDragEl, KEY_SPLIT, MODE_DRAG} from "./constants";
import Cancel from "./Cancel";
import {FuncOp} from "../util/function_operation/constants";
import {funcGlobal} from "../util/function_operation/global";
import {openPopup} from "../../../components/Popup/Component/WrapperPopup";
import {useDispatch, useSelector} from "react-redux";
import {planOpActions} from "../reducer";
import {selectDefaultOpType} from "../../../util/store/selectors";
import BoxSites from "./_Component/BoxSites";

let clear: any = {}, dragEl: HTMLDivElement | any;
const BiologyBrowser: React.FC = () => {
    const defaultOpType = useSelector(selectDefaultOpType);
    const dispatch = useDispatch();

    useEffect(() => {
        window.addEventListener("storage", onStorageUpdate);
        return () => {
            cancelDrag();
            window.removeEventListener("storage", onStorageUpdate);
        }
    }, []);

    const handleDragStart = (e: any, units: any, requestType: OPERATION_TYPE[]) => {
        localStorage.setItem(KEY_STORAGE.REQUIRE_OPERATION_TYPE, JSON.stringify(requestType));
        localStorage.setItem(KEY_STORAGE.UNITS_SELECTED, JSON.stringify(units));

        const mode = MODE_DRAG.START
        localStorage.setItem(KEY_STORAGE.MODE_DRAG, mode);

        onChangePosition(e);

        createDragEl();
        activeDrag();

        return true;
    }

    const handleDragEnd = (e: any) => {
        const target = e.currentTarget;
        target.style.cursor = 'grab';
        const mode = MODE_DRAG.END;
        localStorage.setItem(KEY_STORAGE.MODE_DRAG, mode);
        cancelDrag();
        deleteDragEl(dragEl);
    }

    const onChangePosition = (e: any) => {
        const position = [e.screenX, e.screenY].join(KEY_SPLIT);
        localStorage.setItem(KEY_STORAGE.POSITION, position);
    }

    const handleDragOver = (e: any) => {
        const {pageX, pageY} = e;

        updateDragEl(pageX, pageY);
        onChangePosition(e);

        e.preventDefault();
    }

    const updateDragEl = (pageX: number, pageY: number) => {
        if (dragEl) {
            dragEl.style.cursor = 'grabbing';
            dragEl.style.display = 'block';
            dragEl.style.left = (pageX - 5) + 'px';
            dragEl.style.top = (pageY - 5) + 'px';
        }
    }

    const onStorageUpdate = (e: any) => {
        const {key, newValue} = e;
        switch (key) {
            case KEY_STORAGE.MODE_DRAG: {
                if (newValue === MODE_DRAG.START) {
                    createDragEl();
                    activeDrag();
                } else if (newValue === MODE_DRAG.END) {
                    deleteDragEl(dragEl);
                    cancelDrag();
                }
                break;
            }
            case KEY_STORAGE.POSITION: {
                const [x, y] = (newValue || '').split(KEY_SPLIT);
                const {screenX} = window;
                const screenY = window.outerHeight - window.innerHeight + window.screenY;
                updateDragEl(x - screenX, y - screenY);
                break;
            }
            case KEY_STORAGE.CLEAR_CHECK_BIOLOGY: {
                unCheckAll();
                break;
            }
            default:
                return null;
        }
    }

    const activeDrag = () => {
        document.body.style.overflow = 'hidden';
        document.body.addEventListener('dragover', handleDragOver);
        document.body.addEventListener('drop', handleDrop);
    }

    const cancelDrag = () => {
        document.body.style.overflow = '';
        document.body.removeEventListener('dragover', handleDragOver);
        document.body.removeEventListener('drop', handleDrop);
    }

    const handleDrop = (e: any) => {
        deleteDragEl(dragEl);
        dispatch(planOpActions.togglePopupOperation({
            e,
            defaultOpType,
            callback: () => funcGlobal[FuncOp.UncheckBiology]({uncheck: unCheckAll})
        }))
    };

    const createDragEl = () => {
        const units = JSON.parse(localStorage.getItem(KEY_STORAGE.UNITS_SELECTED) || '');
        dragEl = openPopup(<DragView units={units}/>);
        dragEl.style.position = 'absolute';
        dragEl.style.zIndex = '9999';
        dragEl.style.display = 'none';
    }

    const unCheckAll = () => {
        Object.keys(clear).forEach(key => clear[key]())
    }

    return <div id='biology' className={styles['biology-browser-view'] + ' no-select'}>
        <div className={styles['container-tabs']} id='container-tabs'>
            <BoxSites
                setClear={(key: string, value: any) => clear[key] = value}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
            />
            <Cancel />
        </div>
    </div>
};

export default BiologyBrowser;
