import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from "./style.module.scss";
import Menu from "./Component/Menu";
import CustomizeHarvestOperation from "./Content/CustomizeHarvest";
import {KEY_PREFERENCE} from "./Component/Menu/constants";
import WrapperContent from "./Component/WrapperContent";
import {ACTION, permissions} from "../../util/varibles/permission";
import {USER_ROLE} from "../../util/varibles/constants";
import CertaintyOperation from "./Content/CertaintyOperation";
import SetArrivalTime from "./Content/SetArrivalTime";
import ConfigurePlan from "./Content/ConfigurePlan";
import VesselCrewApp from "./Content/VesselCrewApp";
import ViewModes from "./Content/ViewModes";
import {AppState} from "../../util/store/store";
import SubscribeEmail from "./Content/SubscribeEmail";

const mapStateToProps = (state: AppState) => ({
    roles: state.login.user.roles
});

interface IProps {
    roles: USER_ROLE[]
}

class PreferencePage extends Component<IProps> {
    actions: any = [];
    menu: any = [];

    state = {
        focusId: KEY_PREFERENCE.CUSTOMIZE_HARVEST,
        content: {}
    }

    constructor(props: IProps) {
        super(props);
        const {roles} = this.props;
        this.actions = roles.reduce((rs: any, item: any) => ({...rs, ...permissions[item] || {}}), {});

        if (this.actions[ACTION.PREFERENCE.PLAN]) {
            this.menu.push({
                label: 'Plan operation',
                id: KEY_PREFERENCE.PLAN_OPERATION,
                type: 'group',
                isOpen: true,
                tab: ''
            });
            if (this.actions[ACTION.PREFERENCE.HARVEST_CUSTOMIZE])
                this.menu.push({
                    label: 'Customize harvest operation',
                    id: KEY_PREFERENCE.CUSTOMIZE_HARVEST,
                    type: 'item',
                    isOpen: false,
                    tab: '',
                    render: () => <CustomizeHarvestOperation/>
                });

            if (this.actions[ACTION.PREFERENCE.OP_CERTAIN])
                this.menu.push({
                    label: 'Set operation\'s certainty',
                    id: KEY_PREFERENCE.CERTAINTY_OPERATION,
                    type: 'item',
                    isOpen: false,
                    tab: '',
                    render: () => <CertaintyOperation/>
                });

            if (this.actions[ACTION.PREFERENCE.WORK_TIME])
                this.menu.push({
                    label: 'Set arrival time at site / factory',
                    id: KEY_PREFERENCE.SET_ARRIVAL_TIME,
                    type: 'item',
                    isOpen: false,
                    tab: '',
                    render: () => <SetArrivalTime/>
                });

            if (this.actions[ACTION.PREFERENCE.CONFIGURE_PLAN])
                this.menu.push({
                    label: 'Auto plan configure',
                    id: KEY_PREFERENCE.CONFIGURE_PLAN,
                    type: 'item',
                    isOpen: false,
                    tab: '',
                    render: () => <ConfigurePlan/>
                });
        }

        if (this.actions[ACTION.PREFERENCE.VESSEL_APP])
            this.menu.push({
                label: 'Vessel crew app',
                id: KEY_PREFERENCE.VESSEL_CREW_APP,
                type: 'item',
                isOpen: false,
                tab: '',
                render: () => <VesselCrewApp/>,
            });

        if (this.actions[ACTION.PREFERENCE.MODE])
            this.menu.push({
                label: 'View modes',
                id: KEY_PREFERENCE.MODE_VIEWS,
                type: 'item',
                isOpen: false,
                tab: '',
                render: () => <ViewModes actions={this.actions}/>,
            });
        if (this.actions[ACTION.SUBSCRIBE_EMAIL.VIEW])
            this.menu.push({
                label: `Subscribe completed operation's email`,
                id: KEY_PREFERENCE.SUBSCRIBE_EMAIL,
                type: 'item',
                isOpen: false,
                tab: '',
                render: () => <SubscribeEmail actions={this.actions}/>,
            });

        const {id = '', render = () => null} = this.menu.find((item: any) => item.type === 'item') || {};
        this.state = {focusId: id, content: {[id]: render()}};
    }


    changeMenuItem = (menu: any) => {
        const {id: focusId} = menu;
        const {content}: any = this.state;
        if (content[focusId])
            this.setState({focusId})
        else {
            const {render} = menu;
            this.setState({focusId, content: {...content, [focusId]: render()}})
        }
    }

    render() {
        const {focusId, content}: any = this.state;
        return <div className="m-header bgPage">
            <div className='header-page'>
                <div className='title-page'>
                    <span className='font-w2 mr-1'>Setting:</span>
                    <b>Preference</b>
                </div>
            </div>
            <div className='body-page scroll-small'>
                <div className={styles['wrapper-content']}>
                    <Menu
                        menu={this.menu}
                        focusId={focusId}
                        changeMenuItem={(focusId: any) => this.changeMenuItem(focusId)}
                    />
                    <WrapperContent>
                        {Object.keys(content).map(key => <div key={key} {...{
                            className: styles.content,
                            style: key === focusId ? {} : {display: 'none'}
                        }}>
                            {content[key]}
                        </div>)}
                    </WrapperContent>
                </div>
            </div>
            <div id='weather-suggestions' className={styles.menu}/>
        </div>;
    }
}

export default connect(mapStateToProps, {})(PreferencePage);
