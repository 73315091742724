import {SupportType} from "../../../../constants";
import {
    ACTION_TYPE,
    CERTAIN_TYPE,
    KeyOfTask,
    OP_STATUS,
    OPERATION_TYPE,
    SITE_TYPE,
    TASK_TYPE,
    taskTypes,
    TREATMENT_TYPE,
    treatmentType,
    VESSEL_TYPE
} from "../../../../../../util/varibles/constants";
import {IActivityLog, ISite, IUnit, IVessel, TOperation} from "../../../../../../util/varibles/interface";
import {getTaskByGroupId, uuid} from "../../../../../../util/varibles/global";
import {calculateRound} from "../constants";
import {notify, NotifyCode} from "../../../../../../util/varibles/message";
import {notification} from "antd";
import {getStorages} from "../../../../util/function_operation/constants";
import {IProps, IState} from "./index";

export interface IKeyUpdate {
    key: KeyOfTask
    value?: any
}

export interface ICreateTreatment {
    vessel: IVessel
    store: any
    sites: ISite[]
    properties: any
    error_detail?: any
}

export const createTreatment = (args: ICreateTreatment) => {
    let siteId: any;
    const {properties, error_detail = {}} = args;

    const storeBySite = Object.keys(args.store).reduce((rs: any, key) => {
        const {site_id, total_weight} = args.store[key];

        if (!siteId)
            siteId = site_id

        rs[site_id] = {
            ...rs[site_id] || {},
            [key]: {
                ...args.store[key],
                round_number: calculateRound(total_weight, args.vessel)
            }
        }
        return rs;
    }, {})

    const site: any = args.sites.find((item: any) => item.id === siteId);
    const store = storeBySite[siteId];
    const keyStore = Object.keys(store);

    const tasks: any = keyStore.reduce((list: any, key: string) => {
        const {round_number} = store[key];

        return [...list, {
            type: null,
            group_id: uuid(),
            sub_operation: convertUnit(store[key]),
            round_number,
            error: {},
        }]
    }, [])

    return {
        operation: {
            ...properties,
            site_id: site.id,
            site_name: site.name,
            isInternal: site.type === SITE_TYPE.INTERNAL,
            operation_type: OPERATION_TYPE.TREATMENT,
            tasks,
            error_detail,
            certain_mode: CERTAIN_TYPE.NONE,
            is_cleaning: false
        },
        store,
        site
    }
}

export function convertUnit(data: IUnit) {
    const {
        id,
        unit_id,
        harvest_id,
        is_waiting_unit,
        mortality_risk,
        avg_weight,
        fish_amount,
        total_weight,
        unit_diseases,
        delivery_types,
        site_id,
        site_name,
        round_number,
        fish_group
    } = data;

    return {
        id,
        unit_id,
        harvest_id,
        is_waiting_unit,
        mortality_risk,
        avg_weight,
        fish_amount,
        total_weight,
        unit_diseases,
        delivery_types,
        site_id,
        site_name,
        round_number,
        fish_group
    }
}

export function checkSupportTasks(tasks: any, supportTasks: any) {
    return {
        tasks: tasks.map((item: any) => {
            const {treatment_type} = item;
            if (treatment_type === TREATMENT_TYPE.SORTING) {
                const {sub_tasks} = item;
                return {
                    ...item,
                    sub_tasks: Object.keys(sub_tasks).reduce((rs: any, key) => {
                        rs[key] = sub_tasks[key].map((sub: any) => {
                            delete sub.support_vessels;
                            return sub
                        })
                        return rs;
                    }, {})
                }
            }
            delete item.support_vessels;
            return item;
        }),
        tasksSupport: supportTasks.map((item: any) => ({...item, support_vessels: []}))
    }
}

export function checkTreatment(tasks: any) {
    let isErrorGlobal = false;
    const tasksNew = tasks.map((item: any) => {
        const {type = null, error = {}} = item;
        if (Object.keys(error || {}).length > 0) {
            isErrorGlobal = true;
            return item;
        }

        if (type === null) {
            isErrorGlobal = true;
            return {...item, error: {empty: notify[NotifyCode.E11]()}};
        }
        const {isError, task: taskNew} = checkTask(item);
        if (isError)
            isErrorGlobal = true;
        return taskNew;
    })
    return {
        isError: isErrorGlobal,
        tasks: [...tasksNew]
    }
}

function checkTask(task: any) {
    const {type} = task;
    switch (type) {
        case TASK_TYPE.TREATMENT:
            const {treatment_type = null} = task;
            if (treatment_type === null)
                return {isError: true, task: {...task, error: {treatment_type: notify[NotifyCode.E17]()}}}
            switch (treatment_type) {
                case TREATMENT_TYPE.FRESHWATER:
                case TREATMENT_TYPE.SALTWATER:
                case TREATMENT_TYPE.FRESH_WELLFIGHTER:
                case TREATMENT_TYPE.HYDROGENPEROXIDE: {
                    const {duration} = task;
                    if (duration === undefined)
                        return {isError: true, task: {...task, error: {duration: notify[NotifyCode.E16]()}}};
                    break;
                }
                case TREATMENT_TYPE.SORTING: {
                    const {conditions = [], sub_tasks} = task;
                    if (conditions.length === 0)
                        return {isError: true, task: {...task, error: {conditions: notify[NotifyCode.E10]()}}};

                    let isErrorGlobal = false;
                    const subTasksNew = Object.keys(sub_tasks).reduce((rs: any, key) => {
                        rs[key] = sub_tasks[key].map((sub: any) => {
                            const {isError, task: subTaskNew} = checkTask(sub);
                            if (isError)
                                isErrorGlobal = true;
                            return subTaskNew
                        })
                        return rs;
                    }, {});
                    return {isError: isErrorGlobal, task: {...task, sub_tasks: subTasksNew}}
                }
            }
            break;
        case TASK_TYPE.HARVEST: {
            const {destination_id} = task;
            if (!destination_id)
                return {isError: true, task: {...task, error: {factory: notify[NotifyCode.E14]()}}}
            break;
        }
        case TASK_TYPE.MOVE: {
            const {destination_id} = task;
            if (!destination_id)
                return {isError: true, task: {...task, error: {unit: notify[NotifyCode.E13]()}}}
            break;
        }
        case TASK_TYPE.COMBINE: {
            const {sub_tasks} = task;
            let isErrorGlobal = false;
            const subTasksNew = sub_tasks.map((sub: any) => {
                const {isError, task: subTaskNew} = checkTask(sub);
                if (isError)
                    isErrorGlobal = true;
                return subTaskNew
            })
            return {isError: isErrorGlobal, task: {...task, sub_tasks: subTasksNew}}
        }
        default:
            break;
    }
    return {isError: false, task}
}

export function addTask(tasks: any = [], unit: any, vessel: IVessel) {
    const {id: target_id, total_weight} = unit;
    let index = 0, isIn = false;
    for (let i = 0; i < tasks.length; i++) {
        const {treatment_type} = tasks[i];
        const {id} = tasks[i].sub_operation || tasks[i].sub_operations[0];
        index = i + 1;

        if (id !== target_id) {
            if (isIn)
                break;
        } else {
            isIn = true;
            if (treatment_type === TREATMENT_TYPE.SORTING) {
                index = i;
                break;
            }
        }
    }

    const result = Array.from(tasks);
    result.splice(index, 0, {
        type: null,
        sub_operation: convertUnit(unit),
        group_id: uuid(),
        round_number: calculateRound(total_weight, vessel),
    });
    return result;
}

export function getTaskSupport(tasks: any) {
    return tasks.reduce((list: any, item: any) => {
        const {treatment_type} = item;
        if (treatment_type === TREATMENT_TYPE.SORTING) {
            const {sub_tasks} = item;
            Object.keys(sub_tasks).forEach((key) => {
                sub_tasks[key].forEach((sub: any) => {
                    const {is_support_vessel} = sub;
                    if (is_support_vessel)
                        list.push(sub);
                })
            })
        } else {
            const {is_support_vessel} = item;
            if (is_support_vessel)
                list.push(item);
        }
        return list
    }, []);
}

interface IGenerateOpSupport {
    support_vessels: any
    vessels: any
    tasks: any
    operation: any
}

export function generateOpSupport(args: IGenerateOpSupport) {
    const {support_vessels, vessels, tasks, operation} = args;
    const subTasks = tasks.reduce((list: any, item: any) => {
        if (item.treatment_type === TREATMENT_TYPE.SORTING) {
            const {sub_tasks} = item;
            Object.keys(sub_tasks).forEach(subKey => {
                sub_tasks[subKey].forEach((subTask: any) => {
                    subTask.support_vessels?.forEach((sub: any) => {
                        list[sub.id] = [...list[sub.id] || [], {...subTask, sorting: false}]
                    })
                })
            })
        } else {
            item.support_vessels?.forEach((sub: any) => {
                list[sub.id] = [...list[sub.id] || [], {...item, sorting: false}]
            })
        }
        return list
    }, {});

    return Object.keys(support_vessels).reduce((list: any, key, index) => {
        const {
            start_place_id = '-',
            start_place_field = '-',
            activity_log: currentActivityLog
        } = support_vessels[key];
        const vesselSp: any = vessels.find((item: any) => item.id === key) || {};
        const listOfTask = subTasks[key];
        const id = Date.now() + index;

        if (listOfTask.length === 1 && listOfTask[0].type === TASK_TYPE.HARVEST) {
            const {site_id, site_name, isInternal} = args.operation;
            const {destination_id, destination_name, delivery_types} = listOfTask[0];

            const {
                currentSubOp,
                fish_amount,
                total_weight,
                prev_op_router_id,
                router_id
            } = listOfTask.reduce((list: any, item: any) => {
                const {fish_amount, total_weight} = item.sub_operations[0]
                list.fish_amount += fish_amount;
                list.total_weight += total_weight;
                list.currentSubOp.push(item.sub_operations[0]);
                list.prev_op_router_id = item.prev_op_router_id;
                list.router_id = item.router_id;
                return list;
            }, {
                currentSubOp: [],
                fish_amount: 0,
                total_weight: 0,
                prev_op_router_id: '-',
                router_id: '-'
            });

            list[id] = {
                operation: {
                    sorting: true,
                    site_id,
                    site_name,
                    factory_id: destination_id,
                    factory_name: destination_name,
                    isInternal,
                    delivery_types,
                    id,
                    start_place_id,
                    start_place_field,
                    prev_op_router_id,
                    router_id,
                    is_waiting_unit: vesselSp.type === VESSEL_TYPE.WELL_BOAT,
                    operation_code: id,
                    tenant_id: args.operation.tenant_id,
                    operation_type: OPERATION_TYPE.HARVEST,
                    sub_operations: currentSubOp,
                    tasks: listOfTask,
                    note: '',
                    related_id: args.operation.id,
                    support: SupportType.SUB,
                    fish_amount,
                    total_weight
                },
                activity_log: currentActivityLog,
                vessel: vesselSp
            }
        } else {
            list[id] = {
                operation: {
                    ...operation,
                    id,
                    operation_code: id,
                    tenant_id: args.operation.tenant_id,
                    start_place_id,
                    start_place_field,
                    operation_type: OPERATION_TYPE.TREATMENT,
                    tasks: listOfTask,
                    related_id: args.operation.id,
                    support: SupportType.SUB
                },
                activity_log: currentActivityLog,
                vessel: vesselSp
            }
        }
        return list
    }, {})
}

export function groupByUnit(tasks: any, currentUnitId?: string): {
    group: {
        [id: string]: {
            isHaveSort: boolean
            isLock: boolean
            list: any[]
        }
    }
    listOfUnit: {
        id: string
        unit_id: string
        site_id: string
    }[]
} {
    let isLock = !!currentUnitId;
    const listOfUnit: any = [];
    const group = tasks.reduce((rs: any, item: any, index: number) => {
        const {treatment_type} = item;
        const {id, unit_id, site_id} = item.sub_operation || item.sub_operations[0];
        const data = {...item, index};
        if (!rs[id]) {
            listOfUnit.push(({id, unit_id, site_id}));
            rs[id] = {
                isHaveSort: treatment_type === TREATMENT_TYPE.SORTING,
                isLock,
                list: [data]
            }
        } else {
            rs[id].list.push(data)
            if (treatment_type === TREATMENT_TYPE.SORTING) {
                rs[id].isHaveSort = true;
            }
        }
        if (id === currentUnitId)
            isLock = false;

        return rs;
    }, {});
    return {group, listOfUnit}
}

interface IGetTasksOfVessel {
    vessel: IVessel
    combineTypes: any
    userTenantId: string
}

export function getTasksOfVessel(params: IGetTasksOfVessel) {
    const {vessel, combineTypes, userTenantId} = params;
    const operation_sub_types = Array.from(vessel.operation_sub_types).sort((a: any, b: any) => a - b);

    const vesselTasks = [
        ...operation_sub_types.map((item: any) => ({
            ...treatmentType[item],
            id: [TASK_TYPE.TREATMENT, item].join('/'),
        })),
        taskTypes[TASK_TYPE.MOVE],
    ]
    if (vessel.type !== VESSEL_TYPE.SERVICE)
        vesselTasks.push(taskTypes[TASK_TYPE.HARVEST]);

    const listOfCheck = new Set(vesselTasks.map((item: any) => item.id));
    const subTypes = combineTypes[vessel.tenant_id] || [];
    const ownSubTypes = vessel.tenant_id !== userTenantId ? combineTypes[userTenantId] || [] : [];

    const validCombineTask = [...ownSubTypes, ...subTypes].reduce((list: any, item: any) => {
        const {sub_types} = item;
        const isInvalid = sub_types.some((sub: any) => !listOfCheck.has([sub.type, sub.treatment_type].join('/')));
        const id = [TASK_TYPE.COMBINE, item.id].join('/');
        if (!isInvalid)
            list.push({...item, id});

        return list;
    }, []);

    return [...validCombineTask, ...vesselTasks];
}

export function getCurrentUnitId(operation: TOperation, activity_log: IActivityLog[]) {
    const {current_process, tasks} = operation;
    if (operation.status !== OP_STATUS.PROCESSING || current_process < 0)
        return undefined;

    const {group_id} = activity_log[current_process];
    const task = getTaskByGroupId(tasks, group_id);

    if (!task)
        return undefined

    const {id = undefined} = task.sub_operation || task.sub_operations[0] || {}
    return id;
}

export function updateUnitToTask(unitAdded: IUnit[], units: any, tasks: any) {
    const unitsFail = unitAdded.reduce((list: any, item: any) => {
        const {fish_amount = null, total_weight = null} = units[item.id] || {}
        if (!fish_amount || !total_weight)
            return [...list, item.unit_id]
        return list
    }, []);

    if (unitsFail.length > 0) {
        notification.error({
            message: 'Error',
            description: `Please fill fish amount and total weight for ${unitsFail.join(', ')}`
        })
        return;
    }

    return tasks.map((item: any) => {
        const {type, treatment_type, sub_operation} = item;
        const {id: unitId} = sub_operation;
        const {fish_amount, total_weight, fish_amount_original = fish_amount} = units[unitId];
        const props = {fish_amount, total_weight, fish_amount_original}
        if (treatment_type === TREATMENT_TYPE.SORTING) {
            const {sub_tasks} = item;
            return {
                ...item,
                sub_operation: type === TASK_TYPE.MOVE ? sub_operation : {...sub_operation, ...props},
                sub_tasks: Object.keys(sub_tasks).reduce((rs: any, key) => {
                    rs[key] = sub_tasks[key].map((sub: any) => {
                        const {type} = sub;
                        if (type === TASK_TYPE.HARVEST) {
                            const {sub_operations} = sub;
                            const {delivery_types} = sub_operations[0];
                            return {
                                ...sub,
                                sub_operations: [{
                                    ...sub_operations[0],
                                    ...props,
                                    delivery_types: [{...delivery_types[0], ...props}]
                                }]
                            }
                        }
                        return {...sub, sub_operation: {...sub.sub_operation, ...props}}
                    })
                    return rs;
                }, [])
            }
        }
        if (item.sub_operations) {
            item.sub_operations = [{...item.sub_operations[0], ...props,}]
            return item;
        }
        return {...item, sub_operation: {...sub_operation, ...props}}
    })
}

export function convertTasks(tasks: any[]) {
    const {group, listOfUnit} = groupByUnit(tasks);
    return listOfUnit.reduce((rs: any[], item: any) => [...rs, ...group[item.id].list], [])
}

export function initState(props: IProps): { state: IState, currentUnitId: string | undefined } {
    const {editMode, sites, combineTypes, userTenantId} = props;
    const {operation, vessel, activity_log = []} = props.popupOperation.values;
    const startTime = activity_log.length > 0 ? activity_log[0].est_start_time : Date.now();
    const listOfTask = getTasksOfVessel({vessel, combineTypes, userTenantId});
    const common = {
        support_vessels: [],
        activity_log: [],
        operation: {tasks: []},
        store: {},
        site: {},
        units: {},
        factory: {},
        step: 1,
        loading: false,
        supportTasks: [],
        files: [],
        isCalculate: false,
        startTime,
        listOfTask
    };

    if (editMode === ACTION_TYPE.CREATE) {
        const {site, store, operation} = createTreatment({
            sites,
            store: props.store,
            vessel,
            properties: {id: Date.now(), tenant_id: userTenantId}
        });
        return {
            state: {
                ...common,
                site,
                store,
                operation,
                units: Object.keys(store).reduce((rs: any, key) => {
                    const {id, fish_amount, total_weight, round_number} = store[key];
                    rs[id] = {
                        ...convertUnit(store[key]),
                        storages: getStorages({fish_amount, total_weight, round_number,}, vessel)
                    }
                    return rs;
                }, {}),
                step: 1,
                isCalculate: true,
            },
            currentUnitId: undefined
        }
    } else {
        const {site_id, sub_operations, files = []} = operation;
        const site: any = sites.find(item => item.id === site_id);

        return {
            state: {
                ...common,
                site,
                store: props.store,
                operation: {...operation, tasks: convertTasks(operation.tasks)},
                units: sub_operations.reduce((rs: any, item: any) => {
                    rs[item.id] = item;
                    return rs;
                }, {}),
                step: 1,
                files,
            },
            currentUnitId: getCurrentUnitId(operation, activity_log)
        }
    }
}