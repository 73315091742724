import React, {Component} from 'react';
import styles from "./style.module.scss";
import ValueOfSorting from "./ValueOfSorting";
import {operatorObject} from "../../../../../../../WeatherPage/util";
import Condition from "./Condition";
import {IKeyUpdate} from "../../constants";
import {KeyOfTask, TASK_TYPE, TREATMENT_TYPE} from "../../../../../../../../util/varibles/constants";
import {formatNumber, uuid} from "../../../../../../../../util/varibles/global";
import {UpdateName} from "../../../constants";

interface IProps {
    data: any
    site: any
    unit: any
    factory: any
    listOfTask: any
    isLock: boolean

    handleChange(list: IKeyUpdate[]): void

    handleChangePosition(args: any): void

    onChangeGlobal(key: UpdateName, args: any): void
}

class Sorting extends Component<IProps> {
    handleAdd = (key: any) => {
        const {sub_tasks, sub_operation} = this.props.data;
        const tasks = sub_tasks[key] || [];
        this.props.handleChange([
            {
                key: KeyOfTask.SUB_TASKS, value: {
                    ...sub_tasks,
                    [key]: [...tasks, {type: null, group_id: uuid(), sub_operation}]
                }
            }
        ])
    }

    handleChange = (key: any, tasks: any) => {
        const {sub_tasks} = this.props.data;
        this.props.handleChange([{key: KeyOfTask.SUB_TASKS, value: {...sub_tasks, [key]: tasks}}])
    }

    handleClickOperator = (index: number, indexOperator: number) => {
        const {conditions} = this.props.data;
        this.props.handleChange([{
            key: KeyOfTask.CONDITIONS, value: conditions.map((item: any, i: number) => i === index ? {
                ...item, operator: indexOperator ? ['<', '>='] : ['<=', '>']
            } : item)
        }])
    }

    renderFirst = (common: any) => {
        const {sub_tasks = {}, index} = this.props.data;
        const {operator, value} = this.props.data.conditions[0];
        const key = operator[0] + value;
        const {labelHtml = ''} = operatorObject[operator[0]] || {};
        return <Condition
            key={key}
            parentIndex={[key, index].join('-')}
            tasks={sub_tasks[key] || []}
            handleAdd={() => this.handleAdd(key)}
            handleChange={(tasks: any) => this.handleChange(key, tasks)}
            {...common}
        >
            <div className={styles['operator']} onClick={() => this.handleClickOperator(0, 0)}>
                {labelHtml}
            </div>
            <div>{formatNumber(value)}g</div>
        </Condition>
    }

    render() {
        const {
            site,
            unit,
            factory,
            data,
            listOfTask,
            isLock,
            handleChange,
            handleChangePosition,
            onChangeGlobal
        } = this.props;

        const {sub_tasks = {}, conditions = []} = data;

        const common = {
            site,
            unit,
            factory,
            listOfTask: listOfTask.filter((item: any) => {
                if (item.type === TASK_TYPE.COMBINE) {
                    return !item.sub_types.some((sub: any) => sub.type === TASK_TYPE.TREATMENT && sub.treatment_type === TREATMENT_TYPE.SORTING)
                }

                return item.id !== [TASK_TYPE.TREATMENT, TREATMENT_TYPE.SORTING].join('/')
            }),
            isLock,
            onChangeGlobal,
            handleChangePosition
        }

        const groupByCondition = conditions.length > 0 ? conditions.reduce((list: any, item: any, index: number) => {
            const {value, operator} = item;
            if (conditions[index + 1]) {
                const {value: valueNext, operator: operatorNext} = conditions[index + 1];
                const key = operator[1] + value + '&&' + operatorNext[0] + valueNext;
                const {labelHtml: labelA = ''} = operatorObject[operator[1]] || {};
                const {labelHtml: labelB = ''} = operatorObject[operatorNext[0]] || {};
                list.push(<Condition
                    key={key}
                    tasks={sub_tasks[key] || []}
                    parentIndex={[key, data.index].join('-')}
                    handleAdd={() => this.handleAdd(key)}
                    handleChange={(tasks: any) => this.handleChange(key, tasks)}
                    {...common}
                >
                    <div className={styles['operator']} onClick={() => this.handleClickOperator(index, 1)}>
                        {labelA}
                    </div>
                    <div>{value}g</div>
                    <div className='ml-1'>and</div>
                    <div className={styles['operator']} onClick={() => this.handleClickOperator(index + 1, 0)}>
                        {labelB}
                    </div>
                    <div>{formatNumber(valueNext)}g</div>
                </Condition>)
            } else {
                const key = operator[1] + value;
                const {labelHtml = ''} = operatorObject[operator[1]] || {};
                list.push(<Condition
                    key={key}
                    tasks={sub_tasks[key] || []}
                    parentIndex={[key, data.index].join('-')}
                    handleAdd={() => this.handleAdd(key)}
                    handleChange={(tasks: any) => this.handleChange(key, tasks)}
                    {...common}
                >
                    <div className={styles['operator']} onClick={() => this.handleClickOperator(index, 1)}>
                        {labelHtml}
                    </div>
                    <div>{formatNumber(value)}g</div>
                </Condition>)
            }
            return list;
        }, [this.renderFirst(common)]) : [];

        return <>
            <ValueOfSorting handleChange={handleChange} data={data}/>
            <div/>
            <div className={styles['container-sorting']}>
                {groupByCondition}
            </div>
        </>;
    }
}

export default Sorting;