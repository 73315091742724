import callApi, {api} from "../api/callApi";
import {urlSpot} from "../api/v1";
import {CONTRACT_PERMISSION_TYPE, CONTRACT_STATUS} from "../varibles/constants";

export function getSpotsFetch() {
    return callApi(urlSpot, {
        method: "GET"
    })
}

interface ChangeSpotMode {
    vessel_id: string
    enable_spot: boolean
}

export function changeSpotModeFetch(body: ChangeSpotMode) {
    return callApi(urlSpot + '/mode', {
        method: "POST",
        body: JSON.stringify(body),
    })
}

export function getAllSpotVesselsFetch() {
    return callApi(urlSpot + '/customer', {
        method: "GET"
    })
}

interface ISendRequestSingleOperation {
    vessel_id: string
    due_time: number
}

export function sendRequestSingleOperationFetch(body: ISendRequestSingleOperation) {
    return callApi(urlSpot + '/request/single-op', {
        method: "POST",
        body: JSON.stringify(body),
    })
}

interface ISendOffer {
    start_time: number
    finish_time: number
    note: string
    tenant_id: string
    contract_id: string
    spot_offer: string
}

export function sendOfferFetch(body: ISendOffer) {
    return callApi(urlSpot + '/offer', {
        method: "POST",
        body: JSON.stringify(body),
    })
}

export function getRequestSportVesselsFetch() {
    return callApi(urlSpot + '/request', {
        method: "GET"
    })
}

interface IUpdateRequestStatus {
    tenant_id: string
    contract_id: string
    spot_status: CONTRACT_STATUS
}

export function updateRequestStatusFetch(body: IUpdateRequestStatus) {
    return callApi(urlSpot + '/response', {
        method: "PUT",
        body: JSON.stringify(body),
    })
}

export function updateSingleContractFetch(contractId: string) {
    return callApi(urlSpot + '/request/single-op/' + contractId, {
        method: "PUT",
    })
}

export function createAvailableTimeFetch(body: any) {
    return callApi(urlSpot + '/time', {
        method: "POST",
        body: JSON.stringify(body),
    })
}

export function updateAvailableTimeFetch(body: any) {
    return callApi(urlSpot + '/time', {
        method: "PUT",
        body: JSON.stringify(body),
    })
}

export function deleteAvailableTimeFetch(body: any) {
    return callApi(urlSpot + '/time/delete', {
        method: "POST",
        body: JSON.stringify(body),
    })
}

interface IUpdateContactFetch {
    id: string
    tenant_id: string
    permission: CONTRACT_PERMISSION_TYPE
}

interface IUpdateRequestStatus {
    tenant_id: string
    contract_id: string
    spot_status: CONTRACT_STATUS
}

export const SpotService = {
    updateRequestStatus: (body: IUpdateRequestStatus) => api.put(urlSpot + '/response', body),
    updateContract: (body: IUpdateContactFetch) => api.put(urlSpot + '/request/single-op', body),
    sendOffer: (body: IUpdateRequestStatus) => api.post(urlSpot + '/offer', body),
    getSpots: () => api.get(urlSpot + '/customer')
}