import React from 'react';
import {aDayMillisecond} from "../../../../../util/varibles/constants";
import {HEIGHT_PER_DAY} from "../Operations/constants";
import styles from './style.module.scss';

interface IProps {
    startTime: number
    count: number
    vesselId: string
    isAccessFull: boolean

    onMouseDown(time: number, e: any): void
}

const Days: React.FC<IProps> = ({startTime, vesselId, count, isAccessFull, onMouseDown}) => {
    const list = [];
    const style = {marginBottom: (HEIGHT_PER_DAY - 1) + 'px'};

    for (let i = 0; i <= count; i++) {
        const time = startTime + (i * aDayMillisecond);
        list.push(<div
            className={styles.day}
            style={style}
            key={i}
            data-date={time}
            data-access-full={isAccessFull}
            data-vessel-id={vesselId}
            onMouseDown={(e) => {
                if (e.button === 2) {
                    onMouseDown(time, e)
                }
            }}
        />)
    }

    return <>
        {list}
    </>;
};

export default Days;