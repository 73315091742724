import React, {useState} from 'react';
import {INTEGRATION_MODE, KEY_TAB, OPERATION_TYPE} from "../../../../util/varibles/constants";
import Header from "./Header";
import Body from "./Body";
import stylesContainer from "../style.module.scss";
import {selectTenant} from "../../../../util/store/selectors";
import {useSelector} from "react-redux";
import {calculateWidth} from "../constants";
import {useResizeDetector} from "react-resize-detector";

interface IProps {
    clear: any
    loading: boolean

    onDragStart(e: any, units: any, requestType: OPERATION_TYPE[]): void

    onDragEnd(e: any): void
}

let search: any, clear: any, getSelected: any

const Planned: React.FC<IProps> = (props) => {
    const [colPerRow, setColPerRow] = useState<number>(2);
    const {integration = {}} = useSelector(selectTenant) || {}
    const {onDragStart, onDragEnd} = props;
    const {type = ''} = integration || {};

    const {ref} = useResizeDetector({
        handleHeight: false,
        refreshMode: 'debounce',
        refreshRate: 1000,
        onResize: () => setColPerRow(calculateWidth())
    });

    if (type !== INTEGRATION_MODE.STHP_FILE)
        return null;

    return <div ref={ref} id={KEY_TAB.HARVEST} className={stylesContainer['wrapper-tab']}>
        <Header getSelected={getSelected} clear={clear} search={search}/>
        <Body
            loading={props.loading}
            colPerRow={colPerRow}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            clear={value => {
                props.clear(value);
                clear = value;
            }}
            getSelected={value => getSelected = value}
            search={value => search = value}
        />
    </div>
};

export default Planned;


