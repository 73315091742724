import React, {Component} from 'react';
import Treatment from "./Treatment";
import styles from "./style.module.scss";
import {Select} from "antd";
import WrapperTask from "../WrapperTask";
import {
    convertNumberTimeToStringTime,
    convertStringTimeToNumberTime,
    filterOption,
    uuid
} from "../../../../../../../util/varibles/global";
import {
    aMinutesMillisecond,
    KeyOfTask,
    TASK_TYPE,
    taskTypes,
    treatmentType
} from "../../../../../../../util/varibles/constants";
import Harvest from "./Harvest";
import MoveToUnit from "./MoveToUnit";
import {IKeyUpdate} from "../constants";
import Combine from "./Combine";
import {UpdateName} from "../../constants";
import {notify, NotifyCode} from "../../../../../../../util/varibles/message";

interface IProps {
    site: any
    unit: any
    factory: any
    data: any
    listOfTask: any
    isAllowDrag: boolean
    isSorting: boolean
    parentIndex: number
    isLock: boolean

    handleUpdateTask(task: any): void

    handleDeleteTask(index: number): void

    handleChangePosition(args: any): void

    onChangeGlobal(key: UpdateName, args: any): void
}

class Task extends Component<IProps> {
    static defaultProps = {
        listOfTask: [],
        isAllowDrag: true,
        isSorting: false,
        parentIndex: null
    }

    componentWillUnmount = () => {
        const task = {...this.props.data, [KeyOfTask.IS_SUPPORT_VESSEL]: false};
        this.props.onChangeGlobal(UpdateName.SUPPORT, {task});
    }

    handleUpdateTask = (list: IKeyUpdate[]) => {
        if (this.props.isLock)
            return;

        let task = JSON.parse(JSON.stringify(this.props.data));

        list.forEach(({key, value}) => {
            switch (key) {
                case KeyOfTask.TYPE : {
                    let valueNew: any = {};
                    const {unit} = this.props;
                    if (value === TASK_TYPE.HARVEST) {
                        const {factory = {}} = this.props;
                        valueNew.sub_operations = [unit];
                        valueNew.conditions = [];
                        valueNew.source_id = this.props.site.id;
                        if (factory.destination_id) {
                            const {fish_amount, total_weight} = unit;
                            const {delivery_type, ...args} = factory;
                            valueNew.destination_field = 'factory';
                            valueNew = {...valueNew, ...args};
                            valueNew.sub_operations = [{
                                ...unit,
                                delivery_types: [{...delivery_type, fish_amount, total_weight}]
                            }];
                        }
                    } else {
                        const {fish_amount} = unit;
                        valueNew.sub_operation = {...unit, fish_amount_original: fish_amount};
                    }
                    valueNew.sub_tasks = {};
                    valueNew.type = value;
                    valueNew.error = {};
                    valueNew.group_id = task.group_id;
                    valueNew.index = task.index;
                    valueNew.round_number = task.round_number;
                    task = valueNew;
                    this.props.onChangeGlobal(UpdateName.SUPPORT, {task});
                    break;
                }
                case KeyOfTask.TREATMENT_TYPE: {
                    const valueNew: any = {};
                    valueNew.round_number = task.round_number;
                    valueNew.sub_operation = task.sub_operation;
                    valueNew.sub_tasks = {};
                    valueNew.type = task.type;
                    valueNew.error = {};
                    valueNew.group_id = task.group_id;
                    valueNew.index = task.index;
                    valueNew[key] = value;
                    task = valueNew
                    this.props.onChangeGlobal(UpdateName.SUPPORT, {task});
                    break;
                }
                case KeyOfTask.SUB_TYPES: {
                    const {listOfTask, unit} = this.props;
                    const {fish_amount, total_weight} = unit;
                    const {name, sub_types} = listOfTask.find((item: any) => item.id.includes(value));
                    const valueNew: any = {};
                    valueNew.round_number = task.round_number;
                    valueNew.type = TASK_TYPE.COMBINE;
                    valueNew.sub_operation = {...unit, fish_amount_original: fish_amount};
                    valueNew.type_id = value;
                    valueNew.type_name = name;
                    valueNew.error = {};
                    valueNew.group_id = task.group_id;
                    valueNew.index = task.index;
                    valueNew.sub_tasks = sub_types.map((item: any) => {
                        const {treatment_type, duration} = item;
                        let sub: any = {
                            type: item.type,
                            sub_operation: task.sub_operation,
                            sub_tasks: {},
                            group_id: uuid(),
                            error: {}
                        };

                        if (item.type === TASK_TYPE.HARVEST) {
                            const {factory = {}} = this.props;
                            sub.sub_operations = [unit];
                            sub.conditions = [];
                            sub.source_id = this.props.site.id;
                            if (factory.destination_id) {
                                const {delivery_type, ...args} = factory;
                                sub.destination_field = 'factory';
                                sub = {...sub, ...args};
                                sub.sub_operations = [{
                                    ...unit,
                                    delivery_types: [{...delivery_type, fish_amount, total_weight}]
                                }];
                            }
                        } else {
                            const {fish_amount} = unit;
                            sub.sub_operation = {...unit, fish_amount_original: fish_amount};
                        }

                        if (treatment_type !== undefined)
                            sub.treatment_type = treatment_type

                        if (duration) {
                            sub.duration = duration * aMinutesMillisecond;
                            sub.durationTxt = convertNumberTimeToStringTime(sub.duration);
                        }

                        return sub;
                    });
                    task = valueNew;
                    this.props.onChangeGlobal(UpdateName.SUPPORT, {task});
                    break;
                }
                case KeyOfTask.DURATION: {
                    const durationTxt = value.replace(/ {2,}/g, ' ').toLowerCase();

                    task.durationTxt = durationTxt;
                    const {duration: messageError = undefined} = task.error || {};
                    if (messageError) {
                        const duration = convertStringTimeToNumberTime(durationTxt.trim());
                        if (duration !== undefined) {
                            task.duration = duration;
                            delete task.error.duration;
                        }
                    }
                    break;
                }
                case KeyOfTask.CHECK_DURATION: {
                    const {durationTxt = ''} = task;
                    if (durationTxt.length === 0)
                        task.error = {...task.error || {}, duration: notify[NotifyCode.E16]()};
                    else {
                        const duration = convertStringTimeToNumberTime(durationTxt);
                        if (duration !== undefined) {
                            task.duration = duration;
                            const {duration: messageError = undefined} = task.error || {};
                            if (messageError)
                                delete task.error.duration;
                        } else
                            task.error = {...task.error || {}, duration: notify[NotifyCode.E19]()};
                    }

                    break;
                }
                case KeyOfTask.IS_SUPPORT_VESSEL: {
                    task[key] = value;
                    this.props.onChangeGlobal(UpdateName.SUPPORT, {task});
                    break;
                }
                case KeyOfTask.FACTORY: {
                    this.props.onChangeGlobal(UpdateName.SUPPORT, {factory: value, task});
                    break
                }
                default:
                    task[key] = value;
            }
        });
        task.sorting = this.props.isSorting;

        this.props.handleUpdateTask(task);
    }

    renderContent = () => {
        const {data, site, unit, factory, listOfTask, isLock} = this.props;
        const {type} = data;
        const common = {
            data,
            site,
            unit,
            factory,
            isLock,
            listOfTask,
            handleChange: this.handleUpdateTask,
        }
        const {name = ''} = taskTypes[type] || {};
        switch (type) {
            case TASK_TYPE.TREATMENT: {
                const {handleChangePosition, onChangeGlobal} = this.props;
                const {treatment_type} = data;
                const {name: subName = ''} = treatmentType[treatment_type] || {};
                return {
                    content: <Treatment {...{...common, handleChangePosition, onChangeGlobal}}/>,
                    contentDrag: [{label: name, value: subName}],
                }
            }
            case TASK_TYPE.HARVEST: {
                const {name: siteName} = site;
                const {destination_name} = data;
                return {
                    content: <Harvest {...{...common}}/>,
                    contentDrag: [{label: name, value: `${siteName} => ${destination_name}`}],
                }
            }
            case TASK_TYPE.MOVE: {
                const {destination_name} = data;
                const {isSorting} = this.props;
                return {
                    content: <MoveToUnit {...{...common, isSorting}}/>,
                    contentDrag: [{label: name, value: destination_name}],
                }
            }
            case TASK_TYPE.COMBINE: {
                const {type_name} = data;
                const {listOfTask, parentIndex, isSorting, handleChangePosition, onChangeGlobal} = this.props;
                return {
                    content: <Combine {...{
                        ...common,
                        listOfTask,
                        parentIndex,
                        isSorting,
                        handleChangePosition,
                        onChangeGlobal
                    }}/>,
                    contentDrag: [{label: name, value: type_name}],
                }
            }
            default:
                return {
                    content: null,
                    contentDrag: [{label: 'Task', value: '-'}],
                }
        }
    }

    handleChangeTaskType = (value: any) => {
        const {type: typeOld} = this.props.data;
        const [type, sub_type = null] = value.toString().split('/');

        if (+type === TASK_TYPE.COMBINE) {
            if (this.props.data.type_id !== sub_type)
                this.handleUpdateTask([{key: KeyOfTask.SUB_TYPES, value: sub_type}])

            return;
        }

        const list = [];
        if (type !== typeOld)
            list.push({key: KeyOfTask.TYPE, value: +type})

        if (sub_type !== null)
            list.push({key: KeyOfTask.TREATMENT_TYPE, value: +sub_type})

        this.handleUpdateTask(list);
    }

    render() {
        const {
            data,
            site,
            unit,
            listOfTask,
            isAllowDrag,
            isLock,
            parentIndex,
            handleChangePosition,
            onChangeGlobal,
            handleDeleteTask
        } = this.props;
        const {type, treatment_type = null, type_id = null, error = {}} = this.props.data;
        const {Option} = Select;

        const styleError = {height: '0'}
        let isError = false;
        if (Object.keys(error).length > 0) {
            styleError.height = (Object.keys(error).length * 20 + 3) + 'px';
            isError = true;
        }
        const {content, contentDrag} = this.renderContent();

        let taskType = null;
        if (type) {
            taskType = (type_id || treatment_type) !== null ? [type, type_id || treatment_type].join('/') : type.toString();
        }

        return <>
            <WrapperTask {...{
                site,
                unit,
                data: {elementId: 1, ...data},
                isError,
                contentDrag,
                isAllowDrag,
                isLock,
                index: data.index,
                parentIndex,
                handleDelete: () => handleDeleteTask(data.index),
                handleChange: this.handleUpdateTask,
                handleChangePosition,
                onChangeGlobal
            }}>
                <Select
                    disabled={isLock}
                    className='w-full max-w-160'
                    placeholder="Select a task"
                    variant={"borderless"}
                    showSearch
                    value={taskType}
                    popupClassName={styles['dropdown-custom']}
                    onChange={this.handleChangeTaskType}
                    filterOption={filterOption}
                    popupMatchSelectWidth={false}
                >
                    {listOfTask.map(({id, name}: any) => <Option key={id} value={id.toString()}>{name}</Option>)}
                </Select>
                {content}
            </WrapperTask>
            <div className={styles['task-error']} style={styleError}>
                {Object.keys(error).map(key =>
                    <div className={styles['error-line']} key={key} dangerouslySetInnerHTML={{__html: error[key]}}/>)}
            </div>
        </>
    }
}

export default Task;