import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styles from "./style.module.scss";
import {FULL_DATE_SHORT_TIME_FORMAT} from "../../../../util/varibles/constants";
import {checkLimit} from "../../../../util/varibles/global";
import {handleScrollPointer} from "../../utils";
import {widthMinus} from "./constants";
import {datetime} from "../../../../util/library/datetime";
import {vesselDetailActions} from "../../reducer";
import {selectVessel} from "../../../../util/store/selectors";

let intervalMove: any, rulerEl: HTMLElement | null, relX = 0, position = 0;

interface IProps {
    loading: boolean
}

const Pointer: React.FC<IProps> = ({}) => {
    const [style, setStyle] = useState()
    const dispatch = useDispatch();
    const currentTime = useSelector(selectVessel.currentTime)
    const finishTime = useSelector(selectVessel.finishTime)
    const isDragging = useSelector(selectVessel.isDragging)

    useEffect(() => {
        rulerEl = document.getElementById('ruler');

        return () => {
            document.removeEventListener('mousemove', handleDragging);
            document.removeEventListener('mouseup', handleDragEnd);
            if (intervalMove)
                clearInterval(intervalMove);
        }
    }, []);

    useEffect(() => {
        const style: any = {transition: isDragging ? '' : 'right 100ms'}
        if (rulerEl) {
            const {scrollLeft, offsetWidth} = rulerEl;
            const position = (finishTime - currentTime) / 3600000 * widthMinus * 3 + 45 + scrollLeft
            style.right = checkLimit(45, offsetWidth + 45, position) + 'px';
        } else {
            style.right = ((finishTime - currentTime) / 3600000 * widthMinus * 3 + 45) + 'px';
        }
        setStyle(style)
    }, [isDragging, currentTime]);

    const handleDragging = useCallback((e: any) => {
        const {pageX} = e;
        if (!rulerEl)
            return;
        const {scrollLeft, offsetWidth} = rulerEl;
        const newPosition = checkLimit(0, offsetWidth + 45, pageX + relX) + 45;
        if (newPosition !== position) {
            position = newPosition;
            const duration = (offsetWidth + 90 - newPosition - scrollLeft) / (widthMinus * 3) * 3600000;
            dispatch(vesselDetailActions.dragging({duration: -duration, key: 'finishTime'}))
            window.clearInterval(intervalMove);
            const {left: min, right: max} = rulerEl.getBoundingClientRect();
            intervalMove = handleScrollPointer({
                target: rulerEl, pageX: checkLimit(min, max, pageX), min, max,
                action: (space: number) => {
                    const duration = space * 3600000 / (widthMinus * 3);
                    dispatch(vesselDetailActions.dragging({duration, key: 'currentTime'}))
                },
                clearInterval: () => window.clearInterval(intervalMove)
            });
        }
    }, [])

    const handleDragStart = useCallback((e: any) => {
        const {pageX, currentTarget} = e;
        relX = currentTarget.offsetLeft - pageX;
        document.addEventListener('mousemove', handleDragging);
        document.addEventListener('mouseup', handleDragEnd);
        dispatch(vesselDetailActions.beginDrag())
    }, [])

    const handleDragEnd = useCallback(() => {
        if (intervalMove)
            clearInterval(intervalMove)
        document.removeEventListener('mousemove', handleDragging);
        document.removeEventListener('mouseup', handleDragEnd);
    }, [])

    useEffect(() => {

    }, []);

    return <div {...{className: styles.pointer, style, onMouseDown: handleDragStart}}>
        <span>{datetime(currentTime).format(FULL_DATE_SHORT_TIME_FORMAT)}</span>
    </div>;
};

export default Pointer;