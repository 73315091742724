import React, {useState} from 'react';
import {connect, useDispatch} from "react-redux";
import styles from './style.module.scss';
import stylesContainer from "../../style.module.scss";
import {VIEW_MODE, viewModes} from "../../../../util/varibles/permission";
import {Divider} from "antd";
import Vessels from "./Vessels";
import {PreferenceSetting} from "../../../../util/varibles/userSetting";
import {getSetting} from "../../../../util/varibles/defaultSetting";
import {loginActions} from "../../../LoginPage/reducer";
import {AppState} from "../../../../util/store/store";

const key = PreferenceSetting.VIEW_MODE
const mapStateToProps = (state: AppState) => {
    const viewMode = getSetting(state.login.user.setting, key);
    return {viewMode}
}

interface IProps {
    viewMode: VIEW_MODE
    actions: any
}

const ViewModes: React.FC<IProps> = ({viewMode = VIEW_MODE.VESSEL, actions}) => {
    const dispatch = useDispatch();
    const [mode, setMode] = useState(viewMode);

    const changeMode = (type: VIEW_MODE) => {
        setMode(type)
        dispatch(loginActions.updateUserSetting([{[key]: type}]))
    }

    return <>
        <div className={stylesContainer.header}>
            <div className={stylesContainer.title}>
                View modes
            </div>
        </div>
        <div className={styles.wrapper}>
            <div className={styles.line}>
                {viewModes.reduce((list: any[], item) => {
                    if (actions[item.id])
                        return [...list, <div
                            key={item.id}
                            className={[styles.mode, styles.checkboxFill].join(' ')}
                            data-actived={mode === item.id}
                            onClick={() => changeMode(item.id)}
                        >
                            {item.name}
                        </div>]
                    return list;
                }, [])}
            </div>
            {mode === VIEW_MODE.VESSEL && <>
                <div className={styles.divider}>
                    <Divider/>
                </div>
                <div className={styles.wrapperVessel}>
                    <div className='font-s15 mb-10'>Please select vessel to use</div>
                    <Vessels/>
                </div>
            </>}
        </div>
    </>;
};

export default connect(mapStateToProps, {})(ViewModes);
