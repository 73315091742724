import React from 'react';
import {PlusCircleOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {openPopup} from "../../../../../components/Popup/Component/WrapperPopup";
import PopupEditExternalSite from "../../../Popup/EditExternalSite";

interface IProps {
}

const AddExternalSite: React.FC<IProps> = () => {
    const openEditExternalSite = () => {
        const el = openPopup(<PopupEditExternalSite
            visible={true}
            onClose={() => el.remove()}
        />)
    }
    return <Button className='bt-ghost' data-icon={true} onClick={openEditExternalSite}>
        <PlusCircleOutlined style={{fontSize: '19px', marginLeft: '-1px'}}/>
    </Button>;
};

export default AddExternalSite;