import React, {Component} from 'react';
import ImageDefault from "../../assets/icon/vessel_image_default.svg";
import {Storage} from "aws-amplify";
import {Image} from 'antd';

interface IProps {
    imageUrl: string
    width: any
    height: any
}

class S3Image extends Component<IProps> {
    static defaultProps = {
        width: '100%',
        height: '100%',
    }

    request: any;
    isClose: boolean = false
    state = {url: ImageDefault};

    constructor(props: IProps) {
        super(props);
        this.state = {url: ImageDefault}
        this.getImage(this.props.imageUrl)

    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        const {imageUrl} = nextProps;
        if (this.props.imageUrl !== nextProps.imageUrl) {
            this.setState({url: ImageDefault});
            this.getImage(imageUrl);
        }
        return this.state !== nextState;
    }

    componentWillUnmount() {
        this.isClose = true;
    }

    getImage = (imageUrl: string) => {
        try {
            Storage.get(imageUrl)
                .then((url) => {
                    if (!this.isClose)
                        this.setState({url})
                })
                .catch((error) => console.log(error))
        } catch (error) {
        }
    }

    render() {
        const {url} = this.state;
        const {width, height} = this.props;
        return <Image {...{
            width,
            height,
            src: url.toString(),
            fallback: ImageDefault
        }} />
    }
}

export default S3Image;
