import React, {Component} from 'react';
import "./style.scss";
import {connect} from "react-redux";
import Guide from "./Component/Guide";
import Map from "./Component/Map";
import Content from "./Content";
import {SOCKET_FUNC, SOCKET_LEV} from "../../web-socket/constants";
import WSService from "../../web-socket/web-socket-service";
import {routeActions} from "./reducer";
import {getSearchFromUrl} from "../../util/library/Router";
import GoogleMap, {googleMap} from "../../util/library/googlemap/googlemap";

interface IProps {
    initialData(payload: any): void

    getLocations(): void

    showAutoRoute(payload: any): void

    loadMap(payload: GoogleMap): void
}

class RoutePage extends Component<IProps> {
    socket: WSService | undefined;

    constructor(props: IProps) {
        super(props);
        let source_id = null, destination_id = null;
        const params = getSearchFromUrl();
        if (params.length >= 2) {
            params.forEach(item => {
                const [label, value] = item.split('=');
                if (label === 'source')
                    source_id = value
                else if (label === 'destination')
                    destination_id = value
            })
        }

        if (source_id && destination_id)
            this.props.initialData({source_id, destination_id});

        this.socket = new WSService({
            groups: ['auto-route'],
            listener: {
                lev: SOCKET_LEV.PAGE,
                func: (value: any) => this.processMessage(value)
            }
        });
        this.props.getLocations();
        this.props.loadMap(googleMap('map'))
    }

    processMessage = (value: any) => {
        switch (value.function) {
            case SOCKET_FUNC.AUTO_ROUTE: {
                this.props.showAutoRoute(value);
                break;
            }
            default:
                return;
        }
    }

    render() {
        return <div className="m-header position-relative bgPage">
            <Map/>
            <Content getConnectId={WSService.getConnectId}/>
            <Guide/>
        </div>;
    }
}

export default connect(() => {}, {
    initialData: routeActions.initialDataRequest,
    getLocations: routeActions.getLocationsRequest,
    showAutoRoute: routeActions.autoRouteSuccess,
    loadMap: routeActions.loadMap
})(RoutePage);

