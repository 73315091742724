import {api} from "../api/callApi";
import {urlPoi} from "../api/v1";
import {LOCATION_TYPE} from "../varibles/constants";

interface ICreatePoi {
    name: string
    type: 'poi' | 'site' | 'factory'
}

interface IUpdatePoi {
    name: string
    id: string
    type: 'poi' | 'site' | 'factory'
}

interface IGetWeatherOfPoi {
    id: string,
    type: LOCATION_TYPE
}

export const PoiService = {
    gets: () => api.get(urlPoi + 's'),
    create: (params: ICreatePoi) => api.post(urlPoi, params),
    update: (params: IUpdatePoi) => api.put(urlPoi, params),
    delete: (id: string) => api.deleteBody(urlPoi, {id}),
    weather: (params: IGetWeatherOfPoi) => api.put(urlPoi + '/weather', params),
}