import React from 'react';
import styles from "../style.module.scss";
import stylesContainer from "../style.module.scss";
import SelectTime from "../../../_Component/SelectTime";
import AddUnit from "../../../_Component/AddUnit";
import AttachFile from "../../../_Component/AttachFile";
import AddNote from "../../../_Component/AddNote";
import {UpdateName} from "../../constants";
import {Divider} from "antd";
import Certain from "../../../_Component/Certain";
import Files from "../../../_Component/AttachFile/Files";
import {CERTAIN_TYPE, OP_STATUS, VIEW_SMOOTH} from "../../../../../../../util/varibles/constants";
import LineMiddle from "../../../_Component/LineMiddle";
import GroupByUnit from "../GroupByUnit";
import Cleaning from "../../../_Component/Clening";
import {IState} from "../index";
import {IOperation, IOperations, IVessel} from "../../../../../../../util/varibles/interface";
import Footer from "../../../Footer";
import {planOpActions} from "../../../../../reducer";
import {useDispatch} from "react-redux";
import {IUpdateOp} from "../../../../../constants";

let control: AbortController;

interface IProps {
    listOfUnit: any
    group: any
    currentUnitId: any

    state: IState
    vessel: IVessel
    setState: Function

    handleUpdate(key: UpdateName, args: any): void

    onSubmit(finish: Function): void

    onSave(payload: IUpdateOp): void

    onClose(): void
}

const ChangeOperation: React.FC<IProps> = (props) => {
    const {state, listOfUnit, group, vessel, currentUnitId, setState, handleUpdate} = props;
    const {startTime, site, units = {}, factory, files = [], listOfTask = [], supportTasks, loading} = state;
    const {tasks = [], note = '', certain_mode = CERTAIN_TYPE.NONE, status = null} = state.operation;
    const dispatch = useDispatch();

    const handleSubmit = () => {
        props.onSubmit((data: IOperation) => {
            const {support_vessel} = data.operation;
            if (support_vessel) {
                setState({step: 2, operation: data.operation}, () => {
                    setTimeout(() => {
                        const el = document.getElementById('support-vessel');
                        if (el)
                            el.scrollIntoView(VIEW_SMOOTH);
                    }, 100)
                })
            } else {
                const {id: opId = 0} = data.operation;
                if (control)
                    control.abort();
                control = new AbortController();
                setState({loading: true});
                dispatch(planOpActions.getActivityLog({
                    source: [data],
                    properties: {[data.operation.id]: {start: startTime}},
                    signal: control,
                    success: (ops: IOperations) => {
                        setState({loading: false});
                        const {activity_log} = ops[opId];
                        const newOp = {...data, activity_log};
                        const value = (`${opId}`).length > 13 ? {update: {[opId]: newOp}} : {add: {[opId]: newOp}}
                        const listOfFile = files.length > 0 ? {[opId]: files} : {};
                        props.onSave({...value, files: listOfFile});
                    },
                    failure: () => {
                        setState({loading: false});
                    }
                }))
            }
        })
    }

    const handleChangeFiles = (files: any) => {
        setState({files})
    }

    const handleDeleteFile = (file: any) => {
        const {name} = file;
        const filesNew = files.filter((item: any) => item.name !== name);
        handleChangeFiles(filesNew);
    }

    return <div id='plan-treatment' className={styles['container-plan']}>
        <div className={styles['sub-plan']}>
            <SelectTime time={startTime} onChange={(value) => setState({startTime: value})}/>
            <div className={styles['sub-section-right']}>
                <AddUnit sites={[site]} except={listOfUnit} onUpdate={handleUpdate}/>
                <AttachFile files={files} handleChange={handleChangeFiles} isShowList={false}/>
                <AddNote
                    isTooltip
                    value={note}
                    onChange={(note: string) => handleUpdate(UpdateName.NOT_CALCULATE, {note})}
                />
                <Divider type='vertical'/>
                <Certain
                    value={certain_mode}
                    onChange={(certain_mode: any) => handleUpdate(UpdateName.NOT_CALCULATE, {certain_mode})}
                />
            </div>
        </div>
        <div className={styles['sub-files']} data-is-show={files.length > 0}>
            <Files files={files} onDelete={handleDeleteFile}/>
        </div>
        <div className={styles['wrapper-operation']}>
            {listOfUnit.length === 0 && <div className={styles.line} data-lev='empty'>No task</div>}
            <div id='treatment-operation' className={styles.operation}>
                {(status !== OP_STATUS.PROCESSING && listOfUnit[0]) &&
                    <div className={stylesContainer['line-middle']} data-level='top'>
                        <LineMiddle {...{
                            containerId: 'treatment-operation',
                            unitId: listOfUnit[0].id,
                            index: 0
                        }} />
                    </div>}
                {listOfUnit.map((item: any, index: number) => <GroupByUnit key={item.id} {...{
                    ...group[item.id],
                    currentUnitId,
                    groupIndex: index,
                    site,
                    vessel,
                    unit: units[item.id],
                    factory,
                    listOfTask,
                    onUpdate: handleUpdate,
                }}/>)}
            </div>
        </div>
        <div className={[styles['sub-info'], 'mt-5 mb-10'].join(' ')}>
            {tasks.length > 0 && <Cleaning op={state.operation} onChange={handleUpdate}/>}
        </div>
        <div className='mt-auto'>
            <Footer {...{
                saveTxt: supportTasks.length ? 'Select support vessel' : undefined,
                disableSave: tasks.length === 0,
                loading,
                handleSubmit,
                onClose: props.onClose,
            }}/>
        </div>
    </div>;
};

export default ChangeOperation;
