import React from 'react';
import styles from "../style.module.scss";
import S3Image from "../../../../../components/S3Image";
import HistoryVessel from "./HistoryVessel";
import stylesParent from "../../style.module.scss";
import {durationTxtByStartAndFinish, getStatusOfOp} from "../../../../../util/varibles/global";
import {connect} from "react-redux";
import Position from "../../../Component/Position";
import LoadingBox from "../../../../../components/LoadingBox";
import {NavLink} from "react-router-dom";
import {AppState} from "../../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    operations: state.dashboard.operations,
});

interface IProps {
    data: any
    operations: any
}

const Vessel: React.FC<IProps> = ({data, operations}) => {
    if (!data)
        return <>
            {data.name && <div className={stylesParent.header}>
                <NavLink to={'/vessel/' + data.id}>
                    {data.name}
                </NavLink>
            </div>}
            <div className='mt-100'><LoadingBox message='No data'/></div>
        </>;

    const {capacity, contact, gps, image_url, id, iot_name, name} = data;
    const currentTime = Date.now();
    const {time, Latitude, Longitude, Speed, Heading} = gps
    const operation = operations.find((item: any) => item.vessel_id === id);
    let statusText = 'Idle'
    if (operation) {
        const {current_process, activity_log} = operation;
        statusText = getStatusOfOp({
            operation: operation.operation,
            current_process,
            activity_log,
        });
    }
    const duration = durationTxtByStartAndFinish(time, currentTime, true);

    return <>
        <div className={stylesParent.header}>
            <NavLink to={'/vessel/' + id}>
                {name}
            </NavLink>
        </div>
        <div className={stylesParent.body}>
            <div className={styles['line-info']}>
                <span>Contact number:</span>
                <span>{contact !== '-' && contact}</span>
            </div>
            {capacity ? <div className={styles['line-info']}>
                <span>Capacity:</span>
                <span>{Math.round(capacity / 10000) / 100} t</span>
            </div> : null}
            <div className='mt-10 mb-10'>
                <S3Image {...{imageUrl: image_url, height: 210}}/>
            </div>
            <div className={styles['line-info']}>
                <span>Current speed / Heading:</span>
                <span>{Math.round(Speed * 100) / 100} knots / {Math.round(Heading)}&#xb0;</span>
            </div>
            <Position {...{lat: Latitude, lng: Longitude}}/>
            <div className={[styles['line-comment'], 'mt-10 mb-10'].join(' ')}>
                {duration.length > 0 ? `Updated ${duration} ago` : 'Just updated'}
            </div>
            <div className={styles['line-info']}>
                <span>Status:</span>
                <span>{statusText}</span>
            </div>
            {operation &&
                <div className={styles['line-operation']} data-operation-type={operation.operation.operation_type}>
                    <div/>
                    <a href={`/operation/${operation.operation.tenant_id}/${operation.operation.id}`}>
                        #{operation.operation.operation_code}
                    </a>
                </div>}
            <HistoryVessel iotName={iot_name} position={gps} vessel_id={id}/>
        </div>
    </>;
};

export default connect(mapStateToProps, {})(Vessel);
