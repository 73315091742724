import React, {useEffect, useState} from 'react';
import styles from "./style.module.scss";
import {FULL_DATE_FORMAT} from "../../../../../util/varibles/constants";
import {formatNumber} from "../../../../../util/varibles/global";
import FishDisease from "../../_Component/FishDisease";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Checkbox} from "antd";
import {datetime} from "../../../../../util/library/datetime";
import {useSelector} from "react-redux";
import {selectPlan} from "../../../../../util/store/selectors";
import {redirectCreateRoute} from "../../constants";

interface IProps {
    data: any
    className?: string
    messageText?: string
    message?: any

    getCount(): any

    getSelected(): any

    onCheck(isCheck: boolean): void

    onDragStart(e: any, units: any): void

    onDragEnd(e: any): void
}

let countTime: number = 0, isFlag = false;

const Item: React.FC<IProps> = (props) => {
    const [{isError, message, messageText, isActive}, setError] = useState<{
        isError: boolean,
        message: any,
        messageText?: string,
        isActive: boolean
    }>({isError: false, message: null, isActive: false});
    const {
        className = styles['store-default'],
        getSelected,
        onDragStart,
        onDragEnd,
        onCheck
    } = props;
    const routeIds = useSelector(selectPlan.routeIds);
    const {
        id,
        harvest_id = '-',
        harvest_date, packing_date,
        router_id,
        site_id, site_name,
        unit_id,
        factory_id, factory_name,
        fish_amount, avg_weight, total_weight,
        diseases,
        isCheck,
        count
    } = props.data;

    useEffect(() => {
        const route = router_id || routeIds.has([site_id, factory_id].join('_'));
        let isActive = true, message, messageText, isError = false;
        if (fish_amount <= 0) {
            isActive = false;
            message = `There is no fish in the unit to plan operation.`;
        }

        if (!route) {
            isError = true;
            messageText = `There is no route between ${site_name} and ${factory_name}`;
            message = <>
                <span>There is no route between <b>{site_name}</b> and <b>{factory_name}</b>.</span>
                <span className={styles.link} onMouseDown={() => redirectCreateRoute(props.data)}>Click here </span>
                <span>to create route first, then plan later.</span>
            </>
        }
        setError({isActive, message, messageText, isError})
    }, [routeIds, site_id, factory_id, router_id, fish_amount]);

    const handleMouseDown = (e: any) => {
        if (e.button !== 0 || isError || e.target.closest('.ant-checkbox'))
            return;

        document.addEventListener('mouseup', handleMouseUp);
        document.body.setAttribute('data-is-prevent-select', 'true');

        if (isCheck)
            countTime = Date.now();
        else {
            onCheck(true)
        }
    };

    const handleMouseUp = () => {
        document.removeEventListener('mouseup', handleMouseUp);
        document.body.setAttribute('data-is-prevent-select', 'false');

        if ((Date.now() - countTime) < 500 && !isFlag) {
            onCheck(false);
        }
    };

    const keyCount = [datetime(packing_date).format(FULL_DATE_FORMAT), factory_id].join('|');
    const {[keyCount]: max = 1} = props.getCount() || {};

    const startPlace = [site_name, unit_id].join(', ');
    return <>
        <div {...{
            id: `${id}|${harvest_id}`,
            className: [styles.store, className].join(' '),
            onMouseDown: e => handleMouseDown(e),
            'data-checked': isCheck,
            'data-is-active': isActive,
            'data-is-error': isError,
            tabIndex: 1,
        }}>
            {isError
                ? <div className={styles['icon-error']}><ExclamationCircleOutlined/></div>
                : <Checkbox {...{
                    checked: isCheck,
                    disabled: !isActive || isError,
                    onChange: (e: any) => !isError ? onCheck(e.target.checked) : false
                }}/>}

            <div className={styles.id} title={harvest_id}>
                {max > 1 ? <span className='text-truncate'>{count}</span> : null}
                <span className='text-truncate' title={harvest_id}>{harvest_id}</span>
            </div>
            <div className='text-center'>
                {datetime(harvest_date.replace(' ', 'T')).format(FULL_DATE_FORMAT)}
            </div>
            <div className='text-center'>
                {datetime(packing_date.replace(' ', 'T')).format(FULL_DATE_FORMAT)}
            </div>
            <div className='text-truncate pl-2' title={startPlace}>
                {startPlace}
            </div>
            <div className='text-truncate pl-2' title={factory_name}>{factory_name}</div>
            <div className='pr-2 text-right'>{formatNumber(fish_amount)}</div>
            <div className='pr-2 text-right'>{formatNumber(avg_weight)} gr</div>
            <div className='text-right'>{formatNumber(Math.round(total_weight / 1000) / 1000)} t</div>
            <FishDisease data={diseases}/>

            <div
                className={styles['drag-view']}
                draggable={isActive}
                onDragStart={(e) => isError ? null : onDragStart(e, getSelected())}
                onDragEnd={onDragEnd}
            >
                {message && <div className={styles.note} title={messageText || message}>{message}</div>}
            </div>
        </div>
    </>
};

export default Item;
