import React, {Component} from 'react';
import AvailableTime from "./AvailableTime";
import {IAvailableTime, IVessel} from "../../../../../util/varibles/interface";
import {TargetTypes} from "../Vessels/constants";

interface IProps {
    available_times: IAvailableTime[]
    startPoint: number
    finishTime: number
    vesselIndex: number
    width: number
    vessel: IVessel

    setTarget(target: TargetTypes, e: MouseEvent): void
}

class AvailableTimes extends Component<IProps> {

    render() {
        const {
            startPoint,
            vesselIndex,
            width,
            vessel,
            finishTime,
            available_times,
            setTarget
        } = this.props;

        return available_times.map((sub: any, index) => <AvailableTime key={sub.id} {...{
            index,
            data: sub,
            vessel,
            width,
            startPoint,
            finishTime,
            vesselIndex,
            setTarget,
        }}/>);
    }
}

export default AvailableTimes;