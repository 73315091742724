import React from 'react';
import styles from '../style.module.scss';
import {Switch} from "antd";
import {connect, useDispatch} from "react-redux";
import {DashboardSetting} from "../../../../util/varibles/userSetting";
import {loginActions} from "../../../LoginPage/reducer";
import {dashboardActions} from "../../reducer";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState) => {
    const {activeSite} = state.dashboard.filter || {};
    return {
        activeSite,
    }
};

interface IProps {
    activeSite: boolean
}

const ActiveSite: React.FC<IProps> = ({activeSite}) => {
    const dispatch = useDispatch();

    const onChange = (value: any) => {
        dispatch(dashboardActions.updateFilter({activeSite: value}));
        dispatch(loginActions.updateUserSetting([{[DashboardSetting.ACTIVE_SITE]: value}]))
    };

    return <div className={styles.line}>
        <div className='font-s15'>Only active site</div>
        <Switch checked={activeSite} onChange={onChange}/>
    </div>;
};
export default connect(mapStateToProps, {})(ActiveSite);
