import React from 'react';
import styles from './style.module.scss';
import {IPropsChildren} from "../../../../../util/varibles/interface";

let countTime = 0;

const areEqual = (prevProps: IProps, nextProps: IProps) => {
    const isChange = prevProps.checked !== nextProps.checked
        || prevProps.isError !== nextProps.isError
        || prevProps.isActive !== nextProps.isActive
        || prevProps.message !== nextProps.message
        || prevProps.messageText !== nextProps.messageText
        || JSON.stringify(prevProps.style) !== JSON.stringify(nextProps.style)
        || JSON.stringify(prevProps.data) !== JSON.stringify(nextProps.data)

    return !isChange
};

interface IProps extends IPropsChildren {
    data: any
    checked: boolean
    isError?: boolean
    isActive?: boolean
    message?: any
    messageText?: any
    style?: any

    onContext?(e: React.MouseEvent<HTMLDivElement>): void

    onChange(): void

    onDragStart(e: any): void

    onDragEnd(e: any): void
}

const Card: React.FC<IProps> = props => {
    const {
        checked,
        isError = false,
        message,
        messageText,
        style,
        children,
        onChange,
        onDragStart,
        onDragEnd,
        onContext
    } = props;

    const handleMouseDown = (e: any) => {
        if (e.button !== 0)
            return;

        if (isError)
            return;

        document.addEventListener('mouseup', handleMouseUp);
        document.body.setAttribute('data-is-prevent-select', 'true');

        if (checked)
            countTime = Date.now();
        else {
            onChange()
        }
    };

    const handleMouseUp = () => {
        document.removeEventListener('mouseup', handleMouseUp);
        document.body.setAttribute('data-is-prevent-select', 'false');

        if ((Date.now() - countTime) < 500 && checked) {
            onChange();
        }
    };

    const handleContextMenu = (e: any) => {
        e.preventDefault();
        if (onContext)
            onContext(e);
    }

    return <div
        className={styles.card}
        data-checked={checked}
        onMouseDown={handleMouseDown}
        onContextMenu={handleContextMenu}
        style={style}
    >
        {children}
        <div
            className={styles['drag-view']}
            draggable={!isError}
            onDragStart={(e) => !isError ? onDragStart(e) : null}
            onDragEnd={onDragEnd}
        >
            {message && <div className={styles.note} title={messageText ? messageText : message}>{message}</div>}
        </div>
    </div>;
};

export default React.memo(Card, areEqual);