import React, {ReactNode, useEffect, useState} from 'react';
import styles from './style.module.scss';
import {Empty} from "antd";

const view = {
    custom: (message: string | ReactNode, imgEmpty?: ReactNode) => <>
        <div className={styles.img}>
            {imgEmpty ? imgEmpty : <div className={styles.imgDefault}/>}
        </div>
        <div className={styles.message}>
            {message}
        </div>
    </>,
    ant: (message: string | ReactNode) => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={message}/>
}


interface IProps {
    className?: string
    imgEmpty?: ReactNode
    message?: string | ReactNode
    loading?: boolean
    radius?: number
    level?: 'page' | 'box' | 'bg'
    type?: 'custom' | 'ant'
    spinnerSize?: number
}

const LoadingBox: React.FC<IProps> = (props) => {
    const {
        className = '',
        imgEmpty,
        message = 'No data',
        loading = false,
        level = 'box',
        type = 'ant',
        spinnerSize = 30,
        radius = 0,
    } = props;

    const [size, setSize] = useState({});

    useEffect(() => {
        setSize({width: spinnerSize + 'px', height: spinnerSize + 'px', borderWidth: spinnerSize / 7.5 + 'px'});
    }, [spinnerSize]);

    const style: any = {};
    if (radius) {
        style.borderRadius = `${radius}px`
    }

    return <div className={[styles.wrapper, className].join(' ')} data-level={level} style={style}>
        {loading ? <div
            className={[styles.spinner].join(' ')}
            style={size}
        /> : view[type](message, imgEmpty)}
    </div>;
};

export default LoadingBox;
