import React, {Component} from 'react';
import {connect} from "react-redux";
import {USER_ROLE, VESSEL_DEVICE_TYPE_NAME} from "../../util/varibles/constants";
import styles from './style.module.scss';
import WSService from "../../web-socket/web-socket-service";
import {SOCKET_FUNC, SOCKET_LEV} from "../../web-socket/constants";
import {generateActions, VIEW_MODE} from "../../util/varibles/permission";
import Calendar from "./Calendar";
import CalendarVessel from "./CalendarVessel";
import {PreferenceSetting} from "../../util/varibles/userSetting";
import WrapperTrackingOp from "../OverviewPage/WrapperTrackingOp";
import {getSetting} from "../../util/varibles/defaultSetting";
import {overviewActions} from "./reducer";
import {AppState} from "../../util/store/store";

const mapStateToProps = (state: AppState) => {
    const mode = getSetting(state.login.user.setting, PreferenceSetting.VIEW_MODE)

    return {
        roles: state.login.user.roles,
        user_id: state.login.user.user_id,
        mode,
    }
};

interface IProps {
    roles: USER_ROLE[]
    user_id: string
    mode: VIEW_MODE,

    initialData(payload: any): void

    updateOps(payload: any[]): void

    deleteOps(payload: any[]): void

    addOps(payload: any[]): void
}

class OverviewPage extends Component<IProps> {
    socket: WSService | undefined;
    groupIds = [];
    actions = {};

    constructor(props: any) {
        super(props);
        const {roles = []} = this.props;
        this.actions = generateActions(roles);
        this.props.initialData({action: this.registerWebSocket});
    }

    componentWillUnmount() {
        if (this.socket) {
            this.socket.removeListener(SOCKET_LEV.PAGE);
            this.socket.unregisterGroups(this.groupIds)
        }
    }

    processMessage = (value: any) => {
        switch (value.function) {
            case SOCKET_FUNC.OPERATION_STATUS_CHANGE:
            case SOCKET_FUNC.OPERATION_STATE_CHANGE: {
                this.props.updateOps(value.message);
                break;
            }
            case SOCKET_FUNC.OPERATION_DELETED: {
                this.props.deleteOps(value.message);
                break;
            }
            case SOCKET_FUNC.OPERATION_CONFIRMED: {
                this.props.addOps(value.message);
                break;
            }
            default:
                return;
        }
    }

    registerWebSocket = (vessels: any) => {
        const {user_id} = this.props;
        if (user_id && vessels.length > 0) {
            this.groupIds = vessels.reduce((list: any, item: any) => {
                const {id} = item.vessel
                return [...list, id, id + "-" + VESSEL_DEVICE_TYPE_NAME.LOCATION]
            }, [])
            this.socket = new WSService({
                groups: this.groupIds,
                listener: {
                    lev: SOCKET_LEV.PAGE,
                    func: (value: any) => this.processMessage(value)
                }
            });
        }
    }

    render() {
        const {mode} = this.props;

        return <div className="m-header bgPage">
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    {mode === VIEW_MODE.PLANNER ? <Calendar/> : <CalendarVessel/>}
                </div>
                <WrapperTrackingOp/>
            </div>
        </div>;
    }
}

export default connect(mapStateToProps, {
    initialData: overviewActions.initialDataRequest,
    updateOps: overviewActions.updateOps,
    deleteOps: overviewActions.deleteOps,
    addOps: overviewActions.addOps,
})(OverviewPage);
