import React from 'react';
import Control from "../Control";
import {useSelector} from "react-redux";
import Layout from "./Layout";
import {selectPermission} from "../../../util/store/selectors";

interface IProps {
}

const Map: React.FC<IProps> = () => {
    const permission = useSelector(selectPermission)
    return <div className='map'>
        <Layout/>
        <Control permission={permission}/>
    </div>
};

export default Map;
