import React, {Component, createRef} from 'react';
import styles from "./style.module.scss";
import {checkLimit} from "../../../../../../../util/varibles/global";
import stylesContainer from "../style.module.scss";
import DragEl from "../../../_Component/DragEl";
import {IKeyUpdate} from "../constants";
import {getParentEl, TREATMENT_TYPE} from "../../../../../../../util/varibles/constants";
import LineMiddle from "../../../_Component/LineMiddle";
import Setting from "./Setting";
import {UpdateName} from "../../constants";
import {IPropsChildren, ISite, IUnit} from "../../../../../../../util/varibles/interface";
import ReactDOM from "react-dom/client";

const disableSelect = (event: any) => {
    event.preventDefault();
}

interface IProps extends IPropsChildren {
    site: ISite
    unit: IUnit
    data: any;
    isError: boolean
    contentDrag: any;
    isAllowDrag: boolean
    isLock: boolean
    isEmptyTasks: boolean
    parentIndex: number

    handleDelete?(): void

    handleChange(list: IKeyUpdate[]): void

    handleChangePosition(args: any): void

    onChangeGlobal(key: UpdateName, args: any): void
}

class WrapperTask extends Component<IProps> {
    static defaultProps = {
        isEmptyTasks: false,
        parentIndex: null,
    }
    rootRef: any = createRef<HTMLDivElement>()
    dragEl: HTMLDivElement | any;
    levelMode = 'all';

    componentDidMount = () => {
    }

    componentWillUnmount = () => {
        document.body.style.cursor = 'unset';
        window.removeEventListener('selectstart', disableSelect);
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
    }

    handleMouseDown = () => {
        window.addEventListener('selectstart', disableSelect);
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
        this.dragEl = document.createElement('div');
        this.dragEl.style.position = 'absolute';
        this.dragEl.style.zIndex = '2000';
        this.dragEl.style.pointerEvents = 'none';
        this.dragEl.style.display = 'none';
        const {contentDrag} = this.props;
        const root = ReactDOM.createRoot(this.dragEl as HTMLElement);
        root.render(<DragEl {...{data: contentDrag}}/>);
        const parentEl = getParentEl();
        parentEl.append(this.dragEl);
        this.levelMode = 'all';
    }

    handleMouseMove = (e: any) => {
        const parentEl = document.getElementById('treatment-operation');
        if (!parentEl)
            return;

        parentEl.dataset.isDrag = this.levelMode;

        if (this.rootRef.current)
            this.rootRef.current.dataset.isDrag = true;

        const {pageX, pageY} = e;
        this.dragEl.style.display = 'block';
        const {offsetHeight, offsetWidth} = this.dragEl;
        this.dragEl.style.display = 'none';
        const left = checkLimit(0, window.innerWidth - offsetWidth - 5, pageX);
        const top = checkLimit(0, window.innerHeight - offsetHeight, pageY);
        this.dragEl.style.left = left - 10 + 'px';
        this.dragEl.style.top = top - 10 + 'px';
        this.dragEl.style.display = 'block';
        document.body.style.cursor = 'grabbing';
    }

    handleMouseUp = (e: any) => {
        if (this.dragEl) {
            this.dragEl.remove();
            delete this.dragEl;
        }
        document.body.style.cursor = 'unset';

        const parentEl = document.getElementById('treatment-operation');
        if (!parentEl)
            return;

        delete parentEl.dataset.isDrag;
        if (this.rootRef.current)
            delete this.rootRef.current.dataset.isDrag;
        window.removeEventListener('selectstart', disableSelect);
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);

        if (!e.target)
            return;
        const {index, unitId} = e.target.dataset;
        if (index === undefined)
            return;
        e.target.dataset.hover = false;
        const params = {
            newPosition: index,
            oldPosition: [this.props.data.index, this.props.parentIndex].join('-'),
            unitId,
        };
        this.props.handleChangePosition(params);
    }

    render() {
        const {site, isError, isAllowDrag, isEmptyTasks, isLock, parentIndex} = this.props;
        const {elementId, type, index, is_support_vessel, treatment_type} = this.props.data;

        return <div ref={this.rootRef} className={styles['container-task']}>
            {(isAllowDrag && type) &&
                <div className={stylesContainer['bt-drag']} onMouseDown={this.handleMouseDown}/>}
            <div
                id={elementId}
                className={[styles.task, stylesContainer['wrapper-task']].join(' ')}
                data-is-empty={isEmptyTasks}
                data-task={type}
                data-error={isError}
                data-allow-drag={isAllowDrag}
            >
                {this.props.children}
                <div className={[styles.menu, stylesContainer.menu].join(' ')}>
                    {is_support_vessel && <div className={styles['icon-support-vessel']}/>}
                    {!isLock && <Setting
                        site={site}
                        data={this.props.data}
                        onChange={this.props.handleChange}
                        onDelete={this.props.handleDelete}
                        onChangeGlobal={this.props.onChangeGlobal}
                    />}
                </div>
            </div>
            {(!isLock && isAllowDrag && treatment_type !== TREATMENT_TYPE.SORTING)
                && <div className={[stylesContainer['line-middle'], styles['line-middle']].join(' ')} data-level='task'>
                    <LineMiddle {...{
                        containerId: 'treatment-operation',
                        unitId: this.props.unit.id,
                        index: index + 1,
                        parentIndex
                    }} />
                </div>}
        </div>;
    }
}

export default WrapperTask;
