import {YEAR_WEEK_FORMAT} from "../../../../util/varibles/constants";
import {IProps} from "./Body";
import {datetime} from "../../../../util/library/datetime";
import {getKeyUnit} from "../../util";

export const onFilter = (state: any, props: IProps): any => {
    const {
        searchValue,
        weightFilter,
    } = state;
    const {harvests, fishChanges} = props;
    const {
        plannedSiteFilter,
        plannedFactoryFilter,
        plannedProdAreaFilter,
        showSTHPEmpty,
        sthpPriorWeeks,
    } = props.setting;

    const listOfSiteFilter = new Set(plannedSiteFilter);
    const listOfProdAreaFilter = new Set(plannedProdAreaFilter);
    const countSite = plannedSiteFilter.length;
    const countProdArea = plannedProdAreaFilter.length;
    const currentWeek = +datetime().format(YEAR_WEEK_FORMAT);


    return harvests.reduce((rs: any, item: any) => {
        let {
            harvest_id = '',
            site_id = '',
            factory_id = '',
            site_name = '',
            factory_name = '',
            prod_area = '',
            avg_weight = 0,
            fish_amount = 0,
            total_weight = 0,
            harvest_date
        } = item;

        const key = getKeyUnit(item);
        const changes = Object.keys(fishChanges[key] || {}).reduce((value: number, subKey) => value + fishChanges[key][subKey], 0);
        if (changes) {
            fish_amount += changes;
            total_weight = fish_amount * avg_weight
        }

        const [minWeight, maxWeight] = weightFilter;
        const week = +datetime(harvest_date.replace(' ', 'T')).format(YEAR_WEEK_FORMAT);
        const isOldWeek = sthpPriorWeeks || week >= currentWeek;
        if (!isOldWeek) return false;

        const isSearch = searchValue.length > 0 ?
            [harvest_id.toLowerCase(), site_name.toLowerCase(), factory_name.toLowerCase()]
                .some(item => item.indexOf(searchValue.toLowerCase()) !== -1) : true
        if (!isSearch) return false;

        const isSite = (countSite === 0 || listOfSiteFilter.has(site_id))
            && (countProdArea === 0 || listOfProdAreaFilter.has(prod_area));
        if (!isSite) return false;

        const isFactory = plannedFactoryFilter.length === 0 || plannedFactoryFilter.indexOf(factory_id) !== -1;
        if (!isFactory) return false;

        const isMin = minWeight ? minWeight <= avg_weight : true;
        if (!isMin) return false;

        const isMax = maxWeight ? maxWeight >= avg_weight : true;
        if (!isMax) return false;

        const value = {...item, fish_amount, total_weight}
        return showSTHPEmpty || fish_amount > 0 ? [...rs, value] : rs;
    }, [])
}

export const getPlan = (source: any) => Object.keys(source).reduce((rs: any, key) => {
    const {fish_amount, isRoute} = source[key];
    if (fish_amount > 0 && isRoute)
        rs[key] = source[key];
    return rs;
}, {})