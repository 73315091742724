import React, {Component} from 'react';
import {connect} from 'react-redux';
import {aWeekMillisecond} from "../../../../../util/varibles/constants";
import styles from "../style.module.scss";
import {getKeyWeek} from "../../../../../util/varibles/global";
import {IStateGlobal} from "../../../../../util/varibles/interface";
import {datetime} from "../../../../../util/library/datetime";
import {planOpActions} from "../../../reducer";

interface ILazyLoadingAction {
    isLoad: boolean
    key: string
    weeks: any
    startTime: number
    finishTime: number

    action(): void
}

const mapStateToProps = (state: IStateGlobal) => ({
    weeks: state.planOperation.weeks,
    beforeCount: state.planOperation.beforeCount,
    afterCount: state.planOperation.afterCount,
})

interface IProps {
    elementId: string
    weeks: any
    beforeCount: boolean
    afterCount: boolean
    calendarWidth: number

    lazyLoading(payload: { params: ILazyLoadingAction }): void
}

class LazyLoading extends Component<IProps> {
    state = {
        loadMore: 0
    }

    componentDidMount() {
        const {elementId} = this.props;
        const el = document.getElementById(elementId);
        if (el) {
            el.addEventListener('wheel', (e: any) => {
                const {weeks, beforeCount, afterCount} = this.props;
                const {deltaY} = e;
                const {loadMore} = this.state;
                if (loadMore) return;
                const {scrollTop, scrollHeight, offsetHeight}: any = el;
                const isScrolledToTop = scrollTop + deltaY <= 0;
                const isScrolledToBottom = (scrollHeight - offsetHeight - (scrollTop + deltaY)) < 0;
                if (isScrolledToTop && deltaY < 0) {
                    const [year, weekNo]: any = weeks[0].split(/\|/);
                    const finishTime = datetime().set({day: 1, year, week: weekNo}).startOf('day').time;
                    const startTime = finishTime - (3 * aWeekMillisecond);
                    let newWeeks = [...weeks];
                    for (let i = 1; i <= 3; i++) {
                        const keyWeek = getKeyWeek(finishTime - (i * aWeekMillisecond));
                        newWeeks = [keyWeek, ...newWeeks]
                    }
                    const params = {
                        isLoad: beforeCount,
                        key: 'beforeCount',
                        weeks: newWeeks,
                        startTime, finishTime: finishTime - 1,
                        action: () => this.setState({loadMore: 0})
                    }
                    this.setState({loadMore: 1}, () => this.props.lazyLoading({params}));
                } else if (isScrolledToBottom && deltaY > 0) {
                    const [year, weekNo]: any = weeks[weeks.length - 1].split(/\|/);
                    const startTime = datetime().set({
                        day: 1,
                        year,
                        week: weekNo
                    }).startOf('day').time + aWeekMillisecond;
                    const finishTime = startTime + (3 * aWeekMillisecond) - 1;
                    let newWeeks = [...weeks];
                    for (let i = 1; i <= 3; i++) {
                        const keyWeek = getKeyWeek(startTime + (i * aWeekMillisecond));
                        newWeeks = [...newWeeks, keyWeek]
                    }
                    const params = {
                        isLoad: afterCount,
                        key: 'afterCount',
                        weeks: newWeeks,
                        startTime, finishTime: finishTime - 1,
                        action: () => this.setState({loadMore: 0})
                    }
                    this.setState({loadMore: 2}, () => this.props.lazyLoading({params}));
                } else if (this.state.loadMore !== 0)
                    this.setState({loadMore: 0})
            })
        }
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        return this.state !== nextState
    }

    render() {
        const {loadMore} = this.state;
        const style = {width: `${this.props.calendarWidth}%`}
        return <>
            <div className={styles['loading-top']} data-is-show={loadMore === 1} style={style}>
                <div className={styles['container-loading']}>
                    <div className=' spinner-border text-secondary' role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            <div className={styles['loading-bottom']} data-is-show={loadMore === 2} style={style}>
                <div className={styles['container-loading']}>
                    <div className=' spinner-border text-secondary' role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </>;
    }
}

export default connect(mapStateToProps, {
    lazyLoading: planOpActions.lazyLoadingRequest
})(LazyLoading);