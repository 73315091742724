import React from 'react';
import {Checkbox} from "antd";
import styles from "./style.module.scss";

interface IProps {
    isTracking: boolean

    updateTracking(isTracking: boolean): void
}

const Tracking: React.FC<IProps> = React.memo(({isTracking, updateTracking}) => {
    return <Checkbox {...{
        className: styles['track-vessel'],
        checked: isTracking,
        onChange: () => updateTracking(!isTracking)
    }} >
        Track vessel
    </Checkbox>;
}, (prev, next) => prev.isTracking === next.isTracking);

export default Tracking;