import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ACTION_TYPE, CERTAIN_TYPE, OPERATION_TYPE} from "../../../../../../util/varibles/constants";
import styles from "./style.module.scss";
import stylesContainer from "./style.module.scss";
import {Divider} from "antd";
import AddUnit from "../../_Component/AddUnit";
import AddNote from "../../_Component/AddNote";
import Certain from "../../_Component/Certain";
import Footer from "../../Footer";
import {generateOp} from "./constants";
import {IOperations, IServiceTask, ISite} from "../../../../../../util/varibles/interface";
import SelectTime from "../../_Component/SelectTime";
import LineMiddle from "../../_Component/LineMiddle";
import Unit from "./Unit";
import {getServiceTasksByVessel} from "../../../../../../util/varibles/global";
import {updateData, UpdateName} from "../constants";
import {planOpActions} from "../../../../reducer";
import {IUpdateOp} from "../../../../constants";
import {IGetActivityLog} from "../../../../saga";
import {AppState} from "../../../../../../util/store/store";

const initState = {
    startTime: Date.now(),
    operation: {site_id: '', site_name: '', tasks: []},
    store: {},
    site: {},
    units: {},
    loading: false,
    listOfTask: [],
    isCalculate: false
}

const mapStateToProps = (state: AppState) => ({
    tenant_id: state.login.user.tenant_id,
    sites: state.planOperation.sites,
    serviceTasks: state.planOperation.serviceTasks,
    operations: state.planOperation.operations,
})

interface IProps {
    tenant_id: any
    popupOperation: any
    sites: ISite[]
    serviceTasks: IServiceTask[]
    store: any
    editMode: ACTION_TYPE
    operations: IOperations
    loading?: boolean

    getActivityLog(payload: IGetActivityLog): void

    onSave(payload: IUpdateOp, action?: Function): void

    onClose(): void
}

interface IState {
    startTime: number
    operation: any
    store: any
    site: any
    units: any
    loading: boolean
    listOfTask: any
    isCalculate: boolean
}

class Service extends Component<IProps, IState> {
    state = initState;
    abort = new AbortController();

    constructor(props: IProps) {
        super(props);
        this.state = this.generateState(this.props)
    }

    shouldComponentUpdate = (nextProps: Readonly<IProps>, nextState: Readonly<{}>): boolean => {
        if (JSON.stringify(this.props.popupOperation) !== JSON.stringify(nextProps.popupOperation)) {
            const el = document.getElementById('container-service');
            if (el)
                setTimeout(() => el.scrollTo(0, 0), 100);
            this.setState(this.generateState(nextProps));
        }

        return this.state !== nextState
    }

    generateState = (props: IProps): IState => {

        const {store, sites, editMode, serviceTasks} = props;
        const {operation, vessel, activity_log = []} = props.popupOperation.values;
        const startTime = activity_log.length > 0 ? activity_log[0].est_start_time : Date.now();

        const listOfTask = getServiceTasksByVessel({
            serviceTasks,
            vessel
        });

        const common = {
            startTime,
            listOfTask,
            loading: false
        }

        if (editMode === ACTION_TYPE.CREATE) {
            let siteId: any;
            const storeBySite = Object.keys(store).reduce((rs: any, key) => {
                const {site_id} = store[key];
                if (!siteId)
                    siteId = site_id
                rs[site_id] = {
                    ...rs[site_id] || {},
                    [key]: store[key]
                }
                return rs;
            }, {})
            const firstOfSite = storeBySite[siteId];
            const site: any = sites.find((item: any) => item.id === siteId);
            const params = {
                vessel,
                store: firstOfSite,
                keyStore: Object.keys(firstOfSite),
                site,
                properties: {
                    tenant_id: this.props.tenant_id
                }
            }

            return {
                ...common,
                isCalculate: true,
                ...generateOp(params),
                site,
                units: Object.keys(store).reduce((rs: any, key) => {
                    const {id, unit_id, avg_weight, total_weight, fish_amount} = store[key];
                    rs[id] = {id, unit_id, avg_weight, total_weight, fish_amount}
                    return rs;
                }, {}),
            }
        } else {
            const {site_id = '', sub_operations = []} = operation || {};
            const site: any = sites.find((item: any) => item.id === site_id);

            return {
                ...common,
                isCalculate: false,
                site,
                store,
                operation,
                units: sub_operations.reduce((rs: any, item: any) => {
                    rs[item.id] = item;
                    return rs;
                }, {})
            }
        }
    }

    handleSubmit = () => {
        const {action, editMode} = this.props.popupOperation;
        if (this.state.isCalculate) {
            const {operation, startTime}: any = this.state;
            const {vessel} = this.props.popupOperation.values;
            const {units = {}}: any = this.state;
            const sub_operations = operation.tasks.reduce((list: any, item: any) => {
                const {id} = item.sub_operation || item.sub_operations[0];
                const {note = ''} = units[id] || {};
                list[id] = {...units[id], note};
                return list;
            }, {});
            const {site_id = '', site_name = ''} = operation || {};
            const data = {
                activity_log: [],
                operation: {
                    ...operation,
                    sub_operations: Object.keys(sub_operations).reduce((rs: any, key) => {
                        const {id, unit_id, note} = sub_operations[key];
                        return [...rs, {id, unit_id, note, site_id, site_name}]
                    }, [])
                },
                vessel,
            };

            const {id: opId = 0} = this.props.popupOperation.values.operation;
            const properties: any = {
                [opId]: {
                    start: startTime
                }
            }
            this.abort = new AbortController();
            this.setState({loading: true});
            this.props.getActivityLog({
                source: [data],
                properties,
                signal: this.abort,
                success: (ops) => {
                    this.setState({loading: false});
                    this.props.onSave(editMode === ACTION_TYPE.CREATE ? {add: ops} : {update: ops}, action);
                },
                failure: () => {
                    this.setState({loading: false});
                }
            })
        } else {
            const {units}: any = this.state;
            const {operation, vessel, activity_log} = this.props.popupOperation.values;
            const data = {
                operation: {
                    ...operation,
                    sub_operations: operation.sub_operations.map((item: any) => units[item.id] || item)
                },
                vessel,
                activity_log
            };

            this.props.onSave({update: {[operation.id]: data}, isCheck: false}, action);
        }
    }

    handleUpdate = (key: UpdateName, args: any) => {
        const action = updateData[OPERATION_TYPE.SERVICE][key];
        if (action)
            this.setState(action({state: this.state, props: this.props, args}));
    }

    render() {
        const {vessel} = this.props.popupOperation.values;
        const {startTime, site, units = {}, operation, listOfTask = [], loading}: any = this.state;

        const listOfUnit: any = [];

        const {tasks = [], note = '', certain_mode = CERTAIN_TYPE.NONE} = operation || {};

        const groupByUnit = tasks.reduce((rs: any, item: any, index: number) => {
            const {id, unit_id, site_id} = item.sub_operation;
            const data = {...item, index};
            if (!rs[id]) {
                listOfUnit.push(({id, unit_id, site_id}));
                rs[id] = {
                    list: [data]
                }
            } else {
                rs[id].list.push(data)
            }
            return rs;
        }, {});

        return <>
            <div id='container-service' className={styles.wrapper}>
                <div className={styles['sub-plan']}>
                    <SelectTime time={startTime} onChange={(value) => this.setState({startTime: value})}/>
                    <div className={styles['sub-section-right']}>
                        <AddUnit
                            sites={[site]}
                            except={listOfUnit}
                            onUpdate={this.handleUpdate}
                        />
                        <AddNote
                            title='Service'
                            value={note}
                            onChange={(note: string) => this.handleUpdate(UpdateName.NOT_CALCULATE, {note})}
                        />
                        <Divider type='vertical'/>
                        <Certain
                            value={certain_mode}
                            onChange={(certain_mode: any) => this.handleUpdate(UpdateName.NOT_CALCULATE, {certain_mode})}
                        />
                    </div>
                </div>
                <div className={styles['wrapper-operation']}>
                    {listOfUnit.length === 0 && <div className={styles['line']} data-lev='empty'>No task</div>}
                    <div id='service-operation' className={styles['operation']}>
                        <div className={stylesContainer['line-middle']} data-level='top'>
                            <LineMiddle {...{containerId: 'service-operation', unitId: listOfUnit[0].id, index: 0}} />
                        </div>
                        {listOfUnit.map((item: any, index: number) => <Unit key={item.id} {...{
                            ...groupByUnit[item.id],
                            groupIndex: index,
                            site,
                            vessel,
                            unit: units[item.id],
                            listOfTask,
                            onUpdate: this.handleUpdate,
                        }}/>)}
                    </div>
                </div>
            </div>
            <Footer {...{
                loading: loading || this.props.loading,
                handleSubmit: this.handleSubmit,
                onClose: () => {
                    this.props.onClose();
                    this.abort.abort();
                },
            }}/>
        </>;
    }
}


export default connect(mapStateToProps, {
    getActivityLog: planOpActions.getActivityLog
})(Service);
