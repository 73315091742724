import React, {useState} from 'react';
import stylesContainer from "../../style.module.scss";
import {Checkbox, Switch} from "antd";
import styles from './style.module.scss';
import {
    CONTRACT_TYPE,
    contractPermissionType,
    OPERATION_TYPE,
    operationType
} from "../../../../util/varibles/constants";
import {renderOpts} from "../../../../util/varibles/global";
import {selectOneSetting, selectPermission} from "../../../../util/store/selectors";
import {GlobalSetting} from "../../../../util/varibles/userSetting";
import {useDispatch, useSelector} from "react-redux";
import {loginActions} from "../../../LoginPage/reducer";
import {ACTION} from "../../../../util/varibles/permission";

const key = GlobalSetting.MAIL

interface IProps {
    actions: any
}

const SubscribeEmail: React.FC<IProps> = () => {
    const setting = useSelector(selectOneSetting(key));
    const permission = useSelector(selectPermission)
    const [form, setForm] = useState<any>(setting || {});
    // const [email, setEmail] = useState(form.email);
    const dispatch = useDispatch()

    const handleChange = (value: any) => {
        const {status = -1, email = ''} = {...form, ...value};
        const newValue: any = {status};
        if (permission[ACTION.SUBSCRIBE_EMAIL.OPERATION]) {
            const {
                internal_operation = -1,
                external_operation = -1,
                available_operation = -1,
                operation_type = []
            } = {...form, ...value};
            newValue.internal_operation = internal_operation;
            newValue.external_operation = external_operation;
            newValue.available_operation = available_operation;
            newValue.operation_type = operation_type;
        }
        if (permission[ACTION.SUBSCRIBE_EMAIL.VESSEL]) {
            const {contract = []} = {...form, ...value};
            newValue.contract = contract;
        }
        if (email.length > 0) {
            newValue.email = email;
        }
        setForm(newValue);
        dispatch(loginActions.updateUserSetting([{[key]: newValue}]));
    }
    // const isValidEmail = (email || '').match(patternEmail)
    // const isEmpty = (email || '').length === 0;
    return <div className={styles.box}>
        <div className={stylesContainer.header}>
            <div className={stylesContainer.title}>
                Subscribe to email (for completed operation)
            </div>
            <Switch
                checked={form.status === 0}
                onChange={checked => {
                    handleChange({status: checked ? 0 : -1})
                }}
            />
        </div>

        {/*<div className={styles.section}>*/}
        {/*    <div className='font-s15 mb-5'>*/}
        {/*        System will send email of completed operation to your user name email login. However, You can select*/}
        {/*        another email address:*/}
        {/*    </div>*/}
        {/*    <div className={styles.email}>*/}
        {/*        <Input*/}
        {/*            value={email}*/}
        {/*            placeholder='Input another email'*/}
        {/*            disabled={form.status === -1}*/}
        {/*            onChange={({target}) => {*/}
        {/*                const {value} = target;*/}
        {/*                setEmail(value);*/}
        {/*            }}*/}
        {/*        />*/}
        {/*        {(form.email !== email && form.status !== -1) && <>*/}
        {/*            <div className='text-comment' onClick={() => setEmail(form.email || '')}>*/}
        {/*                Cancel*/}
        {/*            </div>*/}
        {/*            <Tooltip title={(isValidEmail && !isEmpty) ? null : notify[NotifyCode.E31]()}>*/}
        {/*                <Button*/}
        {/*                    disabled={!isValidEmail && !isEmpty}*/}
        {/*                    type="link"*/}
        {/*                    onClick={() => handleChange({email})}*/}
        {/*                >*/}
        {/*                    Save*/}
        {/*                </Button>*/}
        {/*            </Tooltip>*/}
        {/*        </>}*/}
        {/*    </div>*/}
        {/*</div>*/}

        {permission[ACTION.SUBSCRIBE_EMAIL.OPERATION] && <div className={styles.section}>
            <div className={styles.headerSection}>
                The email is filtered by operation's specific:
            </div>
            <div className={styles.bodySection}>
                <div>
                    <div className={styles.titleLine}>
                        <span>Your organization’s operation</span>
                        <Switch
                            disabled={form.status === -1}
                            checked={form.internal_operation !== -1}
                            onChange={checked => handleChange({internal_operation: checked ? 0 : -1})}
                        />
                    </div>
                    <div className='text-comment italic font-s4'>
                        (With the toggle “ON”, you will receive email of your organization's operation and be
                        planned by
                        you)
                    </div>
                    <div className='mt-20'>
                        However, you can select another option:
                    </div>
                    <Checkbox
                        disabled={form.internal_operation === -1 || form.status === -1}
                        checked={form.internal_operation === 1}
                        onChange={({target}) => {
                            const {checked} = target;
                            handleChange({internal_operation: checked ? 1 : 0})
                        }}
                    >
                        Receive email of all your organization's operations
                    </Checkbox>
                </div>
                <div>
                    <div className={styles.titleLine}>
                        <span>EXternal site’s operation</span>
                        <Switch
                            disabled={form.status === -1}
                            checked={form.external_operation !== -1}
                            onChange={checked => handleChange({external_operation: checked ? 0 : -1})}
                        />
                    </div>
                    <div className='text-comment italic font-s4'>
                        (With the toggle “ON”, you will receive email of external site’s operation and be planned by
                        you)
                    </div>
                    <div className='mt-20'>
                        However, you can select another option:
                    </div>
                    <Checkbox
                        disabled={form.external_operation === -1 || form.status === -1}
                        checked={form.external_operation === 1}
                        onChange={({target}) => {
                            const {checked} = target;
                            handleChange({external_operation: checked ? 1 : 0})
                        }}
                    >
                        Receive email of all external site’s operations
                    </Checkbox>
                </div>
                <div>
                    <div className={styles.titleLine}>
                        <span>Spot operation</span>
                        <Switch
                            disabled={form.status === -1}
                            checked={form.available_operation !== -1}
                            onChange={checked => handleChange({available_operation: checked ? 0 : -1})}
                        />
                    </div>
                    <div className='text-comment italic font-s4'>
                        (With the toggle “ON”, you will receive email of spot operation within available times. These
                        available times are created by you)
                    </div>
                    <div className='mt-20'>
                        However, you can select another option:
                    </div>
                    <Checkbox
                        disabled={form.available_operation === -1 || form.status === -1}
                        checked={form.available_operation === 1}
                        onChange={({target}) => {
                            const {checked} = target;
                            handleChange({available_operation: checked ? 1 : 0})
                        }}
                    >
                        Receive email of spot operations within available time, which are created by all users in your
                        organization)
                    </Checkbox>
                </div>
                <div>
                    <div className={[styles.titleLine, 'mb-12'].join(' ')}>
                        <span>Operation type</span>
                    </div>
                    <Checkbox.Group
                        value={form.operation_type || []}
                        disabled={form.status === -1}
                        options={renderOpts([
                            operationType[OPERATION_TYPE.HARVEST],
                            operationType[OPERATION_TYPE.TREATMENT],
                            operationType[OPERATION_TYPE.EVENT],
                            operationType[OPERATION_TYPE.TRANSPORT],
                        ])}
                        onChange={(operation_type) => handleChange({operation_type})}
                    />
                </div>
            </div>
        </div>}

        {permission[ACTION.SUBSCRIBE_EMAIL.VESSEL] && <div className={styles.section}>
            <div className={styles.headerSection}>
                The email is also filtered by your own vessels' contract:
            </div>
            <div className={styles.bodySection}>
                <Checkbox.Group
                    value={form.contract || []}
                    disabled={form.status === -1}
                    options={Object.keys(contractPermissionType).map(key => {
                        const {name} = contractPermissionType[key];
                        return {
                            value: [CONTRACT_TYPE.SINGLE, key].join('#'),
                            label: [name, 'contract'].join(' - ')
                        }
                    })}
                    onChange={(contract) => handleChange({contract})}
                />
            </div>
        </div>}
    </div>;
};

export default SubscribeEmail;