import {initialState} from "./constants";
import {createSlice} from "@reduxjs/toolkit";
import {propsEmpty} from "../../util/varibles/constants";

const opDetailSlice = createSlice({
    name: 'opDetail',
    initialState,
    reducers: {
        getOpRequest: (state, action) => {
            const {tab, id} = action.payload;
            state.keyTab = tab;
            state.loadingOp = true;
            state.data = {...propsEmpty, operation: {id}}
        },
        getOpSuccess: (state, action) => {
            state.loadingOp = false;
            state.data = action.payload;
        },
        getOpFailure: (state) => {
            state.loadingOp = false;
        },
        updatePositionVessel: (state, action) => {
            const {data} = state;
            const {vessel} = data
            if (vessel) {
                const {gps} = vessel;
                const {name, value, time} = action.payload.data;
                if (gps[name] === undefined) return state

                const {time: oldTime} = gps;
                const [gpsTime] = time.split('.');
                const updateTime = gpsTime[gpsTime.length - 1] === 'Z'
                    ? new Date(gpsTime.replace(' ', 'T')).getTime()
                    : new Date(gpsTime.replace(' ', 'T') + 'Z').getTime();
                const newTime = updateTime > oldTime ? updateTime : oldTime
                state.data = {...data, vessel: {...vessel, gps: {...gps, [name]: value, time: newTime}}}
            }
        },
        updateOp: (state, action) => {
            const {operation = {}, activity_log = []} = action.payload[0] || {};
            state.data = {
                ...state.data,
                operation: {...state.data.operation, ...operation},
                activity_log
            }
        }
    },
})

export const opDetailActions = opDetailSlice.actions

const opDetailReducer = opDetailSlice.reducer;

export default opDetailReducer;
