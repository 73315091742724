import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import WrapperStore from "./components/WrapperStore";
import {awsConfig} from "./util/varibles/aws-config";
import {Amplify} from "aws-amplify";
import './util/styles/styles.scss';
import Theme from "./components/Theme";

Amplify.configure(awsConfig);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(<Theme><WrapperStore><App/></WrapperStore></Theme>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
