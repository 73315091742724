import {template, TemplateCode} from "./template";
import {ReactElement} from "react";
import {notification} from "antd";
import {checkZero} from "./global";

export enum NotifyCode {
    E1 = 'error-over-capacity',
    E2 = 'error-merge-different-site',
    E3 = 'error-invalid-operation',
    E4 = 'error-only-merge-harvest',
    E5 = 'error-operation-over-operation',
    E6 = 'error-operation-out-available-time',
    E7 = 'error-operation-out-contract',
    E8 = 'error-start-before-finish',
    E9 = 'error-only-number',
    E10 = 'error-condition-empty',
    E11 = 'error-tank-empty',
    E12 = 'error-no-people-in-chat',
    E13 = 'error-unit-empty',
    E14 = 'error-factory-empty',
    E15 = 'error-confirm-failure',
    E16 = 'error-duration-empty',
    E17 = 'error-treatment-type-empty',
    E18 = 'error-factory-not-exist',
    E19 = 'error-duration-format',
    E20 = 'error-route-empty',
    E21 = 'error-location-not-exist',
    E22 = 'error-created',
    E23 = 'error-data-invalid',
    E24 = 'error-sites-empty',
    E25 = 'error-business-name-empty',
    E26 = 'error-wrong-plan-position',
    E27 = 'error-vessel-busy',
    E28 = 'error-not-save',
    E29 = 'error-required-are',
    E30 = 'error-time-recipients',
    E31 = 'error-email',
    E32 = 'error-required-is',
    E33 = 'error-max-length',
    E34 = 'error-overview-vessel-view-and-op-type',
    E35 = 'error-updated',
    E36 = 'error-deleted',
    E37 = 'error-weight-and-total-empty',
    E38 = 'error-operation-code',
    E39 = 'error-get-data',
    E40 = 'error-unit-no-fish',

    W1 = 'warn-unauthorized',
    W2 = 'warn-factory-time-late',
    W3 = 'warn-no-op-auto-planned',
    W4 = 'warn-operation-in-past',
    W5 = 'warn-miss-vessel-in-filter',
    W6 = 'warn-not-enough-fish',

    S1 = 'success-item-created',
    S2 = 'success-item-updated',
    S3 = 'success-auto-plan',
    S4 = 'success-item-deleted',
    S5 = 'success-delete',
    S6 = 'success-start',
    S7 = 'success-approve',
    S8 = 'success-cancel',
    S9 = 'success-items-deleted',
    S10 = 'success-export',
    S11 = 'success-saved',
    S12 = 'success-sent',
    S13 = 'success-send-email',
    S14 = 'success-download',
}

interface IDescription {
    [index: string]: string | ReactElement
}

const description: IDescription = {
    [NotifyCode.E1]: 'Overcapacity vessel.',
    [NotifyCode.E2]: 'Can not merge harvest from different sites.',
    [NotifyCode.E3]: 'Invalid operation.',
    [NotifyCode.E4]: 'Only merge harvest operation.',
    [NotifyCode.E5]: 'Other operation already exists during this time.',
    [NotifyCode.E6]: 'The available period must cover its spot operations.',
    [NotifyCode.E7]: 'The operation is being planned out of fixed contract period of time.',
    [NotifyCode.E8]: 'Start time must be before finish time.',
    [NotifyCode.E9]: 'Please enter only number (ex: 3.4).',
    [NotifyCode.E10]: 'Condition can\'t be empty.',
    [NotifyCode.E11]: 'Please fill in the missing information for the task or delete the empty task.',
    [NotifyCode.E12]: 'Please choose people to add your chat.',
    [NotifyCode.E13]: 'Unit can\'t be empty.',
    [NotifyCode.E14]: 'Factory can\'t be empty.',
    [NotifyCode.E15]: 'New operation(s) have not been sent to vessel due to system error. Please try again later.',
    [NotifyCode.E16]: 'Duration can\'t be empty.',
    [NotifyCode.E17]: 'Treatment type can\'t be empty.',
    [NotifyCode.E18]: 'The system does not have a company, please create a factory before create operation.',
    [NotifyCode.E19]: '<div>Your duration must be in the format <b>4d 6h 45m</b>.</div>',
    [NotifyCode.E24]: 'List of site is empty',
    [NotifyCode.E25]: 'Business name can\'t be empty.',
    [NotifyCode.E26]: 'You can\'t plan here.',
    [NotifyCode.E27]: 'Vessel is not available in this duration of time.',
    [NotifyCode.E30]: 'Please input people to send email.',
    [NotifyCode.E31]: 'Invalid email.',
    [NotifyCode.E34]: 'Adjustment action is not supported for this operation type, when on Operation Overview.',
    [NotifyCode.E38]: 'Operation code invalid.',

    [NotifyCode.W1]: 'Sorry, you are not authorized to access this page.',
    [NotifyCode.W3]: 'No operations are planned.',
    [NotifyCode.W4]: 'Operation\'s start time is in the past.',
    [NotifyCode.W6]: 'There is not enough fish to harvest.',

    [NotifyCode.S10]: 'File export completed.',
    [NotifyCode.S13]: 'Email sent successfully.',
    [NotifyCode.S14]: 'Download successfully.',
}

interface IDescriptionFunc {
    [index: string]: (data: (string | number)[]) => string | ReactElement
}

const descriptionFunc: IDescriptionFunc = {
    [NotifyCode.E20]: (data) => `There is no route between ${data[0]} and ${data[1]}`,
    [NotifyCode.E21]: (data) => `${data[0]} is not exist in system`,
    [NotifyCode.E22]: (data) => `${data[0]} created failure`,
    [NotifyCode.E35]: (data) => `${data[0]} updated failure`,
    [NotifyCode.E36]: (data) => `${data[0]} deleted failure`,
    [NotifyCode.E23]: (data) => `Invalid ${data[0]}`,
    [NotifyCode.E28]: (data) => `Please save operation ${data[0]} first`,
    [NotifyCode.E33]: (data) => `Max length of the name is ${data[0]} characters`,
    [NotifyCode.E32]: (data) => `${data[0]} is required.`,
    [NotifyCode.E29]: (data) => `${data[0]} are required.`,
    [NotifyCode.E37]: (data) => `${data[0]} number field can not be empty or = 0`,
    [NotifyCode.E39]: (data) => `Get data of ${data[0]} failure`,
    [NotifyCode.E40]: (data) => `${data[0]} has no fish`,

    [NotifyCode.S1]: (data) => `${data[0]} has been created.`,
    [NotifyCode.S2]: (data) => `${data[0]} has been updated.`,
    [NotifyCode.S3]: (data) => `${data[0]}/${data[1]} operations have been successfully planned`,
    [NotifyCode.S4]: (data) => `${data[0]} has been deleted.`,
    [NotifyCode.S5]: (data) => `${data[0]} have been deleted.`,
    [NotifyCode.S6]: (data) => `${data[0]} started.`,
    [NotifyCode.S7]: (data) => `${data[0]} approved.`,
    [NotifyCode.S8]: (data) => `${data[0]} canceled.`,
    [NotifyCode.S11]: (data) => template[TemplateCode.T2]('Operations have been saved successfully.', data[0], data[1]),
    [NotifyCode.S12]: (data) => template[TemplateCode.T2]('These operation(s) have been sent to vessel and updated successful.', data[0], data[1]),

    [NotifyCode.W2]: (data) => `Arrived at factory time should be before ${checkZero(data[0])}:${checkZero(data[1])}`,
    [NotifyCode.W5]: (data) => `Please select vessel ${data[0]} (in filter) to see operation #${data[1]} on the calendar`,
}

type TCallback = (data: any) => void
const callback = (data: string | ReactElement, action?: TCallback) => {
    if (!action || typeof action !== 'function') {
        return data
    } else
        return action(data);
}

export const notify: any = {
    ...Object.keys(description).reduce((rs: any, key: any) => {
        rs[key] = (action?: TCallback) => callback(description[key], action)
        return rs;
    }, {}),

    ...Object.keys(descriptionFunc).reduce((rs: any, key: any) => {
        rs[key] = (data: string[], action?: TCallback) => callback(descriptionFunc[key](data), action);
        return rs;
    }, {}),

    success: (code: NotifyCode, title = 'Success') => (data?: string[]) => {
        const action = (description: any) => notification.success({
            message: title,
            description: template[TemplateCode.T1](description)
        });
        if (data) {
            notify[code](data, action)
        } else {
            notify[code](action)
        }
    },

    error: (code: NotifyCode, title = 'Error') => (data?: string[]) => {
        const action = (description: any) => notification.error({
            message: title,
            description: template[TemplateCode.T1](description)
        });
        if (data) {
            notify[code](data, action)
        } else {
            notify[code](action)
        }
    },

    errorRaw: (description: any, title = 'Error') => {
        notification.error({
            message: title,
            description: template[TemplateCode.T1](description),
            style: {
                width: '450px'
            },
        })
    },

    warn: (code: NotifyCode, title = 'Warn') => (data?: string[]) => {
        const action = (description: any) => notification.warning({
            message: title,
            description: template[TemplateCode.T1](description)
        });
        if (data) {
            notify[code](data, action)
        } else {
            notify[code](action)
        }
    }
}

