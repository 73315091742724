import React, {Component} from 'react';
import {Button, message, Upload} from "antd";
import {PaperClipOutlined} from "@ant-design/icons";
import styles from './style.module.scss';
import Files from "./Files";
import {datetime} from "../../../../../../util/library/datetime";

interface IProps {
    files: any
    isShowList: boolean

    handleChange(files: any): void
}

class AttachFile extends Component<IProps> {
    static defaultProps = {
        isShowList: true
    }
    files: any = [];
    timeout: any;

    handleAdd = (file: any) => {
        const time = datetime().format('YYYYMMDD_HHmmss_');
        const {name} = file;
        const data = {key: time + name, name, file};

        this.files = this.files.reduce((rs: any, item: any) => {
            if (item.name === name) {
                rs.push(data);
            } else
                rs.push(item)
            return rs
        }, []);

        this.files.push(data);
        if (this.timeout)
            clearTimeout(this.timeout)
        this.timeout = setTimeout(() => this.props.handleChange(this.files), 100);
    }

    handleDelete = (file: any) => {
        const {files} = this.props;
        const {name} = file;
        const filesNew = files.filter((item: any) => item.name !== name);
        this.props.handleChange(filesNew);
    }

    render() {
        const {files, isShowList} = this.props;
        this.files = files;
        const list = files.map((item: any) => {
            const {isNew, key, name, file} = item;
            return isNew ? file : {uid: key, name}
        })
        const props = {
            fileList: list,
            multiple: true,
            showUploadList: false,
            className: styles['attach-file'],
            beforeUpload: (file: any) => {
                const isPDF = file.type === 'application/pdf';
                const isLt50M = file.size / 1024 / 1024 < 50;
                if (!isPDF) {
                    message.error(`${file.name} is not a PDF file`);
                    return false;
                }
                if (!isLt50M) {
                    message.error('File must smaller than 50MB!');
                    return false;
                }
                this.handleAdd(file)
                return false;
            },
        };

        return <div className={styles.wrapper} data-is-show-list={isShowList}>
            {isShowList && <Files files={files} onDelete={this.handleDelete}/>}
            <Upload {...props} >
                <Button className='bt-primary pl-10 pr-10' data-icon={true}>
                    <PaperClipOutlined/>
                    <span>Attach PDF file</span>
                </Button>
            </Upload>
        </div>;
    }
}

export default AttachFile;