import React, {Component} from 'react';
import styles from './style.module.scss';
import {connect} from "react-redux";
import {VIEW_MODE} from "../../util/varibles/permission";
import {loginActions} from "../../pages/LoginPage/reducer";
import Operations from "./Operations";
import {IOperation, IStateGlobal} from "../../util/varibles/interface";
import {PreferenceSetting} from "../../util/varibles/userSetting";
import {IVesselOfUser} from "../../pages/LoginPage/constants";

const mapStateToProps = (state: IStateGlobal) => {
    const {[PreferenceSetting.VIEW_MODE]: viewMode} = state.login.user.setting || {};
    return {
        viewMode,
        permission: state.login.user.permission || {}
    }
}

interface IProps {
    permission: any
    viewMode: VIEW_MODE

    onUpdate?(args: any): void

    onTracking?(data: IOperation | { operation: undefined, vessel: IVesselOfUser }): any

    getOpsOfVessel(): void
}

class TrackingOp extends Component<IProps> {
    constructor(props: IProps) {
        super(props);
        if (this.props.viewMode === VIEW_MODE.VESSEL) {
            this.props.getOpsOfVessel();
        }
    }

    render() {
        if (this.props.viewMode !== VIEW_MODE.VESSEL)
            return null;

        return <div className={styles.page}>
            <Operations actions={this.props.permission} onTracking={this.props.onTracking}/>
        </div>;
    }
}

export default connect(mapStateToProps, {
    getOpsOfVessel: loginActions.getOpsOfVesselRequest,
})(TrackingOp);
