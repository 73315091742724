import React, {useEffect, useState} from 'react';
import Vessel from "./Vessel";
import {connect, useDispatch} from "react-redux";
import {ILevelByVessel, IOperations, IVessel} from "../../../../../util/varibles/interface";
import EmptyVessels from "./EmptyVessels";
import {datetime} from "../../../../../util/library/datetime";
import VesselName from "./VesselName";
import styles from './style.module.scss';
import Menu from "../../../../../components/Menu";
import {getStartTimeFromDate} from "../../../../../util/varibles/global";
import {
    ACTION_TYPE,
    AVAILABLE_TIME_TYPE,
    OP_STATUS,
    OPERATION_MODE,
    OPERATION_TYPE
} from "../../../../../util/varibles/constants";
import {openPopup} from "../../../../../components/Popup/Component/WrapperPopup";
import PopupAvailableTime from "../../../Popup/AvailableTime";
import {planOpActions} from "../../../reducer";
import {CALENDAR_ID} from "../../../constants";
import Operations from "../Operations";
import {calculateWidth, EL_RULE} from "../constants";
import {MENU_OP, menuOp, menuOptions} from "../Operations/constants";
import AvailableTimes from "../AvailableTimes";
import Contracts from "../Contracts";
import Icon, {ICON_NAME} from "../../../../../components/Icon";
import LazyLoading from "../LazyLoading";
import {renderMenu} from "../Operations/actions";
import Layout from "./Layout";
import {TargetTypes} from "./constants";
import {AppState} from "../../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    vessels: state.planOperation.vessels,
    operations: state.planOperation.operations,
    level: state.planOperation.level,
    opFocusId: state.planOperation.opFocusId,
    opMode: state.planOperation.opMode,
    userTenantId: state.login.user.tenant_id
});

interface IProps {
    vessels: IVessel[]
    operations: IOperations
    level: ILevelByVessel
    opMode: OPERATION_MODE
    startTime: number
    finishTime: number
    opFocusId: string
    userTenantId: string

    togglePopupOperation(params: any): void

    dragOperation(target: any): any
}

const Vessels: React.FC<IProps> = (props) => {
    const {vessels, startTime, finishTime, operations, level, opMode, opFocusId, userTenantId} = props;
    const [countDay, setCountDay] = useState(0);
    const [show, setShow]: any = useState({});
    const [width, setWidth] = useState(0);
    const [menu, setMenu]: any[] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setCountDay(datetime(finishTime).diff(startTime, 'day'));
    }, [startTime, finishTime])

    useEffect(() => {
        const {data, count} = vessels.reduce((rs: any, {id, isShow}) => {
            if (isShow) {
                rs.data[id] = rs.count;
                rs.count++
            }
            return rs;
        }, {data: {}, count: 0});
        setShow(data);
        setWidth(calculateWidth(count));
    }, [vessels]);


    const handleAddEvent = (date: number, vessel: IVessel) => {
        const start = getStartTimeFromDate({date, operations, level, opMode, vessel});
        props.togglePopupOperation({
            isShow: true,
            operationType: OPERATION_TYPE.EVENT,
            editMode: ACTION_TYPE.CREATE,
            values: {
                activity_log: [{est_start_time: start}, {est_start_time: null}],
                vessel
            }
        })
    }

    const handleAddAvailableTime = (date: number, vessel: IVessel) => {
        const start = getStartTimeFromDate({date, operations, level, opMode, vessel});

        const el = openPopup(<PopupAvailableTime
            visible={true}
            editMode={ACTION_TYPE.CREATE}
            availableTime={{start_time: start}}
            vessel={vessel}
            onClose={() => el.remove()}
        />)
    }

    const setTarget = (target: TargetTypes, e: any) => {
        e.stopPropagation();
        if (e.button !== 2) {
            setMenu([])
            return;
        }
        let list: any[] = [];
        const {type} = target;
        switch (type) {
            case EL_RULE.VESSEL: {
                const {time, vessel} = target;
                const {isOwn, permission, available_time_type} = vessel;
                if (isOwn || permission) {
                    list.push({
                        key: 'event',
                        label: <div onMouseDown={() => handleAddEvent(time, vessel)}>Add event</div>
                    })
                    if (available_time_type === AVAILABLE_TIME_TYPE.ONLY_AVAILABLE_TIME)
                        list.push({
                            key: 'time',
                            label: <div onMouseDown={() => handleAddAvailableTime(time, vessel)}>Add available
                                time</div>
                        })
                } else if (available_time_type === AVAILABLE_TIME_TYPE.ALL) {
                    list.push({
                        key: 'event',
                        label: <div onMouseDown={() => handleAddEvent(time, vessel)}>Add event</div>
                    })
                }
                break;
            }
            case EL_RULE.OPERATION: {
                const {data, edit, isOwn} = target;
                const {status, operation_type} = data.operation;
                const {permission, isOwn: isVesselOwn} = data.vessel;

                const start = data.activity_log[0].est_start_time;
                const isActive = status !== OP_STATUS.PROCESSING
                    && status !== OP_STATUS.FINISHED
                    && start > Date.now();

                const op = {...data, isActive};
                const common = {dispatch, operations, userTenantId, opMode}
                let opts;
                if (isOwn) {
                    opts = menuOptions[operation_type].map((type: MENU_OP) => menuOp[type]({
                        ...data,
                        isActive
                    }, {...common, edit}))

                } else if (permission || isVesselOwn) {
                    opts = [
                        menuOp[MENU_OP.DETAIL](op),
                        menuOp[MENU_OP.APPROVE](op, common),
                        menuOp[MENU_OP.DECLINE](op, common)
                    ];
                } else {
                    opts = [menuOp[MENU_OP.DETAIL](op)]
                }
                list = renderMenu(opts);
                break;
            }
            case EL_RULE.AVAILABLE_TIME: {
                const {time, vessel, availableTime} = target;
                const {isOwn, permission} = vessel;
                list = (!isOwn && !permission) ? [{
                    key: 'add',
                    label: <div
                        className='menu-line'
                        onMouseDown={() => handleAddEvent(Math.max(time, availableTime.start_time), vessel)}
                    >
                        <Icon icon={ICON_NAME.ADD}/>
                        <span>Add event</span>
                    </div>
                }] : [
                    {
                        key: 'edit',
                        label: <div className='menu-line' onMouseDown={target.edit}>
                            <Icon icon={ICON_NAME.EDIT}/>
                            <span>Edit available time</span>
                        </div>
                    },
                    {
                        key: 'delete',
                        label: <div className='menu-line' data-danger='true' onMouseDown={target.remove}>
                            <Icon icon={ICON_NAME.DELETE} danger/>
                            <span>Delete available time</span>
                        </div>
                    }
                ]
                break;
            }
            case EL_RULE.CONTRACT: {
                break;
            }

        }
        setMenu(list);
    }

    const count = vessels.length;
    if (count === 0)
        return <EmptyVessels/>

    const maxWidth = width * Object.keys(show).length;

    return <Menu
        items={menu}
        visible={menu.length === 0 ? false : undefined}
        trigger={['contextMenu']}
    >
        <div className={styles.vessels}>
            {vessels.map(vessel => {
                const {id: vesselId, contracts, available_times} = vessel;

                return <Vessel
                    key={vesselId}
                    width={width}
                    vessel={vessel}
                    index={show[vesselId]}
                    startTime={startTime}
                    countDay={countDay}
                    setTarget={setTarget}
                    calendarId={CALENDAR_ID}
                >
                    <Operations
                        calendarId={CALENDAR_ID}
                        dragOperation={props.dragOperation}
                        width={width}
                        vessels={vessels}
                        index={show[vesselId]}
                        startPoint={startTime}
                        level={level[vesselId]}
                        opFocusId={opFocusId}
                        operations={operations}
                        setTarget={setTarget}
                        openEdit={props.togglePopupOperation}
                    />
                    <AvailableTimes
                        width={width}
                        available_times={available_times}
                        startPoint={startTime}
                        vesselIndex={show[vesselId]}
                        finishTime={finishTime}
                        vessel={vessel}
                        setTarget={setTarget}
                    />
                    <Contracts
                        width={width}
                        contracts={contracts}
                        startPoint={startTime}
                        vessel={vessel}
                        vesselIndex={show[vesselId]}
                        setTarget={setTarget}
                    />
                    <VesselName vessel={vessel}/>
                </Vessel>
            })}

            <LazyLoading elementId={CALENDAR_ID} calendarWidth={maxWidth}/>
            <Layout calendarWidth={maxWidth}/>
        </div>
    </Menu>
};

export default connect(mapStateToProps, {
    togglePopupOperation: planOpActions.togglePopupOperation,
    dragOperation: planOpActions.dragOperation
})(Vessels);