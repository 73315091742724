import React from 'react';
import {Button, Table} from "antd";
import styles from "./style.module.scss";
import {OPERATION_TYPE, TASK_TYPE, treatmentType, vesselTypes} from "../../../../../../../util/varibles/constants";
import {formatNumber} from "../../../../../../../util/varibles/global";
import {IVessel} from "../../../../../../../util/varibles/interface";
import LoadingBox from "../../../../../../../components/LoadingBox";

interface IProps {
    loading: boolean
    source: any[]
    checked: any
    valid: any
    vessels: IVessel[]
    onSelect: Function
}

const ViewVessels: React.FC<IProps> = ({loading, source, checked, valid, vessels, onSelect}) => {
    const columns: any = () => [
        {
            id: 'name',
            key: 'name',
            title: 'VESSEL NAME',
            width: '200px',
            render: (data: any) => <div className={styles['vessel-name']}>
                <span>{data.name}</span>
                {vesselTypes[data.type] && <>
                    <span className='ml-1 mr-1'>-</span>
                    <span className={styles['vessel-type']}>{vesselTypes[data.type].shortName}</span>
                </>}
            </div>
        },
        {
            id: 'capacity',
            width: '130px',
            key: 'capacity',
            title: 'CAPACITY',
            dataIndex: 'capacity',
            render: (capacity: any) => capacity !== undefined ? formatNumber(Math.round(capacity / 1000) / 1000) + 't' : '-'
        },
        {
            id: 'capabilities',
            key: 'capabilities',
            title: 'CAPABILITIES',
            className: styles.capabilities,
            render: (data: any) => {
                const operation_type = [...data.operation_type || []];
                let operationType =
                    (operation_type || [])
                        .sort((a: any, b: any) => a - b)
                        .reduce((list: any, opType: any, key: any) => {
                            switch (opType) {
                                case OPERATION_TYPE.HARVEST: {
                                    return [
                                        ...list,
                                        <span key={key} className={styles.opType}>Harvest</span>
                                    ]
                                }
                                case OPERATION_TYPE.TREATMENT: {
                                    const {operation_sub_types} = data
                                    return [...list, ...operation_sub_types.map((opSub: any, i: number) =>
                                        <span key={key + i} className={styles.opType}>
                                    {(treatmentType[opSub] || {name: ''}).name}
                                </span>)]
                                }
                                default:
                                    return list
                            }
                        }, [])
                return <div className={styles['container-operation-type']}>{operationType}</div>
            }
        },
        {
            id: 'action',
            key: 'action',
            width: '120px',
            className: styles.action,
            render: (vessel: any) => {
                if (Object.keys(valid).length === 0)
                    return null

                const {size} = checked;
                const {operation_type, operation_sub_types} = vessel;
                const capabilities = new Set(operation_type.reduce((rs: any, item: any) => {
                    if (item === OPERATION_TYPE.TREATMENT)
                        return [...rs, ...operation_sub_types.map((sub: any) => [item, sub].join('_'))]
                    return [...rs, item.toString()]
                }, [TASK_TYPE.MOVE]))
                const isDisable = Object.keys(valid).some(key => !capabilities.has(key));

                if (isDisable)
                    return <div className={styles.unSuitable}>Not suitable</div>

                const isSelected = vessels[vessel.id];
                const mode = isSelected === undefined ? 0 : (isSelected !== size ? 1 : 2);
                return <Button
                    className='bt-primary'
                    data-selected={mode}
                    onClick={() => onSelect({vessel, isSelect: !(mode === 2)})}
                >
                    {mode !== 0 ? 'Selected' : 'Select'}
                </Button>
            }
        }
    ]

    return <Table
        rowKey="id"
        loading={loading}
        className={styles.table}
        locale={{
            emptyText: <LoadingBox message='There is no suitable vessel found'/>
        }}
        columns={columns()}
        dataSource={source}
        pagination={false}
    />;
};

export default ViewVessels;
