import React from 'react';
import {connect} from 'react-redux';
import {CONTRACT_TYPE, vesselTypes} from "../../../../util/varibles/constants";
import {Table} from "antd";
import styles from "./style.module.scss";
import {IStateGlobal, IVessel} from "../../../../util/varibles/interface";
import {formatNumber} from '../../../../util/varibles/global';
import Cancel from "./Cancel";

const mapStateToProps = (state: IStateGlobal) => ({
    userTenantId: state.login.user.tenant_id,
    vessels: state.planOperation.vessels,
});

interface IProps {
    userTenantId: string
    vessels: IVessel[]
    spotVessels: IVessel[]
    rowSelection: any
    loading: boolean

    setSpotVessels(vessels: IVessel[]): void
}

const Vessels: React.FC<IProps> = (props) => {
    const {rowSelection, spotVessels, loading, setSpotVessels} = props;

    const columnsVessel: any = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        },
        {
            title: 'Vessel type',
            dataIndex: 'type',
            key: 'type',
            sorter: (a: any, b: any) => a.type - b.type,
            render: (text: number) => <span className='text-capitalize'>{vesselTypes[text - 1].name}</span>,
        },
        {
            title: 'Treatment type',
            dataIndex: 'treatment_type',
            key: 'treatment_type',
        },
        {
            title: 'Capacity',
            dataIndex: 'capacity',
            key: 'capacity',
            sorter: (a: any, b: any) => a.capacity - b.capacity,
            render: (text: number) => <span>{formatNumber(Math.round(text / 1000) / 1000) + ' tons'}</span>,
        },
        {
            title: 'Speed',
            dataIndex: 'speed',
            key: 'speed',
            sorter: (a: any, b: any) => a.speed - b.speed,
            render: (text: number) => <span>{formatNumber(text) + '  knots'}</span>,
        },
        {
            align: 'right',
            width: 135,
            className: styles.action,
            render: (data: any) => {
                const {vessels, userTenantId} = props;
                const {
                    id = '',
                    contracts = []
                } = vessels.find(item => item.id === data.id && item.tenant_id !== userTenantId) || {}
                const contract = contracts.find((contract: any) => contract.type === CONTRACT_TYPE.SINGLE);
                if (!contract)
                    return null
                return <Cancel id={id} contract={contract} setSpotVessels={setSpotVessels}/>
            },
        },
    ];

    return <Table {...{
        className: styles.vessels,
        rowKey: 'id',
        dataSource: spotVessels,
        columns: columnsVessel,
        pagination: false,
        rowSelection,
    }} loading={loading}/>;
};

export default connect(mapStateToProps, {})(Vessels);
