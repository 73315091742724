import React, {Component} from 'react';
import styles from './style.module.scss';
import HeaderComponent from "./Header";
import Calendar from "./Calendar/index";

interface IProps {
}

class Plan extends Component<IProps> {
    render() {
        return <div id='plan' className={[styles['plan-view'], 'no-select'].join(' ')}>
            <HeaderComponent/>
            <div id='content-plan' className={styles['view-body']} data-mode='calendar'>
                <div className={styles['tab-item']}>
                    <Calendar/>
                </div>
            </div>
        </div>;
    }
}

export default Plan;
