import React from 'react';
import styles from "./style.module.scss";
import {Empty} from "antd";
import Task from "./Task";

interface IProps {
    supportTasks: any
    checked: Set<string>

    handleCheck(group_id: any, value: any): void
}

const Tasks: React.FC<IProps> = ({supportTasks, checked, handleCheck}) => {
    const tasks = supportTasks.reduce((rs: any, item: any) => {
        const {type, is_support_vessel} = item;
        if (type === null || !is_support_vessel)
            return rs;

        return [...rs, <Task
            key={rs.length}
            data={item}
            isCheck={checked.has(item.group_id)}
            handleCheck={handleCheck}
        />];
    }, []);

    return <div className={styles.tasks}>
        {tasks.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        {tasks}
    </div>;
};

export default Tasks;
