import React, {Component} from 'react';
import {OP_STATUS} from "../../../../util/varibles/constants";
import PopupOpInfo from "../../../../components/Popup/PopupOpInfo";
import {connect} from "react-redux";
import {IOperation} from "../../../../util/varibles/interface";
import {planOpActions} from "../../reducer";
import {AppState} from "../../../../util/store/store";
import {IUpdateOp} from "../../constants";

const mapStateToProps = (state: AppState) => {
    return {
        files: state.planOperation.files,
    }
};

interface IProps {
    files: any

    isShow: boolean
    isOwn: boolean
    isEdit: boolean
    data: IOperation
    container?: any

    updateOps(payload: IUpdateOp): void

    onClose?(): void
}

class OpInfo extends Component<IProps> {

    static defaultProps = {
        isShow: true,
        isEdit: true,
        isOwn: true,
        container: null,
        files: {}
    }

    state = {
        isShow: false,
    }

    constructor(props: IProps) {
        super(props);
        const {isShow} = this.props;
        this.state = {isShow}
    }

    handleUpdate = ({data, isCheck}: { data: IOperation, isCheck: boolean }) => {
        this.props.updateOps({update: {[data.operation.id]: data}, isCheck});
    }

    handleClose = () => {
        this.setState({isShow: false});
        const {onClose} = this.props;
        if (onClose)
            onClose();
    }

    render() {
        const {isShow} = this.state;
        const {isEdit, files = {}, data, isOwn} = this.props;
        let isApprove = false;
        const {status, id} = data.operation;
        if (files[id])
            data.operation.files = [...files[id]];

        if (!isOwn && [OP_STATUS.PENDING, OP_STATUS.SAVED].indexOf(status) !== -1)
            isApprove = true;

        return <PopupOpInfo {...{
            isEdit,
            isShow,
            isOwn,
            isApprove,
            data,
            updateOperation: this.handleUpdate,
            handleClose: this.handleClose,
        }}/>
    }
}

export default connect(mapStateToProps, {
    updateOps: planOpActions.updateOps
})(OpInfo);

