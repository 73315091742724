import React, {Component} from 'react';
import {connect} from 'react-redux';
import {aDayMillisecond, aHourMillisecond, colors} from "../../../../../util/varibles/constants";
import styles from "./style.module.scss";
import {Checkbox, Radio} from "antd";
import callApi from "../../../../../util/api/callApi";
import {urlDevicesService} from "../../../../../util/api/v1";
import {nextPage} from "../../../../../util/library/Router";
import GoogleMap from "../../../../../util/library/googlemap/googlemap";
import {MAP_ELEMENT} from "../../../../../util/library/googlemap/constants";
import {AppState} from "../../../../../util/store/store";
import LoadingBox from "../../../../../components/LoadingBox";

const mapStateToProps = (state: AppState) => ({
    map: state.dashboard.map,
});

const sixHours = 6 * aHourMillisecond;

const options = [
    {label: '6 hrs', value: sixHours},
    {label: '12 hrs', value: 12 * aHourMillisecond},
    {label: '24 hrs', value: aDayMillisecond},
];

interface IState {
    time: number,
    loading: boolean,
    history: any[]
}

interface IProps {
    vessel_id: string
    position: any
    map?: GoogleMap
    iotName: string
}

class HistoryVessel extends Component<IProps> {
    abortControl: any;
    flag = false;

    state = {
        time: 0,
        loading: false,
        history: []
    }

    componentWillUnmount() {
        if (this.abortControl) {
            this.abortControl.abort();
        }
        if (this.props.map)
            this.props.map.clearSeaways();
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        const {position, map} = nextProps
        if (nextProps.vessel_id !== this.props.vessel_id) {
            this.flag = false;
            if (this.abortControl) {
                this.abortControl.abort();
            }

            this.setState({
                time: 0,
                loading: false,
                history: []
            })
            if (map)
                map.clearSeaways();
        }

        if (nextState.time && JSON.stringify(this.props.position) !== JSON.stringify(position)) {
            const {history} = nextState;
            nextPage(position);
            let startPoint = history[0];
            const start_time = Date.now() - aDayMillisecond;
            while (history.length > 0 && startPoint.time < start_time) {
                history.shift();
                startPoint = history[0]
            }
            this.setState({history})
        }
        return this.state !== nextState
    }

    handleCheckboxChange = (e: any) => {
        const {checked} = e.target;
        const state: any = {};
        if (checked) {
            state.time = options[0].value;
            if (!this.flag)
                this.getHistory();
        } else {
            state.time = 0;
        }
        this.setState(state);
    }

    handleRadioChange = (e: any) => {
        const {value} = e.target;
        this.setState({time: value});
        this.getData(value);
    }

    getHistory = () => {
        this.abortControl = new AbortController();
        const {iotName} = this.props;
        const start_time = Date.now() - aDayMillisecond;
        const end_time = Date.now();
        this.setState({loading: true})
        new Promise(resolve => resolve(callApi(urlDevicesService + '/gps', {
            method: 'POST',
            body: JSON.stringify({start_time, end_time, iot_name: iotName}),
            signal: this.abortControl.signal
        })))
            .then((rs: any) => {
                this.flag = true;
                this.setState({
                    loading: false, history: rs.map((item: any) => {
                        const [gpsTime] = item.time.split('.');
                        const time = gpsTime[gpsTime.length - 1] === 'Z'
                            ? new Date(gpsTime.replace(' ', 'T')).getTime()
                            : new Date(gpsTime.replace(' ', 'T') + 'Z').getTime();
                        return {...item, time}
                    })
                }, () => this.getData(this.state.time));
            })
            .catch(() => {
                this.setState({loading: false});
            })
    }

    getData = (value: number) => {
        const {iotName, map} = this.props;
        const {history} = this.state;
        const start_time = Date.now() - value;
        const end_time = Date.now();
        const points = history.reduce((list: any, item: any) => {
            const {time, Latitude, Longitude} = item;
            if (time >= start_time && time <= end_time)
                return [...list, {lat: Number(Latitude), lng: Number(Longitude)}]
            return list;
        }, [])
        const {Latitude, Longitude} = this.props.position;
        points.push({lat: Number(Latitude), lng: Number(Longitude)})
        const seaWays = [{id: iotName, isMarkers: false, points, width: 3, color: colors[0], name: iotName}]

        map?.generate({
            type: MAP_ELEMENT.POLYLINE,
            seaWays
        });
    }

    render() {
        const {time, loading} = this.state;
        return <div className={styles['history-vessel']} data-is-show={time !== 0}>
            <Checkbox className={styles.checkbox} checked={time !== 0} onChange={this.handleCheckboxChange}>
                Show vessel track
            </Checkbox>
            <Radio.Group {...{
                value: time,
                disabled: loading,
                options,
                optionType: "button",
                buttonStyle: "solid",
                onChange: this.handleRadioChange
            }}/>

            {loading && <LoadingBox level='bg' className={styles.loading} loading={loading}/>}
        </div>;
    }
}

export default connect(mapStateToProps, {})(HistoryVessel);
