import React, {Component} from 'react';
import styles from "./style.module.scss";
import {Button} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import {ACTION_TYPE, SITE_TYPE} from "../../../../../../util/varibles/constants";
import {checkLimit, formatNumber} from "../../../../../../util/varibles/global";
import {IPropsChildren} from "../../../../../../util/varibles/interface";

interface IProps extends IPropsChildren {
    data: any
    editMode: any
    gridTemplateColumns: string

    handleAdd(unit: any, isInternal: boolean): void
}

class Unit extends Component<IProps> {
    render() {
        const {data, editMode, gridTemplateColumns} = this.props;
        const isInternal = data.site_type === SITE_TYPE.INTERNAL;
        return <div className={[styles.unit, styles.row].join(' ')} style={{gridTemplateColumns}}>
            {this.props.children}
            <div className='text-right'>
                <span>{isInternal && formatNumber(checkLimit(0, undefined, data.fish_amount))} </span>
            </div>
            <div className={styles['avg-weight']}>
                <span>{isInternal && formatNumber(data.avg_weight) + " gr"}</span>
            </div>
            <span className='text-right'>
                {isInternal && formatNumber(Math.round(checkLimit(0, undefined, data.total_weight) / 1000) / 1000) + " t"}
            </span>
            <div className='d-flex justify-content-end'>
                {editMode === ACTION_TYPE.CREATE && <Button {...{
                    shape: "circle",
                    type: 'text',
                    size: 'small',
                    className: 'bt-add',
                    icon: <PlusCircleOutlined style={{fontSize: '19px'}}/>,
                    disabled: isInternal && data.fish_amount === 0,
                    title: data.fish_amount === 0 ? 'No fish' : '',
                    onClick: () => this.props.handleAdd(data, isInternal)
                }}/>}
            </div>
        </div>;
    }
}

export default Unit;
