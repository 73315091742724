import React, {createRef, useEffect, useState} from 'react';
import {formatNumber} from "../../../../../util/varibles/global";
import {Checkbox, Collapse} from "antd";
import {LeftOutlined} from "@ant-design/icons";
import styles from "./style.module.scss";
import stylesContainer from "../style.module.scss";
import stylesList from '../List/style.module.scss';
import FishDisease from "../../_Component/FishDisease";
import {getPlan} from "../constants";
import {IPropsChildren} from "../../../../../util/varibles/interface";

interface IProps extends IPropsChildren {
    item: any

    onCheck(isCheck: boolean, data: any, isGroup?: boolean): void

    onDragStart(e: any, units: any): void

    onDragEnd(e: any): void
}

const GroupByUnit: React.FC<IProps> = (props) => {
    const [open, setOpen] = useState(false);
    const rootRef = createRef<HTMLDivElement>()
    const {
        site_name,
        unit_id,
        fish_amount,
        total_weight,
        checkAll,
        indeterminate,
        isActive,
        diseases
    } = props.item;
    const {onDragEnd} = props;

    useEffect(() => {
        const el = rootRef.current;
        if (el) {
            const element = el.getElementsByClassName('ant-collapse-header')[0];
            element.addEventListener('mousedown', handleMouseDown)
        }
    }, [rootRef.current]);

    const handleMouseDown = (e: any) => {
        if (!e.target.closest('.ant-checkbox')) {
            setOpen(prev => !prev)
        }
    };

    const handleChange = (checked: boolean) => {
        props.onCheck(checked, getPlan(props.item.child), false);
    }

    const handleDragStart = (e: any) => props.onDragStart(e, props.item.child);

    return <Collapse
        className={stylesContainer.wrapper}
        activeKey={open ? [1] : []}
        expandIconPosition={'end'}
        expandIcon={({isActive}) => <LeftOutlined rotate={isActive ? -90 : 0}/>}
        items={[{
            key: '1',
            ref: rootRef,
            label: <div className={styles['title-group']} data-is-active={isActive}>
                <Checkbox {...{
                    checked: checkAll,
                    indeterminate,
                    disabled: !isActive,
                    onChange: (e: any) => handleChange(e.target.checked)
                }}/>
                <span>{[site_name, unit_id].join(', ')}</span>
                <span className='text-right'>{formatNumber(fish_amount)} fish(s)</span>
                <span className='text-right'>{formatNumber(Math.floor(total_weight / 1000) / 1000)}t</span>
                <div className={styles.diseases}>
                    <FishDisease data={diseases}/>
                </div>
                <div
                    className={stylesList['drag-view']}
                    draggable={isActive}
                    onDragStart={(e) => isActive ? handleDragStart(e) : null}
                    onDragEnd={onDragEnd}
                />
            </div>,
            children: props.children
        }]}
    />
};

export default GroupByUnit;
