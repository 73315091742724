import React from 'react';
import stylesParent from "../style.module.scss";
import styles from './style.module.scss';
import {diseasedZone, FULL_MONTH_DAY_YEAR_FORMAT} from "../../../../util/varibles/constants";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import {datetime} from "../../../../util/library/datetime";
import {IDiseaseZone} from "../../../../util/varibles/interface";

interface IProps {
    data: IDiseaseZone
}

const DiseasedZone: React.FC<IProps> = ({data}) => {
    const {diseasedType, forskNavn, forskLink, forskNr, originalDate, fromDate} = data;
    const {name = '', color = '', description = ''} = diseasedZone[diseasedType] || {};
    const style: any = {'--diseased-color': color};

    return <>
        <div className={stylesParent.header}>
            <div className={styles.diseasedHeader}>
                <div className='diseased-zone' data-code={diseasedType} style={style}/>
                <div>{name}</div>
            </div>
        </div>
        <div className={styles.diseasedSub}>{forskNr}</div>
        <div className={styles.box}>
            <div className={styles['line-info']} dangerouslySetInnerHTML={{__html: description}}/>
        </div>
        <div className={styles.box}>
            <div className={[styles['line-info'], 'font-w5 font-s15 mb-10'].join(' ')}>Regulations</div>
            <div className={styles['line-info']}>{forskNavn}</div>
            <div className={[styles['line-info'], 'mt-10'].join(' ')}>
                <div className={styles['icon-line']}>
                    <Icon icon={ICON_NAME.CALENDAR}/>
                    <span>From {datetime(originalDate).format(FULL_MONTH_DAY_YEAR_FORMAT)}</span>
                </div>
            </div>
            <div className={styles['line-info']}>
                <div className={styles['icon-line']}>
                    <Icon icon={ICON_NAME.CALENDAR}/>
                    <span>Modified {datetime(fromDate).format(FULL_MONTH_DAY_YEAR_FORMAT)}</span>
                </div>
            </div>
            <div className={styles['line-info']}>
                <div className={styles['icon-line']}>
                    <Icon icon={ICON_NAME.ATTACH}/>
                    <span><a className='link' href={forskLink}>{forskNr}</a></span>
                </div>
            </div>
        </div>
    </>;
};

export default DiseasedZone;
