import React, {useCallback, useState} from 'react';
import {Checkbox, Modal, Tooltip} from "antd";
import styles from "./style.module.scss";
import {propsModal, SITE_TYPE} from "../../../../../../util/varibles/constants";
import {ISite} from '../../../../../../util/varibles/interface';
import Footer from "../../../../../../components/Popup/Component/Footer";

interface IProps {
    sites: ISite[]
    except: any
    isShow: boolean
    isEmpty?: boolean // allow empty

    onAdd(units: any): void

    onClose?(): void
}

const PopupUnit: React.FC<IProps> = (props) => {
    const {sites, except, isShow, isEmpty, onAdd, onClose} = props;
    const [visible, setVisible] = useState(isShow);
    const [selected, setSelected] = useState([]);
    const exceptIds = new Set(except.map((item: any) => item.id));

    const handleAdd = () => {
        onAdd(selected);
        handleClose()
    }

    const handleClose = useCallback(() => {
        setVisible(false);
        if (onClose)
            setTimeout(onClose, 300);
    }, [onClose]);

    const handleChange = (value: any) => {
        setSelected(value);
    }

    return <Modal
        {...propsModal}
        title={<div className={styles.header}>
            <div className={styles.title}>Add unit</div>
            <div className='bt-close' onClick={handleClose}/>
        </div>}
        open={visible}
        okButtonProps={{
            className: 'bt-primary',
            disabled: selected.length === 0
        }}
        cancelButtonProps={{
            className: 'bt-default',
        }}
        mask={false}
        okText='Add'
        onCancel={handleClose}
        onOk={handleAdd}
    >
        <Checkbox.Group
            className={[styles.checkbox, 'checkbox-fill'].join(' ')}
            value={selected}
            onChange={handleChange}
        >
            {sites.reduce((list: any, item) => {
                const {units, type} = item;
                return [...list, ...units.reduce((rs: any, unit) => {
                    if (!exceptIds.has(unit.id)) {
                        const disabled = type === SITE_TYPE.INTERNAL && unit.fish_amount === 0 && !isEmpty;
                        return [...rs, <Checkbox
                            key={unit.id}
                            disabled={disabled}
                            value={unit.id.toString()}
                        >
                            {disabled ? <Tooltip title='No fish'>
                                {unit.unit_id}
                            </Tooltip> : unit.unit_id}
                        </Checkbox>]
                    }
                    return rs
                }, [])]
            }, [])}
        </Checkbox.Group>
        <Footer
            ok={{
                click: handleAdd,
                disabled: selected.length === 0,
                text: 'Add'
            }}
            cancel={{
                click: handleClose
            }}
        />
    </Modal>;
};

export default PopupUnit;
