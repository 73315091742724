import React from 'react';
import {Checkbox} from "antd";
import {UpdateName} from "../../Body/constants";
import {TOperation} from "../../../../../../util/varibles/interface";

interface IProps {
    op: TOperation
    indexHarvest?: number

    onChange(key: UpdateName, args: any): void
}

const TravellingToSite: React.FC<IProps> = ({op,indexHarvest, onChange}) => {
    return <div>
        <Checkbox
            checked={op.is_journey_to_site || op.is_journey_to_site === undefined}
            onChange={(e: any) => onChange(UpdateName.CALCULATE, {index: indexHarvest, is_journey_to_site: e.target.checked})}
        >
            Travelling to site
        </Checkbox>
    </div>;
};

export default TravellingToSite;
