import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    KeyOfStep,
    OP_STATUS,
    OPERATION_MODE,
    OPERATION_TYPE,
    TIME_PER_STEP
} from "../../../../../../../../util/varibles/constants";
import {convertPxToTimeByStep} from "../../../../../../util";
import TimePoint from "./TimePoint";
import {getElById} from "../../../../../../../../util/varibles/global";
import {IActivityLog, IOperation} from "../../../../../../../../util/varibles/interface";
import {getTimeByOpMode} from "../../../../../../util/function_operation/constants";
import {planOpActions} from "../../../../../../reducer";
import {AppState} from "../../../../../../../../util/store/store";
import {IUpdateOp} from "../../../../../../constants";
import Duration from "./Duration";

const timeline: any = {
    [OPERATION_TYPE.HARVEST]: (data: IOperation, activityLog: IActivityLog[]) => {
        const {site_name, sites = []} = data.operation;
        const listOfSite = sites.length === 0 ? [{name: site_name}] : sites;
        let indexSite = 0;
        const listTimeOfSite = activityLog.reduce((rs: any, item: any, index) => {
            if (item.key === KeyOfStep.ARRIVE_SITE && index) {
                const {name = ''} = listOfSite[indexSite] || {};
                indexSite++;
                return [...rs, {key: index, name: 'site', label: 'Site', title: name}]
            }
            return rs
        }, []);
        const indexFactory = activityLog.findIndex((item: any) => item.key === KeyOfStep.ARRIVE_FACTORY);
        return [
            {key: 0, name: 'start', label: 'Start'},
            ...listTimeOfSite,
            {key: indexFactory, name: 'factory', label: 'Factory'},
            {key: -1, name: 'finish', label: 'Complete'}
        ]
    },
    [OPERATION_TYPE.TREATMENT]: () => [
        {key: 0, name: 'start', label: 'Start'},
        {key: -1, name: 'finish', label: 'Complete'}
    ],
    [OPERATION_TYPE.TRANSPORT]: () => [
        {key: 0, name: 'start', label: 'Start'},
        {key: 1, name: 'site', label: 'Smolt site'},
        {key: 5, name: 'site', label: 'Site'},
        {key: -1, name: 'finish', label: 'Complete'}
    ],
    [OPERATION_TYPE.EVENT]: () => [
        {key: 0, name: 'start', label: 'Start'},
        {key: -1, name: 'finish', label: 'Complete'}
    ],
}

const mapStateToProps = (state: AppState) => {
    const {operation_mode = OPERATION_MODE.PLAN} = state.login.user.tenant;
    return {
        operation_mode
    }
}

interface IProps {
    operation_mode: OPERATION_MODE
    calendarId: string
    isOwn: boolean
    startPoint: number,
    activityLog: IActivityLog[]
    data: IOperation
    left: any
    width: number
    index: number
    adjust: any

    updateActivityLog(data: IActivityLog[]): void

    updateMode(mode: any): void

    updateOps(payload: IUpdateOp): void

    setFocusId(payload: string): any
}

class TimeLine extends Component<IProps> {
    targetY = 0;
    dragState = -1;

    componentWillUnmount() {
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
    }

    handleMouseDown = (e: any, index: number) => {
        const {current_process} = this.props.data.operation;
        if (index && index <= current_process)
            return;
        const {pageY} = e;
        this.targetY = pageY;
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
        this.dragState = index;
        this.props.updateMode('time');
        this.props.setFocusId(this.props.data.operation.id);
    }

    handleMouseMove = (e: any) => {
        const newActivityLog = this.updateTime(e);
        this.props.updateActivityLog(newActivityLog);
    }

    handleMouseUp = (e: any) => {
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
        const newActivityLog: any = this.updateTime(e);
        const {data} = this.props;

        this.props.updateMode('');
        this.props.updateOps({update: {[data.operation.id]: {...data, activity_log: newActivityLog}}})
    }

    updateTime = (e: any): IActivityLog[] => {
        const {pageY} = e;
        const {activity_log} = this.props.data;
        const {current_process} = this.props.data.operation;
        const space = pageY - this.targetY;
        if (!space)
            return activity_log;

        const durationMax = (activity_log[this.dragState].est_start_time - activity_log[0].est_start_time) / 2;
        const duration = convertPxToTimeByStep(pageY - this.targetY);
        const max = activity_log.length - 1;

        if (this.dragState === 0) {
            return activity_log.map((item: any, i) => ({
                ...item,
                est_start_time: (duration < 0 && max === i) ? item.est_start_time : item.est_start_time + duration
            }));
        }

        let rest = Math.max(duration, -durationMax), adjustment: any = {};
        for (let i = activity_log.length - 1; i > 0; i--) {
            if (i <= current_process) {
                break;
            } else if (i > this.dragState) {
                adjustment[i] = rest;
            } else {
                const time = activity_log[i].est_start_time - activity_log[i - 1].est_start_time;
                const limit = -time / 2;
                adjustment[i] = Math.max(time + limit, time + rest);
                rest -= limit
                if (rest >= 0)
                    break;
            }
        }

        return activity_log.reduce((rs: any, item: any, i: number) => {
            if (!adjustment[i])
                return rs;
            const {est_start_time: prev} = rs[i - 1];
            if (i > this.dragState) {
                const {est_start_time: current} = rs[i];
                rs[i] = {...item, est_start_time: Math.max(prev + TIME_PER_STEP, current + adjustment[i])};
            } else {
                rs[i] = {...item, est_start_time: prev + adjustment[i]};
            }
            return rs;
        }, JSON.parse(JSON.stringify(activity_log)));
    }

    render() {
        const {startPoint, activityLog, width, index, left, isOwn, operation_mode, adjust} = this.props;
        const {operation_type, id, status, current_process = -1} = this.props.data.operation;
        const el = getElById(this.props.calendarId);
        const lastIndex = activityLog.length - 1;
        if (!el)
            return null
        const {scrollLeft}: any = el;
        const style: any = {left: scrollLeft + 'px'};

        if (left) {
            style.width = `${left + 110}px`;
        } else {
            style.transition = 'width .2s';
            style.width = adjust.leftTimeLine ? adjust.leftTimeLine : `calc(${width * index}% + ${120 - scrollLeft}px)`;
        }

        const generateData = timeline[operation_type];
        const list = generateData ? generateData(this.props.data, activityLog) : [];
        const isEdit = isOwn && status !== OP_STATUS.FINISHED;

        return <>
            {list.map((item: any) => {
                const {key} = item
                const index = key !== -1 ? key : lastIndex;
                const time = getTimeByOpMode[operation_mode](activityLog[index]);
                const isAllow = isEdit && (index > current_process || index === 0)
                return <TimePoint key={key} {...{
                    id,
                    data: item,
                    isEdit: isAllow,
                    style,
                    startPoint,
                    index,
                    time,
                    handleMouseDown: isAllow ? this.handleMouseDown : () => null
                }}/>
            })}
            <Duration
                startPoint={startPoint}
                style={style}
                start={getTimeByOpMode[operation_mode](activityLog[0])}
                finish={getTimeByOpMode[operation_mode](activityLog[lastIndex])}
            />
        </>
    }
}

export default connect(mapStateToProps, {
    updateOps: planOpActions.updateOps,
    setFocusId: planOpActions.setFocusId
})(TimeLine);
