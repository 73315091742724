import React, {Component} from 'react';
import styles from "./style.module.scss";
import {ACTION_TYPE} from "../../../../../../../util/varibles/constants";
import {notify, NotifyCode} from "../../../../../../../util/varibles/message";
import {checkLimit, formatNumber} from "../../../../../../../util/varibles/global";
import DeliveryTypes from "../DeliveryTypes";
import Operation from "./Operation";
import {UpdateName} from "../../constants";
import {getStorages} from "../../../../../util/function_operation/constants";
import {planValidation, VALIDATION, VALIDATION_STATUS} from "../../../../../util/validation";
import OpName from "./OpName";
import {IFactory, IRoute, IVessel, TOperation} from "../../../../../../../util/varibles/interface";
import OpInfo from "./OpInfo";
import MortalityRisk from "../../../_Component/MortalityRisk";
import Factory from "../../../_Component/Factory";
import OpTotal from "./OpTotal";

interface IProps {
    operations: any
    store: any
    vessel: IVessel
    factories: IFactory[]
    routes: IRoute[]
    editMode: ACTION_TYPE

    updateState(state: any): void

    onUpdate(key: UpdateName, args: any): void
}

class Operations extends Component<IProps> {

    handleDelete = ({...args}) => {
        const {indexHarvest, indexUnit} = args;
        const {operations, store} = this.props;
        const {sub_operations} = operations[indexHarvest];
        const {id, harvest_id = '-', fish_amount, total_weight} = operations[indexHarvest].sub_operations[indexUnit];
        const keyStore = id + '|' + harvest_id;
        store[keyStore] = {
            ...store[keyStore],
            fish_amount: store[keyStore].fish_amount + Number(fish_amount),
            total_weight: store[keyStore].total_weight + Number(total_weight)
        }
        if (operations.length === 1 && sub_operations.length === 1) {
            this.props.updateState({operations: [], store});
            return;
        } else if (sub_operations.length === 1) {
            operations.splice(indexHarvest, 1);
        } else {
            operations[indexHarvest].sub_operations.splice(indexUnit, 1);
            operations[indexHarvest] = {
                ...operations[indexHarvest],
                ...sub_operations.reduce((list: any, item: any) => {
                    const {fish_amount, total_weight} = item;
                    list.fish_amount += fish_amount;
                    list.total_weight += total_weight;
                    return list;
                }, {fish_amount: 0, total_weight: 0}),
            }
        }
        this.props.updateState({operations, store});
    }

    handleMerge = (indexHarvestTarget: number, indexHarvestCurrent: number, harvestCurrent: any) => {
        if (indexHarvestTarget === indexHarvestCurrent) return;
        const {operations, vessel} = this.props;
        const harvestTarget = {...operations[indexHarvestTarget]};
        const totalWeight = harvestTarget.total_weight + harvestCurrent.total_weight;


        if (totalWeight > vessel.capacity) {
            notify.error(NotifyCode.E1)();
            return;
        }

        const {sub_operations, sites = []} = harvestCurrent;
        const {factory_id, factory_name, error_detail = {}} = harvestTarget;

        const sitesNew = sites.reduce((list: any, item: any) => {
            const {id} = item;
            const isExist = list.some((sub: any) => sub.id === id);
            if (!isExist) {
                const {routes} = this.props;
                const {status} = planValidation[VALIDATION.ROUTE]({
                    source: item,
                    destination: {id: factory_id, name: factory_name}
                }, {routes});
                return [...list, {...item, isRoute: status !== VALIDATION_STATUS.ERROR}]
            }
            return list;
        }, harvestTarget.sites || []);

        const isRoute = sitesNew.some((item: any) => item.isRoute);

        if (!isRoute)
            error_detail.route = sitesNew.map((item: any) => ({
                source_id: item.id,
                destination_id: factory_id,
                message: notify[NotifyCode.E20]([item.name, factory_name])
            }))
        else if (error_detail.route) {
            delete error_detail.route;
        }
        const subOperationsNew = sub_operations.reduce((list: any, itemNew: any) => {
            const index = list.findIndex((itemOld: any) => itemOld.id === itemNew.id && itemOld.harvest_id === itemNew.harvest_id);
            if (index !== -1) {
                const {fish_amount, total_weight, delivery_types} = itemNew;
                list[index].fish_amount += fish_amount;
                list[index].total_weight += total_weight;
                list[index].delivery_types = delivery_types.reduce((subList: any, subItem: any) => {
                    const {id, fish_amount: subFishAmount, total_weight: subTotalWeight} = subItem;
                    const indexSub = subList.findIndex((deliveryType: any) => deliveryType.id === id);
                    if (indexSub !== -1) {
                        subList[indexSub].fish_amount += subFishAmount;
                        subList[indexSub].total_weight += subTotalWeight;
                    } else {
                        subList.push(subItem);
                    }
                    return subList
                }, list[index].delivery_types)
            } else {
                list.push(itemNew);
            }
            return list;
        }, [...harvestTarget.sub_operations]);

        const operation = {
            ...harvestTarget,
            sites: sitesNew,
            sub_operations: subOperationsNew,
            total_amount: harvestTarget.total_amount + harvestCurrent.total_amount,
            total_weight: totalWeight,
            error_detail
        };

        const storages = getStorages({fish_amount: operation.total_amount, total_weight: totalWeight}, vessel);
        if (storages.length > 0) {
            operation.sub_operations = subOperationsNew.map((item: any) => ({...item, storages}))
        }

        Object.assign(operations[indexHarvestTarget], operation)
        operations.splice(indexHarvestCurrent, 1);
        this.props.updateState({operations})
    }

    render() {
        const {operations, editMode, onUpdate} = this.props;
        return <div className={styles.operations}>
            <div className={styles.body}>
                {operations.map((harvest: TOperation, indexHarvest: number) =>
                    <div className={styles.containerOp} key={indexHarvest}>
                        <OpName indexHarvest={indexHarvest} harvest={harvest} onUpdate={onUpdate}/>
                        <OpInfo/>
                        <Operation key={indexHarvest} {...{
                            harvest,
                            indexHarvest,
                            handleMerge: this.handleMerge
                        }}>
                            {harvest.sub_operations.map((unit: any, indexUnit: number) => <div
                                key={unit.id + unit.harvest_id} className={styles.line}
                                data-lev='sub'
                            >
                                <div data-lev='info'>{[unit.site_name, unit.unit_id].join(', ')} </div>
                                <div className='text-truncate' data-lev='info' title={unit.harvest_id}>
                                    {unit.harvest_id}
                                </div>
                                <div className='justify-end pr-16' data-lev='info'>
                                    {unit.avg_weight < 0 ? '-' : formatNumber(unit.fish_amount)}
                                </div>
                                <div className='justify-end pr-16' data-lev='info'>
                                    {formatNumber(Math.round(unit.total_weight / 1000) / 1000)} t
                                </div>
                                <MortalityRisk
                                    value={unit.mortality_risk}
                                    onChange={value => this.props.onUpdate(UpdateName.MORTALITY_RISK, {
                                        indexHarvest,
                                        indexUnit,
                                        value
                                    })}
                                />
                                {indexUnit === 0 ? <Factory
                                    value={harvest.factory_id}
                                    onChange={value => this.props.onUpdate(UpdateName.FACTORY, {indexHarvest, value})}
                                /> : <div/>}
                                <DeliveryTypes {...{
                                    options: harvest.delivery_types,
                                    delivery_types: unit.delivery_types,
                                    avg_weight: checkLimit(1, undefined, unit.avg_weight),
                                    editMode,
                                    isInternal: unit.avg_weight !== -1,
                                    handleUpdate: ({...args}) => this.props.onUpdate(UpdateName.DELIVERY_TYPES, {
                                        ...args,
                                        indexHarvest,
                                        indexUnit
                                    }),
                                    handleDeleteOperation: () => this.handleDelete({indexHarvest, indexUnit}),
                                }} />
                            </div>)}
                            <OpTotal indexHarvest={indexHarvest} op={harvest} onUpdate={onUpdate}/>
                        </Operation>
                    </div>)}
            </div>
            {operations.length === 0 && <div className={styles.line} data-lev='empty'>No trip</div>}
        </div>;
    }
}

export default Operations;
