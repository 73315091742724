import React from 'react';
import Back from "./Back";
import CurrentProcess from "./CurrentProcess";
import NextStep from "./NextStep";
import {ACTION} from "../../../../util/varibles/permission";
import {OP_STATUS} from "../../../../util/varibles/constants";
import {Button, Result} from "antd";
import {IOperation} from '../../../../util/varibles/interface';
import {useDispatch} from "react-redux";
import styles from './style.module.scss';
import Time from "../Time";
import {loginActions} from "../../../../pages/LoginPage/reducer";

interface IProps {
    data: IOperation
    actions: any
    step: number

    changeStep(step: number): void

    onUpdate?(args: any): void
}

const StepControl: React.FC<IProps> = (props) => {
    const {data, actions, step, changeStep, onUpdate,} = props;
    const dispatch = useDispatch();

    if (!actions[ACTION.VESSEL_SIDE.UPDATE])
        return null;

    const {status, current_process} = data.operation;
    if (status === OP_STATUS.FINISHED)
        return <Result
            status="success"
            title={<div className='font-s16 font-w4'>Operation is completed</div>}
            extra={<div className='d-flex justify-content-center'>
                <Button className='bt-primary' onClick={() => dispatch(loginActions.getOpsOfVesselRequest())}>
                    Continue
                </Button>
            </div>}
        />

    return <div className={styles.control}>
        <div className={styles.section1}>
            {status === OP_STATUS.PROCESSING && <Time
                data={data}
                current_process={current_process}
                step={step}
                onUpdate={onUpdate}
            />}
            <NextStep data={data} step={step} changeStep={changeStep} onUpdate={onUpdate}/>
        </div>
        <div className={styles.section2}>
            <Back step={step} changeStep={changeStep}/>
            <CurrentProcess data={data} step={step} changeStep={changeStep}/>
        </div>
    </div>;
};


export default StepControl;

