import React, {createRef, useEffect} from 'react';
import styles from './style.module.scss';
import {OPERATION_TYPE, operationType} from "../../../../../util/varibles/constants";
import {Select} from "antd";
import {removeClassName} from "../../../../../util/varibles/global";
import Icon, {ICON_NAME} from "../../../../../components/Icon";
import {IVessel} from "../../../../../util/varibles/interface";

interface IProps {
    title: any
    icon: ICON_NAME
    vessel?: IVessel
    isDrag?: boolean
    isAllowChange?: boolean
    operationType: OPERATION_TYPE
    limitOperationTypes: OPERATION_TYPE[]

    changeOperationType(operationType: OPERATION_TYPE): void

    onClose(): void
}

const {Option} = Select;
let elementModal: any, relX = 0, relY = 0, targetX = 0, targetY = 0;

const Header: React.FC<IProps> = (props) => {
    const rootRef = createRef<HTMLDivElement>()
    const {
        icon,
        title,
        vessel,
        isDrag = true,
        isAllowChange = false,
        operationType: type,
        changeOperationType,
        limitOperationTypes
    } = props;

    useEffect(() => {
        // const el = rootRef.current;
        // if (el) {
        //     if (isDrag)
        //         el.addEventListener('mousedown', this.handleMouseDown)
        // const popupEl = getElById('popup-operation');
        // elementModal = elParent.getElementsByClassName('ant-modal')[0];
        // const {offsetLeft: leftParent, offsetTop: topParent} = elementModal;
        // targetX = leftParent;
        // targetY = topParent;
        // }
    }, [rootRef.current]);
    const handleMouseDown = (e: any) => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        // const {pageX, pageY} = e;
        // const elParent = props.parentElement();
        // const {offsetLeft, offsetTop} = elParent.getElementsByClassName('ant-modal')[0];
        // relX = pageX - (offsetLeft - targetX);
        // relY = pageY - (offsetTop - targetY);
        // addClassName(elementModal);
    }

    const handleMouseMove = (e: any) => {
        const {pageX, pageY} = e;
        // const targetLeft = checkLimit(limitWidth[0], limitWidth[1], pageX - relX);
        // const targetTop = checkLimit(limitHeight[0], limitHeight[1], pageY - relY);
        // elementModal.style.left = targetLeft + 'px';
        // elementModal.style.top = targetTop + 'px';
    }

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        removeClassName(elementModal);
    }

    return <div ref={rootRef} {...{className: styles.header, 'data-is-drag': isDrag}}>
        <div className={styles.title}>
            <div className={styles.name}>
                <Icon icon={icon} size='large'/>
                <span>{title}</span>
            </div>
            {(isAllowChange && vessel) && <Select {...{
                className: styles.selectOpType,
                popupClassName: styles.operationTypes,
                value: type,
                onChange: changeOperationType,
                popupMatchSelectWidth: false,
            }}>
                {limitOperationTypes.reduce((list: any, item: number) => {
                    return [...list, <Option key={operationType[item].id} value={operationType[item].id}>
                        {operationType[item].name}
                    </Option>]
                }, [])}
            </Select>}
        </div>
        <div className='bt-close' onClick={props.onClose}/>
    </div>;
};

export default Header;