import React, {useCallback, useEffect, useRef, useState} from 'react';
import Menu from "../../../../../../../components/Menu";
import {KeyOfTask, TASK_TYPE, TREATMENT_TYPE} from "../../../../../../../util/varibles/constants";
import {Checkbox} from "antd";
import Icon, {ICON_NAME} from "../../../../../../../components/Icon";
import {IKeyUpdate} from "../constants";
import styles from './style.module.scss';
import PopupDuplicate from "../../../_Component/Duplicate/PopupDuplicate";
import {UpdateName} from "../../constants";
import {openPopup} from "../../../../../../../components/Popup/Component/WrapperPopup";
import {ISite} from "../../../../../../../util/varibles/interface";

let isClick = false;
let time = 0;
const initMenu = {open: false, placement: ''}

interface IProps {
    site: ISite
    data: any

    onDelete?(): void

    onChange(list: IKeyUpdate[]): void

    onChangeGlobal(key: UpdateName, args: any): void
}

const Setting: React.FC<IProps> = (props) => {
    const {data, site, onChange, onChangeGlobal, onDelete} = props;
    const buttonRef = useRef<HTMLDivElement>(null);
    const [menu, setMenu] = useState(initMenu);
    const [items, setItems] = useState<any[]>([])

    const handleCheck = useCallback((value: any) => {
        const {checked} = value.target;
        onChange([{
            key: KeyOfTask.IS_SUPPORT_VESSEL,
            value: checked
        }]);
    }, [onChange]);


    useEffect(() => {
        const {is_support_vessel, type, treatment_type = null} = data;
        let isAllowSupport = type === TASK_TYPE.HARVEST || TASK_TYPE.COMBINE
            || (type === TASK_TYPE.TREATMENT && treatment_type !== null && treatment_type !== TREATMENT_TYPE.SORTING);

        setItems([
            ...(isAllowSupport ? [{
                key: 'support',
                disabled: true,
                label: <Checkbox checked={is_support_vessel} onChange={handleCheck}>
                    <span className='ml-5'>Need support vessel</span>
                </Checkbox>
            }] : []),
            {
                key: 'duplicate',
                label: <div className='menu-line' onClick={() => {
                    setMenu({...menu, open: false});
                    const {sub_operation} = data;
                    const modal = openPopup(<PopupDuplicate
                        isShow={true}
                        sites={[site]}
                        unit={sub_operation}
                        onAdd={(units: any) => onChangeGlobal(UpdateName.DUPLICATE, {units, task: data})}
                        onClose={() => modal.remove()}
                    />);
                }}>
                    <Icon icon={ICON_NAME.DUPLICATE}/>
                    <span>Replicate to other unit</span>
                </div>
            },
            {
                key: 'delete',
                label: <div className='menu-line' data-danger='true' onClick={onDelete}>
                    <Icon icon={ICON_NAME.DELETE} danger/>
                    <span>Delete task</span>
                </div>
            }
        ])
    }, [data, site, onChangeGlobal, onDelete]);

    const handleMouseDown = useCallback((e: any) => {
        setMenu({...menu, placement: 'bottomRight'});
        if (buttonRef.current) {
            isClick = true;
            const {x, y} = e.target.getBoundingClientRect();
            const event = new MouseEvent("contextmenu", {
                bubbles: true,
                cancelable: false,
                view: window,
                button: 2,
                buttons: 0,
                clientX: x + 28,
                clientY: y + 28
            });
            buttonRef.current.dispatchEvent(event);
        }
    }, [buttonRef]);


    const onOpenChange = useCallback((value: boolean) => {
        const state = {open: value, placement: ''};
        if (isClick) {
            state.placement = 'bottomRight'
            isClick = false;
        } else if ((Date.now() - time) < 5) {
            return;
        }
        setMenu(state);
    }, [])

    return <Menu
        visible={menu.open}
        placement={menu.placement}
        onVisibleChange={onOpenChange}
        items={items}
        trigger={['contextMenu']}
    >
        <div ref={buttonRef} className={styles.button}>
            <div className='three-dot' onMouseDown={handleMouseDown} onMouseUp={() => time = Date.now()}/>
        </div>
    </Menu>;
};

export default Setting;
